import { computed, watchEffect } from 'vue';

export const TIMEOUT_IN_MS = 5000;
/**
 * Awaits the conditions you provide
 * @param computedCallback A reactive callback that returns a boolean
 * @returns suspense - A promise that resolves when the callback resolves to true
 */
export const useSuspense = (computedCallback: () => boolean) => {
  const isReady = computed(computedCallback);

  const suspense = async () => {
    if (isReady.value) return null;
    return new Promise<null | Error>((resolve) => {
      const timeout = setTimeout(() => {
        throw new Error('useSuspense timed out');
      }, TIMEOUT_IN_MS);
      watchEffect(() => {
        if (isReady.value) {
          clearTimeout(timeout);
          resolve(null);
        }
      });
    });
  };
  return { suspense };
};
