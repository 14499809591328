import { Store, Plugin } from 'vuex';
import progress from './modules/progress';
import user from './modules/user';
import lessons from './modules/lessons';
import cloneDeep from 'lodash.clonedeep';
import { VuexPersistence } from 'vuex-persist';
import { VUEX_PERSISTENCE_KEY } from '../lib/constants';

export type StoreType = Store<Record<string, never>>;

export const storeConfig = {
  state() {
    // Return as object to avoid:
    // [vuex] state field "foo" was overridden by a module with the same name at "foo"
    return {};
  },

  getters: {},

  actions: {},

  mutations: {},

  modules: {
    progress,
    user,
    lessons,
  },
  plugins: [] as Plugin<Record<string, never>>[],
};

export let vuexLocalPersistence: VuexPersistence<any> | null = null;

if (typeof window !== 'undefined') {
  vuexLocalPersistence = new VuexPersistence({
    key: VUEX_PERSISTENCE_KEY,
    storage: window.localStorage,
    reducer: (state: any) => ({
      progress: {
        lessonsProgress: state.progress.lessonsProgress,
        isLoadingLessonProgress: state.progress.isLoadingLessonProgress,
      },
      user: {
        userProjects: state.user.userProjects,
      },
      lessons: {},
    }),
  });

  storeConfig.plugins.push(vuexLocalPersistence.plugin);
}

// Initialize Vuex store.
export default new Store(storeConfig);

export function getClonedStore(): StoreType {
  return new Store(cloneDeep(storeConfig));
}
