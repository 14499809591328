<template>
  <div id="catalog" class="catalog">
    <VueHead>
      <title>{{ metaTags.title }}</title>
      <meta key="og:title" property="og:title" :content="metaTags.title" />
      <meta
        key="description"
        property="description"
        :content="metaTags.description"
      />
      <meta
        key="og:description"
        property="og:description"
        :content="metaTags.description"
      />
    </VueHead>
    <div class="catalog__header">
      <div class="catalog__breadcrumbs">
        <Breadcrumbs :breadcrumbs="breadcrumbs" />
      </div>
    </div>
    <h1 class="catalog__title" data-snyk-test="Catalog: title">
      {{ pageTitle }}
    </h1>
    <TheSidebarFilters
      :available-filters="availableFiltersEnhanced"
      :selected-filters="selectedFilters"
      :search-query="searchQuery"
      @update:search-query="(newValue) => (searchQuery = newValue)"
      @update:selected-filters="handleFiltersUpdate"
    >
      <InfiniteScroller
        class="catalog__cards-grid"
        data-snyk-test="Catalog: cards grid"
        @on-reach-bottom="infiniteScrollEventHandler"
      >
        <CatalogCard
          v-for="card of visibleCards"
          :key="card.title"
          :education-resource-id="card.educationResourceId"
          :format="card.format"
          :img="card.img"
          :title="card.title"
          :description="card.description"
          :completed-percentage="card.completedPercentage"
          :is-completed="card.isCompleted"
          :route-location="card.routeLocation"
          :education-content-category="card.educationContentCategory"
          :category="card.category"
          :badge-text="card.badgeText"
          :selected-filters="selectedFilters"
          data-snyk-test="Catalog: card"
        />
        <NoResults
          v-if="visibleCards.length === 0"
          data-snyk-test="Catalog: no results"
        />
      </InfiniteScroller>
    </TheSidebarFilters>
  </div>
</template>

<script setup lang="ts">
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs.vue';
import type { Breadcrumbs as IBreadCrumbs } from '../../components/Breadcrumbs/breadcrumbs';
import TheSidebarFilters from '@patchui/productcl/components/TheSidebarFilters/TheSidebarFilters.vue';
import { useRoute } from 'vue-router';
import { getAvailableFilters, baseFilters } from './filters';
import { ComputedRef, computed, onServerPrefetch, watch, onMounted } from 'vue';
import { useLoggedIn } from '../../hooks/useLoggedIn';
import CatalogCard, {
  ICatalogCardProps,
} from './components/CatalogCard/CatalogCard.vue';
import { useListEcosystems, useListTopics } from '../../hooks/lessonsHooks';
import { useSuspense } from '../../hooks/useSuspense';
import { LessonEcosystem } from '../../lib/lessonMetadata';
import { langMapper } from '../../lib/utils/mappers';
import { useMetaTags } from './useMetaTags';
import NoResults from './components/NoResults/NoResults.vue';
import InfiniteScroller from '../../components/InfiniteScroller/InfiniteScroller.vue';
import { useInfiniteScroll } from '../../hooks/useInfiniteScroll';
import { EducationResource } from '../../api/catalog';
import { listEcosystems, updateFilterWithCounter } from './countRules';
import useCatalogQueryParams from './useCatalogQueryParams';
import { getPathLastSegment } from '../../lib/utils/routeHelper';
import { components } from '../../api/learn-backend-hidden-schema';
import { useEducationProgress } from '../../api/educationProgress';
import { useCatalogStructuredData } from './useCatalogStructuredData/useCatalogStructuredData';
import { Head as VueHead } from '@vueuse/head';

const route = useRoute();

interface MetaTags {
  title: string;
  description: string;
}
interface ICatalogProps {
  category?: LessonEcosystem;
}
const props = defineProps<ICatalogProps>();

const SEOTitle: ComputedRef<string | null> = computed(() => {
  return props.category ? langMapper[props.category as LessonEcosystem] : null;
});

const pageTitle = computed(() => {
  if (SEOTitle.value) {
    return 'Category ' + SEOTitle.value;
  }
  if (route.name === 'CatalogPageByTopic') {
    if (route.path.includes('product-training')) return 'Product training';
    return 'Security education';
  }
  return 'All Topics';
});

const breadcrumbs = [
  [
    {
      icon: 'school-outline',
      label: 'Snyk Learn',
      url: '/',
      isActive: true,
    },
  ],
  [
    {
      label: 'All Topics',
      url: '/catalog',
      isActive: true,
    },
  ],
] as IBreadCrumbs;

const isLoggedIn = useLoggedIn();
const availableFilters = computed(() => {
  return getAvailableFilters(ecosystems.value, topics.value, isLoggedIn.value);
});

const { topics } = useListTopics();

const {
  searchQuery,
  handleFiltersUpdate,
  typeQueryParam,
  updateTypeQueryParam,
  filteredEducationResources,
  categoriesQueryParam,
  updateCategoriesQueryParam,
  selectedFilters,
} = useCatalogQueryParams({ topics });

const { data: educationProgress } = useEducationProgress();

// returns filtered cards based on the query params in the url
const catalogCards = computed<ICatalogCardProps[]>(() => {
  let lessonsList = filteredEducationResources.value.map<ICatalogCardProps>(
    (educationResource: EducationResource) => {
      // Default values
      let isCompleted = false;
      let completedPercentage = 0;

      // If education progress is available
      // And item for the education resource exists
      if (educationProgress.value) {
        const educationProgressItem = educationProgress.value.find(
          (educationProgress: components['schemas']['EducationProgress']) =>
            educationProgress.relationships['education_resource']['data'][
              'id'
            ] === educationResource.id,
        );
        if (educationProgressItem) {
          const {
            attributes: { status, completion_percentage },
          } = educationProgressItem;

          completedPercentage = completion_percentage;

          if (status === 'completed') {
            isCompleted = true;
          }
        }
      }

      return {
        educationResourceId: educationResource.id,
        educationContentCategory:
          educationResource.attributes.education_content_category,
        format: educationResource.type.toUpperCase().replaceAll('_', ' '), // learning_path > Learning path
        img: educationResource.attributes.image,
        title: educationResource.attributes.name,
        description: educationResource.attributes.description,
        completedPercentage: completedPercentage,
        isCompleted: isCompleted,
        routeLocation:
          educationResource.type === 'lesson'
            ? `/lesson/${educationResource.attributes.slug}/`
            : `/learning-paths/${educationResource.attributes.slug}/`,
        category: listEcosystems(educationResource)[0] as string,
        badgeText: null, //TODO: add badge text
      } as ICatalogCardProps;
    },
  );
  return lessonsList;
});

const { ecosystems } = useListEcosystems();

if (route.path.includes('security-education')) {
  if (typeQueryParam.value === null) {
    typeQueryParam.value = ['security-education'];
  }
  if (categoriesQueryParam.value === null) {
    const lastSegment = getPathLastSegment(route.path);
    if (lastSegment !== 'security-education') {
      categoriesQueryParam.value = [lastSegment];
    }
  }
} else if (route.path.includes('product-training')) {
  if (typeQueryParam.value === null) {
    typeQueryParam.value = ['product-training'];
  }
}

const { suspense } = useSuspense(() => {
  if (route.path.includes('security-education')) {
    const lastSegment = getPathLastSegment(route.path);
    let categoriesSelectedFilter =
      lastSegment !== 'security-education'
        ? selectedFilters.value['Categories'].includes(lastSegment)
        : true;
    return (
      ecosystems.value.length > 0 &&
      selectedFilters.value['Type'].includes('security-education') &&
      visibleCards.value.length > 0 &&
      categoriesSelectedFilter
    );
  } else if (route.path.includes('product-training')) {
    return (
      topics.value.length > 0 &&
      selectedFilters.value['Type'].includes('product-training') &&
      visibleCards.value.length > 0
    );
  }

  return (
    visibleCards.value.length > 0 &&
    ecosystems.value.length > 0 &&
    topics.value.length > 0
  );
});

onServerPrefetch(suspense);

const availableFiltersEnhanced = computed(() => {
  // Create copy of available filters to modify
  const availableFiltersCopy = Object.assign({}, availableFilters.value);
  // Hide filter groups that are in full-exclusion relationship
  if (selectedFilters.value['Type'].includes('product-training')) {
    availableFiltersCopy['Categories'].hidden = true;
    availableFiltersCopy['Topics'].hidden = false;
  } else if (selectedFilters.value['Type'].includes('security-education')) {
    availableFiltersCopy['Categories'].hidden = false;
    availableFiltersCopy['Topics'].hidden = true;
  } else {
    availableFiltersCopy['Categories'].hidden = false;
    availableFiltersCopy['Topics'].hidden = false;
  }

  // Add count numbers to filter group
  const filterGroupNames = Object.keys(baseFilters);
  const updatedFilterGroups = filterGroupNames.map((filterGroupName) =>
    updateFilterWithCounter(filterGroupName, filteredEducationResources.value),
  );
  if (updatedFilterGroups) {
    updatedFilterGroups.forEach((filterGroup, i) => {
      if (!filterGroup) return;
      availableFiltersCopy[filterGroupNames[i]] = filterGroup;
    });
  }

  return availableFiltersCopy;
});

onMounted(async () => {
  if (route.path.includes('security-education')) {
    const lastSegment = getPathLastSegment(route.path);
    if (lastSegment !== 'security-education') {
      await updateTypeQueryParam(['security-education']);
      await updateCategoriesQueryParam([lastSegment]);
    } else {
      await updateTypeQueryParam(['security-education']);
    }
  } else if (route.path.includes('product-training')) {
    await updateTypeQueryParam(['product-training']);
  }
});

const metaTags: ComputedRef<MetaTags> = useMetaTags(
  catalogCards.value,
  ecosystems.value,
  props.category,
  typeQueryParam,
  route,
);

const INFINITE_SCROLL_CARDS_INCREMENT = 6;
const {
  visibleItems: visibleCards,
  resetCountOfVisibleItems: resetCountOfVisibleCards,
  infiniteScrollEventHandler,
} = useInfiniteScroll(catalogCards, INFINITE_SCROLL_CARDS_INCREMENT);

useCatalogStructuredData(filteredEducationResources);
// Reset the count of visible cards when the filters change
watch(catalogCards, resetCountOfVisibleCards);
</script>

<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';

@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@mixin use-md-icon($icon) {
  font-family: 'Material Icons', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 20px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';

  &:before {
    content: $icon;
  }
}

.catalog {
  display: block;
  max-width: $pageMaxWidth;

  margin: 0 auto rem(60px);
  padding: space(m) $mobileContainerPadding space(m) $mobileContainerPadding;

  @include media-query(small) {
    padding: 40px $containerPadding 40px $containerPadding;
    margin: 0 auto rem(120px);
  }

  &__title {
    font-weight: 700;
    font-size: rem(48px);
    margin-top: 22px;
    margin-bottom: token('space.xxl');
    color: token('color.brand.midnight');

    @include media-query(small) {
      margin-top: 40px;
    }
    @include media-query(large) {
      margin-top: 34px;
    }
  }

  &__cards-grid {
    display: grid;
    margin-top: space(m);
    gap: token('space.l');
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    @include media-query(medium) {
      grid-template-columns: 1fr 1fr;
    }
    @include media-query(large) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}

.sidebar-filters {
  $self: &;
  &:deep(*) {
    .sidebar-filters__reset {
      display: none;
    }
    .icon--arrow-right {
      color: token('color.brand.electric-blue');
    }
    .icon--filter {
      display: none;
    }
    .sidebar-filters__toggle {
      @include use-md-icon('sort');
    }
    .sidebar-filters-checkboxes {
      padding-left: 0;
    }
    .expandable__signifier {
      min-width: 18px;
      width: 18px;
      margin-right: 6px;
    }
    .sidebar-filters__filter-group__heading {
      @include typography('typography.brand.subhead-small');
      color: token('color.brand.midnight');
      text-transform: inherit;
      letter-spacing: -1px;
    }
  }
}
</style>
