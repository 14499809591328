<template>
  <BaseButton
    class="learnButton"
    :class="isFullWidth ? 'learnButton--full-width' : ''"
    v-bind="props"
  >
    <slot></slot>
  </BaseButton>
</template>

<script lang="ts" setup>
import BaseButton from '@patchui/productcl/components/BaseButton/BaseButton.vue';

interface IButtonProps {
  wrapper?: 'button' | 'a';
  variant?: 'default' | 'primary' | 'danger' | 'link';
  size?: 'small' | 'medium';
  state?: 'hover' | 'focus';
  /**
   * Do you want the button to fill 100% of it's parent or just scale with slot content
   */
  isFullWidth?: boolean;
}

const props = withDefaults(defineProps<IButtonProps>(), {
  size: 'medium',
});
</script>
<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';
.learnButton {
  &.button--medium {
    height: token('space.layout.small');
    padding: 0 token('space.l');
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  &--full-width {
    width: 100%;
  }
}
</style>
