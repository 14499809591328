export enum LearnEnvironment {
  Development = 0,
  Production = 1,
}

export function getEnvironment(): LearnEnvironment {
  if (
    location.hostname === 'production.education-eit.pages.dev' ||
    location.hostname === 'learn-web.snyk-internal.net' ||
    location.hostname === 'education-eit.pages.dev' ||
    location.hostname === 'learn.snyk.io'
  ) {
    return LearnEnvironment.Production;
  }

  return LearnEnvironment.Development;
}

export const isValidDomain = (domain: string): boolean => {
  // process.env is not provided in the browser, so we can't iterate over it
  // https://github.com/ElMassimo/vite-plugin-environment/issues/11
  return (
    domain === process.env.VUE_APP_SNYK_APP_US_GCP ||
    domain === process.env.VUE_APP_API_DOMAIN_US_GCP ||
    domain === process.env.VUE_APP_SNYK_APP_EU_AWS ||
    domain === process.env.VUE_APP_API_DOMAIN_EU_AWS ||
    domain === process.env.VUE_APP_SNYK_APP_US_AWS ||
    domain === process.env.VUE_APP_API_DOMAIN_US_AWS ||
    domain === process.env.VUE_APP_SNYK_APP_AU_AWS ||
    domain === process.env.VUE_APP_API_DOMAIN_AU_AWS
  );
};

export interface PolarisEnvironmentConfig {
  name: string;
  appDomain: string;
  apiDomain: string;
  label: string;
  emoji: string;
}

export const DEFAULT_POLARIS_ENVIRONMENT = {
  name: 'default',
  apiDomain: process.env.VUE_APP_API_DOMAIN_US_GCP as string,
  appDomain: process.env.VUE_APP_SNYK_APP_US_GCP as string,
  label: 'Snyk (recommended)',
  emoji: '🌍',
};
const POSSIBLE_OTHER_POLARIS_ENVIRONMENTS = [
  {
    name: 'eu',
    apiDomain: process.env.VUE_APP_API_DOMAIN_EU_AWS as string,
    appDomain: process.env.VUE_APP_SNYK_APP_EU_AWS as string,
    label: 'Snyk EU',
    emoji: '🇪🇺',
  },
  {
    name: 'aus',
    apiDomain: process.env.VUE_APP_API_DOMAIN_AU_AWS as string,
    appDomain: process.env.VUE_APP_SNYK_APP_AU_AWS as string,
    label: 'Snyk AUS',
    emoji: '🇦🇺',
  },
  {
    name: 'us-aws',
    apiDomain: process.env.VUE_APP_API_DOMAIN_US_AWS as string,
    appDomain: process.env.VUE_APP_SNYK_APP_US_AWS as string,
    label: 'Snyk US',
    emoji: '🇺🇸',
  },
];

export const getOtherPolarisEnvironments = () => {
  const otherPolarisEnvironmentNames = (
    process.env.VUE_APP_OTHER_POLARIS_ENVIRONMENTS || ''
  ).split(';');
  return POSSIBLE_OTHER_POLARIS_ENVIRONMENTS.filter(({ name }) =>
    otherPolarisEnvironmentNames.includes(name),
  );
};
