import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';
import { isTokenExpired, refreshToken } from './auth';
import AVAILABLE_ERROR_PAGE_ERRORS from '../pages/ErrorPage/availableErrors';
import { apiDomain } from './config/domains';
import sessionLockoutState from '../App/sessionLockoutSharedState';

import type { components } from '../api/learn-backend-v1-schema';

type Error = components['schemas']['Error'];

export const refreshTokenRequestStore = {
  lastRequest: 0,
  refreshTokenPromise: null as Promise<string | null> | null,
};
export const resetRefreshTokenRequestStore = () => {
  refreshTokenRequestStore.lastRequest = 0;
  refreshTokenRequestStore.refreshTokenPromise = null;
};

export const REFRESH_TOKEN_REQUEST_THROTTLE = 10000; // 10 seconds
const getRefreshTokenPromiseSingleton = () => {
  const requestIsStale =
    refreshTokenRequestStore.lastRequest + REFRESH_TOKEN_REQUEST_THROTTLE <
    Date.now();

  if (requestIsStale || !refreshTokenRequestStore.refreshTokenPromise) {
    refreshTokenRequestStore.lastRequest = Date.now();
    refreshTokenRequestStore.refreshTokenPromise = refreshToken();
  }
  return refreshTokenRequestStore.refreshTokenPromise;
};

const attachAuthenticationDetails =
  ({ authenticationRequired }: { authenticationRequired: boolean }) =>
  async (req: InternalAxiosRequestConfig) => {
    const AUTH_REQUIRED_ERROR =
      'Unauthenticated request with authenticated API client!';

    // Don't attach authentication details to the refresh token request
    if (req.url === '/v1/learn/auth/refresh') return req;

    if (typeof localStorage === 'undefined') {
      if (authenticationRequired) throw new Error(AUTH_REQUIRED_ERROR);
      // No local storage, so we can't attach the access token
      return req;
    }

    const accessToken = localStorage.getItem('accessToken');

    if (accessToken === null) {
      if (authenticationRequired) throw new Error(AUTH_REQUIRED_ERROR);
      // No access token, so we can't attach it
      return req;
    }

    const tokenExpired = isTokenExpired(accessToken);

    if (!tokenExpired) {
      // Token is not expired, attach it to the request
      req.headers['authorization'] = `Bearer ${accessToken}`;
      return req;
    }

    // Token is expired, refresh it for the request
    const refreshedAccessToken = await getRefreshTokenPromiseSingleton();
    if (refreshedAccessToken === null) {
      if (authenticationRequired) throw new Error(AUTH_REQUIRED_ERROR);
      // Failed to refresh the token, so we can't attach it
      return req;
    }
    // Refreshed the token successfully.
    // Attach the refreshed access token to the request
    req.headers['authorization'] = `Bearer ${refreshedAccessToken}`;
    return req;
  };

const attachApiDomain = (req: InternalAxiosRequestConfig) => {
  if (process.env.VUE_APP_API_PROXY_DOMAIN) {
    req.headers['X-Snyk-Api-Domain'] = apiDomain.value;
  }
  return req;
};

const handleResponseError = async (e: AxiosError) => {
  if (e.response?.status === 401) {
    sessionLockoutState.isLocked = true;
  } else {
    //TODO: capture with datadog frontend error monitoring
    window.console.error('Error in handleResponseError:\n' + JSON.stringify(e));
  }

  const errorResData = e.response?.data as Error | undefined;
  if (
    errorResData &&
    Object.hasOwn(AVAILABLE_ERROR_PAGE_ERRORS, errorResData.message)
  ) {
    window.location.href = `${process.env.VUE_APP_ORIGIN}/error-page?type=${errorResData.message}`;
  }
  return Promise.reject(e);
};

const conditionallyAttachRESTContentTypeHeader = (
  req: InternalAxiosRequestConfig,
) => {
  const { url, method, data } = req;
  if (url && method) {
    const isV3Api = url.startsWith('/rest/') || url.startsWith('/hidden/');
    if (isV3Api && data) {
      // We only set this header for REST requests that have data because axios removes Content-Type if data is undefined
      // https://github.com/axios/axios/blob/v1.x/dist/axios.js#L2197C7-L2197C50
      req.headers['Content-Type'] = 'application/vnd.api+json';
    }
  }
  return req;
};

const BASE_URL = process.env.VUE_APP_API_PROXY_DOMAIN || apiDomain.value;

/**
 * This client should be used for API requests that **do not require authentication**.
 *
 * It will attach the user's authentication details if available, but will make the request
 * without authentication if the user has not logged in.
 */
export const unauthenticatedClient = axios.create({
  // deepcode ignore Ssrf: False positive. Server-side it relies on a value from environment variables.
  baseURL: BASE_URL,
  responseType: 'json',
  // required as a result of this issue https://github.com/axios/axios/issues/1362 https://stackoverflow.com/questions/58655532/increasing-maxcontentlength-and-maxbodylength-in-axios
  maxBodyLength: Infinity,
  maxContentLength: Infinity,
  headers: {
    'Content-Type': 'application/json',
    ...(process.env.VUE_APP_API_PROXY_DOMAIN && {
      'X-Snyk-Api-Domain': apiDomain.value,
    }),
  },
});

unauthenticatedClient.interceptors.request.use(
  attachAuthenticationDetails({ authenticationRequired: false }),
);
unauthenticatedClient.interceptors.request.use(
  conditionallyAttachRESTContentTypeHeader,
);
unauthenticatedClient.interceptors.request.use(attachApiDomain);

/**
 * This client should be used for API requests that **do require authentication**.
 *
 * It will bail pre-request if the user is unauthenticated.
 */
export const authenticatedClient = axios.create({
  baseURL: BASE_URL,
  responseType: 'json',
  headers: {
    ...(process.env.VUE_APP_API_PROXY_DOMAIN && {
      'X-Snyk-Api-Domain': apiDomain.value,
    }),
  },
});

authenticatedClient.interceptors.request.use(
  attachAuthenticationDetails({ authenticationRequired: true }),
);
authenticatedClient.interceptors.request.use(
  conditionallyAttachRESTContentTypeHeader,
);
authenticatedClient.interceptors.request.use(attachApiDomain);

authenticatedClient.interceptors.response.use(
  (res) => res,
  handleResponseError,
);
