import type { operations } from '../../api/learn-backend-v1-schema';
import type { operations as restOperations } from '../../api/learn-backend-rest-schema';
import type { operations as hiddenOperations } from '../../api/learn-backend-hidden-schema';

export type ValueOf<T> = T[keyof T];

export type NonEmptyArray<T> = [T, ...T[]];

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export function typedObjectKeys<T extends object>(obj: T): Array<keyof T> {
  return Object.keys(obj) as Array<keyof T>;
}

export function typedObjectValues<T extends object>(obj: T): Array<ValueOf<T>> {
  return Object.values(obj) as Array<ValueOf<T>>;
}

export type MustInclude<T, U extends T[]> = [T] extends [ValueOf<U>]
  ? U
  : never;

// Sourced from: https://stackoverflow.com/a/70694878
export function stringUnionToArray<T>(
  elements: MustInclude<T, NonEmptyArray<T>>,
) {
  return elements;
}

export function isPresent<T>(t: T | undefined | null | void): t is T {
  return t != null;
}

export function isNotNullOrUndefined<T>(t: T | undefined | null): t is T {
  return t != null && t !== undefined;
}

export type OpenApiBody<OperationName extends keyof operations> =
  'requestBody' extends keyof operations[OperationName]
    ? 'content' extends keyof operations[OperationName]['requestBody']
      ? 'application/json' extends keyof operations[OperationName]['requestBody']['content']
        ? operations[OperationName]['requestBody']['content']['application/json']
        : never
      : never
    : never;

export type OpenApiResponse<
  OperationName extends keyof operations,
  DefaultResponseCode extends number = 200,
> = DefaultResponseCode extends keyof operations[OperationName]['responses']
  ? 'content' extends keyof operations[OperationName]['responses'][DefaultResponseCode]
    ? 'application/json' extends keyof operations[OperationName]['responses'][DefaultResponseCode]['content']
      ? operations[OperationName]['responses'][DefaultResponseCode]['content']['application/json']
      : never
    : never
  : never;

export type RestApiResponse<
  OperationName extends keyof restOperations,
  DefaultResponseCode extends number = 200,
> = DefaultResponseCode extends keyof restOperations[OperationName]['responses']
  ? 'content' extends keyof restOperations[OperationName]['responses'][DefaultResponseCode]
    ? 'application/vnd.api+json' extends keyof restOperations[OperationName]['responses'][DefaultResponseCode]['content']
      ? restOperations[OperationName]['responses'][DefaultResponseCode]['content']['application/vnd.api+json']
      : never
    : never
  : never;

export type HiddenApiResponse<
  OperationName extends keyof hiddenOperations,
  DefaultResponseCode extends number = 200,
> = DefaultResponseCode extends keyof hiddenOperations[OperationName]['responses']
  ? 'content' extends keyof hiddenOperations[OperationName]['responses'][DefaultResponseCode]
    ? 'application/vnd.api+json' extends keyof hiddenOperations[OperationName]['responses'][DefaultResponseCode]['content']
      ? hiddenOperations[OperationName]['responses'][DefaultResponseCode]['content']['application/vnd.api+json']
      : never
    : never
  : never;
