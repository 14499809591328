import { ref } from 'vue';
import { getAssignmentStatus } from '../../../../lib/assignments';
import { format } from 'date-fns';

import type { LessonAssignmentDecoratedResource } from '../../../../api/assignments';
export const ASSIGNMENT_DASHBOARD_ITLY_ORIGIN = 'lesson-assignments-dashboard';
import { ValueOf } from '../../../../lib/utils/type-utilities';
import type { FilterState } from '@patchui/productcl/components/BaseFilters/BaseFilters.vue';

export type MappedAssignment = ReturnType<typeof mapAssignmentToRow>;

export type AssignmentFilterState = Partial<
  Record<keyof MappedAssignment, ValueOf<FilterState>>
>;
export const selectedFilters = ref<AssignmentFilterState>({});

export const resetSelectedFilters = () => {
  selectedFilters.value = {};
};

const fortmatUnixEpochToString = (date?: number) => {
  if (!date) return;
  return format(date, 'yyyy-MM-dd');
};

const parseDateToUnixEpoch = (date?: string) => {
  if (!date) return;
  return Math.floor(new Date(date).valueOf());
};

export const isValidColumnDataKey = (
  field: string,
): field is keyof MappedAssignment => {
  return columns.some((column) => column.dataKey === field);
};

export const createAssignmentsSorter = (
  sortingField: keyof MappedAssignment,
  sortingDirection: 'ASC' | 'DESC',
) => {
  return (a: MappedAssignment, b: MappedAssignment) => {
    const direction = sortingDirection === 'ASC' ? 1 : -1;
    if (a[sortingField] === b[sortingField]) return 0;
    return (a[sortingField] ?? '') > (b[sortingField] ?? '')
      ? direction
      : -direction;
  };
};
export const mapAssignmentToRow = (
  assignment: LessonAssignmentDecoratedResource,
) => {
  const { assignmentStatus, daysUntilAssignmentDue } =
    getAssignmentStatus(assignment);

  return {
    assignmentId: assignment.id,
    lessonTitle: assignment.attributes.lesson_title,
    userEmail: assignment.attributes.user_email,
    assignmentStatus,
    daysUntilAssignmentDue: daysUntilAssignmentDue,
    createdDate: parseDateToUnixEpoch(assignment.attributes.created_at),
    assignedBy: assignment.attributes.assigning_user_email,
    dueDate: parseDateToUnixEpoch(assignment.attributes.due_date),
    completedDate: parseDateToUnixEpoch(assignment.attributes.completed_date),
  };
};

export const mapRowForPrinting = (mappedAssignment: MappedAssignment) => {
  return {
    ...mappedAssignment,
    createdDate: fortmatUnixEpochToString(mappedAssignment.createdDate),
    dueDate: fortmatUnixEpochToString(mappedAssignment.dueDate),
    completedDate: fortmatUnixEpochToString(mappedAssignment.completedDate),
  };
};

export const columns = [
  {
    dataKey: 'lessonTitle',
    title: 'Lesson',
    sortable: true,
    filterType: 'checkboxes', // filterType is only used by the filter component
  },
  {
    dataKey: 'userEmail',
    title: 'User Email',
    sortable: true,
    filterType: 'checkboxes',
  },
  {
    dataKey: 'assignmentStatus',
    title: 'Assignment Status',
    sortable: true,
    filterType: 'tags',
  },
  {
    dataKey: 'createdDate',
    title: 'Created Date',
    sortable: true,
    filterType: 'dateRange',
  },
  {
    dataKey: 'assignedBy',
    title: 'Assigned By',
    sortable: true,
    filterType: 'checkboxes',
  },
  {
    dataKey: 'dueDate',
    title: 'Due Date',
    sortable: true,
    filterType: 'dateRange',
  },
  {
    dataKey: 'completedDate',
    title: 'Completed Date',
    sortable: true,
    slot: 'completed-date',
    filterType: 'dateRange',
  },
] as const;
