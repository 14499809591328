export const langMapper: Record<string, string> = {
  all: 'All',
  javascript: 'JavaScript',
  java: 'Java',
  golang: 'Go',
  php: 'PHP',
  python: 'Python',
  cpp: 'C++',
  csharp: 'C#',
  npm: 'NPM',
  kubernetes: 'Kubernetes',
  docker: 'Docker',
  general: 'General',
  aiml: 'AI/ML',
};
