<template>
  <nav class="breadcrumbs">
    <ol class="breadcrumbs__crumb-list">
      <li
        v-for="(
          {
            crumb,
            activeLink,
            hasMultipleLinks,
            isLastCrumb,
            isHiddenOnMobile,
          },
          i
        ) in parsedBreadcrumbs"
        :key="i"
        :class="[
          'breadcrumbs__crumb-list-item',
          { 'breadcrumbs__crumb-list-item--hide-mobile': isHiddenOnMobile },
        ]"
      >
        <router-link
          v-if="!hasMultipleLinks"
          :to="activeLink.url"
          class="breadcrumbs__crumb-header"
          data-snyk-test="CrumbHeader: link"
        >
          <Icon
            v-if="activeLink.icon"
            :name="activeLink.icon"
            class="breadcrumbs__crumb-header-icon"
          />
          <span class="breadcrumbs__crumb-header-label">
            {{ activeLink.label }}
          </span>
        </router-link>
        <BaseDropdown
          v-else
          class="breadcrumbs__crumb-list-menu"
          data-snyk-test="CrumbDropdown: menu"
        >
          <template #handle>
            <BaseButton
              size="medium"
              variant="link"
              class="breadcrumbs__crumb-header"
              data-snyk-test="CrumbHeader: dropdown handle"
            >
              <Icon
                v-if="activeLink.icon"
                :name="activeLink.icon"
                class="breadcrumbs__crumb-header-icon"
              />
              <span class="breadcrumbs__crumb-header-label">{{
                activeLink.label
              }}</span>
              <template #rightIcon><MenuDownIcon /></template>
            </BaseButton>
          </template>
          <BaseDropdownMenu>
            <BaseDropdownMenuItem v-for="link in crumb" :key="link.label">
              <BaseDropdownMenuLink
                :href="link.url"
                class="breadcrumbs__link"
                >{{ link.label }}</BaseDropdownMenuLink
              >
            </BaseDropdownMenuItem>
          </BaseDropdownMenu>
        </BaseDropdown>
        <span v-if="!isLastCrumb" class="breadcrumbs__crumb-list-item-divider">
          /
        </span>
      </li>
    </ol>
  </nav>
</template>

<script lang="ts" setup>
import { PropType, computed, toRefs } from 'vue';
import type { Breadcrumbs, Crumb, Link } from './breadcrumbs';
import Icon from '../Icon/Icon.vue';
import BaseDropdown from '@patchui/productcl/components/BaseDropdown/BaseDropdown.vue';
import BaseDropdownMenu from '@patchui/productcl/components/BaseDropdownMenu/BaseDropdownMenu.vue';
import BaseDropdownMenuLink from '@patchui/productcl/components/BaseDropdownMenuLink/BaseDropdownMenuLink.vue';
import BaseDropdownMenuItem from '@patchui/productcl/components/BaseDropdownMenuItem/BaseDropdownMenuItem.vue';
import MenuDownIcon from '@patchui/icons/MenuDown.vue';
import BaseButton from '@patchui/productcl/components/BaseButton/BaseButton.vue';

const props = defineProps({
  breadcrumbs: {
    type: Array as PropType<Breadcrumbs>,
    required: true,
  },
});

const { breadcrumbs } = toRefs(props);

interface ParsedBreadcrumb {
  isLastCrumb: boolean;
  hasMultipleLinks: boolean;
  isHiddenOnMobile: boolean;
  crumb: Crumb;
  activeLink: Link;
}

const parsedBreadcrumbs = computed<ParsedBreadcrumb[]>(() =>
  breadcrumbs.value
    .filter((crumb) => crumb.length)
    .map((crumb, i, breadcrumbs) => ({
      isLastCrumb: i === breadcrumbs.length - 1,
      hasMultipleLinks: crumb.length > 1,
      isHiddenOnMobile: crumb.every((link) => link.isHiddenOnMobile),
      crumb,
      activeLink: crumb.find(({ isActive }) => isActive) || crumb[0],
    })),
);
</script>

<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';

.breadcrumbs {
  & *:focus-visible {
    outline: global(focusState, outline);
  }
  &__crumb-list {
    margin: 0;
    display: flex;
    flex-wrap: nowrap;
    max-width: 100%;
    overflow-x: auto;
    padding-bottom: space(s);

    &-item {
      display: flex;
      margin: 0;
      &--hide-mobile {
        display: none;
        @include media-query(small) {
          display: flex;
        }
      }

      &-divider {
        margin: 0 space(xs);
      }
    }
    @include media-query(small) {
      flex-wrap: wrap;
      padding: 0;
    }
  }

  &__crumb-header {
    color: token('color.neutral.90');
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    &:hover,
    &:focus {
      color: $originalBrandPurple;
    }

    &-icon {
      display: flex;
      align-items: center;
      margin-right: space(xxs);
    }
    &-label {
      white-space: nowrap;
    }
    &--active {
      color: $originalBrandPurple;
    }
    &-menu-down {
      margin-left: space(xxxs);
    }

    &.button--link {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__link:link,
  &__link:visited {
    color: black;
    padding: space(xs) space(m);
  }
}
</style>
