import { isPast, differenceInCalendarDays } from 'date-fns';
import type { LessonAssignmentDecoratedResource } from '../api/assignments';
import type { DeepPartial } from './utils/type-utilities';

export const getAssignmentStatus = (
  assignment: DeepPartial<LessonAssignmentDecoratedResource>,
) => {
  let assignmentStatus;
  let daysUntilAssignmentDue;
  if (assignment.attributes?.completed_date) {
    assignmentStatus = 'completed';
  } else if (!assignment.attributes?.due_date) {
    assignmentStatus = 'noDueDate';
  } else if (isPast(new Date(assignment.attributes.due_date))) {
    assignmentStatus = 'overdue';
  } else {
    assignmentStatus = 'dueIn';
    daysUntilAssignmentDue = differenceInCalendarDays(
      new Date(assignment.attributes.due_date),
      new Date(),
    );
  }
  return { assignmentStatus, daysUntilAssignmentDue };
};
