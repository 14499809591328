<template>
  <div v-if="loggedInUser" class="org-selector">
    <SearchableSelect
      data-snyk-test="OrgSelectorPopup"
      :placeholder="placeholderText"
      :items="items"
      :preselected-value="orgId"
      @change="onOrgChange"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, PropType } from 'vue';
import { useStore } from 'vuex';
import SearchableSelect, {
  Item,
} from '../../../../components/SearchableSelect/SearchableSelect.vue';

import { type RegistryUser } from '../../../../lib/auth';

const store = useStore();

const props = defineProps({
  orgId: {
    type: String as PropType<string | null>,
    required: false,
  },
});
const onOrgChange = (item: Item) => {
  emit('orgSelected', item.value.id);
};
const items = computed(() => {
  if (!loggedInUser?.value?.orgs) return [];
  return loggedInUser.value.orgs.map((org) => {
    return {
      searchKey: org.name,
      label: org.name,
      value: org,
    };
  });
});
const placeholderText = computed(() => {
  if (props.orgId) {
    const matchingOrg = loggedInUser.value?.orgs.find(
      ({ id }) => id === props.orgId,
    );

    if (matchingOrg) return matchingOrg.name;
  }

  return `Select organization`;
});
const loggedInUser = computed<RegistryUser | null>(() => {
  return store.getters['user/getLoggedInUser'];
});

const emit = defineEmits<{
  (event: 'orgSelected', newVal: string): void;
}>();
</script>

<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';
</style>
