<template>
  <div class="bubble">
    <div class="bubble__speaker">
      <slot name="left"> </slot>
    </div>
    <div class="bubble__content">
      <slot name="content" />
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';

.bubble {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  gap: token('space.m');

  @include media-query(small) {
    flex-direction: row;
  }

  &__speaker {
    overflow: hidden;
    border-radius: token('radius.medium');
    aspect-ratio: 1 / 1;

    flex-grow: 0;

    width: 60px;
    @include media-query(small) {
      width: 115px;
    }
  }

  &__content {
    font-family: token('typography.family.poppins');
    background-color: token('color.blue.10');
    padding: token('space.m') token('space.l');
    color: token('color.brand.midnight');
    position: relative;
    margin-top: token('space.xs');
    border-radius: 5px;

    min-height: 75px;
    width: 100%;
    flex-grow: 1;

    @include media-query(small) {
      margin-top: 0;
      margin-left: token('space.l');
    }

    &:before {
      content: '';
      width: 0px;
      height: 0px;
      position: absolute;

      left: 30px;
      top: -18px;

      transform: rotateZ(90deg) scaleY(-1);
      border-left: 10px solid transparent;
      border-right: 10px solid token('color.blue.10');
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;

      @include media-query(small) {
        transform: none;
        left: -20px;
        top: 20px;
      }
    }
  }
}
</style>
