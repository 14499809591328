<template>
  <div class="product-training">
    <h2>
      Accelerate your Snyk knowledge<br />
      with in-depth training
    </h2>
    <div class="product-training__content">
      <img
        alt="Snyk Learn product training"
        src="https://res.cloudinary.com/snyk/image/upload/v1699638399/illustration-ui-spot-snyk-learn-integrating-snyk.svg"
        loading="lazy"
      />
      <div class="description">
        <section>
          <h3>Onboard faster</h3>
          <span>
            Learn best practices from product experts to accelerate your Snyk
            implementation and increase your ROI.
          </span>
        </section>
        <section>
          <h3>Increase developer productivity</h3>
          <span>
            Empower devs with lessons on integrations, workflows, and rollout to
            improve adoption across the engineering organization.
          </span>
        </section>
        <section>
          <h3>Reduce risk</h3>
          <span>
            Build and manage a successful application security platform with
            advice from industry experts.
          </span>
        </section>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';

.product-training {
  margin-top: token('space.layout.small');
  @include media-query(large) {
    margin-top: token('space.layout.large');
  }

  h2 {
    @include typography('typography.brand.heading3');
    color: token('color.brand.midnight');
    text-align: center;
    margin-bottom: space(m);

    @include media-query(medium) {
      @include typography('typography.brand.heading2');
      margin-bottom: 58px;
    }
  }

  h3 {
    color: token('color.brand.midnight');

    @include typography('typography.brand.subhead-small');
    color: token('color.brand.midnight');
    text-align: center;
    margin-bottom: token('space.xs');

    @include media-query(large) {
      text-align: left;
      @include typography('typography.brand.subhead');
    }
  }

  &__content {
    display: flex;
    flex-direction: column;

    @include media-query(large) {
      flex-direction: row;
    }

    img {
      margin-bottom: space(l);

      @include media-query(large) {
        margin-bottom: 0;
      }
    }
  }

  .description {
    section {
      margin-bottom: 32px;
      font-weight: 400;
      font-family: token('typography.family.poppins');
      font-size: rem(16px);
      line-height: rem(28px);
      color: token('color.brand.midnight');
      text-align: center;
      padding: 0 space(xs);

      @include media-query(large) {
        padding-right: space(xxl);
        text-align: left;
        margin-bottom: 45px;
      }

      ::v-deep(.heading--3) {
        font-weight: 600;
        font-size: rem(24px);
        font-family: token('typography.family.poppins');
        color: token('color.brand.midnight');
      }
    }
  }
}
</style>
