import { getAnalytics } from './getAnalytics';

export const pageView = (pageType: string, referrer: string) => {
  const analytics = getAnalytics();
  analytics.track('page_view', {
    page_name: pageType,
    page_type: pageType,
    path: window?.location?.pathname,
    referrer,
    url: window?.location?.href,
  });
};
