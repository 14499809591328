<template>
  <Button
    variant="primary"
    wrapper="a"
    :is-full-width="isFullWidth"
    :href="href"
    :target="target"
    @click="(e: KeyboardEvent | MouseEvent) => beforeNavigationChange(trackClick)(e)"
    >{{ text }}
  </Button>
</template>
<script lang="ts" setup>
import Button from './Button.vue';
import { useSnykAppCTA, ISnykAppCTA } from '../../hooks/useSnykAppCTA';
import { beforeNavigationChange } from '../../lib/utils/navigation';
import { toRefs, UnwrapRef } from 'vue';

export interface ISnykAppCtaProps {
  text: string;
  origin: UnwrapRef<ISnykAppCTA['origin']>;
  intent: UnwrapRef<ISnykAppCTA['intent']>;
  isFullWidth?: boolean;
  learnRedirectFragment?: string;
}

const props = defineProps<ISnykAppCtaProps>();
const { text, intent } = toRefs(props);

const { href, trackClick, target } = useSnykAppCTA({
  title: text,
  origin: props.origin,
  intent,
  learnRedirectFragment: props.learnRedirectFragment,
});
</script>
