<template>
  <section>
    <Loading v-if="isLoading" />
    <div v-else-if="error || !data">
      There was an error while fetching the lessons overview
    </div>
    <div v-else>
      <p class="insight">
        Insights: Which lessons your colleagues have viewed, and what security
        issues they cover
      </p>

      <div class="table">
        <Table
          :columns="COLUMN_DEFINITION"
          :rows="rows"
          :export-csv-enabled="true"
          :export-csv-filename="exportFilename"
          @on-download-csv-clicked="onDownloadCsvClicked"
        >
          <template #vuln-links="{ row }">
            <div>
              <span v-for="(vuln, index) in getVulns(row)" :key="vuln.name">
                <!--Next line adds a comma followed by a space if the vuln is not the first one in the list.-->
                <span v-if="index != 0">, </span>
                <a :href="vuln.href">{{ vuln.name }}</a>
              </span>
            </div>
          </template>
          <template #view-users-links="{ row }">
            <div>
              <router-link
                :to="`/admin/lesson-overview/?orgId=${orgId}&lessonSlug=${row.lesson.slug}&category=${row.lesson.ecosystem}`"
              >
                View users
              </router-link>
            </div>
          </template>
          <template #view-lesson-link="{ row }">
            <div>
              <router-link :to="getLessonPath(row.lesson)">
                View lesson
              </router-link>
            </div>
          </template>
        </Table>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { watchEffect, computed, toRefs } from 'vue';
import Table from '../../../../components/Table/Table.vue';
import { useRoute } from 'vue-router';

import Loading from '../Loading/Loading.vue';
import { useLessonsStats } from './useLessonsStats';
import getVulns from './getVulns';
import { getTabLessonsRows } from './getTabLessonsRows';
import { getLessonPath } from '../../../../lib/utils/lessons';
import { TableColumn } from '../../../../components/Table/components/Table.interfaces';
import { useExportFilename } from '../../useExportFilename';
import itly from '../../../../lib/analytics/itly';
import { useListLessonsMetadata } from '../../../../hooks/lessonsHooks';

export interface ITabLessonsProps {
  orgId: string;
}

const props = defineProps<ITabLessonsProps>();
const { orgId } = toRefs(props);
const emit = defineEmits<{
  (event: 'onError', error: Error): void;
}>();

const COLUMN_DEFINITION: TableColumn[] = [
  {
    fieldName: 'lessonName',
    title: 'Lesson',
    sortable: false,
  },
  {
    fieldName: 'views',
    title: 'Views',
    sortable: false,
  },
  {
    fieldName: 'vulnLinks',
    bodySlot: 'vuln-links',
    title: 'CWE/CVE/Rule ID',
    sortable: false,
    exportable: false,
  },
  {
    fieldName: 'viewUsersLink',
    bodySlot: 'view-users-links',
    title: '',
    sortable: false,
    exportable: false,
  },
  {
    fieldName: 'viewLessonLink',
    bodySlot: 'view-lesson-link',
    title: '',
    sortable: false,
    exportable: false,
  },
];

const { data, isLoading, error } = useLessonsStats(orgId);

const route = useRoute();

const onDownloadCsvClicked = () => {
  itly.learnReportIsExported({
    eventSource: 'Learn',
    exportType: 'csv',
    path: route.path,
    origin: 'reports-lessons-overview',
  });
};

const { exportFilename } = useExportFilename(
  'LessonsOverviewReport',
  orgId.value,
);
watchEffect(() => {
  if (error.value && error.value instanceof Error) {
    emit('onError', error.value);
  }
});
const { data: lessons } = useListLessonsMetadata();

// `any` here is because ProductCL's type for items on ThePagination is wrong
const rows = computed<any>(getTabLessonsRows(lessons, data));
</script>

<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';

.insight {
  color: token('color.brand.steel');
}
.table {
  color: token('color.brand.steel');
}
</style>
