import {
  Experiment,
  ExperimentClient,
  ExposureTrackingProvider,
  Exposure,
} from '@amplitude/experiment-js-client';

let experimentClient: ExperimentClient | null;

class SegmentExposureTrackingProvider implements ExposureTrackingProvider {
  track(exposure: Exposure) {
    window.analytics?.track('$exposure', exposure);
  }
}

export function initAmplitude(apiKey: string): void {
  experimentClient = Experiment.initialize(apiKey, {
    fetchTimeoutMillis: 1000,
    automaticExposureTracking: false,
    exposureTrackingProvider: new SegmentExposureTrackingProvider(),
  });
}

export const getAmplitudeClient = () => {
  return experimentClient;
};
