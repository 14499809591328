// If the username contains dots, we format it with spaces and capitalize

import isEmail from 'validator/lib/isEmail';

// Input: " john.doe@example.com" → Output: "John Doe"
export default function formatStudentName(usernameOrEmail: string) {
  if (!isEmail(usernameOrEmail)) return usernameOrEmail;
  const username = usernameOrEmail.split('@')[0];
  if (!username.includes('.')) return username;
  return username
    .split('.')
    .map((word) => capitalize(word))
    .join(' ');
}

function capitalize(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}
