export type ErrorType =
  | 'insufficient_org_permissions'
  | 'insufficient_org_entitlements'
  | 'internal_error';

export const errorContentOptions = {
  insufficient_org_permissions: {
    title: 'Assignments access not available',
    body: `Sorry, you do not have enough permissions to view this organization. Please contact your Snyk Representative or Support for more information.`,
  },
  insufficient_org_entitlements: {
    title: `Assignments access not available`,
    body: `Sorry, you do not meet requirements to view assignments for this organization. 
You need to have the Snyk Learn Program Management platform add-on required to use Assignments. 
Please contact your Snyk Representative or support.snyk.io for more information. 
Also, you need to either be an organization or group admin or to have permission to view assignments.`,
  },
  internal_error: {
    title: 'Sorry something went wrong.',
    body: 'Snyk has encountered an unexpected error.',
  },
};
