<template>
  <WarningBanner />
  <main class="admin-dashboard">
    <div class="admin-dashboard__title-row">
      <h1 class="admin-dashboard__title">Reports</h1>
      <OrgSelector :org-id="orgId" @org-selected="handleOrgSelected" />
    </div>
    <Error v-if="errorStatus" :status="errorStatus" />
    <Loading v-else-if="isLoading" />
    <div v-else-if="orgId" class="admin-dashboard__contents">
      <ButtonGroup
        :options="tabs"
        :value="tabId ?? 'learningPaths'"
        :with-carousel="false"
        class="admin-dashboard__tabs"
        @click="handleTabChange"
      />
      <KeepAlive>
        <TabCategories
          v-if="tabId === 'categories'"
          :key="`categories-${orgId}`"
          :org-id="orgId"
          @onError="handleError"
        />
        <TabUsers
          v-else-if="tabId === 'users'"
          :key="`users-${orgId}`"
          :org-id="orgId"
          @onError="handleError"
        />
        <TabLessons
          v-else-if="tabId === 'lessons'"
          :key="`lessons-${orgId}`"
          :org-id="orgId"
          @onError="handleError"
        />
        <TabLearningPath
          v-else
          :key="`learning-path-${orgId}`"
          :org-id="orgId"
          @onError="handleError"
        />
      </KeepAlive>
    </div>
    <NoOrgSelected v-else />
  </main>
</template>

<script lang="ts" setup>
import { ref, watch, watchEffect } from 'vue';
import ButtonGroup from '../../components/ButtonGroup/ButtonGroup.vue';

import Loading from './components/Loading/Loading.vue';
import Error from './components/Error/Error.vue';
import OrgSelector from './components/OrgSelector/OrgSelector.vue';

import WarningBanner from './components/WarningBanner/WarningBanner.vue';
import NoOrgSelected from './components/NoOrgSelected/NoOrgSelected.vue';
import TabCategories from './components/TabCategories/TabCategories.vue';
import TabUsers from './components/TabUsers/TabUsers.vue';
import TabLessons from './components/TabLessons/TabLessons.vue';
import TabLearningPath from './components/TabLearningPath/TabLearningPath.vue';
import axios from 'axios';

import useAdminDashboardQueryParams from './useAdminDashboardQueryParams';
import itly from '../../lib/analytics/itly';

const {
  tabId,
  updateTabId,
  orgId,
  updateOrgId,
  learningPathSlug,
  updateLearningPathSlug,
  isLoading,
} = useAdminDashboardQueryParams();

type errorStatusType = '403' | '404' | '500';
const errorStatus = ref<errorStatusType | null>(null);

const handleError = (err: Error) => {
  if (!axios.isAxiosError(err)) {
    return;
  }
  const errorStatusCode = err.response?.status;
  switch (errorStatusCode) {
    case 403:
      errorStatus.value = '403';
      break;
    case 404:
      errorStatus.value = '404';
      break;
    default:
      errorStatus.value = '500';
  }
};

const handleOrgSelected = (orgId: string) => {
  updateOrgId(orgId);
  itly.orgIsSelected({
    eventSource: 'Learn',
    orgId,
    path: '/admin/reports/',
  });
};

let lastLearningPathSlug: string | null = null;

const handleTabChange = async (tabId: string) => {
  if (tabId !== 'learningPaths') {
    if (learningPathSlug.value) lastLearningPathSlug = learningPathSlug.value;
    await updateLearningPathSlug(null);
  } else if (lastLearningPathSlug) {
    await updateLearningPathSlug(lastLearningPathSlug);
  }
  updateTabId(tabId);
  itly.learnTabIsChanged({
    eventSource: 'Learn',
    origin: 'reports-nav',
    path: '/admin/reports/',
    tabName: tabId,
  });
};

watch(orgId, () => {
  errorStatus.value = null; // clear error state when changing org
});

const tabs = [
  {
    value: 'learningPaths',
    label: 'Learning path overview',
  },
  {
    value: 'lessons',
    label: 'Lesson overview',
  },
  {
    value: 'users',
    label: 'User overview',
  },
  {
    value: 'categories',
    label: 'Category overview',
  },
];

watchEffect(() => {
  if (!tabId.value && orgId.value) {
    updateTabId('learningPaths');
  }
});
</script>

<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';
.admin-dashboard {
  max-width: $pageMaxWidth;
  width: 100%;
  margin: 0 auto;
  padding: token('space.xxl') $mobileContainerPadding 0;

  @include media-query(small) {
    padding: token('space.xxl') $containerPadding 0;
    max-width: $pageMaxWidth;
  }

  &__title-row {
    display: flex;
    gap: space(m);
    justify-content: space-between;
    align-items: center;
    margin-bottom: space(l);
  }

  &__contents {
    margin-bottom: space(xxl);
  }

  &__tabs {
    margin-bottom: space();
  }

  &__title {
    font-family: token('typography.family.poppins');
    font-weight: 700;
    font-size: rem(32px);
    line-height: rem(40px);
    letter-spacing: -0.01em;
    color: token('color.neutral.90');
    @include media-query(small) {
      font-size: rem(48px);
      line-height: rem(64px);
    }
  }
}
</style>
