<template>
  <section>
    <Loading v-if="isLoading" />
    <div v-else-if="error || !data">
      There was an error while fetching the users overview
    </div>
    <div v-else>
      <p class="insight">
        Insights: How many lessons your colleagues have viewed or completed
      </p>
      <Table
        :export-csv-enabled="true"
        :export-csv-filename="exportFilename"
        :columns="COLUMN_DEFINITION"
        :rows="rows"
        @on-download-csv-clicked="onDownloadCsvClicked"
      />
    </div>
  </section>
</template>

<script lang="ts" setup>
import { watchEffect, computed, toRefs } from 'vue';
import Table from '../../../../components/Table/Table.vue';

import Loading from '../Loading/Loading.vue';

import { useUsersStats } from './useUsersStats';
import type { UserOverview } from '../../../../api/stats';
import { TableColumn } from '../../../../components/Table/components/Table.interfaces';
import { useExportFilename } from '../../useExportFilename';
import { useRoute } from 'vue-router';
import itly from '../../../../lib/analytics/itly';

export interface ITabUsersProps {
  orgId: string;
}

const props = defineProps<ITabUsersProps>();
const { orgId } = toRefs(props);
const emit = defineEmits<{
  (event: 'onError', error: Error): void;
}>();

const COLUMN_DEFINITION: TableColumn[] = [
  {
    fieldName: 'userEmail',
    title: 'User Email',
    sortable: false,
  },
  {
    fieldName: 'lessonsViewed',
    title: 'Views',
    sortable: false,
  },
];

const { exportFilename } = useExportFilename(
  'UsersOverviewReport',
  orgId.value,
);

const route = useRoute();

const onDownloadCsvClicked = () => {
  itly.learnReportIsExported({
    eventSource: 'Learn',
    exportType: 'csv',
    path: route.path,
    origin: 'reports-users-overview',
  });
};

const { data, isLoading, error } = useUsersStats(orgId);

watchEffect(() => {
  if (error.value && error.value instanceof Error) {
    emit('onError', error.value);
  }
});
const sortByLessonsViewed = (a: UserOverview, b: UserOverview) =>
  b.lessonsViewed - a.lessonsViewed;

// `any` here is because ProductCL's type for items on ThePagination is wrong
const rows = computed<any>(() => {
  return (
    [...(data.value?.usersOverview ?? [])]
      .sort(sortByLessonsViewed)
      .map((userOverviewItem) => {
        return {
          userEmail: userOverviewItem.user.email,
          lessonsViewed: userOverviewItem.lessonsViewed,
        };
      }) ?? []
  );
});
</script>

<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';

.insight {
  color: token('color.brand.steel');
}
.table {
  color: token('color.brand.steel');
}
</style>
