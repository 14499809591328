import { ComputedRef } from 'vue';
import { LessonStep } from './lessonStep';

interface AnalyticsProperties {
  lessonId: string;
  lessonTitle: string;
  lessonUrl: string;
  ecosystem: string;
  canonicalUrl?: string;
}

export type LessonGlobalProperties = ComputedRef<AnalyticsProperties>;

export interface Lesson {
  lessonId: string;
  steps: LessonStep[];
}

export const placeholderLessonImage =
  'https://res.cloudinary.com/snyk/image/upload/v1689095972/snyk-learn/lesson-images/multi_ecosystem_placeholder.svg';
