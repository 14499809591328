<template>
  <section>
    <Loading v-if="isLoading" />
    <div v-else-if="error || !data">
      There was an error while fetching the users progress table
    </div>
    <Table
      v-else
      :export-csv-enabled="true"
      :export-csv-filename="exportFilename"
      :columns="COLUMN_DEFINITION"
      :rows="rows"
      :export-csv-rows="unfilteredRows"
      @on-download-csv-clicked="onDownloadCsvClicked"
    />
  </section>
</template>

<script lang="ts" setup>
import { computed, toRefs, watch } from 'vue';
import Table from '../../../../components/Table/Table.vue';

import { STATUSES } from '../../../../store/modules/lessons';
import Loading from '../../../AdminDashboard/components/Loading/Loading.vue';

import {
  type ProgressOverview,
  useUsersProgressStats,
} from './useUsersProgressStats';
import { TableColumn } from '../../../../components/Table/components/Table.interfaces';
import { useExportFilename } from '../../../AdminDashboard/useExportFilename';
import itly from '../../../../lib/analytics/itly';
import { useRoute } from 'vue-router';

export interface IUserProgressTableProps {
  orgId: string;
  lessonId: string;
  lessonTitle: string;
}

const props = defineProps<IUserProgressTableProps>();
const { orgId, lessonId } = toRefs(props);
const emit = defineEmits<{
  (event: 'dataAvailable', newVal: ProgressOverview[]): void;
}>();
const { exportFilename } = useExportFilename(
  'UserProgressOverview',
  props.orgId,
);

const COLUMN_DEFINITION: TableColumn[] = [
  {
    fieldName: 'lessonTitle',
    title: 'Lesson Title',
    hidden: true,
  },
  {
    fieldName: 'userEmail',
    title: 'User Email',
  },
  {
    fieldName: 'status',
    title: 'Status',
  },
  {
    fieldName: 'completionDate',
    title: 'Completion Date',
  },
];

const { data, isLoading, error } = useUsersProgressStats(orgId, lessonId);

// `any` here is because ProductCL's type for items on ThePagination is wrong
const unfilteredRows = computed<any>(() => {
  return (data.value?.progressOverview ?? []).map((userProgress) => {
    return {
      lessonTitle: props.lessonTitle,
      userEmail: userProgress.user.email,
      status: STATUSES.find(
        (lessonStatus) => lessonStatus.value === userProgress.status,
      )?.label,
      completionDate: userProgress.completionDate,
      completionIsExpired: userProgress.completionIsExpired,
    };
  });
});

const rows = computed<any>(() =>
  unfilteredRows.value.filter((row: any) => row.completionIsExpired != true),
);

const route = useRoute();

const onDownloadCsvClicked = () => {
  itly.learnReportIsExported({
    eventSource: 'Learn',
    exportType: 'csv',
    path: route.path,
    origin: 'reports-users-progress-overview',
  });
};

watch(data, () => {
  if (!data.value) return;
  emit('dataAvailable', data.value.progressOverview);
});
</script>

<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';
</style>
