import { useSessionStorage } from '@vueuse/core';
import { LearningPathDetails } from '../../types';

export const learningPathContextKey = 'LP_CONTEXT';

const DEFAULT_BIND_VALUE = null;

export const learningPathContextStorage =
  useSessionStorage<Partial<LearningPathDetails> | null>(
    learningPathContextKey,
    DEFAULT_BIND_VALUE,
    {
      serializer: {
        read: (v: any) => (v ? JSON.parse(v) : null),
        write: (v: any) => JSON.stringify(v),
      },
    },
  );

export const useLearningPathContextStorage = () => {
  return learningPathContextStorage;
};
