import { computed } from 'vue';
import { useHead, useHeadRaw } from '@vueuse/head';
import { placeholderLessonImage } from '../../lib/lesson';
import { prepareOpenGraphImage } from '../../lib/utils/cloudinaryHelper';

import type { Ref } from 'vue';
import type { LessonMetadata } from '../../lib/lessonMetadata';
import type { HeadObject } from '@vueuse/head';
import {
  createArticleEntity,
  createFaqPageEntity,
  createJsonLd,
} from '../../lib/structuredGoogleData';
import { components } from '../../api/learn-backend-hidden-schema';

const createLessonMetaImageKey = (lessonMetadata: LessonMetadata) => {
  const lessonImg = lessonMetadata.img;
  const lessonImgFullUrl = lessonImg || placeholderLessonImage;

  if (lessonImgFullUrl.indexOf('images.ctfassets.net') >= 0) {
    return lessonImgFullUrl;
  }

  return (
    'snyk-learn' +
      lessonImgFullUrl.split('snyk-learn')[1]?.replace('.svg', '') ||
    'snyk-learn/lesson-images/placeholder_generic'
  );
};

export const useLessonHead = (
  lessonMetadata: Ref<LessonMetadata>,
  lessonContentFaqs: Ref<components['schemas']['LessonFaq'][]>,
) => {
  useHead(
    computed(() => {
      const pageTitle = `${lessonMetadata.value.seoTitle?.trim()} | Snyk Learn`;
      const description = lessonMetadata.value.description?.trim();
      const lessonMetaImageKey = createLessonMetaImageKey(lessonMetadata.value);
      const headConfig: HeadObject = {
        title: pageTitle,
        meta: [
          {
            key: 'og:title',
            property: 'og:title',
            content: pageTitle,
          },
          {
            key: 'description',
            name: 'description',
            content: description,
          },
          {
            key: 'og:description',
            property: 'og:description',
            content: description,
          },
          {
            key: 'og:image',
            property: 'og:image',
            content: prepareOpenGraphImage(lessonMetaImageKey, 'og'),
          },
          {
            key: 'twitter:image',
            property: 'twitter:image',
            content: prepareOpenGraphImage(lessonMetaImageKey, 'twitter'),
          },
        ],
      };
      if (lessonMetadata.value.url.includes('learn.snyk.io')) {
        headConfig.link = [
          {
            rel: 'canonical',
            href: lessonMetadata.value.url?.trim(),
          },
        ];
      }
      return headConfig;
    }),
  );

  useHeadRaw(
    computed(() => {
      const graph = [];

      const article = createArticleEntity(lessonMetadata.value);
      graph.push(article);

      const faqPageEntity = createFaqPageEntity(lessonContentFaqs.value);
      if (faqPageEntity) {
        graph.push(faqPageEntity);
      }

      const result = createJsonLd(graph);
      const headConfig = {
        script: [
          {
            type: 'application/ld+json',
            innerHTML: JSON.stringify(result),
          },
        ],
      };
      return headConfig;
    }),
  );
};
