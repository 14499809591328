export const toKebabCase = (str: string) => {
  return str
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[\s_]+/g, '-')
    .toLowerCase();
};

export const pickFirstIfArray = (value: string | string[] | undefined) => {
  if (typeof value === 'string') {
    return value;
  } else if (Array.isArray(value) && value.length) {
    return value[0];
  } else {
    return '';
  }
};
export const isUuid = (id: string) => {
  const uuidRegex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  return uuidRegex.test(id);
};
