import { ref, type Ref } from 'vue';
import { Step } from '@patchui/productcl/components/BaseStepper/BaseStepper.vue';

export const isStepActive = (step: Step) => step.status === 'active';

const updateStepStatuses = (steps: Ref<Step[]>, activeStepIndex: number) => {
  steps.value = steps.value.map((step, index) => {
    if (index < activeStepIndex) {
      step.status = 'complete';
    } else if (index === activeStepIndex) {
      step.status = 'active';
    } else {
      step.status = 'pending';
    }
    return step;
  });
};

export const useStepper = (defaultSteps: Step[]) => {
  let activeStep = defaultSteps.findIndex((step) => step.status === 'active');

  const steps = ref<Step[]>(defaultSteps);

  const nextStep = () => {
    activeStep = Math.min(defaultSteps.length - 1, activeStep + 1);
    updateStepStatuses(steps, activeStep);
  };

  const previousStep = () => {
    activeStep = Math.max(0, activeStep - 1);
    updateStepStatuses(steps, activeStep);
  };

  const resetSteps = () => {
    activeStep = 0;
    updateStepStatuses(steps, activeStep);
  };

  return { steps, nextStep, previousStep, resetSteps };
};
