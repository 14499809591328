import { computed } from 'vue';

import { authenticatedClient } from '../../../../lib/apiClient';
import { OpenApiResponse } from '../../../../lib/utils/type-utilities';
import type { Ref } from 'vue';
import { useQuery } from '@tanstack/vue-query';

export type ProgressOverview = NonNullable<
  OpenApiResponse<'getStatsOrganizationLearningPathDetails'>['progressOverview']
>[number];

export const fetchLearningPathStats = async (
  orgId: string,
  learningPathId: string,
) => {
  const response = await authenticatedClient.get<
    OpenApiResponse<'getStatsOrganizationLearningPathDetails'>
  >(`/v1/learn/stats/org/${orgId}/learning-path/${learningPathId}`);

  return response.data;
};

export const useLearningPathStats = (
  orgId: Ref<string>,
  learningPathId: Ref<string>,
) => {
  const enabled = computed(() => !!learningPathId.value);
  return useQuery({
    queryKey: ['learningPathStats', orgId, learningPathId],
    queryFn: () => fetchLearningPathStats(orgId.value, learningPathId.value),
    enabled,
  });
};
