<template>
  <div class="search-options">
    <FiltersDropdown
      v-model:filteredEcosystems="ecosystemsToFilterTo"
      @clear-all-filters="clearAllFilters"
    />
    <BaseInput
      v-model="searchQuery"
      type="search"
      class="search-options__input"
      placeholder="Search learning program"
      size="medium"
      name="searchQuery"
      aria-label="Search for a lesson"
      clearable
    />
  </div>

  <BaseEmptyState
    v-show="noRowsToDisplay"
    class="empty-state"
    variant="no-results"
  >
    <template #heading>Lesson cannot be found.</template>
    <p>Try refining your search criteria</p>
  </BaseEmptyState>

  <BaseTable
    v-show="!noRowsToDisplay"
    :value="paginatedRows"
    class="table"
    :columns="[
      {
        dataKey: 'lesson',
        title: 'CONTENT',
      },
      {
        dataKey: 'ecosystems',
        title: 'CATEGORIES',
      },
      {
        dataKey: 'format',
        title: 'FORMAT',
      },
    ]"
    :rows="paginatedRows"
    data-snyk-test="LessonListing"
  >
    <template #header-cell:lesson="{}">
      <BaseCheckbox
        name="all-lessons"
        :value="true"
        label="CONTENT"
        :strong-label="true"
        :model-value="assignedLessonIds.length === rows?.length"
        @update:modelValue="(isChecked: boolean) => onSelectAllChange(isChecked)"
      />
    </template>
    <template #lesson="{ row }">
      <div>
        <BaseCheckbox
          :key="row.id"
          :name="row.name"
          :value="row.id"
          :label="row.name"
          :strong-label="true"
          :model-value="assignedLessonIds.includes(row.id)"
          @update:modelValue="(isChecked: boolean) => onCheckboxChange(row.id, isChecked)"
        />
      </div>
    </template>
    <template #ecosystems="{ row }">
      <template v-if="row.ecosystems.length">
        <BaseBadge
          v-for="ecosystem in row.ecosystems"
          :key="ecosystem"
          class="ecosystem-badge"
        >
          {{ langMapper[ecosystem] }}
        </BaseBadge>
      </template>
      <template v-else>
        <BaseBadge
          v-for="topic in row.topics"
          :key="topic"
          class="ecosystem-badge"
        >
          {{ topic }}
        </BaseBadge>
      </template>
    </template>
  </BaseTable>
  <BasePaginationNav
    v-show="!noRowsToDisplay"
    :current-page="currentPage"
    :total-pages="totalPagesCount"
    :max-pages-shown="5"
    @next="() => currentPage++"
    @go-to-page="(pageNo: number | string) => currentPage = Number(pageNo)"
    @prev="() => currentPage--"
  />
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue';

import { useListLessonsMetadata } from '../../../hooks/lessonsHooks';
import { assignedLessonIds } from './newAssignmentsStore';
import BaseTable from '@patchui/productcl/components/BaseTable/BaseTable.vue';
import BasePaginationNav from '@patchui/productcl/components/BasePaginationNav/BasePaginationNav.vue';
import BaseCheckbox from '@patchui/productcl/components/BaseCheckbox/BaseCheckbox.vue';
import BaseInput from '@patchui/productcl/components/BaseInput/BaseInput.vue';
import { usePagination } from '../../../hooks/usePagination';
import FiltersDropdown from './FiltersDropdown.vue';
import BaseBadge from '@patchui/productcl/components/BaseBadge/BaseBadge.vue';
import { langMapper } from '../../../lib/utils/mappers';

import BaseEmptyState from '@patchui/productcl/components/BaseEmptyState/BaseEmptyState.vue';
const { data: lessons } = useListLessonsMetadata();
const searchQuery = ref('');

const ecosystemsToFilterTo = ref(new Set<string>([]));

const clearAllFilters = async function () {
  ecosystemsToFilterTo.value.clear();
};

const rows = computed(() => {
  const searchQueryMatcher = searchQuery.value.toLocaleLowerCase().trim();
  return (lessons.value || [])
    .map((lesson) => {
      return {
        name: lesson.title,
        id: lesson.lessonId,
        ecosystems: lesson.ecosystems,
        topics: lesson.topics,
        format: 'Lesson',
      };
    })
    .filter(({ name }) => name.toLocaleLowerCase().includes(searchQueryMatcher))
    .filter(({ ecosystems }) => {
      // Display only lessons from selected categories
      // Display all lessons when no filters are clicked
      if (ecosystemsToFilterTo.value.size === 0) return true;
      return ecosystems?.some((ecosystem) =>
        ecosystemsToFilterTo.value.has(ecosystem),
      );
    })
    .sort((a, b) => a.name.localeCompare(b.name));
});

const paginationPageSize = 10;
const { currentPage, totalPagesCount, paginatedRows } = usePagination(
  rows,
  paginationPageSize,
);

const onCheckboxChange = (selectedLessonId: string, isChecked: boolean) => {
  if (isChecked) {
    assignedLessonIds.value = [...assignedLessonIds.value, selectedLessonId];
  } else {
    assignedLessonIds.value = assignedLessonIds.value.filter(
      (lessonId) => lessonId !== selectedLessonId,
    );
  }
};

const onSelectAllChange = (isChecked: boolean) => {
  if (isChecked) {
    assignedLessonIds.value = (rows.value || []).map((row) => row.id);
  } else {
    assignedLessonIds.value = [];
  }
};

const noRowsToDisplay = computed(() => {
  return rows.value.length === 0;
});
</script>

<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';

.empty-state {
  margin-left: 0;
}

.table {
  width: 100%;
}

.search-options {
  display: flex;
  align-items: center;
  gap: token('space.m');

  &__input {
    width: 200px;
  }
}

.ecosystem-badge {
  text-transform: none;
  margin-right: token('space.xs');
}
</style>
