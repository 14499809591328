import { useRoute } from 'vue-router';
import { computed } from 'vue';
import { buildAuthLink } from '../lib/auth';
import { getEcosystemFromRoute } from '../lib/cta/redirect';
import itly, { ProductCTAIsClickedProperties } from '../lib/analytics/itly';
import { get, MaybeRef } from '@vueuse/core';
import {
  apiDomain as apiDomainRef,
  appDomain as appDomainRef,
} from '../lib/config/domains';

export interface ISnykAppCTA {
  title: MaybeRef<string>;
  /**
   * Where in the page is the CTA?
   *  If you want to add a new origin, make sure you also add it in this notion doc: https://www.notion.so/snyk/Tracking-Parameter-Guide-be1e3d81a205419ea6b9f5117dab2f0a
   */
  origin: MaybeRef<
    'banner' | 'sidebar' | 'nav' | 'footer' | 'body' | 'filter-dropdown'
  >;
  /**
   * The intent of the user when visiting app.snyk.io.
   * `login` & `signup` take the user to the snyk auth flow, `product` takes the user to the snyk app homepage
   *  If you want to add a new intent, make sure you also add it in this notion doc: https://www.notion.so/snyk/Tracking-Parameter-Guide-be1e3d81a205419ea6b9f5117dab2f0a
   */
  intent: MaybeRef<'login' | 'signup' | 'product' | 'product-login'>;
  learnRedirectFragment?: string;
  appDomain?: string;
  apiDomain?: string;
}

export const useSnykAppCTA = (args: ISnykAppCTA) => {
  const { intent, title, learnRedirectFragment } = args;
  const route = useRoute();

  const href = computed(() => {
    const currentIntent = get(intent);
    const currentOrigin = get(args.origin);

    const page = route?.meta?.productCtaPageParam ?? 'learn-unknown';
    const trackingParams = `loc=${currentOrigin}&page=${page}&cta=${currentIntent}`;
    const apiDomain = args.apiDomain ?? apiDomainRef.value;
    const appDomain = args.appDomain ?? appDomainRef.value;

    if (currentIntent === 'product') {
      return `${appDomain}?${trackingParams}`;
    } else if (currentIntent === 'product-login') {
      const trackingParamsUpdated = `loc=${currentOrigin}&page=${page}&cta=login`;
      return `${appDomain}/login?${trackingParamsUpdated}`;
    } else {
      return buildAuthLink({
        origin: currentOrigin,
        intent: currentIntent,
        page: route?.meta?.productCtaPageParam,
        learnRedirectPath: route.path,
        learnRedirectFragment,
        apiDomain: apiDomain,
      });
    }
  });

  const target = computed(() =>
    get(intent) === 'product' || get(intent) === 'product-login'
      ? '_blank'
      : '_self',
  );

  const trackClick = async () => {
    const payload: ProductCTAIsClickedProperties = {
      title: get(title),
      ecosystem: getEcosystemFromRoute(route),
      eventSource: 'Learn',
      style: 'button',
      origin: get(args.origin),
      path: route.path,
      type: '',
    };

    await itly.productCtaIsClicked(payload);
  };

  return { href, target, trackClick };
};
