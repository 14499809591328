export const SET_STEP_PROGRESS = 'SET_STEP_PROGRESS';
export const SET_LESSON_PROGRESS = 'SET_LESSON_PROGRESS';
export const SET_CURRENTLY_VIEWED_STEP_ID = 'SET_CURRENTLY_VIEWED_STEP_ID';

export const SET_LOADING = 'SET_LOADING';

export const SET_LOGGED_IN_USER = 'SET_LOGGED_IN_USER';
export const SET_NO_LOGGED_IN_USER = 'SET_NO_LOGGED_IN_USER';
export const SET_USER_PROJECTS = 'SET_USER_PROJECTS';

export const SET_LESSONS = 'SET_LESSONS';
