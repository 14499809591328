import { Plugin, Event, ValidationResponse } from '@itly/sdk';
import { appDomain } from '../../config/domains';

const APP_DOMAINS_TO_ALLOW = ['https://app.snyk.io', 'https://app.dev.snyk.io'];
const EVENTS_TO_NOT_SKIP = [
  'Environment Is Selected', // Allow the 'Environment Is Selected' event to be sent on any app domain
];

// Plugin that validates if events should be skipped
// If the current selected app domain is an excluded domain we skip
class ItlySkipTrackPlugin extends Plugin {
  constructor() {
    super('itlySkipTrackingPlugin');
  }

  validate(event: Event): ValidationResponse {
    if (EVENTS_TO_NOT_SKIP.includes(event.name)) return super.validate(event);

    if (this.shouldSkipEvent()) {
      return {
        valid: false,
        message: `Event ${event.name} should be skipped.`,
        pluginId: this.id,
      };
    }

    return super.validate(event);
  }

  shouldSkipEvent(): boolean {
    return !APP_DOMAINS_TO_ALLOW.includes(appDomain.value);
  }
}

export const itlySkipTrackPlugin = new ItlySkipTrackPlugin();
