import { components } from './learn-backend-hidden-schema';
import { HiddenApiResponse } from '../lib/utils/type-utilities';
import { authenticatedClient } from '../lib/apiClient';
import { useLoggedIn } from '../hooks/useLoggedIn';
import { useQuery } from '@tanstack/vue-query';

export const listMyEducationProgress = async (): Promise<
  components['schemas']['EducationProgress'][]
> => {
  const response = await authenticatedClient.get<
    HiddenApiResponse<'listMyEducationProgress'>
  >(`/hidden/self/education_progress?version=2024-03-03~experimental`);
  return response.data.data || [];
};

export const useEducationProgress = () => {
  const isLoggedIn = useLoggedIn();

  return useQuery({
    queryKey: ['educationProgress'],
    queryFn: listMyEducationProgress,
    enabled: isLoggedIn,
  });
};
