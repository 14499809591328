<template>
  <section>
    <Loading v-if="isLoading" />
    <div v-else-if="error || !data">
      There was an error while fetching the categories overview
    </div>
    <div v-else>
      <p class="insight">
        Insights: Which categories your colleagues have selected in their
        profile
      </p>
      <Table
        :export-csv-enabled="true"
        :export-csv-filename="exportFilename"
        :columns="COLUMN_DEFINITION"
        :rows="rows"
        @on-download-csv-clicked="onDownloadCsvClicked"
      />
    </div>
  </section>
</template>

<script lang="ts" setup>
import { watchEffect, computed, toRefs } from 'vue';
import Table from '../../../../components/Table/Table.vue';

import { LessonEcosystem } from '../../../../lib/lessonMetadata';
import { langMapper } from '../../../../lib/utils/mappers';
import Loading from '../Loading/Loading.vue';

import { type CategoryOverview, useCategoryStats } from './useCategoryStats';
import { TableColumn } from '../../../../components/Table/components/Table.interfaces';
import { useRoute } from 'vue-router';
import { useExportFilename } from '../../useExportFilename';
import itly from '../../../../lib/analytics/itly';

export interface ITabCategoriesProps {
  orgId: string;
}

const props = defineProps<ITabCategoriesProps>();
const { orgId } = toRefs(props);

const emit = defineEmits<{
  (event: 'onError', error: Error): void;
}>();

const COLUMN_DEFINITION: TableColumn[] = [
  {
    fieldName: 'ecosystemName',
    title: 'Category',
    sortable: false,
  },
  {
    fieldName: 'numberOfUsers',
    title: 'Number of users',
    sortable: false,
  },
];

const { exportFilename } = useExportFilename(
  'CategoriesOverviewReport',
  orgId.value,
);

const route = useRoute();

const onDownloadCsvClicked = () => {
  itly.learnReportIsExported({
    eventSource: 'Learn',
    exportType: 'csv',
    path: route.path,
    origin: 'reports-categories-overview',
  });
};

const { data, isLoading, error } = useCategoryStats(orgId);

watchEffect(() => {
  if (error.value && error.value instanceof Error) {
    emit('onError', error.value);
  }
});
const sortByNumberOfUsers = (a: CategoryOverview, b: CategoryOverview) =>
  b.numberOfUsers - a.numberOfUsers;

const rows = computed(() => {
  return (
    [...(data.value?.categoriesOverview ?? [])]
      .sort(sortByNumberOfUsers)
      .map((categoryOverview) => {
        return {
          numberOfUsers: categoryOverview.numberOfUsers,
          ecosystemName:
            langMapper[categoryOverview.ecosystemName as LessonEcosystem] ||
            categoryOverview.ecosystemName,
        };
      }) ?? []
  );
});
</script>

<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';

.insight {
  color: token('color.brand.steel');
}

.table {
  color: token('color.brand.steel');
}
</style>
