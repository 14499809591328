import { WidgetData } from '../components/educationWidgets/baseWidget';
import { marked } from 'marked';

export const renderer = {
  code(code: string) {
    const codeLines = code.split('\n');
    const lines = codeLines.map(
      (item) => `<div><span class="vue--code__line">${item}</span></div>`,
    );
    return `<code class="vue--code--multiline">${lines.join('')}</code>`;
  },
  link(href: string, _title: string, text: string) {
    return `<a target="_blank" href="${href}">${text}</a>`;
  },
};

marked.use({ renderer });

export interface StepBlock {
  title: string;
  text: string;
  widgets: WidgetData[];
  img?: string;
}
