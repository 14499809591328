import { onMounted, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

import { RegistryUser } from '../lib/auth';

export interface IUseUserArgs {
  /*
   * Should this hook be read only. If true, it will not try to change the existing state. Default false
   */
  readOnly: boolean;
}

export const useUser = ({ readOnly }: IUseUserArgs = { readOnly: false }) => {
  const store = useStore();
  const route = useRoute();

  const user = computed<RegistryUser | null>(
    () => store.getters['user/getLoggedInUser'],
  );
  const _isLoading = ref(true);
  const isLoading = computed(() => {
    // TODO, we could refactor this out of the store and using something like useQuery as a global user state with loading
    const loginWatcherIsManagingUser = route.query.auth === 'true';
    return _isLoading.value || loginWatcherIsManagingUser;
  });

  onMounted(async () => {
    if (!readOnly) {
      await store.dispatch('user/setLoggedInUser');
    }
    _isLoading.value = false;
  });

  return { user, isLoading };
};
