<template>
  <BrandDisclosureNav class="accountPanel" align="right">
    <template #handle>
      <BaseAvatar :email="user.email" :name="user.username" size="medium" />
    </template>
    <template #contents>
      <div class="accountPanel__content-wrapper">
        <ul ref="navMenu" class="accountPanel__nav-list">
          <li>
            <router-link v-slot="{ navigate, href }" to="/profile/" custom>
              <BrandNavLink
                :href="href"
                title="My profile"
                @click="(e: MouseEvent) => {
                navigate(e);
                handleNavLinkClick();
              }"
              />
            </router-link>
          </li>
          <li v-if="isAssignmentsFeatureEnabled">
            <router-link
              v-slot="{ navigate, href }"
              to="/admin/assignments/"
              custom
            >
              <BrandNavLink
                :href="href"
                title="Assignments"
                subtitle="Early Access: Speak with your Snyk Representative"
                @click="(e: MouseEvent) => {
                navigate(e);
                handleNavLinkClick();
              }"
              />
            </router-link>
          </li>
          <li>
            <router-link
              v-slot="{ navigate, href }"
              to="/admin/reports/"
              custom
            >
              <BrandNavLink
                :href="href"
                title="Reports"
                @click="(e: MouseEvent) => {
                navigate(e);
                handleNavLinkClick();
              }"
              />
            </router-link>
          </li>
          <li>
            <BrandNavLink
              :href="snykAppURL"
              external
              title="Open Snyk Web UI"
              @click="handleNavLinkClick"
            />
          </li>
          <li>
            <router-link v-slot="{ navigate, href }" to="/?logout=true" custom>
              <BrandNavLink :href="href" title="Log out" @click="navigate" />
            </router-link>
          </li>
        </ul>
        <template v-if="currentSelectedTenant">
          <BaseDivider class="accountPanel__divider" />
          <p class="accountPanel__tenant-indicator">
            Logged in: <br />
            {{ currentSelectedTenant.emoji }} {{ currentSelectedTenant.label }}
          </p>
        </template>
      </div>
    </template>
  </BrandDisclosureNav>
</template>

<script lang="ts" setup>
import BaseAvatar from '@patchui/productcl/components/BaseAvatar/BaseAvatar.vue';
import { BrandDisclosureNav, BrandNavLink } from '@patchui/ecosystemcl';
import BaseDivider from '@patchui/productcl/components/BaseDivider/BaseDivider.vue';

import type { RegistryUser } from '../../../../lib/auth';
import { computed, ref } from 'vue';
import { appDomain } from '../../../../lib/config/domains';
import {
  DEFAULT_POLARIS_ENVIRONMENT,
  getOtherPolarisEnvironments,
} from '../../../../lib/config/env';

interface IAccountPanelProps {
  user: RegistryUser;
}
defineProps<IAccountPanelProps>();
const emit = defineEmits(['userMenuNavLinkClicked']);

const navMenu = ref<HTMLUListElement | null>(null);

const snykAppURL = computed(() => {
  return `${appDomain.value}/?loc=learn`;
});

const handleNavLinkClick = () => {
  // Force the user menu to close by hiding it and redisplay it instantly
  if (navMenu.value) {
    navMenu.value.style.display = 'none';
    setTimeout(() => {
      navMenu.value && (navMenu.value.style.display = 'block');
    }, 20);
  }

  emit('userMenuNavLinkClicked');
};

const isAssignmentsFeatureEnabled = process.env.VUE_APP_FF_ASSIGNMENTS_ENABLED;

const allPolarisEnvironments = [
  DEFAULT_POLARIS_ENVIRONMENT,
  ...getOtherPolarisEnvironments(),
];
const currentSelectedTenant = computed(() => {
  return allPolarisEnvironments.find(
    ({ apiDomain }) => apiDomain === localStorage.getItem('api-domain'),
  );
});
</script>

<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';

:deep(.brand-disclosure-nav-contents__content) {
  padding: 0;
}

.accountPanel {
  $self: &;

  &__content-wrapper {
    display: flex;
    flex-direction: column;
  }
  &__divider {
    :deep() {
      margin: 0px;
    }
  }

  &__nav-list {
    width: 250px;
    list-style: none;
    padding: 16px 11px 0px 11px;
  }

  &__collapse-state-icon {
    font-style: normal;
    display: inline-block;
    transition: 0.2s ease-in-out;
    user-select: none;
    color: token('color.brand.electric-blue');
    margin-left: token('size.xsmall.padding');
  }

  &__tenant-indicator {
    margin: 0;
    padding: 8px 24px 20px 24px;
    text-align: center;
    color: token('color.brand.steel');
    line-height: 20px;
  }

  &:hover,
  &:focus,
  &:focus-within {
    #{$self}__collapse-state-icon {
      transform: rotate(180deg);
    }
  }
}
</style>
