import { EcosystemMetadata } from '../../hooks/lessonsHooks';
import { langMapper } from '../../lib/utils/mappers';
import { computed, Ref } from 'vue';
import { LessonEcosystem } from '../../lib/lessonMetadata';
import { RouteLocationNormalizedLoaded } from 'vue-router';

export function useMetaTags(
  lessons: any,
  availableEcosystems: EcosystemMetadata[],
  categoryFromProps: LessonEcosystem | undefined,
  typeQueryParam: Ref<string[] | null>, // can be ['security-education'] or ['product-training']
  route: RouteLocationNormalizedLoaded,
) {
  return computed(() => {
    let descriptionPart = '';
    if (categoryFromProps) {
      if (lessons[0]) descriptionPart = lessons[0].title.toLowerCase();
      if (lessons[1])
        descriptionPart += ` or ${lessons[1].title.toLowerCase()}`;
    } else {
      descriptionPart = availableEcosystems.map((e) => e.label).join(', ');
    }

    if (route.path === '/catalog' || route.path === '/catalog/') {
      if (
        typeQueryParam.value &&
        typeQueryParam.value.includes('security-education')
      ) {
        return {
          title: 'Free Developer Security Education | Snyk Learn',
          description: `Free security education designed for developers in ${descriptionPart}.`,
        };
      } else if (
        typeQueryParam.value &&
        typeQueryParam.value.includes('product-training')
      ) {
        return {
          title: 'Snyk Product Training | Snyk Learn',
          description: `Snyk product training.`,
        };
      }
      return {
        title:
          'Free Developer Security Education & Snyk Product Training | Snyk Learn',
        description: `Free security education and Snyk product training designed for developers in ${descriptionPart}.`,
      };
    } else if (
      route.path === '/catalog/security-education' ||
      route.path === '/catalog/security-education/'
    ) {
      return {
        title: 'Free Developer Security Education | Snyk Learn',
        description: `Free security education designed for developers in ${descriptionPart}.`,
      };
    } else if (
      categoryFromProps &&
      route.path.startsWith('/catalog/security-education') &&
      !(
        route.path.endsWith('/catalog/security-education') ||
        route.path.endsWith('/catalog/security-education/')
      )
    ) {
      return {
        title: `Free ${
          langMapper[categoryFromProps as LessonEcosystem]
        } Security Education Lessons | Snyk Learn`,
        description: `Free security education designed for ${
          langMapper[categoryFromProps as LessonEcosystem]
        } developers like ${descriptionPart}`,
      };
    } else if (
      route.path === '/catalog/product-training' ||
      route.path === '/catalog/product-training/'
    ) {
      return {
        title: 'Snyk Product Training | Snyk Learn',
        description: `Snyk product training.`,
      };
    }

    return {
      title:
        'Free Developer Security Education & Snyk Product Training | Snyk Learn',
      description: `Free security education and Snyk product training designed for developers in ${descriptionPart}.`,
    };
  });
}
