import { createSSRApp } from 'vue';
import deepmerge from 'deepmerge';
import { hydrate as hydrateQueryClient } from '@tanstack/vue-query';

import { createLearnApp } from '../main';
import { vuexLocalPersistence } from '../store';

import type { PageContext } from './types';

export const DOM_MOUNT_TARGET = '#app';

export async function baseHydrate(
  pageContext: PageContext,
  createApp = createSSRApp,
) {
  const { app, router, store, queryClient } = createLearnApp(createApp);

  hydrateQueryClient(queryClient, pageContext.VUE_QUERY_STATE);

  if (vuexLocalPersistence) {
    const savedState = await vuexLocalPersistence.restoreState(
      vuexLocalPersistence.key,
      vuexLocalPersistence.storage,
    );

    store.replaceState(
      deepmerge(
        pageContext.INITIAL_STORE_STATE,
        // State stored in localstorage takes priority over page context.
        savedState,
        {
          arrayMerge: (_destinationArray, sourceArray) => sourceArray,
        },
      ),
    );
  } else {
    store.replaceState(pageContext.INITIAL_STORE_STATE);
  }

  await router.isReady();

  app.mount(DOM_MOUNT_TARGET);
}
