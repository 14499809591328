<template>
  <section class="no-org-selected">
    <img
      src="https://res.cloudinary.com/snyk/image/upload/v1690362701/snyk-learn/Snyk_Logo.svg"
      alt="Patch dog logo"
    />
    <div class="no-org-selected__text">
      <p>Manage assignments</p>
      <p>To start working with assignments, please select an organization</p>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';

.no-org-selected {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  &__text {
    margin-top: token('space.l');

    @include media-query(small) {
      max-width: rem(600px);
    }

    p {
      margin: 0;
    }

    p:first-child {
      @include typography('typography.product.heading3');
      color: token('color.ui.heading');
    }

    p:last-child {
      @include typography('typography.product.body-lead');
      color: token('color.ui.body');
    }
  }
}
</style>
