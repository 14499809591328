import { watch } from 'vue';
import { apiDomain } from '../lib/config/domains';
import { authenticatedClient, unauthenticatedClient } from '../lib/apiClient';
import { Query, useQueryClient } from '@tanstack/vue-query';
import { QueryMetaExtended } from '../types';

export const usePolarisEnvironment = () => {
  const queryClient = useQueryClient();

  watch([apiDomain], () => {
    const domainHasChanged =
      unauthenticatedClient.defaults.baseURL !== apiDomain.value;
    if (domainHasChanged) {
      // If we're using the proxy, we just need to refetch queries to ensure the correct api domain is used
      // this is because we pass the api domain to the proxy as a header and the proxy will use that to make the request
      // to the correct api domain. We don't need to update the base url of the clients, it should stay as the proxy.
      const isUsingLocalProxyDomain = !!process.env.VUE_APP_API_PROXY_DOMAIN;
      if (!isUsingLocalProxyDomain) {
        // If we're not using the proxy and the api-domain has changed
        // we need to update the client base url with the api-domain refetch queries.
        unauthenticatedClient.defaults.baseURL = apiDomain.value;
        authenticatedClient.defaults.baseURL = apiDomain.value;
      }
      queryClient.removeQueries({
        predicate: (query: Query) =>
          !(query.meta as QueryMetaExtended)?.isContentQuery,
      });
    }
  });
};
