<template>
  <div class="learning-path-module">
    <div class="learning-path-module__header">
      <img
        class="learning-path-module__image"
        :src="learningPathModule.imageUrl"
        width="80"
        height="80"
        alt=""
      />
      <div>
        <h2 class="learning-path-module__title">
          {{ learningPathModule.title }}
        </h2>
        <p class="learning-path-module__description">
          {{ learningPathModule.description }}
        </p>
      </div>
    </div>
    <div class="learning-path-module__lessons">
      <LessonLink
        v-for="lesson in learningPathModule.lessons"
        :key="buildLessonPath(lesson.slug)"
        :title="lesson.title"
        :to="buildLessonPath(lesson.slug)"
        :state="lesson.progressStatus ?? 'todo'"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { toRefs } from 'vue';
import { buildLessonPath } from '../../lib/utils/lessons';

import type { components } from '../../api/learn-backend-v1-schema';

import LessonLink from './LessonLink.vue';

interface LearningPathModuleProps {
  learningPathModule: {
    title: string;
    description: string;
    imageUrl: string;
    lessons: {
      lessonId: string;
      title: string;
      ecosystem: string;
      slug: string;
      progressStatus?: components['schemas']['ProgressStatus'];
    }[];
  };
}

const props = defineProps<LearningPathModuleProps>();
const { learningPathModule } = toRefs(props);
</script>

<style lang="scss">
@import '~@/utils.scss';
@import '~@/variables.scss';

.learning-path-module {
  padding: token('space.l');
  border: 1px solid token('color.neutral.30');
  border-radius: token('radius.medium');

  &__header {
    display: block;

    @include media-query(small) {
      display: flex;
      align-items: flex-start;
      justify-content: start;
      gap: token('space.m');
    }
  }

  &__title {
    @include typography('typography.brand.subhead-small');
    color: token('color.brand.midnight');
    font-weight: 600;
  }

  &__image {
    border-radius: token('radius.large');
  }

  &__description {
    color: black;
    line-height: 1.2;
  }

  &__lessons {
    margin-top: token('space.l');

    > * {
      margin-bottom: token('space.m');
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
