const previewHostnames = [
  'preview.learn.snyk.io',
  'content-preview.education-eit.pages.dev',
];

export function isContentPreviewActive(): boolean {
  const isPreviewDomain =
    typeof window !== 'undefined' &&
    previewHostnames.includes(window?.location?.hostname);

  const hasPreviewEnvConfig = !!process.env.VUE_APP_CMS_PREVIEW;
  return isPreviewDomain || hasPreviewEnvConfig;
}
