import { get, MaybeRef } from '@vueuse/core';
import { authenticatedClient } from '../../../../lib/apiClient';
import { OpenApiResponse } from '../../../../lib/utils/type-utilities';
import { useQuery } from '@tanstack/vue-query';

export type LessonOverview = NonNullable<
  OpenApiResponse<'getStatsOrganizationLessonsOverview'>['lessonsOverview']
>[number];

const fetchLessonsStats = async (orgId: string) => {
  const response = await authenticatedClient.get<
    OpenApiResponse<'getStatsOrganizationLessonsOverview'>
  >(`/v1/learn/stats/org/${orgId}/lessons`);

  return response.data;
};

export const useLessonsStats = (orgId: MaybeRef<string>) => {
  return useQuery({
    queryKey: ['orgLessonsStats', orgId],
    queryFn: () => fetchLessonsStats(get(orgId)),
  });
};
