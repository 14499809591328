<template>
  <div class="text-area-copy-to-clipboard">
    <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
    <textarea
      :id="textAreaId"
      :disabled="disabled"
      :value="valueToCopy"
      rows="15"
    ></textarea>
    <button
      data-snyk-test="TextAreaCopyToClipboard: copy-to-clipboard"
      type="button"
      class="clipboard icon"
      @click="copyToClipboard"
    >
      <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
      <label :for="textAreaId">
        {{ buttonText }}
      </label>
    </button>
  </div>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue';
import { makeId } from '@snyk/frontend-lib';

const textAreaId = makeId(5);

const emit = defineEmits<{
  (event: 'copied'): void;
}>();
let timeout: ReturnType<typeof setTimeout> | null = null;

let copied = ref(false);

const buttonText = computed(() => (copied.value ? 'Copied' : 'Copy'));

interface ITextAreaClipProps {
  valueToCopy: string;
  disabled?: boolean;
}
const props = withDefaults(defineProps<ITextAreaClipProps>(), {
  disabled: false,
});

const copyToClipboard = () => {
  if (timeout) {
    clearTimeout(timeout);
  }
  navigator.clipboard.writeText(props.valueToCopy).then(() => {
    emit('copied');
    copied.value = true;
    timeout = setTimeout(() => {
      copied.value = false;
    }, 2000);
  });
};
</script>
<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';

.text-area-copy-to-clipboard {
  position: relative;

  textarea {
    width: 100%;
    height: 100%;
    font-family: token('typography.family.roboto');
    transition: border 0.5s;
    resize: none;
    padding: 0 space(s) space(l) space(s);
    :active {
      border-color: token('color.blue.40');
    }
  }

  button {
    position: absolute;
    bottom: 16px;
    right: 16px;
    font-size: rem(14px);
    color: token('color.blue.50');
    &,
    & > * {
      cursor: pointer;
    }
  }
}
</style>
