export const TRD_ACC_ID = 2025;

export const loadScript = (w: Window, d: Document) => {
  function go() {
    const trdti = setInterval(() => {
      if (d.readyState === 'complete') {
        const bi = d.createElement('script');
        bi.type = 'text/javascript';
        bi.async = true;
        bi.src = 'https://assets.trendemon.com/tag/trends.min.js';
        bi.id = 'trdflame';
        const s = d.getElementsByTagName('script')[0];
        if (s && s.parentNode) {
          s.parentNode.insertBefore(bi, s);
        }
        clearInterval(trdti);
      }
    }, 500);
  }
  go();
};
