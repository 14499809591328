<template>
  <div v-if="exportCsvEnabled" class="table-menu">
    <DownloadButton @click="exportCSV()" />
  </div>
  <PrimeDataTable
    ref="datatable"
    v-model:filters="filters"
    filter-display="menu"
    :export-filename="exportCsvFilename"
    v-bind="exportFunction && { exportFunction }"
    :value="rows"
    class="data-table"
    :paginator="rows.length > ROWS_ON_PAGE"
    :rows="ROWS_ON_PAGE"
    :rows-per-page-options="[10, 20, 50, 100]"
    paginator-template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
    current-page-report-template="{first} to {last} of {totalRecords}"
    always-show-paginator
    @sort="emit('onSort')"
    @update:filters="onFiltersUpdate"
  >
    <template #empty>
      <div v-if="slots.empty">
        <slot name="empty"></slot>
      </div>
    </template>
    <Column
      v-for="(column, i) in columns"
      :key="i"
      :export-header="_.startCase(column.fieldName)"
      :field="column.fieldName"
      :sortable="column.sortable === undefined ? true : column.sortable"
      :filter-field="column.fieldName"
      :exportable="column.exportable"
      :hidden="column.hidden"
      :data-type="column.dataType"
    >
      <template #header>{{ column.title }}</template>
      <template v-if="column.filter" #filter="{ filterModel, filterCallback }">
        <div :class="getWrapperClass(column.filter!.componentType)">
          <component
            :is="getFilterComponent(column.filter!.componentType)"
            v-if="filterModel"
            v-model="filterModel.value"
            v-bind="column.filter!.componentProps"
            @input="filterCallback()"
          />
        </div>
      </template>
      <template #filterclear="{}">
        <BaseButton
          size="medium"
          variant="primary"
          @click="handleClear(column.fieldName)"
        >
          Clear
        </BaseButton>
      </template>
      <template #filterapply="{ filterCallback }">
        <BaseButton size="medium" variant="primary" @click="filterCallback()">
          Apply
        </BaseButton>
      </template>
      <template v-if="column.bodySlot" #body="{ data }">
        <slot :key="bodySlotKey" :name="column.bodySlot" :row="data" />
      </template>
    </Column>
  </PrimeDataTable>
</template>

<script lang="ts" setup>
import { toRefs, ref, useSlots, onMounted } from 'vue';
import PrimeDataTable, {
  DataTableExportFunctionOptions,
  DataTableFilterMeta,
  DataTableOperatorFilterMetaData,
} from 'primevue/datatable';
import Column from 'primevue/column';
import DownloadButton from '../DownloadButton/DownloadButton.vue';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import * as _ from 'lodash';
import type { TableColumn } from './components/Table.interfaces';
import Calendar from 'primevue/calendar';
import BaseButton from '@patchui/productcl/components/BaseButton/BaseButton.vue';
const ROWS_ON_PAGE = 10;

interface ITableProps {
  columns: TableColumn[];
  rows: any[];
  exportCsvRows?: any[];
  exportCsvEnabled?: boolean;
  exportCsvFilename?: string;
  exportFunction?: (options: DataTableExportFunctionOptions) => any;
}

const props = withDefaults(defineProps<ITableProps>(), {
  exportCsvEnabled: false,
  exportCsvFilename: 'snyk-learn-csv-export',
  exportCsvRows: undefined,
});

const { columns, rows } = toRefs(props);

const datatable = ref();

const slots = useSlots();

const emit = defineEmits(['onDownloadCsvClicked', 'onSort', 'onFiltersUpdate']);

const exportCSV = () => {
  let dataOutput = Array.from(datatable.value.processedData);
  // Optionally override data with export-csv-rows prop data
  if (props.exportCsvRows) {
    dataOutput = Array.from(props.exportCsvRows);
  }
  datatable.value.exportCSV({}, dataOutput);
  emit('onDownloadCsvClicked');
};

onMounted(() => {
  //Initialize filters object with filter properties for each column
  columns.value.forEach((column) => {
    if (column.filter && column.filter.model) {
      filters.value[column.fieldName] = column.filter.model;
      filtersInitial.value[column.fieldName] = column.filter.model;
    }
  });
});

const getFilterComponent = (type: string) => {
  switch (type) {
    case 'text':
      return InputText;
    case 'dropdown':
      return Dropdown;
    case 'date':
      return Calendar;
    default:
      return InputText;
  }
};

const getWrapperClass = (type: string) => {
  switch (type) {
    case 'text':
      return 'card flex justify-content-center';
    case 'dropdown':
      return '';
    case 'date':
      return '';
    default:
      return '';
  }
};

type AssignmentsDashboardFilters = {
  [key: string]: DataTableOperatorFilterMetaData;
};

const filters = ref<AssignmentsDashboardFilters>({});
const filtersInitial = ref<AssignmentsDashboardFilters>({});

const handleClear = (fieldName: string) => {
  filters.value[fieldName].constraints.splice(1);
  filters.value[fieldName].operator = filtersInitial.value[fieldName].operator;
  filters.value[fieldName].constraints[0] = {
    value: null,
    matchMode: filtersInitial.value[fieldName].constraints[0].matchMode,
  };
};

const onFiltersUpdate = (_: DataTableFilterMeta) => {
  forceRerenderBodySlotKey();
  emit('onFiltersUpdate');
};

const bodySlotKey = ref(0);

const forceRerenderBodySlotKey = () => {
  // This is a hack to force rerender of the body slot which does not rerender when the table is filtered
  bodySlotKey.value += 1;
};
</script>

<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';

@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@mixin use-md-icon($icon) {
  font-family: 'Material Icons', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 20px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';

  &:before {
    content: $icon;
  }
}

.table-menu {
  display: flex;
  flex-direction: row-reverse;
  margin: space(s) 0;
}

.data-table {
  $self: &;
  &:deep(*) {
    .p-row-toggler {
      background-color: transparent;
      border: none;
    }
    .p-row-toggler-icon:hover {
      cursor: pointer;
    }

    .pi-chevron-right {
      @include use-md-icon('chevron_right');
      color: token('color.neutral.90');
    }
    .pi-chevron-down {
      @include use-md-icon('expand_more');
      color: token('color.neutral.90');
    }

    .pi-filter {
      @include use-md-icon('filter_list');
    }

    .pi-sort-alt {
      width: 20px;
      height: 20px;
      margin-top: 20px;
      display: flex;
    }
    .pi-sort-alt:after,
    .pi-sort-alt:before {
      color: token('color.neutral.60');
      font-family: 'Material Icons', sans-serif;
      position: relative;
      font-size: 20px;
      display: inline-block;
      text-transform: none;
      letter-spacing: normal;
      /* Support for all WebKit browsers. */
      -webkit-font-smoothing: antialiased;
      /* Support for Safari and Chrome. */
      text-rendering: optimizeLegibility;

      /* Support for Firefox. */
      -moz-osx-font-smoothing: grayscale;

      /* Support for IE. */
      font-feature-settings: 'liga';
    }
    .pi-sort-amount-up-alt {
      color: token('color.neutral.60');
      @include use-md-icon('arrow_drop_up');
      margin-top: -8px;
    }
    .pi-sort-amount-down {
      color: token('color.neutral.60');
      @include use-md-icon('arrow_drop_down');
      margin-top: 4px;
    }
    .pi-sort-alt::before {
      content: 'arrow_drop_up';
      margin-top: -14px;
    }
    .pi-sort-alt::after {
      content: 'arrow_drop_down';
      margin-top: -8px;
      right: 20px;
    }

    .pi-angle-left {
      @include use-md-icon('arrow_left');
      background: transparent;
    }
    .pi-angle-double-left {
      @include use-md-icon('arrow_back');
      background: transparent;
    }

    .pi-angle-right {
      @include use-md-icon('arrow_right');
    }
    .pi-angle-double-right {
      @include use-md-icon('arrow_forward');
    }
  }
  border: 1px solid token('color.neutral.30');
  overflow: hidden;
  border-radius: token('radius.large');

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0;
    color: token('color.ui.body');
    @include typography('typography.product.default-small');
  }

  // HEADER START
  &:deep(.p-datatable-thead) {
    border-bottom: 1px solid token('color.neutral.30');
  }
  &:deep(.p-datatable-thead > tr > th) {
    margin: 6px 12px;
    background-color: token('color.brand.white');
  }

  &:deep(.p-column-header-content) {
    @include typography('typography.product.heading7');
    margin-top: 4px;

    color: token('color.ui.body');
    justify-content: flex-start;
  }
  // HEADER END

  // BODY START
  &:deep(.p-datatable-tbody) {
    color: token('color.ui.body');
    @include typography('typography.product.default-small');

    // ROWS
    tr {
      border-bottom: 1px solid token('color.neutral.30');
    }
    tr:last-child {
      border-bottom: none;
    }
    td {
      padding: 8px token('space.xs');
    }
  }
  // BODY END

  &:deep(.p-sortable-column) {
    vertical-align: middle;
    background-color: token('color.brand.white');
  }

  &:deep(.p-column-filter) {
    margin: 0;
  }

  // PAGINATOR START
  &:deep(.p-paginator) {
    padding: 16px 0;
    display: flex;
    align-items: center;

    .p-paginator-current {
      width: 130px;
    }

    @include typography('typography.product.default');
    color: token('color.brand.electric-blue');
    button {
      color: token('color.brand.electric-blue');
    }
    button[disabled] {
      color: token('color.state.disabled.text');
    }
  }
  // PAGINATOR END

  // ICONS START
}
// ICONS END
</style>
