import { useQuery } from '@tanstack/vue-query';
import { authenticatedClient } from '../lib/apiClient';
import { OpenApiResponse } from '../lib/utils/type-utilities';
import type { components } from './learn-backend-v1-schema';

export type Ecosystem = components['schemas']['Ecosystem'];

// One endpoint returns multiple types of data, so we must break out different data types in the api layer
const fetchAllEcosystemData = async () => {
  const response = await authenticatedClient.get<
    OpenApiResponse<'getAuthenticatedUserPreferences'>
  >(`/v1/learn/user/preferences`);
  return response.data;
};

export const listMyEcosystems = async () => {
  const { selectedEcosystems } = await fetchAllEcosystemData();
  return selectedEcosystems;
};
const LIST_MY_ECOSYSTEMS_KEY = 'LIST_MY_ECOSYSTEMS';
export const useListMyEcosystems = () => {
  return useQuery({
    queryKey: [LIST_MY_ECOSYSTEMS_KEY],
    queryFn: listMyEcosystems,
    initialData: [],
    initialDataUpdatedAt: 0,
  });
};

export const listMyEcosystemsInfo = async () => {
  const { selectedEcosystemsInfo } = await fetchAllEcosystemData();
  return selectedEcosystemsInfo;
};
const LIST_MY_ECOSYSTEMS_INFO_KEY = 'LIST_MY_ECOSYSTEMS_INFO';
export const useListMyEcosystemsInfo = () => {
  return useQuery({
    queryKey: [LIST_MY_ECOSYSTEMS_INFO_KEY],
    queryFn: listMyEcosystemsInfo,
    initialData: [],
    initialDataUpdatedAt: 0,
  });
};

export const listEcosystems = async () => {
  const { availableEcosystems } = await fetchAllEcosystemData();
  return availableEcosystems;
};
const LIST_ECOSYSTEMS_KEY = 'LIST_ECOSYSTEMS';
export const useListEcosystems = () => {
  return useQuery({
    queryKey: [LIST_ECOSYSTEMS_KEY],
    queryFn: listEcosystems,
    initialData: [],
    initialDataUpdatedAt: 0,
    staleTime: 1000 * 60 * 60 * 24 * 365, // 1 year
    meta: {
      isContentQuery: true,
    },
  });
};

export const updateMyEcosystems = async (ecosystemIds: number[]) => {
  await authenticatedClient.put<OpenApiResponse<'updateUserEcosystems'>>(
    `/v1/learn/user/preferences/ecosystem`,
    {
      ecosystemIds,
    },
  );
};
