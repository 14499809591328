const SCROLL_EDGE_PADDING = 8; // Pixels
const SCROLL_BEHAVIOR = 'smooth';

export interface ScrollData {
  needsScrollControls: boolean;
  canScrollLeft: boolean;
  canScrollRight: boolean;
}

type ElementType = Element | undefined;

export function getScrollData(elementToScroll: ElementType): ScrollData {
  if (!elementToScroll || !('clientWidth' in elementToScroll))
    throw Error('no element ref exists');

  if (typeof window === 'undefined') {
    return {
      needsScrollControls: false,
      canScrollLeft: false,
      canScrollRight: false,
    };
  }

  const needsScrollControls =
    elementToScroll.scrollWidth > elementToScroll.clientWidth;
  const canScrollLeft = elementToScroll.scrollLeft > 0;
  const canScrollRight =
    elementToScroll.scrollLeft <
    elementToScroll.scrollWidth - elementToScroll.clientWidth;

  return {
    needsScrollControls,
    canScrollLeft,
    canScrollRight,
  };
}

export function scrollRight(elementToScroll: ElementType) {
  if (!elementToScroll || !('clientWidth' in elementToScroll))
    throw Error('no element ref exists');

  // When the user scrolls right, we're going to find the first element
  // who's right edge is off the right edge of the list element. And then we'll scroll
  // to it's left edge.
  const listBoundingBox = elementToScroll.getBoundingClientRect();

  for (const node of elementToScroll.children) {
    const boundingBox = node.getBoundingClientRect();
    if (boundingBox.right > listBoundingBox.right) {
      const leftEdge =
        boundingBox.left - listBoundingBox.left + elementToScroll.scrollLeft;
      const scrollTarget = leftEdge - SCROLL_EDGE_PADDING;

      elementToScroll.scrollTo({
        left: scrollTarget,
        behavior: SCROLL_BEHAVIOR,
      });
      break;
    }
  }
}

export function scrollLeft(elementToScroll: ElementType) {
  if (!elementToScroll || !('clientWidth' in elementToScroll)) return;

  // When the user scrolls left, we're going to find the LAST element
  // who's left edge is off the left edge of the list element. And then we'll scroll
  // so its right edge is on the right hand side of the scroll view.
  const listBoundingBox = elementToScroll.getBoundingClientRect();

  // Screen space coords of the first offscreen tile on the left edge.
  let prevBoundingBox: DOMRect | null = null;

  for (const node of elementToScroll.children) {
    const boundingBox = node.getBoundingClientRect();

    if (boundingBox.left >= listBoundingBox.left) {
      // Break on the first tile that is fully within the viewport.
      break;
    }

    prevBoundingBox = boundingBox;
  }

  const distanceToRightEdge =
    listBoundingBox.right - (prevBoundingBox?.right ?? 0);
  const scrollTarget =
    elementToScroll.scrollLeft - distanceToRightEdge + SCROLL_EDGE_PADDING;

  elementToScroll.scrollTo({ left: scrollTarget, behavior: SCROLL_BEHAVIOR });
}
