<template>
  <router-link
    v-if="enrichedLesson"
    :to="enrichedLesson.pathWithStep"
    :aria-label="enrichedLesson.title"
    @click="onCardClick"
  >
    <Card
      :img-alt="imageAlt"
      :img="cardImage"
      :variant="variant"
      :badge-text="enrichedLesson.isNew ? 'NEW' : undefined"
      :cta-text="ctaText"
      :icon-names="enrichedLesson.ecosystems || []"
      :completed-percentage="enrichedLesson.completedPercentage"
      :is-completed="isLessonComplete"
      :due-badge-status="assignmentStatus"
      :due-badge-days-left="assignmentDaysLeft"
    >
      <template #title>{{ enrichedLesson.title }}</template>
      <template #description>{{ enrichedLesson.description }}</template>
    </Card>
  </router-link>
</template>

<script setup lang="ts">
import { computed, toRefs } from 'vue';
import Card from '../Card/Card.vue';
import itly from '../../lib/analytics/itly';
import { EnrichedLessonMetadata } from '../../hooks/useEnrichedLessons';
import { placeholderLessonImage } from '../../lib/lesson';
import { getLessonPath } from '../../lib/utils/lessons';
import { LessonAssignmentStatus } from '../../types';

interface IProps {
  enrichedLesson: EnrichedLessonMetadata;
  variant?: 'compact' | 'full';
  assignmentStatus?: LessonAssignmentStatus;
  assignmentDaysLeft?: number;
  origin: string;
}
const props = withDefaults(defineProps<IProps>(), {
  variant: 'full',
});
const { enrichedLesson, variant } = toRefs(props);

const onCardClick = () => {
  itly.lessonIsClicked({
    lessonId: enrichedLesson.value.lessonId,
    lessonTitle: enrichedLesson.value.title,
    lessonUrl: getLessonPath(enrichedLesson.value),
    ecosystem: enrichedLesson.value.ecosystem,
    origin: props.origin,
  });
};

const isLessonComplete = computed(
  () => enrichedLesson.value.status === 'completed',
);

const cardImage = computed(
  () => enrichedLesson.value.img || placeholderLessonImage,
);

const imageAlt = computed(
  () => `${enrichedLesson.value.ecosystem} ${enrichedLesson.value.title}`,
);

const ctaText = computed(() => {
  if (isLessonComplete.value) {
    return 'View Lesson';
  } else if (enrichedLesson.value.completedPercentage) {
    return 'Continue learning';
  } else {
    return 'Start learning';
  }
});
</script>
