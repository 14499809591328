import type { LessonEcosystem } from '../../../lib/lessonMetadata';
import type { EnrichedLessonMetadata } from '../../../hooks/useEnrichedLessons';
import { sortLessonsByDatePublishedCb } from '../../../lib/utils/sortHelper';

// Recommended Lesson Sorting logic is structured as follows:
// 1. Exclude lessons with “Completed” and “In Progress” statuses
//   a. Exclude current lesson (when applicable)
// 2. Group lessons into ecosystem buckets:
//   a. User preferences
//   b. Current lesson ecosystem (when applicable)
//   c. Other
// 3. Sort lessons within their ecosystem buckets by published date

export const filterExcludedLessons = (
  lessons: EnrichedLessonMetadata[],
  currentLessonSlug = '',
) => {
  return lessons.filter(
    (lesson) =>
      lesson.status !== 'completed' &&
      lesson.status !== 'inProgress' &&
      lesson.slug !== currentLessonSlug,
  );
};

interface IBuckets {
  preferredEcosystemsBucket: EnrichedLessonMetadata[];
  currentEcosystemBucket: EnrichedLessonMetadata[];
  otherEcosystemsBucket: EnrichedLessonMetadata[];
}

export const groupLessonsIntoEcosystemBuckets = (
  lessons: EnrichedLessonMetadata[],
  preferredEcosystems: LessonEcosystem[],
  currentEcosystem: LessonEcosystem = 'all',
) => {
  return lessons.reduce<IBuckets>(
    (acc, lesson) => {
      if (preferredEcosystems.includes(lesson.ecosystem)) {
        acc.preferredEcosystemsBucket.push(lesson);
      } else if (lesson.ecosystem === currentEcosystem) {
        acc.currentEcosystemBucket.push(lesson);
      } else {
        acc.otherEcosystemsBucket.push(lesson);
      }
      return acc;
    },
    {
      preferredEcosystemsBucket: [],
      currentEcosystemBucket: [],
      otherEcosystemsBucket: [],
    },
  );
};

export const getRecommendedLessons = (
  lessons: EnrichedLessonMetadata[],
  preferredEcosystems: LessonEcosystem[],
  currentLessonSlug?: string,
  currentLessonEcosystem?: LessonEcosystem,
): EnrichedLessonMetadata[] => {
  const filteredLessons = filterExcludedLessons(lessons, currentLessonSlug);

  const {
    preferredEcosystemsBucket,
    currentEcosystemBucket,
    otherEcosystemsBucket,
  } = groupLessonsIntoEcosystemBuckets(
    filteredLessons,
    preferredEcosystems,
    currentLessonEcosystem,
  );

  const allLessonsSorted = [
    ...preferredEcosystemsBucket.sort(sortLessonsByDatePublishedCb),
    ...currentEcosystemBucket.sort(sortLessonsByDatePublishedCb),
    ...otherEcosystemsBucket.sort(sortLessonsByDatePublishedCb),
  ];
  return allLessonsSorted;
};
