<template>
  <BaseDropdown>
    <template #handle>
      <BaseButton size="medium">
        {{ selectedItem?.label || placeholder }}
        <template #rightIcon><MenuDownIcon /></template>
      </BaseButton>
    </template>
    <BaseDropdownMenu class="menu">
      <BaseInput
        v-model="searchQuery"
        type="search"
        placeholder="Search"
        size="small"
        name="searchQuery"
        class="searchInput"
        clearable
        @keydown.enter="onSearchEnter"
      />
      <UtilScrollbars max-height="300px">
        <BaseDropdownMenuItem
          v-for="item in filteredItems"
          :key="item.label"
          @click="() => onChange(item)"
        >
          <BaseDropdownMenuAction
            :selected="item.value.id === preselectedValue"
          >
            {{ item.label }}
          </BaseDropdownMenuAction>
        </BaseDropdownMenuItem>
      </UtilScrollbars>
    </BaseDropdownMenu>
  </BaseDropdown>
</template>

<script lang="ts" setup>
import { ref, computed, toRefs } from 'vue';
import BaseDropdown from '@patchui/productcl/components/BaseDropdown/BaseDropdown.vue';
import BaseDropdownMenu from '@patchui/productcl/components/BaseDropdownMenu/BaseDropdownMenu.vue';
import BaseDropdownMenuItem from '@patchui/productcl/components/BaseDropdownMenuItem/BaseDropdownMenuItem.vue';
import BaseDropdownMenuAction from '@patchui/productcl/components/BaseDropdownMenuAction/BaseDropdownMenuAction.vue';
import MenuDownIcon from '@patchui/icons/MenuDown.vue';
import BaseButton from '@patchui/productcl/components/BaseButton/BaseButton.vue';
import BaseInput from '@patchui/productcl/components/BaseInput/BaseInput.vue';
import UtilScrollbars from '@patchui/productcl/components/UtilScrollbars/UtilScrollbars.vue';

export interface Item {
  searchKey: string;
  label: string;
  value: any;
}
interface SearchableSelectProps {
  placeholder: string;
  items: Item[];
  preselectedValue?: Item['value'];
}
const props = defineProps<SearchableSelectProps>();

const emit = defineEmits(['change']);

const { items } = toRefs(props);
const searchQuery = ref('');
const selectedItem = ref<any | null>(null);

const onChange = (item: Item) => {
  selectedItem.value = item;
  emit('change', item);
};

const onSearchEnter = (e: KeyboardEvent) => {
  const firstItem = filteredItems.value[0];
  if (firstItem) {
    onChange(firstItem);
  } else {
    e.stopPropagation();
  }
};

const filteredItems = computed(() => {
  return items.value.filter((item) =>
    item.searchKey
      .toLocaleLowerCase()
      .includes(searchQuery.value.toLowerCase().trim()),
  );
});
</script>

<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';

.searchInput {
  padding-left: token('size.small.padding');
  padding-right: token('size.small.padding');
  padding-top: token('size.small.padding');
}
</style>
