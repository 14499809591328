import { useUser } from './../../hooks/useUser';
import { computed } from 'vue';

// Example: "UsersOverviewReport-OrgName-2023-07-27"
export const useExportFilename = (prefix: string, orgId: string) => {
  const { user, isLoading } = useUser();

  const exportFilename = computed(() => {
    if (isLoading.value === true) return 'organization';

    const orgName = user.value?.orgs.find((org) => org.id === orgId)?.name;

    return `${prefix}-${orgName}-${new Date().toISOString().substring(0, 10)}`;
  });

  return { exportFilename };
};
