<template>
  <div
    v-if="!isOmitted"
    :class="checkboxClasses"
    :aria-label="indicatorAccessibilityLabel"
    :title="indicatorAccessibilityLabel"
    data-snyk-test="TableOfContentsReadIndicator: Indicator"
  >
    <TextBoxCheck v-if="icon === 'quiz'" :size="18" role="presentation" />
    <CheckIcon v-else :size="18" role="presentation" />
  </div>
</template>

<script lang="ts" setup>
import { ref, toRefs, computed, watch } from 'vue';
import CheckIcon from '@patchui/icons/Check.vue';
import TextBoxCheck from '@patchui/icons/TextBoxCheck.vue';

import { TableOfContentsEntry } from './TableOfContents.interfaces';

const READ_STATE_READABLE_NAMES = {
  read: 'completed',
  partial: 'in progress',
  unread: 'unread',
  hidden: undefined,
} as const;

interface ReadIndicatorProps {
  isLoading: boolean;
  readState: TableOfContentsEntry['readState'];
  title?: string;
  icon: 'quiz' | 'check';
}

const props = defineProps<ReadIndicatorProps>();

const { readState, isLoading, title, icon } = toRefs(props);

const hasChanged = ref(false);

const isOmitted = computed((): boolean => {
  return !readState.value || readState.value == 'hidden';
});

const checkboxClasses = computed(() => ({
  tableOfContents__checkbox: true,
  'tableOfContents__checkbox--partial': readState.value === 'partial',
  'tableOfContents__checkbox--read': readState.value === 'read',
  'tableOfContents__checkbox--changed': hasChanged.value === true,
  'tableOfContents__checkbox--loading':
    readState.value === 'unread' && isLoading.value,
  'tableOfContents__checkbox--check-icon': icon.value === 'check',
  'tableOfContents__checkbox--quiz-icon': icon.value === 'quiz',
}));

const indicatorAccessibilityLabel = computed(() => {
  const sectionState =
    (readState.value && READ_STATE_READABLE_NAMES[readState.value]) ||
    'unknown';

  return `The section ${title?.value ?? ''} is marked as ${sectionState}`;
});

watch(readState, () => (hasChanged.value = true));
</script>

<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';
.tableOfContents__checkbox {
  $self: &;
  width: 24px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 250ms;
  flex-shrink: 0;

  // START UNREAD STATE

  border: 1px token('color.ui.border') solid;
  &--quiz-icon {
    color: #6d6d9c;
  }
  &--check-icon {
    color: #fff;
  }
  // END UNREAD STATE

  &--read {
    background: linear-gradient(token('gradient.vibe-to-dark-teal'));
    color: #fff;
    border: none;
    // We only want the animation if the indicator is being updated,
    // not when the element is mounted.
    &#{$self}--changed {
      animation: pop 0.25s linear;
      animation-iteration-count: 1;
    }
  }

  &--partial {
    &#{$self}--check-icon {
      color: $successBorder;
      border: 1px solid $successBorder;
    }
  }

  &--loading {
    border-style: dashed;
    animation: spin 4s linear;
    animation-iteration-count: infinite;
  }

  > * {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}

@keyframes pop {
  0% {
    box-shadow: 0 0 0 0 $successBorder;
  }

  70% {
    box-shadow: 0 0 3.5px 3.5px change-color($successBorder, $alpha: 0.8);
  }

  100% {
    box-shadow: 0 0 5px 5px change-color($successBorder, $alpha: 0.1);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
