import {
  NavigationGuardNext,
  RouteLocationNormalized,
  useRouter,
} from 'vue-router';
import { StoreType } from './store';
import {
  RegistryUser,
  buildAuthLink,
  getTokens,
  setTokensPostAuthFlow,
} from './lib/auth';
import { apiDomain } from './lib/config/domains';

export const getBeforeEnterValidateLoggedIn =
  (store: StoreType, optionalRedirectPath?: string) =>
  (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ) => {
    const notAuthenticatedRedirectPath = optionalRedirectPath
      ? optionalRedirectPath
      : from.path || 'root';

    const userLoggedIn = store.getters['user/getLoggedInUser'];

    if (userLoggedIn || typeof window == 'undefined') {
      return next();
    }

    try {
      if (!window.localStorage) {
        //TODO: capture with datadog frontend error monitoring
        window.console.info('Unable to access localStorage');
        next(notAuthenticatedRedirectPath);
      }
      const isLoggingIn = to.query.auth === 'true';
      if (isLoggingIn) {
        const queryParamsStringified = Object.entries(to.query)
          .map((entry) => `${entry[0]}=${entry[1]}`)
          .join('&');

        return next(`/?${queryParamsStringified}`);
      }

      const hasAccessToken = !!localStorage.getItem('accessToken');

      if (hasAccessToken) {
        return next();
      }
      return next(notAuthenticatedRedirectPath);
    } catch (e) {
      //TODO: capture with datadog frontend error monitoring;
      window.console.error(JSON.stringify(e));
      return next(notAuthenticatedRedirectPath);
    }
  };

export const getBeforeEnterUserLearningProgressValidateLoggedIn =
  (store: StoreType, optionalRedirectPath?: string) =>
  async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ) => {
    const notAuthenticatedRedirectPath = optionalRedirectPath
      ? optionalRedirectPath
      : from.path || 'root';

    const userLoggedIn = store.getters['user/getLoggedInUser'];

    if (userLoggedIn || typeof window == 'undefined') {
      return next();
    }

    const { authenticate, auth, id, ...rest } = to.query;

    if (
      authenticate === 'automatic' &&
      (to.path == '/user/learning-progress' ||
        to.path == '/user/learning-progress/')
    ) {
      const authLink = buildAuthLink({
        intent: 'login',
        page: to.meta?.productCtaPageParam ?? 'learn-unknown',
        learnRedirectPath: to.path,
        apiDomain: apiDomain.value,
      });
      return next(() => {
        window.location.assign(authLink);
      });
    }

    try {
      if (!window.localStorage) {
        //TODO: capture with datadog frontend error monitoring
        window.console.info('Unable to access localStorage');
        next(notAuthenticatedRedirectPath);
      }
      if (auth === 'true') {
        if (
          (to.path == '/user/learning-progress' ||
            to.path == '/user/learning-progress/') &&
          id &&
          typeof id === 'string'
        ) {
          const router = useRouter();
          const { accessToken, refreshToken } = await getTokens(id);
          setTokensPostAuthFlow(accessToken, refreshToken);
          await store.dispatch('user/setLoggedInUser');

          return next(async () => {
            await router.replace({
              path: to.path, // enforces the preservation of the trailing slash
              query: rest,
              hash: to.hash,
            });
          });
        }
        const queryParamsStringified = Object.entries(to.query)
          .map((entry) => `${entry[0]}=${entry[1]}`)
          .join('&');

        return next(`/?${queryParamsStringified}`);
      }

      const hasAccessToken = !!localStorage.getItem('accessToken');

      if (hasAccessToken) {
        return next();
      }
      return next(notAuthenticatedRedirectPath);
    } catch (e) {
      //TODO: capture with datadog frontend error monitoring;
      window.console.error(JSON.stringify(e));
      return next(notAuthenticatedRedirectPath);
    }
  };

export const getBeforeEnterValidateOrgId =
  (store: StoreType, redirectPath: string) =>
  async (
    to: RouteLocationNormalized,
    _from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ) => {
    const orgId = to.params.orgId;
    if (!orgId) {
      return next();
    }

    await store.dispatch('user/setLoggedInUser');
    const user: RegistryUser = store.getters['user/getLoggedInUser'];

    const isValidOrgId = !!user?.orgs.find((org) => org.id === orgId);

    if (isValidOrgId) {
      return next();
    }

    next(redirectPath);
  };
