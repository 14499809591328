<template>
  <div
    :key="stepId"
    v-observe-visibility="observeVisibilityOptions"
    class="vue--layout-step"
    data-cy="lesson-step"
  >
    <div :class="stepcComputedClass">
      <h2
        v-if="title"
        :id="STEP_ID_PREFIX + stepId"
        :class="['vue--layout-step__meta-title', 'meta__title' + stepIndex]"
      >
        {{ title }}
      </h2>
      <CopyToClipboard class="vue--layout-step__meta__link" :href="stepHref" />
    </div>

    <div :class="stepBodyComputedClass">
      <div class="vue--layout-step__step-blocks">
        <StepBlock
          v-for="(block, index) in mappedBlocks"
          :key="stepId + index"
          :lesson-id="lessonId"
          :step-id="stepId"
          v-bind="block"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { toRefs, ref, computed, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { restStepContainsAnAssessment } from '../../lib/lessonStep';
import {
  DELAY_BEFORE_COMPLETING_STEP_IN_MS,
  MAIN_HEADER_HEIGHT_PX,
  STEP_ID_PREFIX,
} from '../../lib/constants';
import CopyToClipboard from '../../components/CopyToClipboard/CopyToClipboard.vue';
import itly from '../../lib/analytics/itly';
import StepBlock from './StepBlock/StepBlock.vue';

import type { HiddenRestStep } from '../../api/lessons';

export interface StepProps {
  lessonId: string;
  stepIndex: number;
  id: HiddenRestStep['id'];
  title: HiddenRestStep['title'];
  blocks: HiddenRestStep['blocks'];
  selectedEcosystem: string;
}
const props = defineProps<StepProps>();
const {
  id: stepId,
  title,
  blocks,
  stepIndex,
  lessonId,
  selectedEcosystem,
} = toRefs(props);

const mappedBlocks = computed(() =>
  blocks.value.map((block) => {
    const shouldDisplayBlock =
      !selectedEcosystem.value ||
      block.ecosystems.includes('all') ||
      block.ecosystems.includes(selectedEcosystem.value);
    return { ...block, isHidden: !shouldDisplayBlock };
  }),
);
const store = useStore();
const setTimeoutSingleton = ref<ReturnType<typeof setTimeout> | null>(null);

const stepcComputedClass = computed(() => ({
  'vue--layout-step__meta': true,
  'vue--layout-step__meta--congratulations':
    title.value.includes('Congratulations'),
}));

const stepBodyComputedClass = computed(() => {
  return {
    'vue--layout-step__body': true,
    'vue--layout-step__meta--congratulations':
      title.value.includes('Congratulations'),
  };
});

const isStepCompleted = computed<boolean>((): boolean => {
  return store.getters['progress/isStepCompleted'](
    lessonId.value,
    stepId.value,
  );
});
const isAnAssessmentInStep = computed(() =>
  restStepContainsAnAssessment(blocks?.value || []),
);

const route = useRoute();
const stepHref = computed((): string => {
  const origin =
    typeof window !== 'undefined'
      ? window.location.origin
      : process.env.VUE_APP_ORIGIN;

  const url = new URL(route.path, origin);
  url.hash = stepId.value;
  return url.toString();
});

const observeVisibilityOptions = computed(() => {
  const options = {
    callback: visibilityChanged,
    intersection: {
      rootMargin: `-${MAIN_HEADER_HEIGHT_PX + 2}px 0px 0px 0px`, // Account for the header covering the top
    },
    throttle: 200,
    throttleOptions: observeVisibilityThrottleOptions.value,
  };
  return options;
});
const observeVisibilityThrottleOptions = computed(
  (): { leading: string } | null => {
    if (stepIndex.value === 0) {
      return {
        leading: 'visible',
      };
    } else {
      return null;
    }
  },
);

const visibilityChanged = async (isVisible: boolean) => {
  if (setTimeoutSingleton.value) {
    clearTimeout(setTimeoutSingleton.value);
    setTimeoutSingleton.value = null;
  }
  if (isVisible && !isStepCompleted.value && !isAnAssessmentInStep.value) {
    setTimeoutSingleton.value = setTimeout(
      setStepAsCompleted,
      DELAY_BEFORE_COMPLETING_STEP_IN_MS,
    );
  }
  store.dispatch('progress/stepVisibilityChanged', {
    stepId: stepId.value,
    isVisible,
  });
};
const setStepAsCompleted = async () => {
  await store.dispatch('progress/setStepProgress', {
    stepId: stepId.value,
    lessonId: lessonId.value,
    status: 'read',
  });
  itly.lessonSectionIsCompleted({
    lessonId: lessonId.value,
    position: stepIndex.value + 1,
  });
};

onUnmounted(() => {
  visibilityChanged(false);
});
</script>

<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';

.vue--layout-step {
  $self: &;

  &__meta {
    display: flex;
    margin-top: space(m);
    align-items: center;

    @include media-query(small) {
      margin-top: space(xl);
    }

    &-title {
      color: $navyHeader;
      font-size: rem(24px);
      line-height: rem(36px);
      font-weight: 600;
      margin-bottom: space(xxs);
      display: inline-block;
    }
    &__link {
      display: none;
    }
    &:hover {
      .vue--layout-step__meta__link {
        display: inline-flex;
        margin-left: space(xs);
      }
    }

    &--congratulations {
      background: token('color.neutral.10');
      #{$self}__meta-title {
        color: $originalBrandPurple;
        background: token('color.neutral.10');
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
  }
}
</style>
