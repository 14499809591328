import { computed } from 'vue';
import { LessonMetadata } from '../lib/lessonMetadata';
import {
  getLessonCompletionPercentage,
  getLessonPath,
  getLessonStatus,
  isNewLesson,
} from '../lib/utils/lessons';
import { UserLessonProgress } from '../types';
import { ValidStatus } from '../store/modules/lessons';
import { STEP_ID_PREFIX } from '../lib/constants';
import { useProgressQuery } from './useProgress';
import { useSuspense } from '../hooks/useSuspense';
import { useListLessonsMetadata } from './lessonsHooks';

export interface EnrichedLessonMetadata extends LessonMetadata {
  pathWithStep: string;
  isNew: boolean;
  status: ValidStatus;
  completedPercentage: number;
  nextStepId?: string;
}

export const enrichLessonsWithProgressData =
  (progress: UserLessonProgress[] | null) =>
  (lesson: LessonMetadata): EnrichedLessonMetadata => {
    const lessonProgress: UserLessonProgress | undefined = progress?.find(
      (progress) => progress.lessonId === lesson.lessonId,
    );

    const completedPercentage = lessonProgress
      ? getLessonCompletionPercentage(lessonProgress)
      : 0;

    let pathWithStep = getLessonPath(lesson);
    if (lessonProgress?.nextStepId) {
      pathWithStep += '#' + STEP_ID_PREFIX + lessonProgress.nextStepId;
    }

    const output = {
      ...lesson,
      isNew: isNewLesson(lesson),
      status: getLessonStatus(lessonProgress, completedPercentage),
      pathWithStep,
      completedPercentage: completedPercentage,
      ...(lessonProgress?.nextStepId && {
        nextStepId: lessonProgress?.nextStepId,
      }),
    };
    return output;
  };

/**
 * Returns all learn lessons enriched with information about how far the user has got
 */
export const useEnrichedLessonsQuery = () => {
  const { data: progressData } = useProgressQuery();
  const { data: lessons } = useListLessonsMetadata();

  const enrichedLessons = computed(() => {
    return (lessons.value || []).map(
      enrichLessonsWithProgressData(progressData.value?.progress ?? null),
    );
  });
  const { suspense } = useSuspense(() => enrichedLessons.value.length > 0);

  return { enrichedLessons, suspense };
};
