import { stringUnionToArray } from './utils/type-utilities';

import type { components } from '../api/learn-backend-v1-schema';

export type LessonEcosystem = components['schemas']['LessonEcosystem'];

/**
 * List of valid ecosystems within the application.
 *
 * stringUnionToArray enforces that our value-type matches the string union type from the backend. It will generate
 * a build time failure if the type does not exactly match.
 */
export const ecosystems = stringUnionToArray<LessonEcosystem>([
  'all',
  'general',
  'java',
  'javascript',
  'python',
  'golang',
  'php',
  'cpp',
  'csharp',
  'kubernetes',
  'docker',
  'npm',
  'aiml',
]);

export const isValidEcosystem = (
  potentialEcosystem: string | null | undefined,
): potentialEcosystem is LessonEcosystem => {
  return ecosystems.includes(potentialEcosystem as LessonEcosystem);
};

export enum SupportedLanguages {
  'java',
  'javascript',
}

export enum UnSupportedLanguages {
  'python',
  'golang',
  'php',
  'cpp',
  'csharp',
}

export type LessonMetadata = components['schemas']['LessonMetadata'];
