import { Directive } from 'vue';
import { ObserveVisibility as realObserveVisibility } from 'vue-observe-visibility';

const ObserveVisibility: Directive = {
  beforeMount: (el, binding, vnode) => {
    // @ts-expect-error
    vnode.context = binding.instance;
    realObserveVisibility.bind(el, binding, vnode);

    // Trigger the visibility observer if we're running inside Jest.
    if (typeof jest !== 'undefined' && el._vue_visibilityState) {
      el._vue_visibilityState.callback(true);
    }
  },
  updated: (el, binding, vnode) => {
    // @ts-expect-error
    vnode.context = binding.instance;
    realObserveVisibility.update(el, binding, vnode);
  },
  unmounted: realObserveVisibility.unbind,
};

export default ObserveVisibility;
