import { onMounted, ref } from 'vue';
import { useQueryParam } from '../../hooks/useQueryParam';

export const adminDashboardTabs = [
  'learningPaths',
  'lessons',
  'users',
  'categories',
] as const;

export default function useAdminDashboardQueryParams() {
  const [orgId, updateOrgId] = useQueryParam('org');

  type AdminDashboardTab = typeof adminDashboardTabs[number];
  const [tabId, updateTabId] = useQueryParam<AdminDashboardTab>('tab', {
    validator: (val: any) => {
      return adminDashboardTabs.includes(val);
    },
  });

  const [learningPathSlug, updateLearningPathSlug] =
    useQueryParam('learning_path');

  const isLoading = ref(true);
  onMounted(() => {
    isLoading.value = false;
  });

  return {
    orgId,
    updateOrgId,
    tabId,
    updateTabId,
    learningPathSlug,
    updateLearningPathSlug,
    isLoading,
  };
}
