import { useQuery } from '@tanstack/vue-query';
import { get, MaybeRef } from '@vueuse/core';
import { OpenApiResponse } from './../../../../lib/utils/type-utilities';
import { authenticatedClient } from './../../../../lib/apiClient';
import { components } from '../../../../api/learn-backend-v1-schema';
import { AxiosError } from 'axios';

export type ProgressOverview = NonNullable<
  OpenApiResponse<'getStatsOrganizationLessonDetails'>['progressOverview']
>[number];

const fetchUsersProgressStats = async (orgId: string, lessonId: string) => {
  const response = await authenticatedClient.get<
    OpenApiResponse<'getStatsOrganizationLessonDetails'>
  >(`/v1/learn/stats/org/${orgId}/lessons/${lessonId}`);

  return response.data;
};

export const useUsersProgressStats = (
  orgId: MaybeRef<string>,
  lessonId: MaybeRef<string>,
) => {
  return useQuery<
    {
      orgId: string;
      lessonId: string;
      progressOverview: {
        user: components['schemas']['User'];
        status: components['schemas']['ProgressStatus'];
        completionDate?: string;
        completionIsExpired?: boolean;
      }[];
    },
    AxiosError
  >({
    queryKey: ['orgUserProgressStats', orgId, lessonId],
    queryFn: () => fetchUsersProgressStats(get(orgId), get(lessonId)),
  });
};
