type AsyncFunction = () => Promise<void>;

const isModifiedEvent = (e: KeyboardEvent | PointerEvent | MouseEvent) => {
  const hasModifierKeyPressed =
    e.altKey || e.ctrlKey || e.metaKey || e.shiftKey;

  return hasModifierKeyPressed;
};

const race = (fn: AsyncFunction, timeout: number): Promise<void> =>
  Promise.race([
    new Promise<void>((resolve) => setTimeout(resolve, timeout)),
    fn(),
  ]);

/**
 * Wrapper function to ensure async click handler on anchor tags
 * settle before a navigation change.
 *
 * @param fn Function to call before navigation change takes place
 * @param timeout Duration (in milliseconds) before callback times out
 * @return An async function that accepts the originally click triggered event.
 */
export const beforeNavigationChange = (fn: AsyncFunction, timeout = 200) => {
  return async (e: KeyboardEvent | PointerEvent | MouseEvent) => {
    const shouldInterruptNavigation = !isModifiedEvent(e);
    let href;
    if (shouldInterruptNavigation) {
      const currentTarget = <HTMLAnchorElement>e?.currentTarget;
      const closestTarget = currentTarget?.closest('a');
      href = currentTarget.href || closestTarget?.href;
      e.preventDefault();
    }

    await race(fn, timeout);

    if (shouldInterruptNavigation) {
      if (!href) {
        throw new Error('Could not find href');
      }
      window.location.href = href;
    }
  };
};
