<template>
  <component
    :is="icon"
    v-if="icon"
    role="img"
    :aria-label="name"
    :size="size"
  />
</template>

<script lang="ts" setup>
import { toRefs, computed, defineComponent, h } from 'vue';
import LanguageJavascriptIcon from '@patchui/icons/LanguageJavascript.vue';
import LanguageJavaIcon from '@patchui/icons/custom/JavaDuke.vue';
import LanguageGoIcon from '@patchui/icons/LanguageGo.vue';
import LanguagePhpIcon from '@patchui/icons/LanguagePhp.vue';
import LanguagePythonIcon from '@patchui/icons/LanguagePython.vue';
import LanguageCppIcon from '@patchui/icons/LanguageCpp.vue';
import KubernetesIcon from '@patchui/icons/Kubernetes.vue';
import DockerIcon from '@patchui/icons/Docker.vue';
import NpmIcon from '@patchui/icons/Npm.vue';
import SchoolOutlineIcon from '@patchui/icons/School.vue';
import LanguageCsharpIcon from '@patchui/icons/LanguageCsharp.vue';

const props = defineProps({
  size: { type: Number, default: 16 },
  name: {
    type: String,
    required: true,
  },
});

const { name } = toRefs(props);

// We don't have any icon for the general & all ecosystems so we use this component as a placeholder
const EmptyIcon = defineComponent({
  name: 'EmptyIcon',
  render() {
    return h('svg', {
      viewBox: '0 0 24 24',
      xmlns: 'http://www.w3.org/2000/svg',
    });
  },
});

const ICON_MAP = {
  all: EmptyIcon,
  general: EmptyIcon,
  java: LanguageJavaIcon,
  javascript: LanguageJavascriptIcon,
  golang: LanguageGoIcon,
  php: LanguagePhpIcon,
  python: LanguagePythonIcon,
  cpp: LanguageCppIcon,
  csharp: LanguageCsharpIcon,
  kubernetes: KubernetesIcon,
  docker: DockerIcon,
  npm: NpmIcon,
  'school-outline': SchoolOutlineIcon,
} as const;

const icon = computed(
  () => ICON_MAP[name.value as keyof typeof ICON_MAP] || null,
);
</script>
