import { unauthenticatedClient } from '../lib/apiClient';
import { isContentPreviewActive } from '../lib/utils/isContentPreview';
import {
  HiddenApiResponse,
  OpenApiResponse,
} from '../lib/utils/type-utilities';
import type { components } from './learn-backend-hidden-schema';

export const getLesson = async (lessonId: string) => {
  let requestUrl = `/hidden/lessons/${lessonId}?version=2024-03-03~experimental`;
  if (isContentPreviewActive()) {
    requestUrl = `/hidden/preview_lessons/${lessonId}?version=2024-03-03~experimental&content_source=source-preview`;
  }
  const response = await unauthenticatedClient.get<
    HiddenApiResponse<'getLesson'>
  >(requestUrl);

  return response.data.data;
};
export const GET_LESSON_BASE_KEY = 'getLesson';

export const listLessonsMetadata = async () => {
  let requestUrl = `/v1/learn/lessons`;
  if (isContentPreviewActive()) {
    requestUrl += '?content_source=source-preview';
  }
  const response = await unauthenticatedClient.get<
    OpenApiResponse<'getLessons'>
  >(requestUrl);
  return response.data;
};

export const LIST_LESSONS_METADATA_KEY = 'listLessonsMetadata';

export type HiddenRestLesson = components['schemas']['LessonContent'];
export type HiddenRestLessonMetadata = components['schemas']['LessonMetadata'];
export type HiddenRestStep = components['schemas']['LessonStep'];
export type HiddenRestBlock = components['schemas']['StepBlock'];
export type HiddenRestWidget = components['schemas']['Widget'];
