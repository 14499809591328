import { watchEffect, watch, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useMounted } from '@vueuse/core';
import {
  setTokensPostAuthFlow,
  clearAuthState,
  getTokens,
  buildAuthLink,
} from '../lib/auth';
import { useUser } from '../hooks/useUser';
import { Query, useQueryClient } from '@tanstack/vue-query';
import { apiDomain } from '../lib/config/domains';
import sessionLockoutState from './sessionLockoutSharedState';
import { QueryMetaExtended } from '../types';

export const useLoginWatcher = () => {
  const router = useRouter();
  const store = useStore();
  const isMounted = useMounted();

  watchEffect(async () => {
    const route = router.currentRoute.value;
    const { id, auth, ...rest } = route.query;
    if (isMounted.value && auth === 'true' && id && typeof id === 'string') {
      const { accessToken, refreshToken } = await getTokens(id);
      setTokensPostAuthFlow(accessToken, refreshToken);
      await store.dispatch('user/setLoggedInUser');
      await router.replace({
        path: route.path, // enforces the preservation of the trailing slash
        query: rest,
        hash: route.hash,
      });
    }
  });
};

export const fakeAuthenticateDelayInMS = 2000;

export const useAutomaticAuthenticate = () => {
  const router = useRouter();
  const { user, isLoading: isUserLoading } = useUser();
  const authenticationInitiated = ref(false);

  watch(
    [user, isUserLoading, router.currentRoute],
    async () => {
      const route = router.currentRoute.value;
      const { authenticate, ...rest } = route.query;

      if (authenticate === 'automatic' && !isUserLoading.value) {
        await router.replace({
          path: route.path, // enforces the preservation of the trailing slash
          query: rest,
          hash: route.hash,
        });
        if (!user.value) {
          const authLink = buildAuthLink({
            intent: 'login',
            page: route?.meta?.productCtaPageParam,
            learnRedirectPath: route.path,
            apiDomain: apiDomain.value,
          });
          authenticationInitiated.value = true;
          setTimeout(() => {
            window.location.assign(authLink);
          }, fakeAuthenticateDelayInMS);
        }
      }
    },
    { immediate: true },
  );

  return { authenticationInitiated };
};

export const useSessionLockout = () => {
  const queryClient = useQueryClient();

  watch(
    sessionLockoutState,
    (_oldValue, newValue) => {
      if (newValue?.isLocked === true) {
        clearAuthState();
        queryClient.removeQueries({
          predicate: (query: Query) =>
            !(query.meta as QueryMetaExtended)?.isContentQuery,
        });
      }
    },
    { immediate: true, deep: true },
  );
  return { sessionLockoutState };
};
