import { onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useHead } from '@vueuse/head';

declare global {
  interface Window {
    // eslint-disable-next-line
    cookieconsent?: { initialise: Function };
    Sprig?: any;
  }
}

export const cookieConsentConfig = {
  palette: {
    popup: {
      background: '#27265d',
    },
    button: {
      background: '#188b8b',
    },
  },
  theme: 'edgeless',
  content: {
    href: 'https://snyk.io/policies/privacy/',
  },
};

export const useCookieConsent = () => {
  onMounted(() => {
    window.addEventListener('load', () => {
      if (!('__PRERENDER_INJECTED' in window)) {
        if (window.cookieconsent) {
          setTimeout(() => {
            window.cookieconsent?.initialise(cookieConsentConfig);
          }, 2000);
        }
      }
    });
  });
};

export const metaDataText = {
  title: 'Free Interactive Secure Development Training',
  description:
    'Snyk Learn is developer-first security education that offers free interactive lessons on how to fix vulnerabilities in applications, containers, and IaC.',
};

export const useAppHeadMetadata = () => {
  const route = useRoute();

  useHead({
    title: metaDataText.title,
    htmlAttrs: { lang: 'en-US' },
    meta: [
      { charset: 'utf-8' },
      {
        key: 'description',
        name: 'description',
        content: metaDataText.description,
      },
      {
        key: 'og:title',
        property: 'og:title',
        content: metaDataText.title,
      },
      {
        key: 'og:description',
        property: 'og:description',
        content: metaDataText.description,
      },
      {
        key: 'og:url',
        property: 'og:url',
        content:
          typeof window === 'undefined'
            ? `${process.env.VUE_APP_ORIGIN}${route.path}`
            : window.location.href,
      },
      {
        key: 'og:site_name',
        property: 'og:site_name',
        content: 'Snyk Learn',
      },
      { key: 'og:type', property: 'og:type', content: 'website' },
      {
        key: 'og:image',
        property: 'og:image',
        content:
          'https://res.cloudinary.com/snyk/image/upload/c_pad,b_auto,h_630,w_1200/v1632481623/snyk-learn/snyk-learn-share-image.png',
      },
      {
        key: 'twitter:card',
        name: 'twitter:card',
        content: 'summary_large_image',
      },
      { key: 'twitter:site', name: 'twitter:site', content: 'snyksec' },
      {
        key: 'twitter:image',
        name: 'twitter:image',
        content:
          'https://res.cloudinary.com/snyk/image/upload/c_pad,b_auto,h_600,w_1200/v1632481623/snyk-learn/snyk-learn-share-image.png',
      },
    ],
  });
};
