<template>
  <section class="navigation-bar">
    <div class="navigation-bar__grid">
      <span>
        Learning Path |
        <router-link :to="learningPathUrl" @click="emit('backToLearningPath')">
          {{ learningPathTitle }}
        </router-link>
      </span>
      <div class="navigation-bar__grid--prevnext">
        <span>
          <router-link
            v-if="previousLessonUrl"
            :to="previousLessonUrl"
            @click="emit('prevLesson')"
          >
            <ArrowLeftIcon :size="16" /> Previous lesson
          </router-link>
          <p v-else class="disabled">
            <ArrowLeftIcon :size="16" /> Previous lesson
          </p>
        </span>

        <span>
          <router-link
            v-if="nextLessonUrl"
            :to="nextLessonUrl"
            @click="emit('nextLesson')"
          >
            Next lesson <ArrowRightIcon :size="16" />
          </router-link>
          <p v-else class="disabled">
            Next lesson <ArrowRightIcon :size="16" />
          </p>
        </span>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import ArrowRightIcon from '@patchui/icons/ArrowRight.vue';
import ArrowLeftIcon from '@patchui/icons/ArrowLeft.vue';

interface NavigationBarProps {
  learningPathTitle: string;
  learningPathUrl: string;
  previousLessonUrl?: string;
  nextLessonUrl?: string;
}
const emit = defineEmits(['nextLesson', 'prevLesson', 'backToLearningPath']);

defineProps<NavigationBarProps>();
</script>
<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';
@import '~@/mixin';
.navigation-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99;

  background: #f3f4fc;

  width: 100%;
  height: 60px;

  &__grid {
    height: 100%;
    max-width: $pageMaxWidth;
    margin: 0 auto;

    display: grid;
    grid-template-columns: 1fr;
    align-items: center;

    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 700;

    padding-left: token('space.layout.xsmall');

    @include media-query(small) {
      grid-template-columns: 66fr 33fr;
      grid-template-rows: 1fr auto;
      gap: 0 space(xxl);
    }

    &--prevnext {
      display: none;
      @include media-query(small) {
        display: flex;
        justify-content: space-between;
        margin-right: token('space.layout.small');
      }
    }

    a.disabled {
      color: grey;
      cursor: default;
    }
  }

  span:nth-child(1) {
    color: token('color.brand.steel');
  }
}
::v-deep(.icon) {
  vertical-align: middle;
}
</style>
