<template>
  <footer class="footer">
    <div class="footer__wrapper">
      <section class="footer__top page-width-limit">
        <a
          href="https://snyk.io/?loc=learn"
          title="Snyk"
          class="footer__top__logo"
        >
          <img
            src="https://res.cloudinary.com/snyk/image/upload/v1642427601/snyk-learn/Frame_1.svg"
            alt="Snyk logo"
            width="160"
            height="90"
            loading="lazy"
          />
        </a>
        <div class="footer__top__links-box">
          <a
            href="https://snyk.io/schedule-a-demo/?cta=schedule-demo/?loc=learn"
            class="footer__top__links-box__link"
            ><span>Book a demo</span></a
          >
          <SnykAppCta text="Sign up for free" origin="footer" intent="signup" />
        </div>
      </section>
      <hr />
      <div class="nav__wrapper page-width-limit">
        <nav
          v-for="navGroup in navGroups"
          :key="navGroup.title"
          class="nav__group"
        >
          <h3 class="nav__group__heading">{{ navGroup.title }}</h3>
          <ul class="nav__list">
            <li
              v-for="navItem in navGroup.items"
              :key="navItem.label"
              class="nav__list__item"
            >
              <a
                v-if="navItem.href"
                :href="navItem.href"
                class="nav__list__item__link"
              >
                {{ navItem.label }}
              </a>
              <router-link
                v-if="navItem.to"
                :to="navItem.to"
                role="link"
                class="nav__list__item__link"
              >
                {{ navItem.label }}
              </router-link>
            </li>
          </ul>
        </nav>
        <nav class="nav__group">
          <h3 class="nav__group__heading nav__group__heading--compact">
            <a
              href="https://snyk.io/?loc=learn"
              title="Snyk | Open Source Security Platform"
            >
              <img
                src="https://res.cloudinary.com/snyk/image/upload/v1643016564/snyk-learn/snyk-wordmark.svg"
                alt="Snyk | Open Source Security Platform"
                width="68"
                height="35"
                loading="lazy"
              />
            </a>
          </h3>
          <ul class="nav__list">
            <li class="nav__list__item nav__list__item--wide">
              <p>
                Snyk is an open source security platform designed to help
                software-driven businesses enhance developer security. Snyk's
                dependency scanner makes it the only solution that seamlessly
                and proactively finds, prioritizes and fixes vulnerabilities and
                license violations in open source dependencies and container
                images.
              </p>
            </li>
          </ul>
          <nav class="nav__social">
            <h3 class="nav__list__item__label nav__list__item__label--emphasis">
              Track our development
            </h3>
            <ul class="list-social">
              <li
                v-for="socialItem in socialItems"
                :key="socialItem.title"
                class="list-social__item"
                :class="socialItem.extraClasses"
              >
                <a
                  :href="socialItem.href"
                  :title="socialItem.title"
                  rel="noopener noreferrer"
                >
                  <img
                    :src="socialItem.imageSrc"
                    :alt="socialItem.imageTitle"
                    :height="socialItem.height"
                    loading="lazy"
                  />
                </a>
              </li>
            </ul>
          </nav>
        </nav>
      </div>

      <div class="about-snyk page-width-limit">
        <p>
          © {{ currentYear }} Snyk Limited<br />
          Registered in England and Wales | Company number: 09677925<br />
          Registered address: Highlands House, Basingstoke Road, Spencers Wood,
          Reading, Berkshire, RG7 1NT.
        </p>
      </div>
    </div>
    <div class="footer-waves-wrapper">
      <img
        loading="lazy"
        src="https://res.cloudinary.com/snyk/image/upload/snyk-learn/wave-footer.svg"
        alt="Footer Wave Bottom"
      />
    </div>
  </footer>
</template>

<script setup lang="ts">
import { getNavGroups, socialItems } from './footerData';
import SnykAppCta from '../Button/SnykAppCta.vue';

const navGroups = getNavGroups();
const currentYear = new Date().getFullYear();
</script>

<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';

.footer {
  background-color: $codeContentBackground;
  color: white;

  @media print {
    display: none;
  }

  &__wrapper {
    padding-top: token('space.l');
  }

  .page-width-limit {
    max-width: $pageMaxWidth;
    width: 100%;
    margin: 0 auto;
    padding: 0 rem($mobileContainerPadding);

    @include media-query(small) {
      padding: 0 rem($containerPadding);
      max-width: $pageMaxWidth;
    }
  }
  hr {
    border: none;
    border-bottom: 1px solid rgba(109, 109, 156, 0.1);
  }

  &__top {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: space();
    padding-bottom: token('space.m');

    @include media-query(medium) {
      flex-direction: row;
      justify-content: space-between;
    }
    &__logo {
      min-width: rem(160px);
    }

    &__links-box {
      text-align: right;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: token('space.l');

      @include media-query(xsmall) {
        flex-direction: row;
        justify-content: flex-end;
      }

      &__link {
        height: rem(40px);
        width: rem(160px);
        display: flex;
        justify-content: center;
        align-items: center;
        @include typography('typography.brand.body');
        color: token('color.brand.white');
      }
    }
  }

  .nav {
    &__wrapper {
      display: flex;
      flex-direction: column;
      gap: space(l) space(m);
      flex-wrap: wrap;
      margin-top: token('space.xxl');
      @include media-query('xsmall') {
        flex-direction: row;
      }
    }

    &__group {
      flex: 1;
      &:last-child {
        flex: 2;
      }

      &__heading {
        font-family: token('typography.family.poppins');
        font-weight: 600;
        font-size: rem(18px);
        line-height: rem(28px);
        margin-bottom: token('space.l');
        color: token('color.brand.white');
      }
      &__heading--compact {
        margin-bottom: 0;
      }
    }

    &__list {
      list-style: none;
      padding: 0;

      &__item {
        margin: 0;
        min-width: rem(120px);
        max-width: rem(300px);
        & > * {
          @include typography('typography.product.body-small');
        }

        &--wide {
          min-width: rem(220px);
        }

        &__link {
          display: inline-block;
          line-height: rem(30px);
          color: token('color.brand.white');
          text-decoration: none;
          transition: transform 0.2s ease-in-out;
          &:hover,
          &:focus {
            transform: translateX(space(xxs));
          }
        }

        &__label {
          color: token('color.brand.white');
          font-size: 1.125rem;
          line-height: 1.75rem;
          font-weight: 600;
          margin-top: token('space.l');
          margin-bottom: token('space.m');
          &--emphasis {
            text-transform: uppercase;
            letter-spacing: 0.12em;
          }
        }
      }
    }
  }

  .nav__social {
    margin-top: space(m);

    @media (min-width: rem(320px)) {
      flex-direction: column;
      margin-right: 0;
    }
    @media (min-width: rem(860px)) {
      text-align: left;
      flex-basis: 25%;
      margin-top: 0;
    }

    .list-social {
      list-style: none;
      margin: 0 0 space(default) 0;
      padding: 0;

      .list-social__item {
        display: inline-block;
        height: rem(20px);
        list-style-type: none;
        margin: 0 space(default) token('space.l') 0;
        padding: 0;

        img {
          opacity: 1;
          &:hover {
            opacity: 0.8;
          }
        }

        &--podcast-ad {
          width: rem(210px);
          height: rem(68px);
          display: block;

          img {
            opacity: 1;

            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }
  }
}

.about-snyk {
  color: #fff;
  p {
    @include typography('typography.product.body-small-strong');
    line-height: 1.5rem;
    padding: 0;
    margin: 0;
  }
}

.footer-waves-wrapper {
  display: flex;
  align-items: flex-end;
  img {
    width: 100%;
    max-width: 100%;
  }
}
</style>
