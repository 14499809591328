<template>
  <div :class="tableOfContentsClasses" title="table of contents">
    <div class="tableOfContents__row tableOfContents__row--header">
      <BaseDropdown
        v-if="icon && alternatePages && alternatePages.length > 0"
        class="tableOfContents__dropdown"
      >
        <template #handle>
          <BaseButton
            size="small"
            variant="link"
            class="tableOfContents__dropdown-handle"
            :title="currentlySelectedLanguageAccessibilityText"
          >
            <Icon
              :name="icon"
              :size="24"
              class="tableOfContents__icon"
              role="presentation"
              title=""
              aria-label=""
            />
            <template #rightIcon
              ><MenuDownIcon class="tableOfContents__dropdown-handle-icon"
            /></template>
          </BaseButton>
        </template>
        <BaseDropdownMenu>
          <DropdownLink
            v-for="link in alternatePages"
            :key="link.label"
            v-bind="link"
            @click="onAlternatePageLinkClick"
          />
        </BaseDropdownMenu>
      </BaseDropdown>
      <div
        v-else-if="icon"
        class="tableOfContents__dropdown tableOfContents__dropdown--fake"
      >
        <Icon
          :name="icon"
          :size="24"
          class="tableOfContents__icon"
          :title="currentlySelectedLanguageAccessibilityText"
          :aria-label="currentlySelectedLanguageAccessibilityText"
        />
      </div>

      <div class="tableOfContents__row-title">{{ title }}</div>
    </div>
    <nav aria-label="Table Of Contents">
      <div
        v-for="(contentRow, rowIndex) in contents"
        :key="contentRow.id ? contentRow.id : contentRow.title"
        data-snyk-test="Table Of Contents: row"
        :class="[
          'tableOfContents__row',
          { 'tableOfContents__row--highlighted': contentRow.isHighlighted },
        ]"
      >
        <TableOfContentsReadIndicator
          :title="contentRow.title"
          :read-state="contentRow.readState"
          :is-loading="progressLoading"
          :icon="contentRow.icon"
          :is-highlighted="contentRow.isHighlighted"
        />
        <router-link
          :title="`Go to section ${contentRow.title.toLowerCase()}`"
          :to="contentRow.href"
          class="tableOfContents__row-title"
          @click="onRowLinkClick(rowIndex)"
        >
          {{ contentRow.title }}
        </router-link>
        <aside v-if="contentRow.asideText" class="tableOfContents__row-aside">
          {{ contentRow.asideText }}
        </aside>
      </div>
    </nav>
  </div>
</template>

<script lang="ts" setup>
import { PropType, computed, toRefs } from 'vue';
import BaseDropdown from '@patchui/productcl/components/BaseDropdown/BaseDropdown.vue';
import Icon from '../Icon/Icon.vue';
import DropdownLink from '../Breadcrumbs/DropdownLink.vue';
import TableOfContentsReadIndicator from './TableOfContentsReadIndicator.vue';
import {
  TableOfContentsAlternatePage,
  TableOfContentsEntry,
} from './TableOfContents.interfaces';
import BaseDropdownMenu from '@patchui/productcl/components/BaseDropdownMenu/BaseDropdownMenu.vue';
import MenuDownIcon from '@patchui/icons/MenuDown.vue';
import BaseButton from '@patchui/productcl/components/BaseButton/BaseButton.vue';

const props = defineProps({
  /**
   * Title to show in the header bar of the table of contents
   */
  title: {
    type: String,
    required: true,
  },

  /**
   * Icon to display alongside the title, usually indicating the current ecosystem for the page.
   */
  icon: {
    type: String,
    required: false,
  },

  /**
   * A list of pages that have (for example) been translated to other programming languages,
   * but contain the same overall content.
   */
  alternatePages: {
    type: Array as PropType<TableOfContentsAlternatePage[]>,
    required: false,
  },

  /**
   * Array of Table of Contents entries, their link locations, titles and their current state
   */
  contents: {
    type: Array as PropType<TableOfContentsEntry[]>,
    required: true,
  },

  /**
   * Whether the component should display with increased padding etc because it is fullscreen on
   * a mobile device.
   */
  fullscreen: {
    type: Boolean,
    required: false,
  },

  /**
   * Whether the user's current state for a section is still being loaded from an external data source.
   *
   * Will place a loading spinner animation alongside the TOC Entry.
   */
  progressLoading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['rowLinkClicked', 'alternatePageLinkClicked']);

const { fullscreen, title, icon, alternatePages } = toRefs(props);

const tableOfContentsClasses = computed(() => {
  return {
    tableOfContents: true,
    'tableOfContents--fullscreen': fullscreen.value,
  };
});

const currentlySelectedLanguageAccessibilityText = computed(() => {
  let baseText = `Currently viewing ${title.value}`;

  if (icon?.value) baseText += ` in ${icon.value}`;
  else baseText += '.';

  if (alternatePages?.value && alternatePages.value.length > 0)
    return `${baseText}. ${alternatePages.value.length} languages are available`;

  return baseText;
});

const onRowLinkClick = (rowIndex: number) => {
  const rowNumber = rowIndex + 1;
  emit('rowLinkClicked', { rowNumber });
};
const onAlternatePageLinkClick = () => {
  emit('alternatePageLinkClicked');
};
</script>

<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';

$borderStyle: 1px solid $neutral90;
$horizontalPadding: 20px;

.tableOfContents {
  $self: &;
  overflow: hidden;
  border-radius: 4px;
  border: $borderStyle;

  &__row {
    display: flex;
    position: relative;
    border-bottom: $borderStyle;
    padding: space() $horizontalPadding;
    gap: $horizontalPadding;
    align-items: center;

    &--header {
      color: $midnight;
      background: $snow;

      padding: space(default) $horizontalPadding;
      padding-left: $horizontalPadding - 4px;
      line-height: 28px;
      min-height: 56px;
      gap: inherit;
      align-items: flex-start;

      #{$self}__row-title {
        font-family: token('typography.family.poppins');
        font-weight: 600;
        font-size: rem(18px);
      }
    }

    &:last-of-type {
      border-bottom: 0;
    }

    &--highlighted::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      height: 100%;
      width: rem(6px);
      background-color: token('color.brand.electric-blue');
    }
  }

  &__dropdown {
    margin-right: space();

    &--fake {
      height: 24px;
    }

    &-handle {
      display: flex;
      padding: 0 0 0 space(xxs);
      color: token('color.brand.midnight');
    }

    &-handle-icon {
      padding: 0;
      margin-left: -8px;
    }

    & .dropdown-menu__menu {
      width: 120px;
    }
  }

  &__row-title {
    flex-grow: 2;
    margin-top: 3px;
  }

  &__icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  &--fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    border-radius: 0;
    border: 0;

    #{$self}__row {
      padding: 18px 64px;

      &:last-of-type {
        border-bottom: $borderStyle;
      }
    }
  }
}
</style>
