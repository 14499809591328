import {
  SET_STEP_PROGRESS,
  SET_LESSON_PROGRESS,
  SET_LOADING,
  SET_CURRENTLY_VIEWED_STEP_ID,
} from '../mutation-types';
import { Commit } from 'vuex';
import { authenticatedClient } from '../../lib/apiClient';
import { OpenApiResponse } from '../../lib/utils/type-utilities';

export interface IStepProgress {
  stepId?: string;
  lessonId: string;
  status: 'read' | 'partial' | 'unread';
}

export type Progress = Partial<Record<string, IStepProgress[]>>;

export interface State {
  lessonsProgress: Progress;
  isLoadingLessonProgress: boolean;
  currentlyViewedStepId: string | null;
  _viewedStepsQueue: string[];
}
const defaultState: State = {
  lessonsProgress: {},
  isLoadingLessonProgress: false,
  currentlyViewedStepId: null,
  _viewedStepsQueue: [],
};

export default {
  namespaced: true,
  state: defaultState,
  getters: {
    currentlyViewedStepId(state: State) {
      return state.currentlyViewedStepId;
    },
    lessonProgress:
      (state: State) =>
      (lessonId: string): IStepProgress[] => {
        return state.lessonsProgress[lessonId] ?? [];
      },
    isStepCompleted:
      (_state: State, getters: any) =>
      (lessonId: string, stepId: string): boolean => {
        const lessonProgress: IStepProgress[] =
          getters.lessonProgress(lessonId);
        return lessonProgress.some(
          (step) => step.stepId === stepId && step.status === 'read',
        );
      },
    isLoadingLessonProgress: (state: State): boolean =>
      state.isLoadingLessonProgress,
  },

  actions: {
    async fetchLessonProgress(
      { commit }: { commit: Commit },
      lessonId: string,
    ): Promise<void> {
      try {
        commit(SET_LOADING, { isLoading: true });
        const response = await authenticatedClient.get<
          OpenApiResponse<'getAuthenticatedUserProgressByLesson'>
        >(`/v1/learn/user/progress/${lessonId}`);
        const lessonProgress: IStepProgress[] = response.data.stepsCompleted
          ? response.data.stepsCompleted.map((step) => ({
              stepId: step.stepId,
              lessonId: lessonId,
              status: 'read',
            }))
          : [];
        commit(SET_LESSON_PROGRESS, { lessonId, lessonProgress });
        commit(SET_LOADING, { isLoading: false });
      } catch (e) {
        commit(SET_LOADING, { isLoading: false });
        window.console.error(JSON.stringify(e));
      }
    },
    stepVisibilityChanged(
      { commit }: { commit: Commit },
      payload: { stepId: string; isVisible: boolean },
    ) {
      commit(SET_CURRENTLY_VIEWED_STEP_ID, payload);
    },
    async setStepProgress(
      {
        commit,
        rootGetters,
      }: {
        commit: Commit;
        rootGetters: any;
      },
      payload: IStepProgress,
    ): Promise<void> {
      try {
        commit(SET_STEP_PROGRESS, payload);
        const user = rootGetters['user/getLoggedInUser'];
        if (user) {
          await authenticatedClient.put(`/v1/learn/user/progress`, {
            lessonId: payload.lessonId,
            completedStepId: payload.stepId,
          });
        }
      } catch (e) {
        //TODO: capture with datadog frontend error monitoring
        window.console.error(JSON.stringify(e));
      }
    },
  },

  mutations: {
    [SET_STEP_PROGRESS](state: State, payload: IStepProgress): void {
      const lessonProgress = state.lessonsProgress[payload.lessonId];
      const filteredProgress = (lessonProgress || []).filter(
        (step) => step.stepId !== payload.stepId,
      );

      state.lessonsProgress[payload.lessonId] = [...filteredProgress, payload];
    },
    [SET_LESSON_PROGRESS](
      state: State,
      payload: { lessonId: string; lessonProgress: IStepProgress[] },
    ): void {
      state.lessonsProgress[payload.lessonId] = payload.lessonProgress;
    },
    [SET_CURRENTLY_VIEWED_STEP_ID](
      state: State,
      payload: { stepId: string; isVisible: boolean },
    ): void {
      if (payload.isVisible) {
        state._viewedStepsQueue.push(payload.stepId);
      } else {
        state._viewedStepsQueue = state._viewedStepsQueue.filter(
          (stepId) => stepId !== payload.stepId,
        );
      }
      state.currentlyViewedStepId = state._viewedStepsQueue[0] ?? null;
    },
    [SET_LOADING](state: State, payload: { isLoading: boolean }): void {
      state.isLoadingLessonProgress = payload.isLoading;
    },
  },
};
