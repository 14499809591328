<template>
  <BaseDropdownMenuItem class="list-item">
    <router-link v-slot="{ navigate, href }" :to="url" custom>
      <BaseDropdownMenuLink
        :class="routerLinkClasses"
        :href="href"
        @click="navigate"
      >
        <Icon v-if="icon" :name="icon" class="list-item__link-icon" />
        {{ label }}
      </BaseDropdownMenuLink>
    </router-link>
  </BaseDropdownMenuItem>
</template>

<script lang="ts" setup>
import { PropType, toRefs, computed } from 'vue';
import Icon from '../Icon/Icon.vue';
import type { IconName } from '../Icon/iconCommon';
import BaseDropdownMenuItem from '@patchui/productcl/components/BaseDropdownMenuItem/BaseDropdownMenuItem.vue';
import BaseDropdownMenuLink from '@patchui/productcl/components/BaseDropdownMenuLink/BaseDropdownMenuLink.vue';

const props = defineProps({
  url: { type: String, required: true },
  label: { type: String, required: true },
  icon: { type: String as PropType<IconName> },
  isActive: { type: Boolean },
});

const { isActive } = toRefs(props);

const routerLinkClasses = computed(() => ({
  'list-item__link': true,
  active: isActive.value,
}));
</script>

<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';

*:focus-visible {
  outline: global(focusState, outline);
}
.list-item {
  margin: 0;
  list-style: none;
  padding: space(xxs) space(m) space(xxs) space(s);

  .active {
    color: $originalBrandPurple;
  }

  &__link {
    display: flex;
    color: token('color.neutral.90');

    &-icon {
      display: flex;
      align-items: center;
      margin-right: space(xxs);
    }
    &-label {
      white-space: nowrap;
    }

    &-menu-down {
      margin-left: space(xxxs);
    }
  }
}
</style>
