import { computed, Ref } from 'vue';
import { CourseInstance, Graph, Organization, Offer } from 'schema-dts';
import { useHeadRaw } from '@vueuse/head';
import { components } from '../../api/learn-backend-v1-schema';

const snykOrganization: Organization = {
  name: 'Snyk Ltd',
  '@id': 'https://snyk.io/#organization',
  '@type': 'Organization',
};

const getCourseWorkLoad = (numOfLessonsInLearningPath: number) => {
  const minutes = String(numOfLessonsInLearningPath * 30);
  return `PT${minutes}M`;
};

const offers = [
  {
    '@type': 'Offer',
    category: 'Free',
  } as Offer,
];

export const useLearningPathStructuredData = (
  learningPathDetails: Ref<components['schemas']['LearningPathDetails']>,
) => {
  useHeadRaw(
    computed(() => {
      if (learningPathDetails.value === undefined) return {};
      const numOfLessonsInLearningPath =
        learningPathDetails.value.modules.reduce(
          (acc, module) => (acc += module.lessons.length),
          0,
        );
      const graph: Graph = {
        '@context': 'https://schema.org',
        '@graph': [
          {
            //Required
            '@type': 'Course',
            name: learningPathDetails.value.title,
            description: learningPathDetails.value.description,
            provider: snykOrganization,
            isAccessibleForFree: true,
            educationalCredentialAwarded: 'Snyk Learn Certificate',
            hasCourseInstance: {
              '@type': 'CourseInstance',
              courseMode: ['Online'],
              courseWorkload: getCourseWorkLoad(numOfLessonsInLearningPath),
            } as CourseInstance,
            offers,
            //Optional
            author: snykOrganization,
            inLanguage: 'en-US',
            ...(learningPathDetails.value.seoKeywords && {
              keywords: learningPathDetails.value.seoKeywords.join(','),
            }),
            ...(learningPathDetails.value.datePublished && {
              datePublished: learningPathDetails.value.datePublished,
            }),
          },
        ],
      };
      const headConfig = {
        script: [
          {
            type: 'application/ld+json',
            innerHTML: JSON.stringify(graph),
          },
        ],
      };
      return headConfig;
    }),
  );
};
export default useLearningPathStructuredData;
