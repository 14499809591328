import { langMapper } from '../../../../lib/utils/mappers';
import { EnrichedLessonOverview } from './TabLessons.interfaces';
import { LessonMetadata } from '../../../../lib/lessonMetadata';
import { Ref } from 'vue';
import { OpenApiResponse } from '../../../../lib/utils/type-utilities';

export function getTabLessonsRows(
  lessons: Ref<LessonMetadata[] | undefined>,
  data: Ref<OpenApiResponse<'getStatsOrganizationLessonsOverview'> | undefined>,
) {
  return () =>
    getTabLessonRowsCb(lessons.value ?? [], data.value?.lessonsOverview).map(
      (lessonOverview) => {
        return {
          lessonName: lessonOverview.lesson.title,
          views: lessonOverview.inProgressCount + lessonOverview.completedCount,
          ...lessonOverview,
        };
      },
    );
}

function sortEnrichedLessonsByTotalViews(
  a: EnrichedLessonOverview,
  b: EnrichedLessonOverview,
) {
  const bTotal = b.inProgressCount + b.completedCount;
  const aTotal = a.inProgressCount + a.completedCount;

  return bTotal - aTotal;
}

export function getTabLessonRowsCb(
  lessons: LessonMetadata[],
  lessonsOverview:
    | {
        completedCount: number;
        inProgressCount: number;
        todoCount: number;
        lessonId: string;
      }[]
    | undefined,
) {
  if (lessons.length === 0) return [];

  return (
    lessonsOverview
      ?.flatMap((lo) => {
        const lesson = lessons.find(({ lessonId }) => lessonId === lo.lessonId);

        if (!lesson) return [];

        return [
          {
            lesson,
            ecosystem: langMapper[lesson!.ecosystem],
            ...lo,
          } as EnrichedLessonOverview,
        ];
      })
      .sort(sortEnrichedLessonsByTotalViews) ?? []
  );
}
