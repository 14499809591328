// todo add errors logging (but need to add some error logger fist, e.g. datadog), separate PR
import PluginSegment from '@itly/plugin-segment';
import itly, { Environment } from './itly';
import { AnalyticsConfig } from './config';
import { itlySkipTrackPlugin } from './plugins/itlySkipTrackingPlugin';
import { Validation } from '@itly/sdk';

export function initItly(analyticsConfig: AnalyticsConfig): void {
  const segment = new PluginSegment(analyticsConfig.segmentWriteKey);
  itly.load({
    environment: analyticsConfig.itlyEnvironment as Environment,
    validation: Validation.SkipOnInvalid,
    plugins: [segment, itlySkipTrackPlugin],
  });
}
