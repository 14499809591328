import { EnrichedLessonMetadata } from '../../hooks/useEnrichedLessons';
import { UserLessonProgress } from './../../types';
import type { LessonMetadata } from '../lessonMetadata';
import { sortLessonsByDatePublishedCb } from './sortHelper';
import { ValidStatus } from '../../store/modules/lessons';
/**
 * Get ten of the latest lessons in order.
 */
export const filterLatestLessons = (
  lessonsMetadata: LessonMetadata[] | EnrichedLessonMetadata[],
  limit = 10,
): LessonMetadata[] | EnrichedLessonMetadata[] => {
  const mappedLessons = lessonsMetadata.map((lessonMetadata) => {
    return lessonMetadata;
  });

  const publishedLessons = mappedLessons.filter((lesson) => lesson.published);

  return (
    publishedLessons
      // Sort lessons by date published
      .sort(sortLessonsByDatePublishedCb)
      // Get only ten lessons
      .slice(0, limit)
  );
};

export const buildLessonPath = (slug: string) => {
  // We add a slash to the lessons url
  // This is because we use the pre-render plugin
  // On Google cloud we need the / to have it server the index.html file
  // Otherwise it will redirect to <lessonUrl>/index.html
  return `/lesson/${slug}/`;
};

export const getLessonPath = (lessonMetadata: LessonMetadata) => {
  return buildLessonPath(lessonMetadata.slug);
};

export const getLessonCompletionPercentage = (
  lessonProgress: UserLessonProgress,
): number => {
  if (!lessonProgress.stepsCompletedCount || !lessonProgress.stepsTotalCount) {
    return 0;
  }

  return Math.min(
    Number(
      lessonProgress.stepsCompletedCount / lessonProgress.stepsTotalCount,
    ) * 100,
    100,
  );
};

const THIRTY_DAYS_MS = 60 * 60 * 24 * 30 * 1000;
export const isNewLesson = (lesson: LessonMetadata) => {
  return (
    new Date(lesson.datePublished).getTime() >
    new Date().getTime() - THIRTY_DAYS_MS
  );
};

export const getLessonStatus = (
  lessonProgress: UserLessonProgress | undefined,
  completedPercentage: number,
): ValidStatus => {
  return lessonProgress?.isCompleted
    ? 'completed'
    : completedPercentage > 0
    ? 'inProgress'
    : 'todo';
};
