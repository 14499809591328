<template>
  <BaseDropdown
    :close-on-click="false"
    class="filters-dropdown"
    :disable-teleport="disableTeleport"
    :padding="false"
  >
    <template #handle>
      <BaseButton size="medium">
        {{ filterText }}
        <template #rightIcon><FilterVariantIcon /></template>
      </BaseButton>
    </template>
    <BaseDropdownMenu
      class="filters-dropdown__dropdown-menu"
      aria-label="Filter by ecosystem"
      role="listbox"
    >
      <BaseDropdownMenuItem class="filters-dropdown__dropdown-header">
        <p>Filter</p>
        <BaseButton
          variant="link"
          class="filters-dropdown__dropdown-button"
          size="small"
          @click="clearAllFilters"
        >
          Clear all
        </BaseButton>
      </BaseDropdownMenuItem>
      <BaseDropdownMenuItem>
        <p class="filters-dropdown__dropdown-menu-item-title">Categories</p>
        <BaseCheckbox
          v-for="{ ecosystem, label } in ecosystems"
          :key="ecosystem"
          role="option"
          :aria-label="label"
          :label="label"
          :value="ecosystem"
          :model-value="filteredEcosystems.has(ecosystem)"
          @update:modelValue="
            (newVal) => updateFilteredEcosystems(newVal, ecosystem as LessonEcosystem)
          "
        />
      </BaseDropdownMenuItem>
    </BaseDropdownMenu>
  </BaseDropdown>
</template>

<script setup lang="ts">
import { computed, inject } from 'vue';
import BaseDropdownMenu from '@patchui/productcl/components/BaseDropdownMenu/BaseDropdownMenu.vue';
import BaseDropdownMenuItem from '@patchui/productcl/components/BaseDropdownMenuItem/BaseDropdownMenuItem.vue';
import BaseDropdown from '@patchui/productcl/components/BaseDropdown/BaseDropdown.vue';
import BaseCheckbox from '@patchui/productcl/components/BaseCheckbox/BaseCheckbox.vue';
import BaseButton from '@patchui/productcl/components/BaseButton/BaseButton.vue';
import FilterVariantIcon from '@patchui/icons/FilterVariant.vue';
import { LessonEcosystem } from '../../../lib/lessonMetadata';
import { useListEcosystems } from '../../../hooks/lessonsHooks';
import itly from '../../../lib/analytics/itly';

export interface IFiltersProps {
  filteredEcosystems: Set<string>;
}

const props = defineProps<IFiltersProps>();

const disableTeleport = inject('disableTeleport', false);

const { ecosystems } = useListEcosystems();

const emit = defineEmits<{
  (event: 'clearAllFilters'): void;
  (event: 'update:filteredEcosystems', ecosystems: Set<string>): void;
}>();

const clearAllFilters = function () {
  emit('clearAllFilters');
};

const filterText = computed(() => {
  const filterText = 'Filter';
  const filtersCount = props.filteredEcosystems.size;
  if (filtersCount === 0) {
    return filterText;
  } else if (filtersCount <= 9) {
    return `${filterText}s (${filtersCount})`;
  }
  return `${filterText}s (9+)`;
});

const updateFilteredEcosystems = async function (
  newVal: boolean,
  ecosystem: LessonEcosystem,
) {
  const ecosystemsToSave = new Set(props.filteredEcosystems);

  if (newVal) {
    ecosystemsToSave.add(ecosystem);
  } else {
    ecosystemsToSave.delete(ecosystem);
  }

  emit('update:filteredEcosystems', ecosystemsToSave);

  if (ecosystemsToSave.has(ecosystem)) {
    itly.ecosystemIsSelected({
      ecosystem: ecosystem,
    });
  }
};
</script>

<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';
.filters-dropdown {
  border-radius: space(xxs);
  padding: 0 rem(12px);
  border: 1px solid token('color.neutral.40');
  color: token('color.neutral.80');

  &.dropdown--open {
    border: 1px solid token('color.blue.30');
    color: token('color.brand.electric-blue');
    background-color: token('color.blue.10');
  }

  &__dropdown-handle {
    white-space: nowrap;
  }

  &__dropdown-menu {
    padding: 0 token('space.xs');
  }

  &__dropdown-checkbox {
    padding-top: space(default);
    padding-bottom: space(default);
  }

  &__dropdown-checkbox:first-of-type {
    padding-top: 0;
  }

  &__dropdown-checkbox:last-of-type {
    padding-bottom: 0;
  }

  &__dropdown-menu-item-title {
    margin-left: space(xs);
    padding-top: rem(12px);
    padding-bottom: space(xs);
    font-weight: 600;
    font-size: rem(16px);
    color: token('color.brand.midnight');
    margin-bottom: 0px;
    font-family: token('typography.family.poppins');
  }

  &__dropdown-header {
    display: flex;
    justify-content: space-between;
    min-width: 328px;
    border-bottom: 1px solid token('color.neutral.30');
    padding-bottom: space(xs);
    align-items: center;

    p {
      font-family: token('typography.family.poppins');
      font-style: normal;
      margin-left: space(xs);
      margin-bottom: 0px;
      font-size: rem(14px);
      font-weight: 600;
      letter-spacing: 0.12em;
      text-transform: uppercase;
      color: token('color.brand.midnight');
    }
  }

  &__dropdown-footer {
    padding: 0 space(xs) space(s) space(xs);

    span {
      display: block;
      color: token('color.neutral.80');
      margin-bottom: space(m);
      font-size: rem(16ox);
      font-weight: 400;
    }

    &__dropdown-button {
      color: token('color.blue.50');
    }
  }
}

.checkbox {
  margin-left: space(default);
  &--start {
    display: flex;
    color: token('color.neutral.80');
    margin-top: space(default);
    span {
      padding-left: space(s);
    }
    ::v-deep(.checkbox__label) {
      margin-top: 1px;
    }
    &:last-of-type {
      margin-bottom: space(s);
    }
    &:first-of-type {
      margin-top: 0px;
    }
  }
}
</style>
