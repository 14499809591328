<template>
  <OrgSelector
    :org-id="props.orgId"
    @validOrgSelected="onValidOrgSelected"
    @invalidOrgSelected="onInvalidOrgSelected"
    @orgClicked="onOrgClicked"
  />
  <div v-if="errorMessage">{{ errorMessage }}</div>
</template>

<script lang="ts" setup>
import OrgSelector, {
  InvalidOrgSelected,
} from '../../../components/SearchableSelect/OrgSelector.vue';
import { selectedOrg, selectedOrgHaveEntitlement } from './newAssignmentsStore';
import { provide, ref } from 'vue';
import { components } from '../../../api/learn-backend-v1-schema';
import { useRouter } from 'vue-router';

const props = defineProps<{
  orgId?: string;
}>();

const router = useRouter();
const errorMessage = ref<undefined | string>(undefined);

const onValidOrgSelected = (org: components['schemas']['Organization']) => {
  errorMessage.value = undefined;
  selectedOrg.value = org;
};
const onInvalidOrgSelected = (invalidOrgSelected: InvalidOrgSelected) => {
  errorMessage.value = invalidOrgSelected.errorMsg;
  selectedOrg.value = null;
};
const onOrgClicked = (orgClicked: components['schemas']['Organization']) => {
  if (orgClicked.id === selectedOrg.value?.id) return; // Do not reset values when same org is selected
  router.replace({
    name: 'NewAssignments',
    params: { orgId: orgClicked.id },
  });
  selectedOrg.value = null;
  selectedOrgHaveEntitlement.value = false;
  errorMessage.value = undefined;
};

provide('disableTeleport', false);
</script>

<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';
</style>
