import { get, MaybeRef } from '@vueuse/core';
import { fetchUsersStats } from '../../../../api/stats';
import { components } from '../../../../api/learn-backend-v1-schema';
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/vue-query';

export const useUsersStats = (orgId: MaybeRef<string>) => {
  return useQuery<
    {
      orgId: string;
      usersOverview: {
        user: components['schemas']['User'];
        lessonsViewed: number;
      }[];
    },
    AxiosError
  >({
    queryKey: ['orgUsersStats', orgId],
    queryFn: () => fetchUsersStats(get(orgId)),
  });
};
