import { RouteLocationNormalizedLoaded, useRouter } from 'vue-router';
import { onMounted, Ref, ref, watch, shallowRef, ShallowRef } from 'vue';

import { isPresent } from '../lib/utils/type-utilities';

interface UseQueryParamOptions {
  /**
   * Validation function to run against the query parameter(s). Any values that don't pass the
   * validation function will be omitted from the returned value.
   *
   * @default () => true
   */
  validator?: (value: string) => boolean;
}

const useQueryParamOptionDefaults = {
  validator: () => true,
} as const;

/**
 * Stores/retrieves a string from the query parameters.
 */
export const useQueryParam = <ParamValue extends string>(
  paramName: string,
  opts?: UseQueryParamOptions,
): [ShallowRef<ParamValue | null>, (value: string | null) => Promise<void>] => {
  const validator = opts?.validator || useQueryParamOptionDefaults.validator;

  const router = useRouter();

  const getVal = (currentRoute: RouteLocationNormalizedLoaded) => {
    const rawQueryValue = currentRoute.query[paramName];
    const queryValue = Array.isArray(rawQueryValue)
      ? rawQueryValue[0]
      : rawQueryValue;

    if (queryValue && validator(queryValue)) {
      return queryValue as ParamValue;
    } else {
      return null;
    }
  };
  const paramValue: ShallowRef<ParamValue | null> =
    shallowRef<ParamValue | null>(null); // shallowRef used due to ref generic typing issue https://github.com/vuejs/core/issues/2136

  onMounted(() => {
    paramValue.value = getVal(router.currentRoute.value);
  });

  watch(router.currentRoute, (newVal) => {
    paramValue.value = getVal(newVal);
  });

  const updateParam = async (value: string | null) => {
    await router.replace({
      path: router.currentRoute.value.path, // enforces the preservation of the trailing slash
      query: {
        ...router.currentRoute.value.query,
        [paramName]: value ? value : undefined,
      },
      hash: router.currentRoute.value.hash,
    });
  };

  return [paramValue, updateParam];
};

/**
 * Stores an array of strings as a CSV query parameter reactively.
 *
 * @param paramName Parameter name to store in the query string
 */
export const useQueryParamArray = (
  paramName: string,
  opts?: UseQueryParamOptions,
): [Ref<string[] | null>, (value: string[] | null) => Promise<void>] => {
  const validator = opts?.validator || useQueryParamOptionDefaults.validator;
  const router = useRouter();

  const getVal = (currentRoute: RouteLocationNormalizedLoaded) => {
    const rawQueryValue = currentRoute.query[paramName];
    if (!rawQueryValue) return null;

    const queryValue = Array.isArray(rawQueryValue)
      ? rawQueryValue
      : rawQueryValue?.split(',') ?? [];

    return queryValue.filter(isPresent).filter(validator);
  };

  const paramValue = ref<string[] | null>(null);

  onMounted(() => {
    paramValue.value = getVal(router.currentRoute.value);
  });

  watch(router.currentRoute, (newVal) => {
    paramValue.value = getVal(newVal);
  });
  const updateParam = async (value: string[] | null) => {
    await router.replace({
      path: router.currentRoute.value.path, // enforces the preservation of the trailing slash
      query: {
        ...router.currentRoute.value.query,
        [paramName]: value && value.length > 0 ? value?.join(',') : undefined,
      },
    });
  };

  return [paramValue, updateParam];
};
