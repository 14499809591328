<template>
  <div ref="scroller">
    <slot></slot>
    <div ref="endOfScroller"></div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue';
const emits = defineEmits(['onReachBottom']);
const endOfScroller = ref<Element | null>(null);
const ROOT_ELEMENT_VISIBILITY_MARGIN = '100px'; // How far from the bottom of the root element should the root element be visible before triggering the callback
onMounted(() => {
  const observer = new IntersectionObserver(
    (entries) => {
      if (entries[0].isIntersecting) emits('onReachBottom');
    },
    { rootMargin: ROOT_ELEMENT_VISIBILITY_MARGIN },
  );
  if (endOfScroller.value) {
    observer.observe(endOfScroller.value);
  }
});
</script>
