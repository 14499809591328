<template>
  <div id="app" :inert="authenticationInitiated || undefined">
    <div class="header">
      <Header />
    </div>
    <SessionLockoutModal v-if="sessionLockoutState.isLocked" />
    <AutomaticAuthModal v-if="authenticationInitiated" />
    <div class="content">
      <router-view />
    </div>
    <Footer />
  </div>
</template>

<script lang="ts" setup>
import { computed, watchEffect, onMounted } from 'vue';
import Header from '../components/Header/Header.vue';
import Footer from '../components/Footer/Footer.vue';
import {
  useLoginWatcher,
  useAutomaticAuthenticate,
  useSessionLockout,
} from './appAuthHooks';
import { useLogoutWatcher } from './useLogoutWatcher';
import { useCookieConsent, useAppHeadMetadata } from './appHooks';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

import { useUser } from '../hooks/useUser';
import AutomaticAuthModal from '../components/Modal/AutomaticAuthModal.vue';
import SessionLockoutModal from '../components/Modal/SessionLockoutModal.vue';
import { loadScript, TRD_ACC_ID } from './trendemon';
import { useLearningPathContextStorage } from '../pages/Lesson/useLearningPathContextStorage';
import { usePolarisEnvironment } from '../hooks/usePolarisEnvironment';

useAppHeadMetadata();
useCookieConsent();
const { authenticationInitiated } = useAutomaticAuthenticate();
const { sessionLockoutState } = useSessionLockout();
useLogoutWatcher();
useLoginWatcher();

const route = useRoute();

const isLogoutFlow = computed(() => route.query.logout === 'true');
const { user } = useUser({
  readOnly: isLogoutFlow.value,
});
const store = useStore();
watchEffect(() => {
  // TODO, we should not be doing this at the APP level
  if (user.value) {
    store.dispatch('user/setUserProjects');
  }
});

onMounted(() => {
  const w = window as any;
  if (!w.TRD_ACC_ID) {
    w.TRD_ACC_ID = TRD_ACC_ID;
  }
  if (w.addEventListener) {
    w.addEventListener('beforeunload', () => {
      const learningPathContextStorage = useLearningPathContextStorage();
      learningPathContextStorage.value = null;
    });
  }

  if (document.readyState === 'complete') {
    loadScript(w, document);
  } else {
    window.addEventListener('load', () => {
      loadScript(w, document);
    });
  }
  usePolarisEnvironment();
});
</script>

<style lang="scss" scoped>
@import '~@/variables';
@import '~@/utils';

.header {
  width: 100%;
  position: fixed;
  top: 0;
  height: $mainHeaderHeight;
  z-index: layer(drawer);
}

.content {
  padding: 0;
  margin-top: $mainHeaderHeight;
  min-height: 700px;
}
</style>

<style lang="scss">
@import '~@/utils';
@import '../globals';
@import '../../public/assets/styles/toasts';
@import '@patchui/productcl/assets/styles/variables.css';
@import 'primevue/resources/themes/lara-light-blue/theme.css';

// Enables close-on-click behaviour when multiple dropdowns
// are in the same viewport.
.dropdown > .dropdown__handle {
  z-index: layer(dropdown) - 1;
}
</style>
