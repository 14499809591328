<template>
  <SearchableSelect
    :placeholder="orgName"
    :items="items"
    @change="onOrgChange"
  />
  <div
    v-if="
      (isLoading && fetchStatus === 'fetching') ||
      (fetchAssignmentsIsLoading && fetchAssignmentsFetchStatus === 'fetching')
    "
  >
    Checking org permissions...
    <BaseLoadingSpinner />
  </div>
</template>

<script lang="ts" setup>
import SearchableSelect from './SearchableSelect.vue';
import { computed, ref, watch, watchEffect } from 'vue';
import { useUser } from '../../hooks/useUser';
import type { Item } from './SearchableSelect.vue';
import { fetchUsersStats, FETCH_USERS_STATS_BASE_KEY } from '../../api/stats';
import BaseLoadingSpinner from '@patchui/productcl/components/BaseLoadingSpinner/BaseLoadingSpinner.vue';

import axios, { AxiosError } from 'axios';
import { components } from '../../api/learn-backend-v1-schema';
import { useQuery } from '@tanstack/vue-query';
import { useListOrgAssignments } from '../../api/assignments';
import { selectedOrgHaveEntitlement } from '../../pages/Assignments/NewAssignments/newAssignmentsStore';
import {
  errorContentOptions,
  ErrorType,
} from '../../pages/AssignmentsDashboard/components/Error/errorContent';

export interface InvalidOrgSelected {
  errorMsg: string;
  errorType: ErrorType;
  org: components['schemas']['Organization'];
}

const emit = defineEmits<{
  (e: 'validOrgSelected', org: components['schemas']['Organization']): void;
  (e: 'invalidOrgSelected', invalidOrgSelected: InvalidOrgSelected): void;
  (e: 'orgClicked', org: components['schemas']['Organization']): void;
}>();

interface OrgSelectorProps {
  orgId?: string;
}
const props = defineProps<OrgSelectorProps>();
const { user } = useUser();
const defaultSelectedOrg = user.value?.orgs.find(
  (org) => org.id === props.orgId,
);
const items = computed(() => {
  if (!user.value) return [];
  return user.value.orgs.map((org) => {
    return {
      searchKey: org.name,
      label: org.name,
      value: org,
    };
  });
});

const orgName = computed(() => {
  if (!selectedOrg.value) return 'Select organization';
  return (
    user.value?.orgs.find((org) => org.id === selectedOrg.value?.id)?.name ||
    'Select organization'
  );
});

const selectedOrg = ref<components['schemas']['Organization'] | undefined>(
  defaultSelectedOrg,
);
const selectedOrgId = computed(() => selectedOrg.value?.id);
const onOrgChange = (item: Item) => {
  selectedOrg.value = item.value;
  emit('orgClicked', item.value);
};

const enabled = computed(() => !!selectedOrgId.value);

const { data, error, isLoading, fetchStatus } = useQuery<
  {
    orgId: string;
    usersOverview: {
      user: components['schemas']['User'];
      lessonsViewed: number;
    }[];
  },
  AxiosError
>({
  queryKey: [FETCH_USERS_STATS_BASE_KEY, selectedOrgId],
  queryFn: () => fetchUsersStats(selectedOrgId.value || ''),
  retry: false,
  enabled,
  staleTime: 60 * 1000,
  refetchOnMount: false,
});

watch(
  () => props.orgId,
  (orgId) => {
    if (orgId && selectedOrg.value === undefined) {
      selectedOrg.value = {
        name: orgName.value,
        id: orgId,
      };
    }
  },
  { immediate: true },
);

const orgId = computed(() => selectedOrg.value?.id);

const {
  error: fetchAssignmentsError,
  data: assignments,
  isLoading: fetchAssignmentsIsLoading,
  fetchStatus: fetchAssignmentsFetchStatus,
} = useListOrgAssignments(orgId);

watch(assignments, () => {
  if (assignments.value) selectedOrgHaveEntitlement.value = true;
});

type ErrorContentKey = keyof typeof errorContentOptions;

const handleError = (
  error: Error | null,
  errorContentKey: ErrorContentKey,
  selectedOrg: components['schemas']['Organization'],
) => {
  if (axios.isAxiosError(error)) {
    const errorStatusCode = error.response?.status;
    let errorMsg = errorContentOptions['internal_error'].body;
    let errorType: ErrorType = 'internal_error';

    switch (errorStatusCode) {
      case 403:
        errorMsg = errorContentOptions[errorContentKey].body;
        errorType = errorContentKey;
        break;
    }

    emit('invalidOrgSelected', {
      errorMsg,
      errorType,
      org: selectedOrg,
    });
  }
};

watchEffect(() => {
  // We only want to watch for changes when we are before or after fetching and that the org is selected
  if (
    !isLoading.value &&
    !fetchAssignmentsIsLoading.value &&
    selectedOrg.value !== undefined
  ) {
    if (data.value === undefined) {
      // Fetch user stats failed
      handleError(
        error.value,
        'insufficient_org_permissions',
        selectedOrg.value,
      );
    } else if (assignments.value === undefined) {
      // Fetch user assignments failed
      handleError(
        fetchAssignmentsError.value,
        'insufficient_org_entitlements',
        selectedOrg.value,
      );
    } else if (
      data.value.orgId &&
      selectedOrg.value.id === data.value.orgId &&
      assignments.value
    ) {
      // User stats and list org assignments fetches have succeeded and the fetched org id matches the selected org id
      emit('validOrgSelected', selectedOrg.value);
    }
  }
});
</script>

<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';
</style>
