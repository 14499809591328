<template>
  <div class="button-group">
    <ChevronLeftIcon
      v-if="withCarousel"
      :class="getArrowClass('left')"
      title="Scroll Carousel left"
      data-snyk-test="ButtonGroup: left-navigation"
      :size="24"
      @click="scrollLeft(listEl)"
    />
    <div
      ref="listEl"
      class="button-group__wrapper"
      @scroll.passive="recalculateScrollVisibility"
    >
      <button
        v-for="button in optionsWithCurrentlySelected"
        :key="button.value"
        :class="getButtonClass(button)"
        :data-snyk-test="`ButtonGroup: ${button.value}`"
        @click="clickHandler(button.value)"
      >
        {{ button.label }}
      </button>
    </div>
    <ChevronRightIcon
      v-if="withCarousel"
      :class="getArrowClass('right')"
      title="Scroll Carousel right"
      data-snyk-test="ButtonGroup: right-navigation"
      :size="24"
      @click="scrollRight(listEl)"
    />
  </div>
</template>

<script lang="ts" setup>
import { PropType, ref, toRefs, computed, onMounted, onUnmounted } from 'vue';
import ChevronRightIcon from '@patchui/icons/ChevronRight.vue';
import ChevronLeftIcon from '@patchui/icons/ChevronLeft.vue';
import { scrollRight, scrollLeft, getScrollData } from '../../lib/carousel';

interface Option {
  value: string;
  label: string;
  selected?: boolean;
}

const props = defineProps({
  options: {
    type: Array as PropType<Array<Option>>,
    required: true,
  },
  value: {
    type: String,
    required: true,
  },
  withCarousel: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits<{
  (e: 'click', id: string): void;
}>();

const { options, value, withCarousel } = toRefs(props);
const needsScrollControls = ref(false);
const canScrollLeft = ref(false);
const canScrollRight = ref(false);
const listEl = ref<HTMLDivElement>();

const optionsWithCurrentlySelected = computed<Option[]>(() =>
  options.value.map((option) => ({
    ...option,
    selected: option.value === value.value,
  })),
);

const clickHandler = (clickedValue: string) => {
  if (clickedValue !== value.value) {
    emit('click', clickedValue);
  }
};

const getButtonClass = (option: Option) => {
  return option.selected
    ? 'button-group__button button-group__button--selected'
    : 'button-group__button';
};

/** Determines whether the carousel requires pagination/navigation */
const recalculateScrollVisibility = () => {
  if (!withCarousel.value) return;
  const scrollData = getScrollData(listEl.value);
  canScrollLeft.value = scrollData.canScrollLeft;
  canScrollRight.value = scrollData.canScrollRight;
  needsScrollControls.value = scrollData.needsScrollControls;
};

const getArrowClass = (arrow: 'right' | 'left'): string => {
  const defaultClass = 'button-group__scroll-btn';
  const visibleClass = `${defaultClass} ${defaultClass}--visible`;
  return needsScrollControls.value &&
    (arrow === 'right' ? canScrollRight.value : canScrollLeft.value)
    ? visibleClass
    : defaultClass;
};

onMounted(() => {
  recalculateScrollVisibility();
  window.addEventListener('resize', recalculateScrollVisibility);
});

onUnmounted(() => {
  window.removeEventListener('resize', recalculateScrollVisibility);
});
</script>

<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';

.button-group {
  display: flex;

  &__wrapper {
    display: flex;
    white-space: nowrap;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  &__scroll-btn {
    color: $electricBlue;
    cursor: pointer;
    display: inline-block;
    opacity: 0;

    &--visible {
      opacity: 1;
    }
  }

  &__button {
    cursor: pointer;
    border-radius: 8px;
    color: $steel;
    border: 1px solid #d3d3d9;
    padding: space(xs) space(s);
    margin: 0 space(xs);
    line-height: rem(24px);

    &:hover,
    &:focus {
      color: token('color.brand.electric-blue');
      background-color: color-mix(
        in srgb,
        token('color.brand.electric-blue'),
        transparent 90%
      );
      border-color: transparent;
    }

    &:focus-visible {
      outline: 2px solid token('color.brand.electric-blue');
    }

    // Ensure that the selected button always displays hover styles
    &--selected {
      color: token('color.brand.electric-blue');
      background-color: color-mix(
        in srgb,
        token('color.brand.electric-blue'),
        transparent 90%
      );
      border-color: transparent;
    }

    &:first-of-type {
      margin-left: 0;
    }
  }
}
</style>
