<template>
  <div class="error">
    <img
      src="https://res.cloudinary.com/snyk/image/upload/v1655883217/snyk-learn/PatchX.png"
      alt="patch-forbidden"
    />
    <div class="error__text">
      <p>{{ content.title }}</p>
      <p>{{ content.body }}</p>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, toRefs } from 'vue';
import { errorContentOptions } from './errorContent';
interface IProps {
  status: '403' | '404' | '500';
}

const props = defineProps<IProps>();
const { status } = toRefs(props);

const content = computed(
  () => errorContentOptions[status.value] || errorContentOptions['500'],
);
</script>
<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';

.error {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  &__text {
    margin-top: token('space.l');

    @include media-query(small) {
      max-width: rem(600px);
    }

    p {
      font-size: rem(23px);
      color: token('color.brand.midnight');
      font-family: token('typography.family.poppins');
      line-height: 30px;
      font-feature-settings: 'tnum' on, 'lnum' on;
    }

    p:first-child {
      font-weight: 700;
    }

    p:last-child {
      font-weight: 400;
      margin-top: token('space.l');
    }
  }
}
</style>
