<template>
  <Bubble v-if="learningPath" class="learning-path-progress">
    <template #left>
      <div>
        <PatchIcon :colorize="true" />
      </div>
    </template>
    <template #content>
      <template v-if="learningPathState == 'completed'">
        <p class="learning-path-progress__title">Congrats!</p>
        <p class="learning-path-progress__title">
          You have completed the {{ learningPath.title }} Learning Path.
        </p>
      </template>

      <template v-else-if="firstIncompleteLesson">
        <p class="learning-path-progress__title">
          {{
            learningPathState === 'todo'
              ? "Let's kick off with the first lesson."
              : "Let's get you to the right place."
          }}
        </p>
        <router-link
          v-if="firstIncompleteLessonLink !== null"
          v-slot="{ href, navigate }"
          :to="firstIncompleteLessonLink"
          custom
        >
          <Button
            class="learning-path-progress__cta-button"
            variant="primary"
            wrapper="a"
            :href="href"
            @click="navigate"
          >
            {{ firstIncompleteLesson.title }}
          </Button>
        </router-link>
      </template>

      <template v-else-if="!isLoggedIn || isFetching">
        <h2 class="learning-path-progress__title">
          Save your learning progress.
        </h2>
        <ul class="learning-path-progress__benefits-list">
          <li>
            <CheckIcon class="learning-path-progress__check-icon" :size="24" />
            Track your learning progress
          </li>
          <li>
            <CheckIcon class="learning-path-progress__check-icon" :size="24" />
            Keep up to date with the latest vulnerabilities
          </li>
          <li>
            <CheckIcon class="learning-path-progress__check-icon" :size="24" />
            Scan your application code to stay secure
          </li>
        </ul>
        <SnykAppCta
          class="learning-path-progress__cta-button"
          text="Sign up for free"
          origin="body"
          intent="signup"
          :is-full-width="false"
        />
      </template>

      <!-- This is a fallback in case we get into an invalid state -->
      <template v-else>
        <p class="learning-path-progress__title">Did you know?</p>
        <p class="learning-path-progress__title">
          Snyk helps developers fix over 8000 critical vulnerabilities every
          day.
        </p>
      </template>
    </template>
  </Bubble>
</template>

<script setup lang="ts">
import { computed, toRefs } from 'vue';
import { useLearningPathDetails } from './useLearningPathDetails';
import CheckIcon from '@patchui/icons/Check.vue';

import Bubble from '../../components/Bubble/Bubble.vue';
import Button from '../../components/Button/Button.vue';
import SnykAppCta from '../../components/Button/SnykAppCta.vue';
import { useLoggedIn } from '../../hooks/useLoggedIn';
import { buildLessonPath } from '../../lib/utils/lessons';
import PatchIcon from '@patchui/icons/custom/SnykDog.vue';

interface LearningPathProgressProps {
  slug: string;
  ecosystem: string;
}

const props = defineProps<LearningPathProgressProps>();
const { ecosystem, slug } = toRefs(props);

const isLoggedIn = useLoggedIn();
const { data: learningPath, isFetching } = useLearningPathDetails(
  slug,
  ecosystem,
);

const learningPathState = computed(() => learningPath.value?.progress?.status);

const firstIncompleteLesson = computed(() => {
  for (const module of learningPath.value?.modules ?? []) {
    for (const lesson of module.lessons) {
      if (lesson.progressStatus && lesson.progressStatus !== 'completed')
        return lesson;
    }
  }

  return null;
});

const firstIncompleteLessonLink = computed(() => {
  if (!firstIncompleteLesson.value) return null;

  return buildLessonPath(firstIncompleteLesson.value.slug);
});
</script>

<style lang="scss">
@import '~@/utils';
@import '~@/variables';

.learning-path-progress {
  &__title {
    @include typography('typography.brand.subhead-small');
    font-weight: 600;
    margin-bottom: token('space.m');
  }

  &__benefits-list {
    padding: 0 0 8px 0;
    li {
      display: flex;
      align-items: center;
      column-gap: 10px;
      list-style: none;
      font-size: 15px; // non-standard
    }
  }

  &__check-icon {
    color: token('color.brand.electric-blue');
    width: 18px;
    height: 18px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 2px solid token('color.brand.electric-blue');
  }
  &__cta-button {
    min-width: 220px;
  }
}
</style>
