<template>
  <div v-if="recentLessons.length > 0">
    <h2 v-if="$slots.title" class="latest-lessons__title">
      <slot name="title"></slot>
    </h2>
    <div>
      <section class="latest-lessons__cards" data-snyk-test="LessonListing">
        <LessonCard
          v-for="lesson in recentLessons"
          :key="lesson.lessonId"
          :enriched-lesson="lesson"
          :origin="props.origin"
        />
      </section>
      <div class="latest-lessons__view-more">
        <BaseButton
          class="latest-lessons__view-more_button"
          data-snyk-test="LatestLessons: view more"
          wrapper="a"
          variant="primary"
          size="medium"
          :href="'/catalog/'"
          :target="'_self'"
        >
          View more lessons
        </BaseButton>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, ComputedRef, onServerPrefetch } from 'vue';
import BaseButton from '@patchui/productcl/components/BaseButton/BaseButton.vue';

import { filterLatestLessons } from '../../../lib/utils/lessons';
import LessonCard from '../../../components/LessonCard/LessonCard.vue';
import { useEnrichedLessonsQuery } from '../../../hooks/useEnrichedLessons';
import type { EnrichedLessonMetadata } from '../../../hooks/useEnrichedLessons';
import { useSuspense } from '../../../hooks/useSuspense';

const { enrichedLessons } = useEnrichedLessonsQuery();

const numberOfLessonToDisplay = 3;

const recentLessons: ComputedRef<EnrichedLessonMetadata[]> = computed(
  () =>
    filterLatestLessons(
      enrichedLessons.value,
      numberOfLessonToDisplay,
    ) as EnrichedLessonMetadata[],
);
const { suspense } = useSuspense(() => enrichedLessons.value.length > 0);
onServerPrefetch(suspense);
interface ILatestLessonsProps {
  origin: string;
}
const props = defineProps<ILatestLessonsProps>();
</script>
<style lang="scss" scoped>
@import '~@/utils.scss';
@import '~@/variables.scss';
@import '../../../components/Card/cardMixins.scss';

.latest-lessons {
  &__title {
    text-align: center;
    @include typography('typography.brand.heading3');
    color: token('color.brand.midnight');
    @include media-query(medium) {
      @include typography('typography.brand.heading2');
    }
  }
  &__cards {
    @include cards-wrapper-grid;
  }

  &__view-more {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }

  &__view-more_button.button--medium {
    padding: 20px space(default);
    background-color: token('color.brand.electric-blue');
    border-radius: token('radius.medium');
    font-size: rem(17px);
    line-height: rem(24px);
    gap: 8px;
  }
}
</style>
