<template>
  <div class="card" :class="classes">
    <div v-if="variant !== 'compact'" class="card__hero">
      <img
        class="card__hero__image"
        :width="344"
        :height="157"
        :alt="imgAlt"
        :src="img"
      />
      <BaseBadge
        v-if="badgeText"
        class="card__hero__pill"
        variant="info"
        size="large"
      >
        {{ badgeText }}
      </BaseBadge>
    </div>
    <div class="card__content">
      <BaseHeading class="card__title" :level="2">
        <slot name="title"> </slot>
      </BaseHeading>
      <div class="card__description">
        <slot name="description"> </slot>
      </div>
      <div
        v-if="dueBadgeStatus && !isCompleted"
        data-snyk-test="Card: assignment status"
        class="card__assignment-status"
      >
        <DueDateBadge
          :status="dueBadgeStatus"
          :due-in-days="dueBadgeDaysLeft"
        />
      </div>
      <div class="card__icon">
        <template v-for="iconName in iconNames" :key="iconName">
          <BaseTooltip
            v-if="iconName"
            :description="langMapper[iconName] || iconName"
          >
            <Icon :name="iconName" :size="26" />
          </BaseTooltip>
        </template>
      </div>
      <div class="card__footer" data-test-id="card-footer">
        <BaseButton class="card__cta" icon-right size="medium">
          {{ ctaText }}
          <template #rightIcon><ArrowRightIcon :size="18" /></template>
        </BaseButton>
        <ProgressMeter
          :percentage="completedPercentage"
          :is-completed="isCompleted"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseHeading from '@patchui/productcl/components/BaseHeading/BaseHeading.vue';
import BaseButton from '@patchui/productcl/components/BaseButton/BaseButton.vue';
import ArrowRightIcon from '@patchui/icons/ArrowRight.vue';
import Icon from '../Icon/Icon.vue';
import ProgressMeter from './ProgressMeter.vue';
import BaseBadge from '@patchui/productcl/components/BaseBadge/BaseBadge.vue';
import BaseTooltip from '@patchui/productcl/components/BaseTooltip/BaseTooltip.vue';
import { langMapper } from '../../lib/utils/mappers';
import DueDateBadge from '../DueDateBadge/DueDateBadge.vue';

import type { LessonEcosystem } from '../../lib/lessonMetadata';
import { LessonAssignmentStatus } from '../../types';

export interface ICardProps {
  imgAlt: string;
  img: string;
  badgeText?: string | null;
  ctaText: string;
  iconNames?: LessonEcosystem[];
  completedPercentage: number;
  isCompleted: boolean;
  variant: 'compact' | 'full';
  dueBadgeStatus?: LessonAssignmentStatus;
  dueBadgeDaysLeft?: number;
}
const props = defineProps<ICardProps>();

const classes = {
  'card--compact': props.variant === 'compact',
};
</script>

<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';

.card {
  background: #f6f4fa;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid transparent;
  border-radius: token('radius.xlarge');
  overflow: hidden;
  cursor: pointer;
  &:hover,
  &:active,
  &:focus,
  &:focus-within {
    border-color: token('color.brand.electric-blue');
    box-shadow: token('elevation.far');
    transition-property: 'box-shadow,border';
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  }

  @include media-query(small) {
    min-height: rem(302px);
  }

  &--compact {
    min-height: min-content;
  }

  &__hero {
    position: relative;
    &__image {
      display: block;
      width: 100%;
      background-color: token('color.brand.midnight');
      object-fit: contain;
    }
    &__pill {
      position: absolute;
      top: 16px;
      left: 16px;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
      'header'
      'title'
      'description'
      'footer';
    gap: space();
    align-content: stretch;
    justify-content: stretch;
    margin: token('space.m') token('space.m');
    flex: 1;
    min-height: 236px;
  }

  &__icon {
    height: rem(28px);
    line-height: rem(24px);
    color: #0a26b8; // not in brandUI
    text-transform: uppercase;
    letter-spacing: 0.12em;
    font-family: token('typography.family.poppins');
    font-size: rem(14px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: space(s);

    &-name {
      flex-grow: 1;
    }

    .material-design-icon {
      vertical-align: middle;
    }
  }

  &__pill {
    height: rem(28px);
  }

  &__title.heading {
    color: token('color.brand.midnight');
    font-family: token('typography.family.poppins');
    font-weight: 600;
    font-size: rem(18px);
    line-height: rem(28px);
  }

  &__description {
    color: $greyColor;
    font-weight: 400;
    font-size: rem(16px);
    line-height: rem(25px);
    @include media-query(small) {
      margin-bottom: 0;
    }
  }

  &__footer {
    display: inline-flex;
    justify-content: space-between;
  }

  &__cta {
    background-color: token('color.blue.10');
    color: token('color.blue.40');
    border-color: token('color.blue.30');
    &:hover {
      border-color: token('color.blue.40');
    }
  }
}
</style>
