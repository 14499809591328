<template>
  <section class="recommended-lessons">
    <h2><slot name="title"></slot></h2>
    <p v-if="$slots.subtitle"><slot name="subtitle"></slot></p>
    <div class="recommended-lessons__cards">
      <LessonCard
        v-for="lesson in sortedLessons"
        :key="lesson.lessonId"
        :enriched-lesson="lesson"
        :origin="props.origin"
      />
    </div>
    <router-link
      v-if="showViewAllButton"
      v-slot="{ navigate, href }"
      to="/catalog/"
      custom
    >
      <Button
        data-snyk-test="RecommendedLessons: view-all-button"
        class="recommended-lessons__button"
        variant="primary"
        wrapper="a"
        :href="href"
        @click="
          (e: MouseEvent) => {
            trackButtonClick();
            navigate(e);
          }
        "
      >
        View all recommended lessons
      </Button>
    </router-link>
  </section>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import LessonCard from '../../../components/LessonCard/LessonCard.vue';
import Button from '../../../components/Button/Button.vue';

import { getRecommendedLessons } from './recommendedLessonsSorting';
import { useEnrichedLessonsQuery } from '../../../hooks/useEnrichedLessons';
import itly from '../../../lib/analytics/itly';
import type { LessonEcosystem } from '../../../lib/lessonMetadata';
import { components } from '../../../api/learn-backend-v1-schema';
import { useListMyEcosystemsInfo } from '../../../api/ecosystems';
const LESSON_CARDS_TO_SHOW = 3;

interface IRecommendedLessonsProps {
  showViewAllButton?: boolean;
  currentLessonSlug?: string;
  currentLessonEcosystem?: LessonEcosystem;
  currentLessonEducationContentCategory?: components['schemas']['LessonMetadata']['educationContentCategory'];
  origin: string;
  path: string;
}

const props = withDefaults(defineProps<IRecommendedLessonsProps>(), {
  currentLessonSlug: '',
  currentLessonEcosystem: 'all',
  currentLessonEducationContentCategory: 'security education',
  showViewAllButton: false,
});

const { enrichedLessons } = useEnrichedLessonsQuery();

const selectedEcosystemsNames = computed(() => {
  return selectedEcosystemsInfo.value.map(
    (ecosystem) => ecosystem.name as LessonEcosystem,
  );
});
const { data: selectedEcosystemsInfo } = useListMyEcosystemsInfo();

const sortedLessons = computed(() => {
  if (
    !props.currentLessonEcosystem ||
    !props.currentLessonSlug ||
    !props.currentLessonEducationContentCategory
  ) {
    return getRecommendedLessons(
      enrichedLessons.value,
      selectedEcosystemsNames.value,
      props.currentLessonSlug,
      props.currentLessonEcosystem,
    ).slice(0, LESSON_CARDS_TO_SHOW);
  }
  // When a user is in a DevSec lesson they get recommendations for other DevSec lessons and not Training lessons
  // When a user is in a Training lesson they get recommendations for other Training lessons and not DevSec lessons
  if (props.currentLessonEducationContentCategory === 'product training') {
    return getRecommendedLessons(
      enrichedLessons.value.filter(
        (enrichedLesson) =>
          enrichedLesson.educationContentCategory === 'product training',
      ),
      selectedEcosystemsNames.value,
      props.currentLessonSlug,
      props.currentLessonEcosystem,
    ).slice(0, LESSON_CARDS_TO_SHOW);
  } else {
    return getRecommendedLessons(
      enrichedLessons.value.filter(
        (enrichedLesson) =>
          enrichedLesson.educationContentCategory === 'security education',
      ),
      selectedEcosystemsNames.value,
      props.currentLessonSlug,
      props.currentLessonEcosystem,
    ).slice(0, LESSON_CARDS_TO_SHOW);
  }
});

const trackButtonClick = () => {
  try {
    itly.learnButtonIsClicked({
      action: 'link',
      eventSource: 'Learn',
      origin: props.origin,
      path: props.path,
    });
  } catch (e) {
    //TODO: capture with datadog frontend error monitoring
    window.console.error(JSON.stringify(e));
  }
};
</script>
<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';
@import '../../../components/Card/cardMixins.scss';
.recommended-lessons {
  margin: token('space.layout.small') 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    width: 100%;
    @include typography('typography.brand.subhead');
  }

  p {
    width: 100%;
    color: token('color.brand.midnight');
    margin-top: token('space.xs');
    @include typography('typography.product.default');
  }

  &__cards {
    @include cards-wrapper-grid;
  }

  &__button {
    margin-top: token('space.xl');
  }
}
</style>
