<template>
  <section class="tab-learning-path">
    <Loading v-if="learningPathStats.isPending.value" />
    <div v-else-if="learningPathStats.error.value || !learningPathStats.data">
      There was an error while fetching the learning path overview
    </div>
    <div v-else>
      <p class="tab-learning-path_insight">
        Insights: Which learning paths matter the most to your developers
      </p>
      <div class="tab-learning-path__dropdown-area">
        <LearningPathSelector
          :learning-path-title="learningPathTitle"
          @learning-path-selected="
            (learningPathId) =>
              handleLearningPathSelected(learningPathId, learningPathTitle)
          "
        />
      </div>
      <div class="tab-learning-path__body">
        <div>
          <Table
            :export-csv-enabled="true"
            :export-csv-filename="exportFilename"
            :columns="COLUMN_DEFINITION"
            :rows="rows"
            @on-download-csv-clicked="onDownloadCsvClicked"
          >
            <template #status="{ row }">
              <BaseBadge>
                {{
                  STATUSES.find(
                    (lessonStatus) => lessonStatus.value === row.status,
                  )?.label || row.status
                }}
              </BaseBadge>
            </template>
          </Table>
        </div>
        <div>
          <CopyAndShare
            class="tab-learning-path__copy-and-share"
            :value-to-copy="orgMemberEmailsToCopy"
            @copied="() => handleCopyButtonIsClicked('userEmails')"
          >
            <template #title>
              Share this learning path with your developers
            </template>
            <template #description>
              Use these buttons to copy the learning path link or collect the
              emails of users who haven't started this learning path yet
            </template>
          </CopyAndShare>
          <TextAreaCopyToClipboard
            disabled
            :value-to-copy="emailTemplateToCopy"
            @copied="() => handleCopyButtonIsClicked('emailText')"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { ref, toRefs, watchEffect, computed } from 'vue';
import CopyAndShare from '../../../AdminLessonOverview/components/CopyAndShare/CopyAndShare.vue';
import TextAreaCopyToClipboard from '../../../../components/TextAreaCopyToClipboard/TextAreaCopyToClipboard.vue';
import Loading from '../Loading/Loading.vue';
import Table from '../../../../components/Table/Table.vue';
import {
  useLearningPathStats,
  type ProgressOverview,
} from './useLearningPathStats';
import { useLearningPaths } from './useLearningPaths';
import itly from '../../../../lib/analytics/itly';
import { inviteLessonTemplate } from '../../../AdminLessonOverview/inviteLessonTemplate';
import { getPathToLearningPath } from '../../../../lib/utils/learningPaths';
import LearningPathSelector from '../LearningPathSelector/LearningPathSelector.vue';
import { STATUSES } from '../../../../store/modules/lessons';
import useAdminDashboardQueryParams from '../../useAdminDashboardQueryParams';
import BaseBadge from '@patchui/productcl/components/BaseBadge/BaseBadge.vue';
import { TableColumn } from '../../../../components/Table/components/Table.interfaces';
import { useExportFilename } from '../../useExportFilename';
import { useRoute } from 'vue-router';

export interface ITabLearningPathProps {
  orgId: string;
}

const props = defineProps<ITabLearningPathProps>();
const { orgId } = toRefs(props);
const emit = defineEmits<{
  (event: 'onError', error: Error): void;
}>();

const { exportFilename } = useExportFilename(
  'LearningPathsOverviewReport',
  orgId.value,
);

const route = useRoute();

const onDownloadCsvClicked = () => {
  itly.learnReportIsExported({
    eventSource: 'Learn',
    exportType: 'csv',
    path: route.path,
    origin: 'reports-learning-path-overview',
  });
};

const COLUMN_DEFINITION: TableColumn[] = [
  {
    fieldName: 'learningPathTitle',
    title: 'Learning Path Title',
    sortable: false,
    hidden: true,
  },
  {
    fieldName: 'userEmail',
    title: 'User Email',
    sortable: false,
  },
  {
    fieldName: 'status',
    title: 'Status',
    bodySlot: 'status',
    sortable: false,
  },
  {
    fieldName: 'completionDate',
    title: 'Last Completion Date',
    sortable: false,
  },
];

const { learningPathSlug, updateLearningPathSlug } =
  useAdminDashboardQueryParams();

const learningPathIdRef = ref<string>('');
const { data: learningPaths } = useLearningPaths();
watchEffect(() => {
  if (
    !learningPathIdRef.value &&
    learningPaths.value?.['learning-paths'].length
  ) {
    if (learningPathSlug && learningPathSlug.value) {
      try {
        // @ts-ignore
        learningPathIdRef.value = learningPaths.value?.['learning-paths'].find(
          (lp) => lp.slug === (learningPathSlug.value as string),
        ).learningPathId;
      } catch (e) {
        console.error('Unknown learning path');
      }
    } else {
      learningPathIdRef.value =
        learningPaths.value?.['learning-paths'][0].learningPathId;
      const slug = learningPaths.value?.['learning-paths'][0].slug;
      if (slug !== undefined) {
        updateLearningPathSlug(slug);
      }
    }
  }
});
const learningPathStats = useLearningPathStats(orgId, learningPathIdRef);

const handleLearningPathSelected = (
  learningPathId: string,
  learningPathTitle: string,
) => {
  learningPathIdRef.value = learningPathId;
  itly.learningPathReportIsSelected({
    eventSource: 'Learn',
    title: learningPathTitle,
    path: '/admin/reports/',
  });
};

watchEffect(() => {
  if (
    learningPathStats.error.value &&
    learningPathStats.error.value instanceof Error
  ) {
    emit('onError', learningPathStats.error.value);
  }
});

// `any` here is because ProductCL's type for items on ThePagination is wrong
const rows = computed<any>(() => {
  return (learningPathStats.data.value?.progressOverview || []).map(
    (overview) => {
      return {
        userEmail: overview.user.email,
        status: overview.status,
        learningPathTitle: learningPathTitle.value,
        completionDate: overview.completionDate,
      };
    },
  );
});

const orgMemberEmailsToCopy = computed(() => {
  return [
    ...new Set(
      learningPathStats.data.value?.progressOverview
        .filter((po: ProgressOverview) => po.status === 'todo')
        .map((lp: ProgressOverview) => lp.user.email),
    ),
  ].join(',');
});

const emailTemplateToCopy = computed(() => {
  const learningPathUrl = getPathToLearningPath(
    learningPathStats.data.value?.learningPathSummary.slug || '',
  );
  const learningPathTitle =
    learningPathStats.data.value?.learningPathSummary.title || '';
  return inviteLessonTemplate(learningPathUrl, learningPathTitle);
});

const learningPathTitle = computed(() => {
  return learningPathStats.data.value?.learningPathSummary.title || '';
});

const handleCopyButtonIsClicked = (contentType: string) => {
  itly.copyButtonIsClicked({
    contentType,
    ecosystem: 'all',
    eventSource: 'Learn',
    origin: 'reports-learning-path-overview',
  });
};
</script>

<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';

.tab-learning-path__dropdown-area {
  width: fit-content;
}

.table {
  color: token('color.brand.steel');
}

.tab-learning-path {
  &__insight {
    color: token('color.brand.steel');
  }

  &__text-before-dropdown {
    color: token('color.brand.steel');
    margin: 0;
    padding-right: space(m);
  }

  &__dropdown-area {
    display: flex;
    margin-bottom: token('space.m');
    position: relative;
    z-index: 999;
  }

  &__body {
    display: flex;
    flex-direction: column;
    margin-bottom: space(xl);

    > :first-child:is(div) {
      margin: 0;
    }

    > :last-child:is(div) {
      margin: space(m) 0;
    }

    @include media-query(small) {
      flex-direction: row;
      justify-content: space-between;
      > div {
        width: 45%;
        margin-bottom: 0;
      }
    }
  }

  &::v-deep(.table-menu) {
    /* Hack to align menu with learning path selector on a page */
    margin-top: -48px;
  }

  &__copy-and-share {
    margin-bottom: space(l);
  }
}
</style>
