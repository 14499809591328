import { authenticatedClient } from '../../../../lib/apiClient';
import { OpenApiResponse } from '../../../../lib/utils/type-utilities';
import { components } from '../../../../api/learn-backend-v1-schema';
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/vue-query';

const fetchLearningPaths = async () => {
  const response = await authenticatedClient.get<
    OpenApiResponse<'getLearningPaths'>
  >(`/v1/learn/learning-paths`);

  return response.data;
};

export const useLearningPaths = () => {
  return useQuery<
    {
      'learning-paths': components['schemas']['LearningPathSummary'][];
    },
    AxiosError
  >({
    queryKey: ['getLearningPaths'],
    queryFn: fetchLearningPaths,
  });
};
