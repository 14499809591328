import { isBefore, startOfDay, addDays } from 'date-fns';
const getLocalStartOfDay = (date: Date): Date => {
  const start = startOfDay(date);
  // Adjust the time to match local timezone by constructing a new date with the same YMD but set to midnight local time
  const localStart = new Date(
    start.getFullYear(),
    start.getMonth(),
    start.getDate(),
    0,
    0,
    0,
  );
  return localStart;
};

// TODO: Uncomment when a future startDate is supported
// Due date: valid if today or future
// export const isValidAssignmentStartDate = (date: Date | undefined) => {
//   if (!date) return true;
//
//   const todayStart = getLocalStartOfDay(subDays(new Date(), 1)).getTime(); // We reduce by a day because on an unknown bug in the date picker component, even if correct today date is valid it still marks it as disabled
//   const dateStart = getLocalStartOfDay(date).getTime();
//   return !isBefore(dateStart, todayStart);
// };

// Due date: valid if tomorrow or future
export const isValidAssignmentDueDate = (date: Date | undefined) => {
  if (!date) return true;

  const tomorrowStart = getLocalStartOfDay(addDays(new Date(), 1)).getTime();
  const dateStart = getLocalStartOfDay(date).getTime();
  return !isBefore(dateStart, tomorrowStart);
};
