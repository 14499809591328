import {
  CheckboxesGroup,
  FilterGroup,
} from '@patchui/productcl/components/TheSidebarFilters/TheSidebarFilters.vue';
import { EcosystemMetadata } from '../../hooks/lessonsHooks';
import { components } from '../../api/learn-backend-hidden-schema';

export const getAvailableFilters = (
  ecosystems: EcosystemMetadata[],
  topics: string[],
  isLoggedIn: boolean,
) => {
  const categoriesFilters = getCategoriesFilters(ecosystems);
  // Topics filters are disabled until further notice
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const topicsFilters = getTopicsFilters(topics);
  let availableFilters = {
    ...baseFilters,
    ...categoriesFilters,
    ...topicsFilters,
  };
  if (isLoggedIn) {
    availableFilters = { ...availableFilters, ...statusFilters };
  }
  return availableFilters as Record<string, FilterGroup>;
};

export const baseFilters: Record<string, CheckboxesGroup> = {
  Type: {
    type: 'checkboxes',
    name: 'Type',
    id: 'type',
    options: [
      {
        label: 'Product training',
        value: 'product-training',
      },
      {
        label: 'Security education',
        value: 'security-education',
      },
    ],
  },
  Format: {
    type: 'checkboxes',
    name: 'Format',
    id: 'format',
    options: [
      {
        label: 'Learning paths',
        value: 'learning_path',
      },
      {
        label: 'Lessons',
        value: 'lesson',
      },
    ],
  },
};

const getCategoriesFilters = (ecosystems: EcosystemMetadata[]) => {
  const options = ecosystems.map((ecosystem) => ({
    label: ecosystem.label,
    value: ecosystem.ecosystem,
  }));
  return {
    Categories: {
      type: 'checkboxes',
      name: 'Categories',
      id: 'categories',
      options,
    },
  };
};

export const ecosystemsFilters: Record<string, CheckboxesGroup> = {
  Categories: {
    type: 'checkboxes',
    name: 'Categories',
    id: 'categories',
    options: [
      // TODO: Replace with dynamic ecosystem list
      {
        label: 'General',
        value: 'general',
      },
      {
        label: 'JavaScript',
        value: 'javascript',
      },
      {
        label: 'Python',
        value: 'python',
      },
      {
        label: 'Go',
        value: 'golang',
      },
      {
        label: 'C#',
        value: 'csharp',
      },
      {
        label: 'C++',
        value: 'cpp',
      },
      {
        label: 'Java',
        value: 'java',
      },
      {
        label: 'PHP',
        value: 'php',
      },
      {
        label: 'Kubernetes',
        value: 'kubernetes',
      },
      {
        label: 'AI/ML',
        value: 'aiml',
      },
    ],
  },
};

export const getTopicsFilters = (topics: string[]) => {
  const options = topics.map((topic) => ({
    label: topic,
    value: topic,
  }));
  return {
    Topics: {
      type: 'checkboxes',
      name: 'Topics',
      id: 'topics',
      options,
    },
  };
};

export const statusFilters: Record<string, CheckboxesGroup> = {
  Status: {
    type: 'checkboxes',
    name: 'Status',
    id: 'status',
    options: [
      {
        label: 'To do',
        value: 'todo',
      },
      {
        label: 'In progress',
        value:
          'in_progress' as components['schemas']['EducationProgress']['attributes']['status'],
      },
      {
        label: 'Completed',
        value:
          'completed' as components['schemas']['EducationProgress']['attributes']['status'],
      },
    ],
  },
};
