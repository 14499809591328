import { EducationResource } from '../../api/catalog';
import { baseFilters, ecosystemsFilters } from './filters';
import { CheckboxesGroup } from '@patchui/productcl/components/TheSidebarFilters/TheSidebarFilters.vue';

type CustomType = {
  [key: string]: number;
};

const countRules: Record<
  string,
  (educationResources: EducationResource[]) => CustomType
> = {
  Format: (educationResources) => {
    const [learningPathKey, lessonKey] = baseFilters['Format']['options'].map(
      (option) => option.value,
    );
    return {
      [lessonKey]: educationResources.filter(
        (educationResource) =>
          educationResource.type.toLowerCase() === lessonKey,
      ).length,
      [learningPathKey]: educationResources.filter(
        (educationResource) =>
          educationResource.type.toLowerCase() === learningPathKey,
      ).length,
    };
  },
  Type: (educationResources) => {
    // eg ['product-training', 'security-education']
    const listTypeKeys = baseFilters['Type']['options'].map(
      (option) => option.value,
    );

    const out: any = {};
    // eg { 'product-training': 5, 'security-education': 6 }
    listTypeKeys.forEach((key) => {
      out[key] = educationResources.filter(
        (educationResource) =>
          educationResource.attributes.education_content_category.toLowerCase() ===
          key.replace('-', ' '),
      ).length;
    });
    return out;
  },
  Categories: (educationResources) => {
    const out: CustomType = {};
    const categoriesKeys = ecosystemsFilters['Categories']['options'].map(
      (option) => option.value,
    );
    categoriesKeys.forEach((key) => {
      out[key] = educationResources.filter((educationResource) =>
        listEcosystems(educationResource).includes(key),
      ).length;
    });
    return out;
  },
};

const updateFilterWithCounter = (
  filterName: string,
  educationResources: EducationResource[],
): CheckboxesGroup | undefined => {
  // Wrap with try/catch to be on a safer side
  try {
    // Find original filter by name
    const originalFilter =
      baseFilters[filterName] || ecosystemsFilters[filterName];
    // Check counter function existence
    const countFunction = countRules[filterName];
    // If there is no count function don't modify original filter
    if (!countFunction) return originalFilter;
    // Count number of items by filter name
    const counters = countFunction(educationResources);
    // Iterate over filter options and update count when possible
    const filterWithCount = Object.assign({}, originalFilter);
    filterWithCount['options'].forEach((option) => {
      option.count = counters[option.value];
      option.disabled = option.count === 0;
    });
    return filterWithCount;
  } catch (error) {
    console.error(error);
    return;
  }
};

export const listEcosystems = (
  educationResource: EducationResource,
): string[] => {
  const ecosystems = educationResource.attributes.tags.find(
    (tag) => tag.tag_type === 'ecosystem',
  )?.tag_values;
  return ecosystems || [];
};

export { countRules, updateFilterWithCounter };
