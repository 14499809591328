<template>
  <BaseBadge :variant="badgeVariant" class="badge">
    {{ badgeText.toUpperCase() }}
  </BaseBadge>
</template>

<script lang="ts" setup>
import { computed, toRefs } from 'vue';
import BaseBadge, {
  Variant,
} from '@patchui/productcl/components/BaseBadge/BaseBadge.vue';
import { LessonAssignmentStatus } from '../../types';

interface DueDateBadgeProps {
  status: LessonAssignmentStatus;
  dueInDays?: number;
}
// eslint-disable-next-line vue/no-setup-props-destructure
const props = defineProps<DueDateBadgeProps>();
const { status, dueInDays } = toRefs(props);

const mappingVariant = {
  overdue: 'critical-severity',
  noDueDate: 'default',
  completed: 'success',
  dueIn: 'warn',
};

const mappingLabel = {
  overdue: 'overdue',
  noDueDate: 'no due date',
  completed: 'completed',
  dueIn: `due in ${dueInDays.value} day${dueInDays.value == 1 ? '' : 's'}`,
};

const badgeText = computed<string>(() => {
  return mappingLabel[status.value] || '';
});

const badgeVariant = computed<Variant>(() => {
  return (mappingVariant[status.value] || 'default') as Variant;
});
</script>

<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';

.badge {
  font-weight: 600;
}
</style>
