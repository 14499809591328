import type { LessonMetadata } from '../lessonMetadata';
import { ValidStatus } from '../../store/modules/lessons';
import { EnrichedLessonMetadata } from '../../hooks/useEnrichedLessons';

const DESIRED_STATUS_ORDER: ValidStatus[] = ['inProgress', 'todo', 'completed'];
function sortByStatusCb(a: EnrichedLessonMetadata, b: EnrichedLessonMetadata) {
  const aPos = DESIRED_STATUS_ORDER.indexOf(a.status);
  const bPos = DESIRED_STATUS_ORDER.indexOf(b.status);

  return aPos - bPos;
}

const createEcosystemSorterCb =
  (desiredEcosystemOrder: string[]) =>
  (a: EnrichedLessonMetadata, b: EnrichedLessonMetadata) => {
    const aPos = desiredEcosystemOrder.indexOf(a.ecosystem);
    const bPos = desiredEcosystemOrder.indexOf(b.ecosystem);

    return aPos - bPos;
  };

const sortLessonsByDatePublishedCb = function (
  a: LessonMetadata,
  b: LessonMetadata,
): number {
  return (
    new Date(b.datePublished).getTime() - new Date(a.datePublished).getTime()
  );
};

export {
  sortByStatusCb,
  createEcosystemSorterCb,
  sortLessonsByDatePublishedCb,
};
