import { computed, ref } from 'vue';
import { getLessonPath } from '../../lib/utils/lessons';
import { useSuspense } from '../../hooks/useSuspense';
import { useListLessonsMetadata } from '../../hooks/lessonsHooks';

import type { Ref } from 'vue';
import type { LessonMetadata } from '../../lib/lessonMetadata';
import type { Breadcrumbs } from '../../components/Breadcrumbs/breadcrumbs';

export const useLessonBreadcrumbs = (lessonSlug: Ref<string>) => {
  const { data: lessons } = useListLessonsMetadata();

  const breadcrumbs = computed<Breadcrumbs>(() => {
    const homePageCrumb = [
      {
        icon: 'school-outline',
        label: 'Snyk Learn',
        url: '/',
        isActive: true,
        isHiddenOnMobile: true,
      },
    ];

    const catalogCrumb = [
      {
        label: 'Lessons',
        url: '/catalog/?format=lesson',
        isActive: true,
      },
    ];

    const lessonCrumb = (lessons.value || [])
      .map((lessonMetaData) => ({
        label: lessonMetaData.title,
        url: getLessonPath(lessonMetaData),
        isActive: lessonSlug.value === lessonMetaData.slug,
      }))
      // Only return the current lesson
      .filter((lesson) => lesson.isActive);

    return [homePageCrumb, catalogCrumb, lessonCrumb];
  });

  const { suspense } = useSuspense(() => {
    const lessonCrumbIsPopulated = breadcrumbs.value[2].length > 0;
    return lessonCrumbIsPopulated;
  });

  return { breadcrumbs, suspense };
};

export const selectedEcosystem = ref<string | null>(null);

export const useSelectedEcosystem = (lessonMetaData: Ref<LessonMetadata>) => {
  const ecosystem = computed(() => {
    if (
      !selectedEcosystem.value ||
      !lessonMetaData.value.ecosystems?.includes(
        selectedEcosystem.value as LessonMetadata['ecosystem'],
      )
    ) {
      selectedEcosystem.value = lessonMetaData.value.ecosystem;
      return selectedEcosystem.value;
    } else {
      return selectedEcosystem.value;
    }
  });

  const { suspense } = useSuspense(() => {
    const selectedEcosystemPopulated = ecosystem.value !== null;
    return selectedEcosystemPopulated;
  });

  return { ecosystem, suspense };
};
