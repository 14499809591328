<template>
  <div
    v-if="!finishedLoadingProfilePage"
    class="vue--page-user-profile vue--page-user-profile--loading"
  >
    <BaseSkeleton
      class="vue--page-user-profile__skeleton vue--page-user-profile__skeleton--title vue--page-user-profile__skeleton--restrict-width"
      :height="52"
    />
    <BaseSkeleton
      class="vue--page-user-profile__skeleton vue--page-user-profile__skeleton--restrict-width"
      :height="26"
    />

    <BaseSkeleton
      :height="26"
      class="vue--page-user-profile__skeleton vue--page-user-profile__skeleton--restrict-width"
    />

    <BaseSkeleton class="vue--page-user-profile__skeleton" :height="26" />

    <BaseSkeleton class="vue--page-user-profile__skeleton" :height="26" />
    <BaseSkeleton class="vue--page-user-profile__skeleton" :height="316" />
  </div>
  <div v-else class="vue--page-user-profile">
    <div class="vue--page-user-profile__intro">
      <BaseHeading :level="1" class="title">My Profile</BaseHeading>
      <BaseAvatarUsername
        class="avatar"
        :name="currentlyLoggedInUser!.username"
        :email="currentlyLoggedInUser!.email"
        display-email
        size="large"
        variant="compact"
      />
    </div>
    <div class="vue--page-user-profile__content">
      <div class="vue--page-user-profile__content__title">
        <BaseHeading :level="1">Category preference</BaseHeading>
        <p>Select your categories to personalize your learning experience.</p>
      </div>
      <PreferenceTab
        :ecosystems="ecosystems"
        :ecosystems-selected="selectedEcosystems"
        @selection-change="savePreference"
      />
      <div :class="alertClass">
        <BaseAlert variant="success" :inline="true">{{
          alertMessage
        }}</BaseAlert>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, watch } from 'vue';
import BaseHeading from '@patchui/productcl/components/BaseHeading/BaseHeading.vue';
import BaseAvatarUsername from '@patchui/productcl/components/BaseAvatarUsername/BaseAvatarUsername.vue';
import BaseAlert from '@patchui/productcl/components/BaseAlert/BaseAlert.vue';
import BaseSkeleton from '@patchui/productcl/components/BaseSkeleton/BaseSkeleton.vue';
import { useStore } from 'vuex';

import { RegistryUser } from '../../lib/auth';
import {
  useListEcosystems,
  useListMyEcosystems,
  updateMyEcosystems,
} from '../../api/ecosystems';
import PreferenceTab, {
  EcosystemOptions,
} from './PreferenceTab/PreferenceTab.vue';

import type { Ecosystem } from '../../api/ecosystems';

const PREFERENCE_TAB_ALERT_TIMEOUT_MS = 4000;

enum AlertStates {
  NONE = 'none',
  SAVING = 'saving',
  SAVED = 'saved',
  ERROR = 'error',
}

const store = useStore();

const preferenceTabAlert = ref(AlertStates.NONE);
const currentlyLoggedInUser = ref<RegistryUser | null>(null);
const clearAlertTimeout = ref<ReturnType<typeof setTimeout> | null>(null);

const { data: ecosystemData } = useListEcosystems();
const ecosystems = computed((): EcosystemOptions[] => {
  return ecosystemData.value.map(({ id, displayName }: Ecosystem) => ({
    label: displayName,
    value: id,
  }));
});
const { data: selectedEcosystems } = useListMyEcosystems();

const alertClass = computed((): string => {
  return preferenceTabAlert.value !== AlertStates.NONE
    ? 'save-preference-alert save-preference-alert--show'
    : 'save-preference-alert';
});
const alertMessage = computed((): string => {
  if (preferenceTabAlert.value === AlertStates.SAVING) {
    return 'Saving...';
  } else if (preferenceTabAlert.value === AlertStates.SAVED) {
    return 'Saved.';
  } else if (preferenceTabAlert.value === AlertStates.ERROR) {
    return 'Error';
  }
  return '';
});
const finishedLoadingProfilePage = computed((): boolean => {
  return currentlyLoggedInUser.value !== null && ecosystems.value.length > 0;
});
const loggedInUser = computed(() => store.state.user.loggedInUser);

watch(
  loggedInUser,
  (newUser) => {
    if (!newUser) {
      const loggedInUser = store.getters['user/getLoggedInUser'];
      if (loggedInUser) {
        currentlyLoggedInUser.value = loggedInUser;
      }
      return;
    }
    currentlyLoggedInUser.value = newUser;
  },
  { immediate: true, deep: true },
);

const savePreference = async (ecosystemIds: number[]) => {
  if (clearAlertTimeout.value) clearTimeout(clearAlertTimeout.value);
  preferenceTabAlert.value = AlertStates.SAVING;

  try {
    await updateMyEcosystems(ecosystemIds);
  } catch (e) {
    preferenceTabAlert.value = AlertStates.ERROR;
    return;
  }

  preferenceTabAlert.value = AlertStates.SAVED;
  clearAlertTimeout.value = setTimeout(() => {
    preferenceTabAlert.value = AlertStates.NONE;
  }, PREFERENCE_TAB_ALERT_TIMEOUT_MS);
};
</script>

<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';

.vue--page-user-profile {
  font-family: token('typography.family.poppins');
  max-width: $pageMaxWidth;
  position: relative;
  padding-top: space(s);

  @include media-query(small) {
    margin: 0 auto;
  }

  &__skeleton {
    display: block;
    margin: token('space.m') token('space.l');

    &--restrict-width {
      width: 50%;

      @include media-query(small) {
        width: 33%;
      }
    }

    &--title {
      margin-bottom: 28px;
    }
  }

  &--loading {
    padding-top: rem(56px);
    padding-bottom: rem(91px);

    @include media-query(small) {
      padding-bottom: rem(124px);
    }
  }

  &__intro {
    .title {
      text-align: left;
      color: black;
      font-weight: bold;
      font-size: 2rem;
      margin-top: 36px;
      margin-bottom: 36px;
      padding: 0 token('space.l');

      @include media-query(small) {
        font-size: 3rem;
        margin-top: space(xl);
        margin-bottom: space(xl);
        padding: 0 $containerPadding;
      }
    }

    .avatar {
      text-align: left;
      font-weight: 400;
      margin-bottom: token('space.xl');
      padding: 0 token('space.l');

      @include media-query(small) {
        margin-bottom: space(xxl);
        padding: 0 $containerPadding;
      }
    }
  }

  &__content {
    text-align: center;

    margin-bottom: 166px;
    padding: 0 token('space.l');

    @include media-query(small) {
      padding: 0 rem($containerPadding);
    }

    &__title {
      text-align: left;
      margin-top: token('space.xl');
      margin-bottom: space(s);
      font-size: rem(23px);

      .heading {
        font-size: rem(24px);
        @include media-query(small) {
          font-size: rem(35px);
        }
      }
      @include media-query(small) {
        font-size: rem(35px);
        margin-top: space(xl);
        margin-bottom: space(s);
      }
      p {
        margin: space(s) 0;
        font-size: rem(16px);
        text-align: left;
        color: token('color.neutral.90');
      }
    }

    .save-preference-alert {
      position: fixed;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;
      bottom: 0;
      opacity: 0;
      transition: token('transition.enter.moderate');
      z-index: layer(notification);

      &--show {
        opacity: 1;
        bottom: space(s);

        @include media-query(medium) {
          bottom: token('space.layout.huge');
        }
      }
    }
  }
}
</style>
