import { SET_LESSONS } from '../mutation-types';
import { unauthenticatedClient } from '../../lib/apiClient';
import { isContentPreviewActive } from '../../lib/utils/isContentPreview';

import type { Commit } from 'vuex';
import type { AxiosResponse } from 'axios';
import type { LessonMetadata, LessonEcosystem } from '../../lib/lessonMetadata';
import type { OpenApiResponse } from '../../lib/utils/type-utilities';

const PRIVATE_SET_LESSON_REQUEST = 'SET_LESSON_REQUEST';

export const STATUSES = [
  { value: 'todo', label: 'To Do' },
  { value: 'inProgress', label: 'In Progress' },
  { value: 'completed', label: 'Completed' },
] as const;

export type ValidStatus = typeof STATUSES[number]['value'];

export interface State {
  _lessonMetadataRequest: Promise<AxiosResponse<LessonMetadata[]>> | null;
  lessonsMetadata: LessonMetadata[];
}

const defaultState: State = {
  _lessonMetadataRequest: null,
  lessonsMetadata: [],
};

export default {
  namespaced: true,
  state: defaultState,
  getters: {
    getAllLessonsMetadata: (state: State) => {
      return (options = { includeUnpublished: false }): LessonMetadata[] => {
        return state.lessonsMetadata.filter(
          (lessonMetadata) =>
            lessonMetadata.published || options.includeUnpublished,
        );
      };
    },
    getLessonMetadata:
      (_state: State, getters: any) =>
      (
        lessonSlug: string,
        ecosystem?: LessonEcosystem,
      ): LessonMetadata | undefined => {
        const lessonsMetadata: LessonMetadata[] = getters.getAllLessonsMetadata(
          { includeUnpublished: isContentPreviewActive() },
        );

        if (ecosystem) {
          return lessonsMetadata.find(
            (lesson) =>
              lesson.slug === lessonSlug && lesson.ecosystem === ecosystem,
          );
        } else {
          return lessonsMetadata.find(
            (metadata) => lessonSlug === metadata.slug,
          );
        }
      },
  },

  actions: {
    async fetchLessons(
      { commit, state }: { commit: Commit; state: State },
      payload: { refresh?: boolean } = {},
    ): Promise<void> {
      // Short circuit if data is already available
      if (state.lessonsMetadata.length > 0 && !payload.refresh) return;

      let requestUrl = `/v1/learn/lessons`;
      if (isContentPreviewActive()) {
        requestUrl += '?content_source=source-preview';
      }
      const metadataRequest =
        state._lessonMetadataRequest ||
        unauthenticatedClient.get<OpenApiResponse<'getLessons'>>(requestUrl, {
          params: {
            includeUnpublished: true,
          },
        });

      // Store the request so that we never make multiple requests while one is
      // in flight.
      commit(PRIVATE_SET_LESSON_REQUEST, metadataRequest);

      const response = await metadataRequest;

      // Free the request object
      commit(PRIVATE_SET_LESSON_REQUEST, null);

      const lessonEcosystems = response.data;
      commit(SET_LESSONS, lessonEcosystems);
    },
  },

  mutations: {
    [SET_LESSONS](state: State, lessons: LessonMetadata[]): void {
      state.lessonsMetadata = lessons;
    },
    [PRIVATE_SET_LESSON_REQUEST](
      state: State,
      lessonRequest: Promise<AxiosResponse<LessonMetadata[]>>,
    ): void {
      state._lessonMetadataRequest = lessonRequest;
    },
  },
};
