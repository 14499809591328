<template>
  <BaseInput
    v-model="searchQuery"
    type="search"
    placeholder="Search"
    size="small"
    name="searchQuery"
    aria-label="Search for a user"
    clearable
  />
  <BaseEmptyState
    v-show="noRowsToDisplay"
    class="empty-state"
    variant="no-results"
  >
    <template #heading>User email cannot be found.</template>
    <a v-if="inviteLink" :href="inviteLink" target="_blank">Add Members</a>
  </BaseEmptyState>
  <BaseTable
    v-show="!noRowsToDisplay"
    :value="paginatedRows"
    :loading="isLoading"
    :columns="[
      {
        dataKey: 'name',
        title: 'NAME',
      },
      {
        dataKey: 'email',
        title: 'EMAIL',
      },
    ]"
    :rows="paginatedRows"
    class="table"
  >
    <template #header-cell:name="{ column }">
      <BaseCheckbox
        name="all-users"
        :value="true"
        :label="column.title"
        :strong-label="true"
        :model-value="
          assignedUserIds.length === usersData?.usersOverview.length
        "
        @update:modelValue="(isChecked: boolean) => onSelectAllChange(isChecked)"
      />
    </template>
    <template #name="{ row }">
      <BaseCheckbox
        :key="row.id"
        :name="row.name"
        :value="row.id"
        :label="row.name"
        :strong-label="true"
        :model-value="assignedUserIds.includes(row.id)"
        @update:modelValue="(isChecked: boolean) => onCheckboxChange(row.id, isChecked)"
      />
    </template>
  </BaseTable>
  <BasePaginationNav
    v-show="!noRowsToDisplay"
    :current-page="currentPage"
    :total-pages="totalPagesCount"
    :max-pages-shown="5"
    @next="() => currentPage++"
    @go-to-page="(pageNo: number | string) => currentPage = Number(pageNo)"
    @prev="() => currentPage--"
  />
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue';
import { selectedOrg, assignedUserIds } from './newAssignmentsStore';
import BaseTable from '@patchui/productcl/components/BaseTable/BaseTable.vue';
import BasePaginationNav from '@patchui/productcl/components/BasePaginationNav/BasePaginationNav.vue';
import BaseCheckbox from '@patchui/productcl/components/BaseCheckbox/BaseCheckbox.vue';
import BaseInput from '@patchui/productcl/components/BaseInput/BaseInput.vue';
import BaseEmptyState from '@patchui/productcl/components/BaseEmptyState/BaseEmptyState.vue';
import {
  fetchUsersStats,
  FETCH_USERS_STATS_BASE_KEY,
} from '../../../api/stats';
import { usePagination } from '../../../hooks/usePagination';
import { components } from '../../../api/learn-backend-v1-schema';
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/vue-query';
import { appDomain } from '../../../lib/config/domains';
const { data: usersData, isLoading } = useQuery<
  {
    orgId: string;
    usersOverview: {
      user: components['schemas']['User'];
      lessonsViewed: number;
    }[];
  },
  AxiosError
>({
  queryKey: [FETCH_USERS_STATS_BASE_KEY, selectedOrg.value?.id],
  queryFn: () => fetchUsersStats(selectedOrg.value?.id || ''),
  retry: false,
  staleTime: 60 * 1000,
  refetchOnMount: false,
});

const searchQuery = ref('');

const rows = computed(() => {
  const searchQueryMatcher = searchQuery.value.toLocaleLowerCase().trim();
  return (usersData.value?.usersOverview || [])
    .map((userOverview) => {
      return {
        name: userOverview.user.name || userOverview.user.username,
        email: userOverview.user.email,
        id: userOverview.user.id,
      };
    })
    .filter(({ name, email }) =>
      `${name}${email}`.toLocaleLowerCase().includes(searchQueryMatcher),
    );
});

const paginationPageSize = 10;
const { currentPage, totalPagesCount, paginatedRows } = usePagination(
  rows,
  paginationPageSize,
);

const onCheckboxChange = (selectedUserId: string, isChecked: boolean) => {
  if (isChecked) {
    assignedUserIds.value = [...assignedUserIds.value, selectedUserId];
  } else {
    assignedUserIds.value = assignedUserIds.value.filter(
      (userId) => userId !== selectedUserId,
    );
  }
};

const onSelectAllChange = (isChecked: boolean) => {
  if (isChecked && usersData.value?.usersOverview) {
    assignedUserIds.value = usersData.value.usersOverview.map(
      ({ user }) => user.id,
    );
  } else {
    assignedUserIds.value = [];
  }
};

const noRowsToDisplay = computed(() => {
  if (isLoading.value) {
    return false;
  }
  return rows.value.length === 0;
});

const inviteLink = computed(() => {
  return selectedOrg.value
    ? `${appDomain.value}/org/${selectedOrg.value.name}/manage/members`
    : '';
});
</script>

<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';

.empty-state {
  margin-left: 0;
}

.table {
  width: 100%;
}
</style>
