<template>
  <Modal>
    <h2>Your session has expired</h2>
    <p>Sorry, but we are all about security.</p>
    <p>Log in to continue where you left off.</p>
    <BaseButton
      variant="primary"
      size="medium"
      :href="loginHref"
      role="link"
      data-cy="session-expired-login"
      @click="handleBaseButtonClick"
    >
      Log in
    </BaseButton>
  </Modal>
</template>

<script lang="ts" setup>
import Modal from './Modal.vue';
import { useSnykAppCTA } from '../../hooks/useSnykAppCTA';
import BaseButton from '@patchui/productcl/components/BaseButton/BaseButton.vue';
//import sessionLockoutState from '../../App/sessionLockoutState'; //TODO: use after session lockout state added
const { href: loginHref } = useSnykAppCTA({
  intent: 'login',
  origin: 'nav',
  title: 'Login',
});

const handleBaseButtonClick = () => {
  //TODO: use after session lockout state added
  //sessionLockoutState.isLocked = false;
};
</script>

<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';
h2 {
  @include typography('typography.brand.heading3');
  color: token('color.neutral.90');
  margin-bottom: token('space.xl');
}
p {
  @include typography('typography.brand.body');
  font-weight: 400;
  color: token('color.brand.steel');
}
</style>
