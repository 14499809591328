import { authenticatedClient } from '../lib/apiClient';
import { OpenApiResponse } from '../lib/utils/type-utilities';

export type UserOverview = NonNullable<
  OpenApiResponse<'getStatsOrganizationUsersOverview'>['usersOverview']
>[number];

export const fetchUsersStats = async (orgId: string) => {
  const response = await authenticatedClient.get<
    OpenApiResponse<'getStatsOrganizationUsersOverview'>
  >(`/v1/learn/stats/org/${orgId}/users`);
  return response.data;
};

export const FETCH_USERS_STATS_BASE_KEY = 'orgUsersStats';
