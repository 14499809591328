import BaseToastItem from '@patchui/productcl/components/BaseToastItem/BaseToastItem.vue';
import {
  useToast as useToastification,
  TYPE,
  POSITION,
} from 'vue-toastification';

import type { PluginOptions } from 'vue-toastification';

const MAX_TOASTS = 3;

const defaultToastOptionConfig = {
  timeout: 3000,
  closeOnClick: true,
  closeButton: false,
} as const;

export const toastOptions: PluginOptions = {
  position: POSITION.TOP_RIGHT,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  maxToasts: MAX_TOASTS,
  newestOnTop: false,
  icon: false,
  toastDefaults: {
    // ToastOptions object for each type of toast
    [TYPE.ERROR]: {
      ...defaultToastOptionConfig,
      timeout: 10000,
    },
    [TYPE.SUCCESS]: { ...defaultToastOptionConfig },
    [TYPE.INFO]: { ...defaultToastOptionConfig },
  },
};

const generateToastContent =
  (variant: 'success' | 'danger' | 'default', text: string) => () =>
    (
      <BaseToastItem variant={variant}>
        {{
          default: () => <>{text}</>,
        }}
      </BaseToastItem>
    );

const toast = useToastification();
export const useToast = () => {
  const success = (text: string) => {
    const content = generateToastContent('success', text);
    toast.success(content);
  };
  const info = (text: string) => {
    const content = generateToastContent('default', text);
    toast.info(content);
  };
  const error = (text: string) => {
    const content = generateToastContent('danger', text);
    toast.error(content);
  };
  return {
    success,
    info,
    error,
  };
};
