<template>
  <section class="no-org-selected">
    <img
      src="https://res.cloudinary.com/snyk/image/upload/v1655814287/snyk-learn/patch-spyglass.png"
      alt="A dog using a spyglass to search for the action buttons"
      width="296"
      height="140"
    />
    <div class="no-org-selected__text">
      <p>Select an organization for the Snyk Learn report</p>
      <p>
        Gain insights into which lessons and lesson categories users are
        accessing for each organization.
      </p>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';

.no-org-selected {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  &__text {
    margin-top: token('space.l');

    @include media-query(small) {
      max-width: rem(600px);
    }

    p {
      @include typography('typography.brand.subhead');
      color: token('color.brand.midnight');
    }

    p:first-child {
      font-weight: 700;
    }

    p:last-child {
      font-weight: 400;
      margin-top: token('space.l');
    }
  }
}
</style>
