import { get, MaybeRef } from '@vueuse/core';
import { ref, computed } from 'vue';

export const usePagination = <T>(rows: MaybeRef<T[]>, pageSize: number) => {
  const currentPage = ref(1);
  const totalPagesCount = computed(() => {
    const totalRows = get(rows).length || 1;
    const pageCount = Math.ceil(totalRows / pageSize);
    return pageCount;
  });
  const paginatedRows = computed(() => {
    return get(rows).slice(
      (currentPage.value - 1) * pageSize,
      currentPage.value * pageSize,
    );
  });
  return { paginatedRows, currentPage, totalPagesCount };
};
