<template>
  <main class="admin-lesson-overview">
    <Loading v-if="lesson === null" />
    <!-- <template v-else-if="forbidden">
      <Forbidden></Forbidden> TODO handle forbidden properly
    </template> -->
    <template v-else>
      <div class="admin-lesson-overview__title">
        {{ lesson.title }}
      </div>
      <p class="assignments-dashboard__description">
        Showing most recent lesson completions for each user. Historical lesson
        completions are included in the CSV download.
      </p>
      <div v-if="orgId && lesson" class="admin-lesson-overview__body">
        <div>
          <UserProgressTable
            :org-id="orgId"
            :lesson-id="lesson.lessonId"
            :lesson-title="lesson.title"
            @dataAvailable="updateOrgMemberEmailsToCopy"
          />
        </div>
        <div>
          <CopyAndShare
            :value-to-copy="orgMemberEmailsToCopy"
            @copied="() => handleCopyButtonIsClicked('userEmails')"
          />
          <br />
          <TextAreaCopyToClipboard
            disabled
            :value-to-copy="emailTemplateToCopy"
            @copied="() => handleCopyButtonIsClicked('emailText')"
          />
        </div>
      </div>
    </template>
  </main>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue';
import useAdminLessonOverview from './useAdminLessonOverview';
import CopyAndShare from './components/CopyAndShare/CopyAndShare.vue';
import UserProgressTable from './components/UsersProgressTable/UsersProgressTable.vue';
import Loading from '../AdminDashboard/components/Loading/Loading.vue';
// import Forbidden from '../AdminDashboard/components/Forbidden/Forbidden.vue';
import { ProgressOverview } from './components/UsersProgressTable/useUsersProgressStats';
import TextAreaCopyToClipboard from '../../components/TextAreaCopyToClipboard/TextAreaCopyToClipboard.vue';
import { getLessonPath } from '../../lib/utils/lessons';
import { inviteLessonTemplate } from './inviteLessonTemplate';
import itly from '../../lib/analytics/itly';

const { lesson, orgId } = useAdminLessonOverview();

const orgMemberEmailsToCopy = ref('');
const updateOrgMemberEmailsToCopy = (payload: ProgressOverview[]) => {
  // Unique emails of org members who haven't started the lesson yet
  orgMemberEmailsToCopy.value = [
    ...new Set(
      payload
        .filter((po: ProgressOverview) => po.status === 'todo')
        .map((po: ProgressOverview) => po.user.email),
    ),
  ].join(',');
};

const emailTemplateToCopy = computed(() => {
  const lessonPath = getLessonPath(lesson.value!);
  const lessonTitle = lesson.value!.title;
  return inviteLessonTemplate(lessonPath, lessonTitle);
});

const handleCopyButtonIsClicked = (contentType: string) => {
  itly.copyButtonIsClicked({
    contentType,
    ecosystem: lesson.value!.ecosystem,
    eventSource: 'Learn',
    origin: 'reports-lesson-details',
  });
};
</script>
<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';

.admin-lesson-overview {
  max-width: $pageMaxWidth;
  width: 100%;
  margin: 0 auto;
  padding: token('space.xxl') $mobileContainerPadding 0;

  @include media-query(small) {
    padding: token('space.xxl') $containerPadding 0;
    max-width: $pageMaxWidth;
  }

  &__title {
    font-family: token('typography.family.poppins');
    font-weight: 700;
    font-size: rem(48px);
    line-height: rem(64px);
    letter-spacing: -1px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: space(s);

    @include media-query(small) {
      font-size: rem(48px);
      line-height: rem(64px);
    }
  }

  &__icon {
    line-height: rem(64px);
  }

  &__body {
    display: flex;
    flex-direction: column;
    margin-bottom: space(xl);
    > div {
      margin: space(m) 0;
    }

    @include media-query(small) {
      flex-direction: row;
      justify-content: space-between;
      > div {
        width: 45%;
        margin-bottom: 0;
      }
    }
  }
}
</style>
