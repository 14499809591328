<template>
  <BaseDropdown :close-on-click="false">
    <template #handle>
      <BaseButton :variant="!!appliedFilterCount() ? 'selected' : 'default'">
        <template #leftIcon><FilterVariantIcon /> </template>
        {{ buttonText() }}
        <template #rightIcon><MenuDownIcon /> </template>
      </BaseButton>
    </template>
    <template #arrow><div /></template>
    <BaseFilters
      v-model="selectedFilters"
      :available-filters="availableFilters"
      :show-search="true"
      @update:modelValue="onFiltersUpdate"
    />
  </BaseDropdown>
</template>

<script lang="ts" setup>
import { toRefs, computed } from 'vue';

import FilterVariantIcon from '@patchui/icons/FilterVariant.vue';
import MenuDownIcon from '@patchui/icons/MenuDown.vue';
import BaseButton from '@patchui/productcl/components/BaseButton/BaseButton.vue';
import BaseDropdown from '@patchui/productcl/components/BaseDropdown/BaseDropdown.vue';
import BaseFilters from '@patchui/productcl/components/BaseFilters/BaseFilters.vue';
import {
  columns,
  ASSIGNMENT_DASHBOARD_ITLY_ORIGIN,
  selectedFilters,
} from './tableUtils';
import type {
  TagsFilter,
  DateRangeFilter,
  CheckboxFilter,
} from '@patchui/productcl/components/BaseFilters/BaseFilters.vue';
import {
  typedObjectValues,
  isNotNullOrUndefined,
} from '../../../../lib/utils/type-utilities';
import itly from '../../../../lib/analytics/itly';

import type { MappedAssignment } from './tableUtils';

interface FilterProps {
  mappedAssignments: MappedAssignment[];
}

const props = defineProps<FilterProps>();
const { mappedAssignments } = toRefs(props);

const availableFilters = computed(() => {
  return columns.map((column) => {
    if (column.filterType === 'tags') {
      const options = [
        ...new Set(
          mappedAssignments.value
            .map((assignment) => assignment[column.dataKey])
            .filter(Boolean),
        ),
      ].sort();
      const filter: TagsFilter = {
        id: column.dataKey,
        name: column.title,
        type: column.filterType,
        options,
      };
      return filter;
    } else if (column.filterType === 'dateRange') {
      const filter: DateRangeFilter = {
        id: column.dataKey,
        name: column.title,
        type: column.filterType,
        options: [],
      };
      return filter;
    } else if (column.filterType === 'checkboxes') {
      const columnValues = mappedAssignments.value
        .map((assignment) => assignment[column.dataKey])
        .filter(Boolean);

      const uniqueColumnValues = columnValues
        .filter((item, index, self) => self.indexOf(item) === index)
        .filter(isNotNullOrUndefined);
      const options = uniqueColumnValues.sort().map((value) => ({
        label: value,
        value,
      }));

      const filter: CheckboxFilter = {
        id: column.dataKey,
        name: column.title,
        type: column.filterType,
        options,
      };

      return filter;
    } else {
      throw new Error('column.filterType not supported');
    }
  });
});

const onFiltersUpdate = () => {
  itly.learnTableIsManipulated({
    eventSource: 'Learn',
    path: '/admin/assignments/',
    origin: ASSIGNMENT_DASHBOARD_ITLY_ORIGIN,
    action: 'Filter',
  });
};

/** Get the total number of currently selected filters */
const appliedFilterCount = () =>
  typedObjectValues(selectedFilters.value).reduce(
    (acc, filter) => (acc += filter?.length || 0),
    0,
  );

const buttonText = () => {
  const appliedFilters = appliedFilterCount();
  if (appliedFilters === 0) return 'Add filter';
  if (appliedFilters === 1) return '1 filter';
  return `${appliedFilters} filters`;
};
</script>
