import { get, MaybeRef } from '@vueuse/core';

import { authenticatedClient } from '../../../../lib/apiClient';
import { OpenApiResponse } from '../../../../lib/utils/type-utilities';
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/vue-query';

export type CategoryOverview = NonNullable<
  OpenApiResponse<'getStatsOrganizationCategoriesOverview'>['categoriesOverview']
>[number];

const fetchCategoryStats = async (orgId: string) => {
  const response = await authenticatedClient.get<
    OpenApiResponse<'getStatsOrganizationCategoriesOverview'>
  >(`/v1/learn/stats/org/${orgId}/categories`);

  return response.data;
};

export const useCategoryStats = (orgId: MaybeRef<string>) => {
  return useQuery<
    {
      orgId: string;
      categoriesOverview: {
        ecosystemName: string;
        numberOfUsers: number;
      }[];
    },
    AxiosError
  >({
    queryKey: ['orgCategoryStats', orgId],
    queryFn: () => fetchCategoryStats(get(orgId)),
  });
};
