import { watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { clearAuthState } from '../lib/auth';
import { Query, useQueryClient } from '@tanstack/vue-query';
import { QueryMetaExtended } from '../types';

export const useLogoutWatcher = () => {
  const router = useRouter();
  const store = useStore();
  const queryClient = useQueryClient();
  watch(
    () => router.currentRoute.value.query.logout,
    async () => {
      const route = router.currentRoute.value;
      const { logout, ...rest } = route.query;
      if (logout === 'true') {
        clearAuthState();
        queryClient.removeQueries({
          predicate: (query: Query) =>
            !(query.meta as QueryMetaExtended)?.isContentQuery,
        });
        await store.dispatch('user/setNoLoggedInUser');
        await router.replace({
          path: route.path, // enforces the preservation of the trailing slash
          query: rest,
          hash: route.hash,
        });
      }
    },
  );
};
