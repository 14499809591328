<template>
  <BrandHeaderDisclosureNav
    v-if="!isLoginLoading"
    v-bind="{ align: 'right' }"
    title="Login"
  >
    <template #contents>
      <div class="tenant-selector__wrapper">
        <p class="tenant-selector__title">SNYK LEARN LOGIN</p>
        <ul class="tenant-selector__menu">
          <template
            v-for="dropdownItem in defaultNavItems"
            :key="dropdownItem.apiDomain"
          >
            <li>
              <BrandNavCTAButton
                role="link"
                :title="dropdownItem.label"
                :href="dropdownItem.loginHref.value"
                class="tenant-selector__menu-item"
                @click="
        (e: KeyboardEvent | MouseEvent) => {
          onLoginClick(e, dropdownItem.apiDomain, dropdownItem.appDomain);
        }"
              >
                <span class="tenant-selector__menu-item__text">
                  {{ dropdownItem.labelWithEmoji }}
                </span>
              </BrandNavCTAButton>
            </li>
          </template>
        </ul>
        <p class="tenant-selector__title">OTHER REGIONS</p>
        <p class="tenant-selector__sub-title">
          For Snyk Enterprise customers with regional contracts.
          <a
            title="regional hosting contracts"
            href="https://docs.snyk.io/working-with-snyk/regional-hosting-and-data-residency"
          >
            More info
          </a>
        </p>
        <ul class="tenant-selector__menu">
          <template
            v-for="dropdownItem in otherEnvironmentNavItems"
            :key="dropdownItem.apiDomain"
          >
            <li>
              <BrandNavCTAButton
                role="link"
                :title="dropdownItem.label"
                :href="dropdownItem.loginHref.value"
                class="tenant-selector__menu-item"
                @click="
        (e: KeyboardEvent | MouseEvent) => {
          onLoginClick(e, dropdownItem.apiDomain, dropdownItem.appDomain);
        }"
              >
                <span class="tenant-selector__menu-item__text">
                  {{ dropdownItem.labelWithEmoji }}
                </span>
              </BrandNavCTAButton>
            </li>
          </template>
        </ul>
      </div>
    </template>
  </BrandHeaderDisclosureNav>
  <div v-else>
    <BaseLoadingSpinner size="medium" aria-label="Loading spinner" />
  </div>
</template>

<script lang="ts" setup>
import { Ref, ref } from 'vue';
import BaseLoadingSpinner from '@patchui/productcl/components/BaseLoadingSpinner/BaseLoadingSpinner.vue';
import {
  BrandHeaderDisclosureNav,
  BrandNavCTAButton,
} from '@patchui/ecosystemcl';
import {
  handleLoginClick,
  mapEnvironmentToDropdownItem,
} from './tenantSelectorLogic';
import {
  DEFAULT_POLARIS_ENVIRONMENT,
  getOtherPolarisEnvironments,
} from '../../../../lib/config/env';

const isLoginLoading: Ref<boolean> = ref(false);

const defaultNavItems = [
  mapEnvironmentToDropdownItem(DEFAULT_POLARIS_ENVIRONMENT),
];
const otherEnvironmentNavItems = getOtherPolarisEnvironments().map(
  mapEnvironmentToDropdownItem,
);

const onLoginClick = (
  e: KeyboardEvent | MouseEvent,
  apiDomain: string,
  appDomain: string,
) => {
  isLoginLoading.value = true;
  handleLoginClick(e, apiDomain, appDomain);
};
</script>

<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';

:deep(.tenant-selector__menu-item.tenant-selector__menu-item) {
  background: #fff;
  padding: 8px 16px;
}
.tenant-selector {
  &__wrapper {
    min-width: 300px;
    display: flex;
    flex-direction: column;
  }

  &__title {
    @include typography('typography.brand.mini-header');
    margin: 8px 0;
    padding: 0 16px;
    color: #6d6d9c; // not in productCL
  }
  &__sub-title {
    @include typography('typography.product.body-small');
    color: #383f76; // not in productCL
    padding: 0 16px;
    margin: 8px 0;
  }
  &__menu {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  &__menu-item {
    width: 100%;
    color: token('color.brand.midnight');
    &__text {
      font-weight: 600;
      text-align: left;
    }
  }
}
</style>
