import { LessonFAQ } from '../../../src/types';
import { LessonMetadata } from '../lessonMetadata';
import { langMapper } from '../../lib/utils/mappers';
import { marked } from 'marked';

export interface IJsonLd {
  '@context': string;
  '@graph': IGraphEntity[];
}

interface IGraphEntity {
  '@type': string;
}

interface IFaqPage extends IGraphEntity {
  inLanguage: string;
  mainEntity: IGoogleQuestion[];
}

interface IGoogleQuestion {
  '@type': string;
  name: string;
  acceptedAnswer: IGoogleAnswer;
}

interface IGoogleAnswer {
  '@type': string;
  text: string;
}

interface IArticle extends IGraphEntity {
  headline: string;
  author: Person;
  datePublished: string;
  publisher: {
    '@id': string;
  };
  description: string;
  name: string;
  inLanguage: 'en-US';
  keywords?: string;
  image?: string;
}

interface Person {
  '@type': string;
  name: string;
}

export const createFaqPageEntity = (faqs: LessonFAQ[]): IFaqPage | null => {
  const ldFaqs = faqs.map((faq) => {
    return {
      '@type': 'Question',
      name: faq.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: marked.parse(faq.answer),
      },
    };
  });

  if (ldFaqs.length) {
    return {
      '@type': 'FAQPage',
      inLanguage: 'en-US',
      mainEntity: ldFaqs,
    };
  } else {
    return null;
  }
};

export const createArticleEntity = (
  lessonMetadata: LessonMetadata,
): IArticle => {
  return {
    '@type': 'Article',
    publisher: {
      '@id': 'https://snyk.io/#organization',
    },
    description: lessonMetadata.description,
    author: {
      '@type': 'Person',
      name: lessonMetadata.author,
      //Not providing URL as it is optional, and we don't have one
    },
    datePublished: lessonMetadata.datePublished,
    inLanguage: 'en-US',
    name: lessonMetadata.seoTitle.replace(
      '|',
      `in ${langMapper[lessonMetadata.ecosystem]} |`,
    ),
    headline: lessonMetadata.seoTitle,
    ...(lessonMetadata.img && {
      image: lessonMetadata.img,
    }),
    ...(lessonMetadata.seoKeywords && {
      keywords: lessonMetadata.seoKeywords.join(','),
    }),
  };
};

export const createJsonLd = (entities: IGraphEntity[]): IJsonLd => {
  return {
    '@context': 'https://schema.org',
    '@graph': entities,
  };
};
