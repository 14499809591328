import {
  SET_LOGGED_IN_USER,
  SET_NO_LOGGED_IN_USER,
  SET_USER_PROJECTS,
} from '../mutation-types';
import { Commit } from 'vuex';
import { fetchUserProjects, getUser, RegistryUser } from '../../lib/auth';

import { identifyAnonymousUser } from '../../lib/analytics/analytics';

const PRIVATE_SET_USER_REQUEST = 'SET_USER_REQUEST';

declare global {
  interface Window {
    Appcues?: any;
  }
}

export interface State {
  loggedInUser: RegistryUser | null;
  userProjects: Array<any>;
  _userRequest: Promise<RegistryUser> | null;
}

export const defaultState: State = {
  loggedInUser: null,
  userProjects: [],
  _userRequest: null,
};

export default {
  namespaced: true,
  state: defaultState,
  getters: {
    getLoggedInUser(state: State): RegistryUser | null {
      return state.loggedInUser;
    },
    getUserProjects(state: State): Array<any> {
      return state.userProjects;
    },
  },

  actions: {
    async setLoggedInUser({
      commit,
      state,
    }: {
      commit: Commit;
      state: State;
    }): Promise<void> {
      if (state.loggedInUser) return;

      try {
        const isFirstGetUserRequest = !state._userRequest;
        const userFetchRequest = isFirstGetUserRequest
          ? getUser()
          : state._userRequest;

        commit(PRIVATE_SET_USER_REQUEST, userFetchRequest);
        const user = await userFetchRequest;

        if (user) {
          commit(SET_LOGGED_IN_USER, user);
          if (isFirstGetUserRequest) {
            await identifyAnonymousUser(user.id);
          }
        }
        commit(PRIVATE_SET_USER_REQUEST, null);

        const isProduction = process.env.VUE_APP_MODE === 'production';
        if (isProduction) {
          analytics.ready(function () {
            if (window.Appcues) {
              if (user) {
                window.Appcues?.identify(user.id);
              } else {
                window.Appcues?.anonymous();
              }
            }
          });
        }
      } catch (e) {
        //TODO: capture with datadog frontend error monitoring
        window.console.error(JSON.stringify(e));
      }
    },
    setNoLoggedInUser({ commit }: { commit: Commit; getters: any }) {
      commit(SET_NO_LOGGED_IN_USER);
    },
    async setUserProjects({
      commit,
    }: {
      commit: Commit;
      getters: any;
    }): Promise<void> {
      try {
        const projects = await fetchUserProjects();
        if (projects) {
          commit(SET_USER_PROJECTS, projects);
        }
      } catch (e) {
        //TODO: capture with datadog frontend error monitoring
        window.console.error(JSON.stringify(e));
      }
    },
  },

  mutations: {
    [SET_LOGGED_IN_USER](state: State, user: RegistryUser): void {
      state.loggedInUser = user;
    },
    [SET_NO_LOGGED_IN_USER](state: State): void {
      state.loggedInUser = null;
    },
    [SET_USER_PROJECTS](state: State, projects: Array<any>): void {
      state.userProjects = projects;
    },
    [PRIVATE_SET_USER_REQUEST](
      state: State,
      userRequest: Promise<RegistryUser> | null,
    ): void {
      state._userRequest = userRequest;
    },
  },
};
