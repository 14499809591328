<template>
  <Teleport to="body">
    <div class="wrapper">
      <main class="modal">
        <slot></slot>
      </main>
      <div class="grey-background"></div>
    </div>
  </Teleport>
</template>
<script lang="ts" setup>
import { onMounted, onUnmounted } from 'vue';
onMounted(() => {
  document.documentElement.style.overflow = 'hidden';
});

onUnmounted(() => {
  document.documentElement.style.overflow = 'scroll';
});
</script>
<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';

.wrapper {
  position: fixed;
  z-index: layer(modal);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.grey-background {
  background-color: token('color.brand.steel');
  opacity: 0.6;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.modal {
  position: relative;
  z-index: layer(modal) + 1;
  max-width: 460px;
  width: 90%;
  padding: 50px 4% 86px; // 86px is for the curve image
  background-color: white;
  border-radius: 6px;
  box-shadow: 0px 8px 12px 1px rgba(0, 0, 0, 0.15);
  overflow: hidden;

  background-image: url('https://res.cloudinary.com/snyk/image/upload/v1658745712/snyk-learn/modal-wave-v2.svg');
  background-repeat: no-repeat;
  background-size: 102%; // over 100% to deal with firefox white gaps
  background-position: 50% 102%; // over 100% to deal with firefox white gaps
}
</style>
