import { onMounted, computed, onServerPrefetch } from 'vue';
import { useQueryParam } from '../../hooks/useQueryParam';
import {
  ecosystems,
  LessonEcosystem,
  LessonMetadata,
} from '../../lib/lessonMetadata';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { RouterHelper } from '../../routerHelper';
import { useListLessonsMetadata } from '../../hooks/lessonsHooks';

export default function useAdminLessonOverview() {
  const router = useRouter();
  const store = useStore();

  const [orgId] = useQueryParam('orgId');

  const [lessonSlug] = useQueryParam('lessonSlug');
  const lesson = computed<LessonMetadata | null>(() => {
    if (!lessons.value || !lessonSlug.value) return null;
    const foundLesson = lessons.value.find(
      (lesson) => lesson.slug === lessonSlug.value,
    );
    return foundLesson || null;
  });

  const { data: lessons, suspense } = useListLessonsMetadata();

  onServerPrefetch(suspense);

  const [category] = useQueryParam<LessonEcosystem>('category', {
    validator: (val) => {
      return ecosystems.includes(val as any);
    },
  });

  onMounted(async () => {
    await suspense();
    if (!category.value || !lesson.value) {
      router.push(RouterHelper.getAdminDashboardRoute(store).path);
    }
  });

  return {
    orgId,
    lesson,
    category,
  };
}
