import type { StepBlock } from './stepBlock';
import type { HiddenRestBlock } from '../api/lessons';

export interface LessonStep {
  stepId: string;
  title: string;
  text: string;
  blocks: StepBlock[];
}

export const restStepContainsAnAssessment = (blocks: HiddenRestBlock[]) => {
  return blocks.some(({ widgets }) => {
    return widgets.some(
      ({ metadata }) => metadata.type === 'multipleChoiceQuestion',
    );
  });
};
