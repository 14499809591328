import { ref, watchEffect } from 'vue';
import { useRoute } from 'vue-router';
import { pickFirstIfArray } from '../lib/utils/stringHelper';

// The only reason we need this hook is because there is a bug in vue-router functionality
// whereby the route parameter changes before the component has been unmounted
// https://github.com/vuejs/vue-router/issues/3393

export const useRouteParam = (paramName: string, pageName: string) => {
  const route = useRoute();

  const routeParam = ref(pickFirstIfArray(route.params[paramName] || ''));

  watchEffect(() => {
    if (route.name === pageName) {
      routeParam.value = pickFirstIfArray(route.params[paramName]);
    }
  });

  return routeParam;
};
