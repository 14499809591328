/// <reference types="@types/segment-analytics" />

import { initItly } from './iteratively';
import itly, { PageProperties } from './itly';
import { initAmplitude } from './amplitude';

import * as googleTagManager from './googleTagManager';
import type { RouteLocationNormalized } from 'vue-router';

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS;
  }
}

export class Analytics {
  public static initializeAnalytics(): void {
    const itlyEnvironment = process.env.VUE_APP_ANALYTICS_ENV;
    const segmentWriteKey = process.env.VUE_APP_ANALYTICS_SEGMENT_KEY;
    const amplitudeApiKey = process.env.VUE_APP_AMPLITUDE_KEY;

    if (!itlyEnvironment || !segmentWriteKey || !amplitudeApiKey) {
      window.console.log('analytics is missing config params');
    }

    const analyticsConfig = {
      itlyEnvironment,
      // Write keys are not secret (they are visible in the browser)
      segmentWriteKey,
      amplitudeApiKey,
    };

    if (typeof window === 'undefined') return;

    try {
      // @ts-ignore
      initItly(analyticsConfig);
      // @ts-ignore
      initAmplitude(analyticsConfig.amplitudeApiKey);
    } catch (e) {
      window.console.error(
        'Unable to initialize analytics due to: ' + JSON.stringify(e),
      );
    }
  }

  public static async anonymousUserId(): Promise<string | undefined> {
    try {
      const analyticsAnonymousId = window?.analytics?.user?.()?.anonymousId?.();
      if (analyticsAnonymousId) {
        return analyticsAnonymousId;
      }
      const localStorageFallback =
        window?.localStorage?.getItem?.('ajs_anonymous_id');

      if (localStorageFallback) {
        return JSON.parse(localStorageFallback);
      } else {
        return undefined;
      }
    } catch (e) {
      //TODO: capture with datadog frontend error monitoring;
      window.console.error(JSON.stringify(e));
      return undefined;
    }
  }

  public static analyticsPage(
    toRoute: RouteLocationNormalized,
    fromRoute: RouteLocationNormalized,
  ): void {
    if (typeof window === 'undefined') return;

    if (window.analytics) {
      window.analytics.ready(() => {
        let name = toRoute.name?.toString();
        if (!name) {
          if ('lessonSlug' in toRoute.params) {
            [name] = Array.isArray(toRoute.params['lessonSlug'])
              ? toRoute.params['lessonSlug']
              : [toRoute.params['lessonSlug']];
          } else {
            name = '';
          }
        }

        const properties: PageProperties = {
          url: window?.location?.href,
          search: JSON.stringify(toRoute.query),
        };

        if ('ecosystem' in toRoute.params) {
          [properties.ecosystem] = Array.isArray(toRoute.params['ecosystem'])
            ? toRoute.params['ecosystem']
            : [toRoute.params['ecosystem']];
        }

        try {
          itly.page('Snyk Learn', name, properties);
          // GA 4 tracking
          const referrer =
            window?.document?.referrer ||
            window?.location?.origin + fromRoute.fullPath;
          googleTagManager.pageView(name, referrer);
        } catch (e) {
          window.console.error(
            'Unable to collect page analytics due to: ' + JSON.stringify(e),
          );
        }
      });
    } else {
      window.console.error(
        'Unable to collect page analytics, window.analytics not available',
      );
    }
  }
}

export const identifyAnonymousUser = async (userId: string) => {
  try {
    const anonymousId = await Analytics.anonymousUserId();
    const options = anonymousId
      ? {
          segment: {
            options: {
              anonymousId,
            },
          },
        }
      : undefined;

    itly.identify(userId, {}, options);
  } catch (e) {
    //TODO: capture with datadog frontend error monitoring
    window.console.error(JSON.stringify(e));
  }
};
