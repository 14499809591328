<template>
  <Modal>
    <h2>🔐Authenticating...</h2>
    <p>
      We’ve identified you have a Snyk account already. We will connect your
      session with Snyk Learn now!
    </p>
    <p>Fasten your seatbelt & hold on to your seat.</p>
    <div ref="animationElement" class="lottie-animation"></div>
  </Modal>
</template>
<script lang="ts" setup>
import { onMounted, ref, toRefs } from 'vue';
import Modal from './Modal.vue';
import type Lottie from 'lottie-web';

const animationElement = ref<HTMLElement | null>(null);
interface IAutomaticAuthProps {
  disableAnimation?: boolean;
}

const props = defineProps<IAutomaticAuthProps>();
const { disableAnimation } = toRefs(props);

onMounted(async () => {
  if (animationElement.value) {
    const lottieInstance = (await import(
      'lottie-web'
    )) as unknown as typeof Lottie;

    const anim = lottieInstance.loadAnimation({
      container: animationElement.value,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '/assets/lottie/plug-connection.json',
    });

    anim.addEventListener('data_ready', () => {
      const prefersReducedMotion =
        window?.matchMedia?.('(prefers-reduced-motion: reduce)')?.matches ??
        false;

      if (prefersReducedMotion || disableAnimation?.value) {
        anim.loop = false;
        anim.goToAndStop(anim.getDuration(true), true);
      }
    });
  }
});
</script>
<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';
h2 {
  @include typography('typography.brand.heading3');
  color: token('color.neutral.90');
  margin-bottom: token('space.xl');
}
p {
  @include typography('typography.brand.body');
  font-weight: 400;
  color: token('color.brand.steel');
}
.lottie-animation {
  margin: token('space.xl') auto token('space.xxs');
  width: 130px;
  height: 130px;
  transform: rotate(-45deg);
}
</style>
