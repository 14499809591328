import { getAppOrigin } from './../../lib/utils/url';

export const inviteLessonTemplate = (
  lessonPath: string,
  lessonTitle: string,
) => {
  const appOrigin = getAppOrigin();
  return `
Hi,
  
We want to raise the awareness of ${lessonTitle} in your codebase and improve your security success.
  
To start learning now:
1. Go to Snyk Learn - https://learn.snyk.io/
2. Sign in to your account in the login/register field in the page header.
3. Go to ${appOrigin}${lessonPath} and learn more about ${lessonTitle}
  
If you have any questions, just reach out.  We’re here for you!
  
Best regards.
  `;
};
