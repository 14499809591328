<template>
  <BaseButton
    :disabled="assignmentIds.length === 0"
    label="Change due date"
    variant="link"
    size="medium"
    @click="() => (showDialogue = true)"
  >
    <template #leftIcon>
      <BaseTooltip
        description="Change due date"
        :options="{ placement: 'right', offset: 10 }"
      >
        <CalendarEdit />
      </BaseTooltip>
    </template>
  </BaseButton>

  <BaseDialog
    v-if="showDialogue"
    overlay
    dismissible
    open
    :title="`Edit assignments`"
    @close="closeDialogue"
    @submit.prevent="bulkUpdateAssignments"
  >
    <BaseDatePicker
      v-model="newDueDate"
      :disabled-dates="disabledDueDates"
      :min-date="new Date()"
      placeholder="No due date"
      :highlight="{ options: { highlightDisabled: true } }"
      :teleport="true"
      label="Enter the new due date for selected assignments"
      :auto-apply="false"
      @update:modelValue="validateDueDate"
    />

    <p>
      The due date must be at least one day after the assignments start date.
    </p>

    <template #actions>
      <BaseLoadingSpinner v-if="editInProgress" inline>
        Please wait...
      </BaseLoadingSpinner>
      <template v-else>
        <BaseButton type="submit" variant="primary" :disabled="!newDueDate">
          Confirm
        </BaseButton>
        <BaseButton type="submit" @click="closeDialogue"> Cancel </BaseButton>
      </template>
    </template>
  </BaseDialog>
</template>

<script lang="ts" setup>
import { ref, toRefs } from 'vue';
import { useQueryClient } from '@tanstack/vue-query';
import BaseDatePicker from '@patchui/productcl/components/BaseDatePicker/BaseDatePicker.vue';
import CalendarEdit from '@patchui/icons/CalendarEdit.vue';
import BaseButton from '@patchui/productcl/components/BaseButton/BaseButton.vue';
import BaseDialog from '@patchui/productcl/components/BaseDialog/BaseDialog.vue';
import BaseLoadingSpinner from '@patchui/productcl/components/BaseLoadingSpinner/BaseLoadingSpinner.vue';
import BaseTooltip from '@patchui/productcl/components/BaseTooltip/BaseTooltip.vue';
import { useToast } from '../../../../hooks/useToast';
import { selectedOrg } from '../../assignmentUtils';
import { isValidAssignmentDueDate } from '../../../../lib/utils/assignmentDateValidator';
import {
  updateOrgAssignments,
  LIST_ORG_ASSIGNMENTS_BASE_KEY,
} from '../../../../api/assignments';
import moment from 'moment';

export interface EditButtonProps {
  assignmentIds: string[];
}
const props = defineProps<EditButtonProps>();
const { assignmentIds } = toRefs(props);
const toast = useToast();
const queryClient = useQueryClient();

const showDialogue = ref(false);
const editInProgress = ref(false);

const emit = defineEmits<{
  (e: 'assignmentsUpdateComplete'): void;
}>();
const newDueDate = ref<number | any>(null);

const disabledDueDates = (date: Date | undefined) =>
  !isValidAssignmentDueDate(date);

const validateDueDate = (output: number | number[] | undefined): void => {
  if (
    typeof output === 'number' &&
    isValidAssignmentDueDate(new Date(output))
  ) {
    newDueDate.value = output;
  } else {
    newDueDate.value = null;
  }
};

const closeDialogue = () => (showDialogue.value = false);

const countOfAssignmentText = (count: number) =>
  count === 1 ? '1 assignment' : `${count} assignments`;

const bulkUpdateAssignments = async () => {
  editInProgress.value = true;
  try {
    const orgId = selectedOrg.value?.id;
    if (!orgId) {
      throw new Error('No org selected'); // Should never happen, required for type safety
    }
    await updateOrgAssignments(
      orgId,
      assignmentIds.value,
      moment(newDueDate.value).format('YYYY-MM-DD'),
    );

    toast.success(
      `Successfully updated due date for ${countOfAssignmentText(
        assignmentIds.value.length,
      )}`,
    );
    queryClient.refetchQueries({
      queryKey: [LIST_ORG_ASSIGNMENTS_BASE_KEY, orgId],
    });
    emit('assignmentsUpdateComplete');
  } catch (err) {
    toast.error(`Error`);
  } finally {
    closeDialogue();
    editInProgress.value = false;
  }
};
</script>
