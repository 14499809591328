import { degrees, PDFDocument, PDFFont, PDFPage, rgb } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';

interface AddTextOptions {
  text: string;
  size: number;
  font: PDFFont;
  x?: number;
  y?: number;
  color?: any;
}

async function addText(page: PDFPage, options: AddTextOptions) {
  const textWidth = options.font.widthOfTextAtSize(options.text, options.size);

  page.drawText(options.text, {
    x: page.getWidth() / 2 - textWidth / 2,
    y: options.y,
    size: options.size,
    font: options.font,
    color: options.color ?? rgb(1, 1, 1),
    rotate: degrees(0),
  });
}

function formatDate(date: any) {
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  return date.toLocaleDateString('en-US', options);
}

async function fetchAsArrayBuffer(url: string): Promise<ArrayBuffer> {
  const res = await fetch(url);
  return res.arrayBuffer();
}

interface CertificateInput {
  learningPathTitle: string;
  studentName: string;
  studentEmail: string;
  date: Date;
}

async function makeCertificate({
  learningPathTitle,
  studentName,
  studentEmail,
  date,
}: CertificateInput) {
  // Load PDF template for certificate from public assets
  const existingPdfBytes = await fetchAsArrayBuffer(
    '/assets/certificate/template.pdf',
  );

  // Create pdf doc from template
  const pdfDoc = await PDFDocument.load(existingPdfBytes);
  pdfDoc.registerFontkit(fontkit);

  // Load custom fonts
  const loadFont = async (fontUrl: string) => {
    const fontBuffer = await fetchAsArrayBuffer(fontUrl);
    return pdfDoc.embedFont(fontBuffer);
  };

  const [poppinsFont_800, poppinsFont_700, robotoFont_400] = await Promise.all([
    loadFont('/assets/fonts/Poppins-ExtraBold.ttf'),
    loadFont('/assets/fonts/Poppins-Bold.ttf'),
    loadFont('/assets/fonts/Roboto-Regular.ttf'),
  ]);

  // Register custom fonts

  // Get first page of PDF file
  const pages = pdfDoc.getPages();
  const certificatePage = pages[0];

  // Add learning path title to a certificate
  await addText(certificatePage, {
    text: learningPathTitle.toUpperCase(),
    size: 36,
    font: poppinsFont_800,
    color: rgb(68 / 255, 28 / 255, 153 / 255),
    y: 335,
  });

  // Add student name to a certificate
  await addText(certificatePage, {
    text: studentName,
    size: 48,
    font: poppinsFont_700,
    color: rgb(1, 1, 1),
    y: 220,
  });

  // Add description line 1 to a certificate
  const descriptionLine1 = `This is to certify that ${studentEmail} has successfully completed`;
  await addText(certificatePage, {
    text: descriptionLine1,
    size: 16,
    font: robotoFont_400,
    color: rgb(1, 1, 1),
    y: 175,
  });

  // Add description line 2 to a certificate
  const descriptionLine2 = `the "${learningPathTitle}" Learning path on ${formatDate(
    date,
  )}`;
  await addText(certificatePage, {
    text: descriptionLine2,
    size: 16,
    font: robotoFont_400,
    color: rgb(1, 1, 1),
    y: 155,
  });

  // Save pdf file as binary and return blob with pdf mimetype
  const pdfBytes = await pdfDoc.save();

  const blob = new Blob([pdfBytes], { type: 'application/pdf' });
  return blob;
}

export default makeCertificate;
