import { computed } from 'vue';
import { useUser } from './useUser';

/**
 * Returns a ref<bool> that indicates whether the user is currently logged in.
 */
export const useLoggedIn = () => {
  const { user } = useUser();

  return computed(() => Boolean(user.value));
};
