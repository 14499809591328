<template>
  <div class="pagination">
    <span>
      {{ `${pageSize} per page` }}
    </span>
    <BasePaginationNav
      :current-page="pageNumber"
      :total-pages="totalPageCount"
      :max-pages-shown="5"
      @next="() => setPage(pageNumber + 1)"
      @prev="() => setPage(pageNumber - 1)"
      @goToPage="(value) => setPage(Number(value))"
    />
    <span>
      {{ pagePositioningText }}
    </span>
  </div>
</template>

<script lang="ts" setup>
import { computed, toRefs } from 'vue';
import BasePaginationNav from '@patchui/productcl/components/BasePaginationNav/BasePaginationNav.vue';

export interface PaginationProps {
  pageNumber: number;
  pageSize: number;
  totalRecordCount: number;
}
const props = defineProps<PaginationProps>();
const { pageNumber, pageSize, totalRecordCount } = toRefs(props);
const emit = defineEmits<{
  (e: 'setPage', value: number): void;
}>();

const setPage = (page: number) => {
  emit('setPage', page);
};
const totalPageCount = computed(() =>
  Math.ceil(totalRecordCount.value / pageSize.value),
);

const pagePositioningText = computed(() => {
  const pageIndex = pageNumber.value - 1;
  const firstRecordOnPage = Math.min(
    pageIndex * pageSize.value + 1,
    totalRecordCount.value,
  );
  const lastRecordOnPage = Math.min(
    pageSize.value + firstRecordOnPage - 1,
    totalRecordCount.value,
  );
  return `Showing ${firstRecordOnPage}-${lastRecordOnPage} of ${totalRecordCount.value}`;
});
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
}
</style>
