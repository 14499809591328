interface NavItem {
  href?: string;
  to?: string;
  label: string;
}

export interface NavGroup {
  title: string;
  items: NavItem[];
}

export const getNavGroups = (): NavGroup[] => {
  return [
    {
      title: 'Product',
      items: [
        {
          href: 'https://snyk.io/product/open-source-security-management/?loc=learn',
          label: 'Snyk Open Source',
        },
        {
          href: 'https://snyk.io/product/snyk-code/?loc=learn',
          label: 'Snyk Code',
        },
        {
          href: 'https://snyk.io/product/container-vulnerability-management/?loc=learn',
          label: 'Snyk Container',
        },
        {
          href: 'https://snyk.io/product/infrastructure-as-code-security/?loc=learn',
          label: 'Snyk Infrastructure as Code',
        },
        { href: 'https://snyk.io/solutions/aspm/', label: 'ASPM Solution' },
        {
          href: 'https://snyk.io/solutions/application-security/',
          label: 'Application Security Solution',
        },
        {
          href: 'https://snyk.io/solutions/software-supply-chain-security/',
          label: 'Supply Chain Security Solution',
        },

        {
          href: 'https://snyk.io/platform/deepcode-ai/',
          label: 'Deepcode AI',
        },
      ],
    },
    {
      title: 'Resources',
      items: [
        {
          href: 'https://security.snyk.io/?loc=learn',
          label: 'Vulnerability DB',
        },
        {
          href: 'https://snyk.io/advisor/?loc=learn',
          label: 'Snyk OSS Advisor',
        },
        {
          href: 'https://support.snyk.io/hc/en-us/?loc=learn',
          label: 'Documentation',
        },
        {
          href: 'https://security.snyk.io/disclosed-vulnerabilities/?loc=learn',
          label: 'Disclosed Vulnerabilities',
        },
        {
          href: 'https://support.snyk.io/hc/en-us/?loc=learn',
          label: 'FAQs',
        },
        {
          href: 'https://snyk.io/code-checker/?loc=learn',
          label: 'Code Checker',
        },
        {
          href: 'https://snyk.io/open-source-audit/?loc=learn',
          label: 'Audit Services',
        },
      ],
    },
    {
      title: 'Company',
      items: [
        { href: 'https://snyk.io/about/?loc=learn', label: 'About us' },
        {
          href: 'https://snyk.io/about/snyk-impact/?loc=learn',
          label: 'Snyk Impact',
        },
        { href: 'https://snyk.io/customers/?loc=learn', label: 'Customers' },
        { href: 'https://snyk.io/careers/?loc=learn', label: 'Jobs at Snyk' },
        {
          href: 'https://snyk.io/policies/terms-of-service/?loc=learn',
          label: 'Policies',
        },
        {
          href: 'https://preferences.snyk.io/dont_sell/?loc=learn',
          label: 'Do Not Sell My Personal Information',
        },
      ],
    },
    {
      title: 'Connect',
      items: [
        { href: 'https://snyk.io/blog/?loc=learn', label: 'Blog' },
        {
          href: 'https://snyk.io/learn/?loc=learn',
          label: 'Security Fundamentals',
        },
        {
          href: 'http://snyk.io/series/?loc=learn',
          label: 'Security Series',
        },
        {
          href: 'https://support.snyk.io/hc/en-us/?loc=learn',
          label: 'Support',
        },
        {
          href: 'https://snyk.io/press-kit/?loc=learn',
          label: 'Press Kit',
        },
        { href: 'https://snyk.io/events/?loc=learn', label: 'Events' },
        {
          href: 'https://snyk.io/vulnerability-disclosure/?loc=learn',
          label: 'Report a new vuln',
        },
      ],
    },
    {
      title: 'Topics',
      items: [
        { to: '/catalog/security-education/', label: 'Security Education' },
        {
          href: '/catalog/product-training/',
          label: 'Product Training',
        },

        {
          href: '/catalog/?format=learning_path',
          label: 'Learning Paths',
        },
      ],
    },
  ];
};

export interface SocialItem {
  title: string;
  href: string;
  imageTitle: string;
  imageSrc: string;
  height: number;
  extraClasses?: string;
}

export const socialItems: SocialItem[] = [
  {
    title: 'Github',
    href: 'https://github.com/Snyk/',
    imageTitle: "Github's Logo",
    imageSrc: '/assets/images/footer-github-icon.svg',
    height: 20,
  },
  {
    title: 'NPM',
    href: 'https://www.npmjs.com/snyk',
    imageTitle: "Node Package Manger's Logo",
    imageSrc: '/assets/images/footer-npm-icon.svg',
    height: 20,
  },
  {
    title: 'DevSecOps Community Podcast',
    href: 'https://www.devseccon.com/the-secure-developer-podcast/',
    imageTitle: 'DevSecOps Community Podcast',
    imageSrc:
      'https://res.cloudinary.com/snyk/image/upload/v1632399229/snyk-learn/community-banner-footer.svg',
    height: 68,
    extraClasses: 'list-social__item--podcast-ad',
  },
  {
    title: 'Twitter',
    href: 'https://twitter.com/snyksec',
    imageTitle: "Twitter's Logo",
    imageSrc: '/assets/images/footer-twitter-icon.svg',
    height: 20,
  },
  {
    title: 'Youtube',
    href: 'https://www.youtube.com/channel/UCh4dJzctb0NhSibjU-e2P6w',
    imageTitle: "Youtube's Logo",
    imageSrc: '/assets/images/footer-youtube-icon.svg',
    height: 20,
  },
  {
    title: 'Facebook',
    href: 'https://www.facebook.com/snyksec',
    imageTitle: "Facebook's Logo",
    imageSrc: '/assets/images/footer-facebook-icon.svg',
    height: 20,
  },
  {
    title: 'LinkedIn',
    href: 'https://www.linkedin.com/company/snyk',
    imageTitle: "LinkedIn's Logo",
    imageSrc: '/assets/images/footer-linkedin-icon.svg',
    height: 20,
  },
];
