<template>
  <section v-if="faqs.length" class="faqs">
    <h2 class="faqs__title">FAQs</h2>
    <BaseExpandable
      v-for="faq in parsedFaqs"
      :key="faq.question"
      :pre-render="true"
    >
      <template #title>
        <p class="faqs__question">{{ faq.question }}</p>
      </template>
      <div class="faqs__answer" v-html="faq.answer"></div>
    </BaseExpandable>
  </section>
</template>
<script lang="ts" setup>
import { toRefs, computed } from 'vue';
import BaseExpandable from '@patchui/productcl/components/BaseExpandable/BaseExpandable.vue';
import { marked } from 'marked';
import domPurify from 'isomorphic-dompurify';

interface IFaq {
  question: string;
  answer: string;
}

const props = defineProps<{ faqs: IFaq[] }>();
const { faqs } = toRefs(props);

const parsedFaqs = computed(() =>
  faqs.value.map((faq) => ({
    question: faq.question,
    answer: domPurify.sanitize(marked.parse(faq.answer)),
  })),
);
</script>
<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';
.faqs {
  &__title {
    color: black;
    font-size: rem(24px);
    line-height: rem(36px);
    font-weight: 600;
    margin-bottom: token('space.xl');
    display: inline-block;
  }
  &__question {
    margin: 10px 0;
    width: 100%;
    @include typography('typography.product.default');
    line-height: 1.25;
    color: token('color.neutral.90');
    white-space: normal;
  }

  &__answer {
    @include typography('typography.product.default');
    line-height: 1;
    color: token('color.brand.steel');
    :deep(strong) {
      font-weight: bold;
    }
    :deep(em) {
      font-style: italic;
    }
    :deep(ol) {
      list-style-type: decimal;
      list-style-position: inside;
    }
  }

  ::v-deep(.expandable__content) {
    padding: 12px 12px 12px 32px;
  }
}
</style>
