<template>
  <section class="selector">
    <AttentionBox icon-name="laptop">
      <template #title>
        <h3>{{ title }}</h3>
      </template>
      <template v-if="ecosystems.length > 1" #body>
        <div class="selector--body">
          <BaseTabs
            :panes="panes"
            :selected-tab="selectedTab.index"
            @changeTab="onTabChange"
          />
        </div>
      </template>
    </AttentionBox>
  </section>
</template>

<script lang="ts" setup>
import { toRefs, computed, onServerPrefetch } from 'vue';

import AttentionBox from '../../components/AttentionBox/AttentionBox.vue';
import { langMapper } from '../../lib/utils/mappers';
import { selectedEcosystem } from './lessonStore';
import BaseTabs from '@patchui/productcl/components/BaseTabs/BaseTabs.vue';
import { useToast } from '../../hooks/useToast';
import { useSuspense } from '../../hooks/useSuspense';

interface EcosystemSelectorProps {
  ecosystems: string[];
}
const props = defineProps<EcosystemSelectorProps>();
const { ecosystems } = toRefs(props);
const toast = useToast();

const title = computed(() => {
  return ecosystems.value.length === 1
    ? langMapper[ecosystems.value[0]] || ecosystems.value[0]
    : 'Select your ecosystem';
});

const panes = computed(() => {
  return ecosystems.value
    .map((ecosystem) => {
      return {
        label: langMapper[ecosystem] || ecosystem,
        slot: ecosystem,
      };
    })
    .sort((a, b) => {
      return a.label < b.label ? -1 : 1;
    });
});

const selectedTab = computed(() => {
  const tabIndex = panes.value.findIndex(
    (tab) => tab.slot === selectedEcosystem.value,
  );
  const ecosystemFound = tabIndex !== -1;
  return { ecosystemFound, index: Math.max(0, tabIndex) };
});

const { suspense } = useSuspense(() => {
  const ecosystemForTabSelectionFound = selectedTab.value.ecosystemFound;
  return ecosystemForTabSelectionFound;
});

onServerPrefetch(suspense);

const onTabChange = (tabIndex: number) => {
  // Be aware that this runs on component mount
  const newEcosystem = panes.value[tabIndex].slot;
  const pageHasSetAnEcosystem = !!selectedEcosystem.value;
  const newEcosystemIsDifferent = newEcosystem !== selectedEcosystem.value;
  if (pageHasSetAnEcosystem && newEcosystemIsDifferent) {
    selectedEcosystem.value = newEcosystem;
    toast.info(`Lesson switched to ${panes.value[tabIndex].label}`);
  }
};
</script>

<style lang="scss" scoped>
@import '~@/utils';

.selector {
  margin: token('space.layout.small') 0 token('space.layout.small');
  &--body {
    margin: token('space.l') token('space.xxl');
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
  }
}
</style>
