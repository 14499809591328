<template>
  <BaseButton size="small" variant="default" @click="downloadAssignmentsAsCSV">
    <template #leftIcon><DownloadIcon /></template>
    Export as CSV
  </BaseButton>
</template>

<script lang="ts" setup>
import { toRefs } from 'vue';
import DownloadIcon from '@patchui/icons/Download.vue';
import BaseButton from '@patchui/productcl/components/BaseButton/BaseButton.vue';
import { useToast } from '../../../../hooks/useToast';
import { jsonArrayToCsv, downloadCsv } from '../../../../lib/utils/csv';
import itly from '../../../../lib/analytics/itly';
import {
  ASSIGNMENT_DASHBOARD_ITLY_ORIGIN,
  columns,
  mapRowForPrinting,
} from './tableUtils';

import type { MappedAssignment } from './tableUtils';

interface ButtonProps {
  mappedAssignments: MappedAssignment[];
}

const props = defineProps<ButtonProps>();
const { mappedAssignments } = toRefs(props);

const toast = useToast();

const downloadAssignmentsAsCSV = () => {
  const headers = columns.map((column) => ({
    title: column.title,
    dataKey: column.dataKey,
  }));
  const csv = jsonArrayToCsv(
    headers,
    mappedAssignments.value.map(mapRowForPrinting),
  );
  const filename = 'assignments.csv';
  downloadCsv(csv, filename);
  toast.info(
    `Downloading ${mappedAssignments.value.length} assignments: "${filename}"`,
  );
  itly.learnReportIsExported({
    eventSource: 'Learn',
    exportType: 'csv',
    path: '/admin/assignments/',
    origin: ASSIGNMENT_DASHBOARD_ITLY_ORIGIN,
  });
};
</script>
<style lang="scss" scoped></style>
