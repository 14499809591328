<template>
  <div id="lesson" class="lesson">
    <div class="lesson__content">
      <div class="lesson__header">
        <div class="lesson__breadcrumbs">
          <Breadcrumbs :breadcrumbs="lessonBreadcrumbs" />
        </div>
        <h1 class="lesson__title">{{ lessonContent?.metadata.title }}</h1>
        <h2 class="lesson__subtitle">{{ lessonContent?.metadata.subtitle }}</h2>
        <EcosystemSelector
          v-if="
            lessonMetadata.educationContentCategory === 'security education' &&
            lessonContent?.metadata?.available_ecosystems &&
            lessonContent.metadata.available_ecosystems.length > 0
          "
          :ecosystems="lessonContent.metadata.available_ecosystems || []"
        />
      </div>

      <div v-if="lessonContent">
        <template v-for="(step, index) in lessonContent.steps" :key="step.id">
          <LessonStep
            v-bind="step"
            :lesson-id="lessonMetadata.lessonId"
            :step-index="index"
            :selected-ecosystem="selectedEcosystem"
          />
          <LessonBanner v-if="index === 2 && !user" class="lesson__banner" />
        </template>
      </div>
      <div v-else class="lesson__loading-skeleton-container">
        <BaseSkeleton
          v-for="n in 10"
          :key="n"
          class="lesson__loading-skeleton"
          :height="24"
        />
      </div>
    </div>

    <div class="lesson__sidebar">
      <LessonSidebar
        v-if="lessonMetadata"
        :lesson-id="lessonMetadata.lessonId"
        :lesson-title="lessonMetadata.title"
      />
    </div>
    <div class="lesson__footer">
      <FAQs :faqs="lessonContent?.faqs || []" />
      <RecommendedLessons
        :current-lesson-ecosystem="selectedEcosystem as LessonMetadata['ecosystem']"
        :current-lesson-slug="lessonSlug"
        :current-lesson-education-content-category="
          lessonMetadata.educationContentCategory
        "
        origin="lesson-recommended-lessons"
        :path="lessonPath"
      >
        <template #title>What to learn next?</template>
      </RecommendedLessons>
    </div>
    <LearningPathContext
      v-if="learningPathContextStorage"
      :lesson-id="lessonMetadata.lessonId"
    />
  </div>
</template>

<script lang="ts" setup>
import { provide, onServerPrefetch, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import BaseSkeleton from '@patchui/productcl/components/BaseSkeleton/BaseSkeleton.vue';

import { onBeforeRouteLeave } from 'vue-router';
import LearningPathContext from './LearningPathContext/LearningPathContext.vue';
import { useLearningPathContextStorage } from './useLearningPathContextStorage';
import LessonStep from './LessonStep.vue';
import LessonSidebar from './LessonSidebar/LessonSidebar.vue';
import EcosystemSelector from './EcosystemSelector.vue';
import FAQs from '../../components/FAQs/FAQs.vue';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs.vue';
import LessonBanner from '../../components/LessonBanner/LessonBanner.vue';
import RecommendedLessons from './RecommendedLessons/RecommendedLessons.vue';
import { buildLessonPath } from '../../lib/utils/lessons';
import itly from '../../lib/analytics/itly';
import { useLessonHead } from './useLessonHead';
import { useRouteParam } from '../../hooks/useRouteParam';
import {
  MULTI_LESSON_PAGE_NAME,
  LEARNING_PATH_PAGE_NAME,
} from '../../lib/constants';
import { getLesson, GET_LESSON_BASE_KEY } from '../../api/lessons';
import { useLessonBreadcrumbs, useSelectedEcosystem } from './lessonStore';

import type { RegistryUser } from '../../lib/auth';
import type { LessonMetadata } from '../../lib/lessonMetadata';
import type { LessonGlobalProperties } from '../../lib/lesson';
import { isContentPreviewActive } from '../../lib/utils/isContentPreview';
import { useQuery } from '@tanstack/vue-query';
import { QueryMetaExtended } from '../../types';

const lessonSlug = useRouteParam('lessonSlug', MULTI_LESSON_PAGE_NAME);
const store = useStore();
const lessonMetadata = computed((): LessonMetadata => {
  return store.getters['lessons/getLessonMetadata'](lessonSlug.value);
});

const learningPathContextStorage = useLearningPathContextStorage();
const enabled = computed(() => !!lessonMetadata.value);
const queryKeyLessonId = computed(() => lessonMetadata.value.lessonId);
const { data: lessonContent, suspense: lessonsSuspense } = useQuery({
  queryKey: [GET_LESSON_BASE_KEY, queryKeyLessonId],
  queryFn: () => {
    return getLesson(lessonMetadata.value.lessonId);
  },
  staleTime: isContentPreviewActive() ? 0 : Infinity,
  enabled,
  meta: {
    isContentQuery: true,
  } as QueryMetaExtended,
});

const lessonContentFaqs = computed(() => lessonContent.value?.faqs || []);

const lessonPath = computed(() => buildLessonPath(lessonSlug.value));
const { breadcrumbs: lessonBreadcrumbs, suspense: breadcrumbsSuspense } =
  useLessonBreadcrumbs(lessonSlug);

const { ecosystem: selectedEcosystem, suspense: ecosystemSuspense } =
  useSelectedEcosystem(lessonMetadata);

onServerPrefetch(async () => {
  await Promise.all([
    lessonsSuspense(),
    breadcrumbsSuspense(),
    ecosystemSuspense(),
  ]);
});

useLessonHead(lessonMetadata, lessonContentFaqs);

const lessonSlugAndEcosystem = computed(() => {
  return `${lessonSlug.value}|${selectedEcosystem.value}`;
});
const user = computed(
  (): RegistryUser | null => store.getters['user/getLoggedInUser'],
);
const lessonSlugAndUser = computed(
  () => `${lessonSlug.value}|${user.value?.id}`,
);

const lessonGlobalProperties: LessonGlobalProperties = computed(() => ({
  lessonId: lessonMetadata.value.lessonId,
  lessonTitle: lessonMetadata.value.title,
  lessonUrl: `${process.env.VUE_APP_ORIGIN}${lessonPath.value}`,
  ecosystem: selectedEcosystem.value,
}));

const captureLessonView = () => {
  itly.learnLessonIsViewed({
    ecosystem: selectedEcosystem.value,
    lessonId: lessonMetadata.value.lessonId,
    lessonTitle: lessonMetadata.value.title,
    lessonUrl: `${process.env.VUE_APP_ORIGIN}${lessonPath.value}`,
  });
};

watch(
  lessonSlugAndUser,
  async () => {
    if (user.value) {
      await store.dispatch(
        'progress/fetchLessonProgress',
        lessonMetadata.value.lessonId,
      );
    }
  },
  { immediate: true },
);

provide('lessonGlobalProperties', lessonGlobalProperties);

onMounted(() => {
  captureLessonView();
});
watch(lessonSlugAndEcosystem, async () => {
  captureLessonView();
});

onBeforeRouteLeave((to, from) => {
  if (
    to.name !== MULTI_LESSON_PAGE_NAME &&
    from.name !== LEARNING_PATH_PAGE_NAME
  ) {
    const learningPathContextStorage = useLearningPathContextStorage();
    learningPathContextStorage.value = null;
  }
});
</script>

<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';
@import '~@/mixin';

$lessonLeftOffset: $navigationWidth;
.lesson {
  display: block;
  max-width: $pageMaxWidth;

  margin: 0 auto;
  padding: space(m) $mobileContainerPadding space(m) $mobileContainerPadding;

  &__sidebar {
    display: none;
  }

  &__content,
  &__footer {
    margin-top: 24px;
    min-width: 0; // ref: https://css-tricks.com/preventing-a-grid-blowout/
  }

  @include media-query(small) {
    padding: 40px $containerPadding 40px $containerPadding;
  }

  @include media-query(large) {
    display: grid;
    grid-template-columns: 66fr 33fr;
    grid-template-rows: 1fr auto;
    grid-template-areas:
      'content sidebar'
      'footer footer';
    gap: space(l) space(xxxl);

    &__content {
      grid-area: content;
    }

    &__sidebar {
      display: block;
      grid-area: sidebar;
    }

    &__footer {
      grid-area: footer;
      width: 100%;
    }
  }

  &__title {
    font-weight: 700;
    font-size: rem(32px);
    line-height: rem(40px);
    margin-top: space(m);

    @include media-query(small) {
      font-size: rem(48px);
      line-height: rem(64px);
      letter-spacing: -1px;
      margin-top: 40px;
    }
  }

  &__subtitle {
    font-weight: 600;
    font-size: rem(18px);
    line-height: rem(28px);

    @include media-query(small) {
      margin-top: space(xs);
    }
  }

  &__loading-skeleton {
    margin-top: space(s);
  }
  .vue--layout-lesson-content {
    width: 100%;
    max-width: $pageMaxWidth;
    margin: 0 auto;
  }
  &__banner {
    margin-top: space(m);

    @include media-query(small) {
      margin-top: space(xl);
    }
  }
}
</style>

<style lang="scss">
@import '~@/utils';
@import '~@/variables';

.lesson {
  &__footer {
    // Tile carousel has a lot of internal padding, but we also
    // provide padding via the Lesson, so this removes theirs.
    .tile-carousel__title {
      padding-left: 0;
    }

    .tile-carousel__navigation {
      padding-right: 0;
    }

    .tile-carousel__tile-list article:first-of-type {
      padding-left: space(xs);
    }
  }
}
</style>
