<template>
  <div class="error">
    <img
      src="https://res.cloudinary.com/snyk/image/upload/v1690362701/snyk-learn/Snyk_Logo.svg"
      alt="patch-forbidden"
    />
    <div class="error__text">
      <p>{{ content.title }}</p>
      <p>{{ content.body }}</p>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, toRefs } from 'vue';
import { errorContentOptions } from './errorContent';
import type { ErrorType } from './errorContent';
interface ErrorProps {
  type: ErrorType;
}
const props = defineProps<ErrorProps>();
const { type } = toRefs(props);

const content = computed(
  () =>
    errorContentOptions[type.value] || errorContentOptions['internal_error'],
);
</script>
<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';

.error {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  &__text {
    margin-top: token('space.l');

    @include media-query(small) {
      max-width: rem(600px);
    }

    p {
      margin: 0;
    }

    p:first-child {
      @include typography('typography.product.heading3');
      color: token('color.ui.heading');
    }

    p:last-child {
      @include typography('typography.product.body-lead');
      color: token('color.ui.body');
    }
  }
}
</style>
