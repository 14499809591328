<template>
  <section class="vue--error-page">
    <VueHead>
      <title>Something went wrong | Snyk Learn</title>
    </VueHead>
    <div class="vue--error-page__content">
      <img
        class="not-found-img"
        src="https://res.cloudinary.com/snyk/image/upload/v1530707740/logo-404_xtrphw.svg"
        alt="PageNotFound Icon"
      />
      <BaseHeading :level="1" class="title">
        Oh no, something went wrong
      </BaseHeading>
      <p class="description">{{ errorMessage }}</p>
      <a
        class="link"
        href="https://learn.snyk.io/"
        title="Go to Snyk Learn home page"
      >
        Go to Snyk Learn home page →
      </a>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { Head as VueHead } from '@vueuse/head';
import BaseHeading from '@patchui/productcl/components/BaseHeading/BaseHeading.vue';
import AVAILABLE_ERROR_PAGE_ERRORS from './availableErrors';

const errorMessage = ref(AVAILABLE_ERROR_PAGE_ERRORS.default);

onMounted(() => {
  const route = useRoute();

  const errorType = route.query.type as string;
  if (
    errorType &&
    Object.keys(AVAILABLE_ERROR_PAGE_ERRORS).includes(errorType)
  ) {
    const errorTypeKey = errorType as keyof typeof AVAILABLE_ERROR_PAGE_ERRORS;
    errorMessage.value = AVAILABLE_ERROR_PAGE_ERRORS[errorTypeKey];
  }
});
</script>

<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';

.vue--error-page {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #31388c;
  height: 100vh - #{mainHeaderHeight};
  padding-bottom: space(xl);
  font-family: token('typography.family.poppins');

  &__content {
    .not-found-img {
      text-align: center;
      margin-top: space(xl);
      display: flex;
      margin-left: auto;
      margin-top: space(xxxl);
      margin-bottom: space(xl);
      margin-right: auto;
      width: 45%;
    }

    .title {
      text-align: center;
      color: token('color.brand.white');
      margin-bottom: space(l);
      font-family: token('typography.family.poppins');
      font-weight: 500;
    }

    .description {
      text-align: center;
      color: token('color.brand.white');
      font-size: rem(16px);
      margin-bottom: space(l);
    }

    .link {
      display: block;
      text-align: center;
      color: token('color.brand.white');
      font-size: small;
    }
  }
}
</style>
