<template>
  <div data-snyk-test="PreferenceTab" class="vue--preference-tab">
    <BaseBlock class="vue--baseblock-preferences">
      <BaseCheckbox
        v-for="ecosystem in ecosystems"
        :key="ecosystem.value"
        :label="ecosystem.label"
        :value="ecosystem.value"
        :model-value="ecosystemsSelected.includes(ecosystem.value)"
        @update:modelValue="(newVal) => updateValue(ecosystem, newVal)"
      />
    </BaseBlock>
  </div>
</template>

<script lang="ts" setup>
import { PropType, toRefs } from 'vue';
import BaseCheckbox from '@patchui/productcl/components/BaseCheckbox/BaseCheckbox.vue';
import BaseBlock from '@patchui/productcl/components/BaseBlock/BaseBlock.vue';

export interface EcosystemOptions {
  label: string;
  value: number;
}

const props = defineProps({
  ecosystems: {
    type: Array as PropType<Array<EcosystemOptions>>,
    required: true,
  },
  ecosystemsSelected: {
    type: Array as PropType<Array<number>>,
    required: true,
  },
});
const emit = defineEmits(['selection-change']);

const { ecosystemsSelected } = toRefs(props);

const updateValue = (ecosystem: EcosystemOptions, newVal: boolean) => {
  let newCombinedVal = [...ecosystemsSelected.value];

  if (newVal) {
    newCombinedVal.push(ecosystem.value);
  } else {
    newCombinedVal = newCombinedVal.filter((val) => val !== ecosystem.value);
  }

  emit('selection-change', newCombinedVal);
};
</script>

<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';

.vue--baseblock-preferences {
  display: flex;
  flex-flow: column wrap;
  gap: space(m) space(m);
  padding: space(m);
  text-align: left;
  @include media-query(small) {
    flex-flow: row wrap;
  }
}

.checkbox {
  width: 50%;
  @include media-query(small) {
    width: 30%;
  }
}
</style>
