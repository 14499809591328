<template>
  <section class="learning-path">
    <div class="learning-path__header">
      <Breadcrumbs
        class="learning-path__breadcrumbs"
        :breadcrumbs="breadcrumbs"
      />
      <h1 class="learning-path__title">{{ pageTitle }}</h1>
      <LearningPathSkeleton v-if="isLoading" />
      <div v-else-if="error || !learningPath">
        There was an error while fetching this content. Please try again.
      </div>
      <div
        v-else
        class="learning-path__introduction"
        v-html="introductionHtml"
      ></div>
    </div>
    <aside class="learning-path__sidebar">
      <CertificateBlock
        v-if="!isLoading && learningPath"
        :certificate-image-url="learningPath.certificateImageUrl"
        :certificate-name="learningPath.title"
        class="learning-path__sidebar-certificate"
        :is-user-logged-in="isUserLoggedIn"
        :status="learningPathState"
        :percentage="learningPathPercentage"
        :download-available="learningPath.certificateAvailable"
        :completion-date="completionDate"
      />
    </aside>
    <div class="learning-path__modules">
      <LearningPathProgress
        class="learning-path__progress-cta"
        :slug="slug"
        :ecosystem="ecosystem"
      />
      <LearningPathModule
        v-for="(learningPathModule, idx) in learningPath?.modules ?? []"
        :key="idx"
        class="learning-path__module"
        :learning-path-module="learningPathModule"
      />
    </div>
  </section>
</template>

<script setup lang="ts">
import { computed, Ref, watchEffect } from 'vue';
import { onBeforeRouteLeave, useRoute } from 'vue-router';
import { marked } from 'marked';
import { useHead } from '@vueuse/head';

import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs.vue';
import type { Breadcrumbs as IBreadCrumbs } from '../../components/Breadcrumbs/breadcrumbs';
import LearningPathModule from '../../components/LearningPathModule/LearningPathModule.vue';
import { prepareOpenGraphImage } from '../../lib/utils/cloudinaryHelper';
import { placeholderLessonImage } from '../../lib/lesson';

import LearningPathSkeleton from './LearningPathSkeleton.vue';
import LearningPathProgress from './LearningPathProgress.vue';
import { useLearningPathDetails } from './useLearningPathDetails';
import domPurify from 'isomorphic-dompurify';

import CertificateBlock from './components/CertificateBlock/CertificateBlock.vue';
import { useUser } from '../../hooks/useUser';
import { useLearningPathContextStorage } from '../Lesson/useLearningPathContextStorage';
import pick from 'lodash-es/pick';
import {
  LESSON_PAGE_NAME,
  LEARNING_PATH_PAGE_NAME,
  MULTI_LESSON_PAGE_NAME,
} from '../../lib/constants';
import useLearningPathStructuredData from './useLearningPathStructuredData';
import { components } from '../../api/learn-backend-v1-schema';
const { user, isLoading: isUserLoading } = useUser();
const isUserLoggedIn = computed(() => !!(user.value && !isUserLoading.value));
const route = useRoute();
const slug = route.params.learningPathSlug as string;
const ecosystem = (route.params.ecosystem || 'all') as string;
const {
  data: learningPath,
  isLoading,
  error,
} = useLearningPathDetails(slug, ecosystem);

const completionDate = computed(() => {
  if (learningPath.value?.progress?.completionDate) {
    return new Date(learningPath.value.progress.completionDate);
  } else {
    return new Date();
  }
});

const pageTitle = computed(() => learningPath.value?.title ?? 'Learning Path');

const learningPathState = computed(() => learningPath.value?.progress?.status);
const learningPathPercentage = computed(
  () => learningPath.value?.progress?.amount || 0,
);

const learningPathContextStorage = useLearningPathContextStorage();
watchEffect(() => {
  if (learningPath.value?.learningPathId) {
    learningPathContextStorage.value = pick(learningPath.value, [
      'title',
      'ecosystem',
      'slug',
      'modules',
      'progress',
      'certificateAvailable',
      'learningPathId',
    ]);
  }
});

onBeforeRouteLeave((to, from) => {
  if (
    ![MULTI_LESSON_PAGE_NAME, LESSON_PAGE_NAME].includes(to.name as string) &&
    from.name !== LEARNING_PATH_PAGE_NAME
  ) {
    const learningPathContextStorage = useLearningPathContextStorage();
    learningPathContextStorage.value = null;
  }
});

const baseBreadcrumbs: IBreadCrumbs = [
  [
    {
      icon: 'school-outline',
      label: 'Snyk Learn',
      url: '/',
      isActive: true,
    },
  ],
  [
    {
      label: 'Learning paths',
      url: '/catalog/?format=learning_path',
      isActive: true,
    },
  ],
];

const breadcrumbs = computed(() => {
  if (!learningPath.value?.title) return baseBreadcrumbs;

  return [
    ...baseBreadcrumbs,
    [
      {
        label: pageTitle.value,
        url: route.fullPath,
        isActive: false,
      },
    ],
  ];
});

const introductionHtml = computed(
  () =>
    learningPath.value?.introduction &&
    domPurify.sanitize(marked(learningPath.value?.introduction)),
);

const socialImageUrl = computed(
  () => learningPath.value?.cardImageUrl || placeholderLessonImage,
);

useHead(
  computed(() => ({
    title: `${pageTitle.value} | Snyk Learn`,
    meta: [
      {
        key: 'description',
        name: 'description',
        content: learningPath.value?.description,
      },
      {
        key: 'og:title',
        property: 'og:title',
        content: pageTitle.value,
      },
      {
        key: 'og:description',
        property: 'og:description',
        content: learningPath.value?.description,
      },
      {
        key: 'og:image',
        property: 'og:image',
        content: prepareOpenGraphImage(socialImageUrl.value, 'og'),
      },
      {
        key: 'twitter:image',
        property: 'twitter:image',
        content: prepareOpenGraphImage(socialImageUrl.value, 'twitter'),
      },
    ],
  })),
);
useLearningPathStructuredData(
  learningPath as Ref<components['schemas']['LearningPathDetails']>,
);
</script>

<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';

.learning-path {
  display: grid;
  grid-template-areas:
    'header'
    'sidebar'
    'modules';

  max-width: $pageMaxWidth;

  margin: 0 auto rem(60px);
  padding: space(m) $mobileContainerPadding space(m) $mobileContainerPadding;

  @include media-query(small) {
    padding: 40px $containerPadding 40px $containerPadding;
    margin: 0 auto rem(120px);
  }

  // #region grid layout
  &__header {
    grid-area: header;
    min-width: 0; // ref: https://css-tricks.com/preventing-a-grid-blowout/
  }

  &__sidebar {
    grid-area: sidebar;
  }

  &__modules {
    grid-area: modules;
  }

  @include media-query(large) {
    display: grid;
    grid-template-areas:
      'header sidebar'
      'modules sidebar';
    grid-template-columns: 1fr 380px;
    grid-auto-rows: min-content;
    gap: 0 token('space.l');

    &__header {
      grid-area: header;
    }

    &__sidebar {
      grid-area: sidebar;
      grid-row-start: 1;
      grid-row-end: -1;
      margin-bottom: 0;
    }

    &__modules {
      grid-area: modules;
    }
  }
  // #endregion

  &__title {
    font-weight: 700;
    font-size: rem(48px);
    line-height: rem(64px);
    margin-top: 22px;
    margin-bottom: 28px;
    color: token('color.brand.midnight');

    @include media-query(small) {
      margin-top: 40px;
    }
    @include media-query(large) {
      margin-top: 34px;
    }
  }

  &__introduction {
    margin-bottom: token('space.xxl');

    & > ::v-deep(h2) {
      font-weight: bold;
    }
  }

  &__progress-cta {
    margin-bottom: token('space.xl');
  }

  &__module {
    margin-bottom: token('space.xxl');

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__sidebar {
    height: min-content;
    margin-bottom: token('space.xxl');

    @media only screen and (max-width: 640px) {
      border-radius: 0;
      margin-left: -$mobileContainerPadding;
      margin-right: -$mobileContainerPadding;
    }
  }
}
</style>
