<template>
  <NavigationBar
    v-if="learningPathTitle"
    v-bind="propsToPass"
    @next-lesson="onNavigationClick('Next lesson')"
    @prev-lesson="onNavigationClick('Previous lesson')"
    @back-to-learning-path="onNavigationClick('Back to learning path')"
  />
</template>

<script lang="ts" setup>
import { computed, ref, watchEffect } from 'vue';
import { LearningPathDetails, LearningPathModule } from '../../../types';
import NavigationBar from '../NavigationBar/NavigationBar.vue';
import { getPathToLearningPath } from '../../../lib/utils/learningPaths';
import { learningPathContextStorage } from '../useLearningPathContextStorage';
import { buildLessonPath } from '../../../lib/utils/lessons';
import itly, {
  LearningPathNavIsClickedProperties,
} from '../../../lib/analytics/itly';
import { useRouter } from 'vue-router';

interface LearningPathContextProps {
  lessonId: string;
}

const props = defineProps<LearningPathContextProps>();

const learningPathContext = ref<Partial<LearningPathDetails>>();

const learningPathTitle = computed(() => {
  return learningPathContext.value?.title;
});

const learningPathId = computed(() => {
  return learningPathContext.value?.learningPathId;
});

const router = useRouter();

const path = router.currentRoute.value.path;

const onNavigationClick = (
  action: LearningPathNavIsClickedProperties['action'],
) => {
  const eventPayload: LearningPathNavIsClickedProperties = {
    action: action,
    eventSource: 'Learn',
    path: path,
    learningPathId: learningPathId.value as string,
    learningPathTitle: learningPathTitle.value as string,
  };
  itly.learningPathNavIsClicked(eventPayload);
};

const learningPathUrl = computed(() => {
  return getPathToLearningPath(learningPathContext.value?.slug as string);
});

const propsToPass = computed(() => {
  return {
    learningPathTitle: learningPathTitle.value || '', // to fix typecheck warning for required prop
    learningPathUrl: learningPathUrl.value,
    ...(nextLessonUrl.value ? { nextLessonUrl: nextLessonUrl.value } : {}),
    ...(previousLessonUrl.value
      ? { previousLessonUrl: previousLessonUrl.value }
      : {}),
  };
});

// Flat array of all lessons in a learning path
const lessonsInLearningPath = computed<LearningPathModule['lessons']>(() => {
  return (
    learningPathContext.value?.modules?.flatMap(
      (module: LearningPathModule) => module.lessons,
    ) || []
  );
});

//  Flat array of all lessons URLs in a learning path
const lessonUrls = computed<string[]>(() => {
  return lessonsInLearningPath.value.map((lesson) =>
    buildLessonPath(lesson.slug),
  );
});

const nextLessonUrl = computed<string | null>(() => {
  const cur: number = lessonsInLearningPath.value.findIndex(
    (lesson) => lesson.lessonId === props.lessonId,
  );
  if (cur === -1 || cur + 1 === lessonsInLearningPath.value.length) return null;
  return lessonUrls.value[cur + 1];
});

const previousLessonUrl = computed<string | null>(() => {
  const cur: number = lessonsInLearningPath.value.findIndex(
    (lesson) => lesson.lessonId === props.lessonId,
  );
  if (cur === -1 || cur === 0) return null;
  return lessonUrls.value[cur - 1];
});

watchEffect(() => {
  if (learningPathContextStorage.value && !learningPathContext.value) {
    learningPathContext.value = learningPathContextStorage.value;
  }
});
</script>
<style lang="scss" scoped></style>
