<template>
  <div class="empty-state" style="">
    <BedEmptyIcon class="empty-state__icon" />
    <BaseProse size="large">All quiet on the assignments front</BaseProse>
    <router-link
      v-slot="{ navigate, href }"
      :to="newAssignmentsRoute(props.orgId)"
      custom
    >
      <BaseButton
        variant="primary"
        size="medium"
        :href="href"
        @click="navigate"
      >
        Create assignments
      </BaseButton>
    </router-link>
  </div>
</template>

<script lang="ts" setup>
import BedEmptyIcon from '@patchui/icons/BedEmpty.vue';
import BaseButton from '@patchui/productcl/components/BaseButton/BaseButton.vue';
import BaseProse from '@patchui/productcl/components/BaseProse/BaseProse.vue';
import { newAssignmentsRoute } from '../../assignmentUtils';

const props = defineProps<{
  orgId: string;
}>();
</script>

<style lang="scss" scoped>
.empty-state {
  &__icon {
    width: 40px;
    height: 40px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 50px;
}
</style>
