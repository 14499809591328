import {
  createMemoryHistory,
  createRouter,
  createWebHistory,
  Router,
} from 'vue-router';
import { Analytics } from './lib/analytics/analytics';
import { RouterHelper } from './routerHelper';

import type { StoreType } from './store';

const HEADER_HEIGHT = 68;
// 16 is just an offset so the section title
// isn't going to be displayed right after header
const ANCHOR_TOP_OFFSET = HEADER_HEIGHT + 16;

export const createPlayerRouter = (store: StoreType): Router => {
  const routes = RouterHelper.routesForCurriculum(store);
  const playerRouter = createRouter({
    history:
      typeof window === 'undefined'
        ? createMemoryHistory()
        : createWebHistory(),
    routes: routes,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    scrollBehavior(to, from, savedPosition) {
      // Prevent scrolling back to top when user navigating within Catalog page (including SEO pages per type/category)
      if (
        from.name?.toString().startsWith('Catalog') &&
        to.name?.toString().startsWith('Catalog')
      ) {
        return;
      }
      // Skip if destination full path has query parameters and differs in no other way from previous
      if (from && Object.keys(to.query).length) {
        if (to.fullPath.split('?')[0] == from.fullPath.split('?')[0]) return;
      }
      // If there is a hash then scroll to specific place on the page
      if (to.hash) {
        const stepElement = document.querySelector(to.hash);
        if (stepElement) {
          return {
            el: stepElement,
            behavior: 'smooth',
            top: ANCHOR_TOP_OFFSET,
          };
        }

        return new Promise((resolve) => {
          setTimeout(() => {
            resolve({
              el: to.hash,
              behavior: 'smooth',
              top: ANCHOR_TOP_OFFSET,
            });
          }, 400);
        });
      }

      // Default scroll position will be 0, 0 unless overridden by a saved position
      const position = {
        left: 0,
        top: 0,
      };

      // Override default with saved position (if it exists)
      if (savedPosition) {
        position.left = savedPosition.left;
        position.top = savedPosition.top;
      }

      // Set the correct scroll position
      return position;
    },
  });

  playerRouter.afterEach(async (to, from) => {
    const isFirstLoadedPage = !from?.matched?.length; // On the first page load, from has the default page path of '/'
    const didPathChange = to.path !== from.path;
    if (isFirstLoadedPage || didPathChange) {
      Analytics.analyticsPage(to, from);
    }
  });

  return playerRouter;
};
