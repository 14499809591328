import { RouteRecordRaw } from 'vue-router';

import LessonPage from './pages/Lesson/Lesson.vue';
import PageNotFound from './pages/PageNotFound/PageNotFound.vue';
import UserProfile from './pages/UserProfile/UserProfile.vue';
import ErrorPage from './pages/ErrorPage/ErrorPage.vue';
import Catalog from './pages/Catalog/Catalog.vue';
import LearningPath from './pages/LearningPath/LearningPath.vue';
import Home from './pages/Home/Home.vue';
import FallbackPage from './pages/Fallback.vue';
import AdminDashboard from './pages/AdminDashboard/AdminDashboard.vue';
import AdminLessonOverview from './pages/AdminLessonOverview/AdminLessonOverview.vue';
import NewAssignments from './pages/Assignments/NewAssignments/NewAssignments.vue';
import AssignmentsDashboard from './pages/AssignmentsDashboard/AssignmentsDashboard.vue';

import {
  getBeforeEnterUserLearningProgressValidateLoggedIn,
  getBeforeEnterValidateLoggedIn,
  getBeforeEnterValidateOrgId,
} from './routerHelperCommon';
import { StoreType } from './store';
import { getEcosystemFromRoute, getOrgIdFromRoute } from './lib/cta/redirect';
import { LESSON_PAGE_NAME, MULTI_LESSON_PAGE_NAME } from './lib/constants';
import { isContentPreviewActive } from './lib/utils/isContentPreview';

declare module 'vue-router' {
  interface RouteMeta {
    productCtaPageParam?: string;
  }
}

export class RouterHelper {
  static routesForCurriculum(store: StoreType): RouteRecordRaw[] {
    const mainIndexRedirectRoute = RouterHelper.mainIndexRedirectRoute();
    const indexRedirectRoute = RouterHelper.indexRedirectRoute();

    const homeRoute = RouterHelper.getHomeRoute(store);
    const oldLessonRoute = RouterHelper.getOldLessonRoute();
    const lessonRoute = RouterHelper.getLessonRoute(store);

    const userProfilePage = RouterHelper.userProfilePage(store);
    const notFoundRedirect = RouterHelper.notFoundRedirect();
    const notFoundPage = RouterHelper.notFoundPage();
    const errorPage = RouterHelper.errorPage();
    const adminDashboardRoute = RouterHelper.getAdminDashboardRoute(store);
    const adminLessonOverviewRoute =
      RouterHelper.getAdminLessonOverviewRoute(store);
    const learningProgressPage = RouterHelper.learningProgressPage(store);
    const learningPathPage = RouterHelper.learningPathPage(store);
    const newAssignmentsPage = RouterHelper.getNewAssignmentsRoute(store);
    const assignmentsDashboardRoute =
      RouterHelper.getAssignmentsDashboardRoute(store);
    const assignmentsDashboardSpecificOrgRoute =
      RouterHelper.getAssignmentsDashboardSpecificOrgRoute(store);
    const catalogPageRoute = RouterHelper.catalogPage(store);
    const catalogPageByCategoryRoute =
      RouterHelper.catalogPageByCategory(store);
    const catalogPageByTopicRoute = RouterHelper.catalogPageByTopic(store);
    return [
      homeRoute,
      mainIndexRedirectRoute,
      indexRedirectRoute,
      oldLessonRoute,
      lessonRoute,
      userProfilePage,
      errorPage,
      notFoundPage,
      notFoundRedirect,
      adminDashboardRoute,
      adminLessonOverviewRoute,
      learningProgressPage,
      learningPathPage,
      newAssignmentsPage,
      assignmentsDashboardRoute,
      assignmentsDashboardSpecificOrgRoute,
      catalogPageRoute,
      catalogPageByTopicRoute,
      catalogPageByCategoryRoute,
      this.spaFallback(),
    ];
  }

  // Remove index.html from routes
  static indexRedirectRoute(): RouteRecordRaw {
    return {
      path: '/:pathMatch(.*)*/index.html',
      redirect: (to) => {
        return to.path.replace(/index.html$/, '');
      },
    };
  }

  // We treat this one differently because it's hard
  // to get the wildcard of indexRedirectRoute to match the root '/' too
  static mainIndexRedirectRoute(): RouteRecordRaw {
    return {
      path: '/index.html',
      name: 'root',
      redirect: '/',
    };
  }

  static getOldLessonRoute(): RouteRecordRaw {
    return {
      name: LESSON_PAGE_NAME,
      path: '/lessons/:lessonSlug/:ecosystem',
      redirect: (to) => {
        return `/lesson/${to.params.lessonSlug}`;
      },
    };
  }

  static getLessonRoute(store: StoreType): RouteRecordRaw {
    return {
      name: MULTI_LESSON_PAGE_NAME,
      path: '/lesson/:lessonSlug',
      component: LessonPage,
      meta: { productCtaPageParam: 'learn-lesson' },
      beforeEnter: async (to, from, next) => {
        const { lessonSlug } = to.params;

        const opts = { refresh: false };

        if (isContentPreviewActive()) {
          opts.refresh = true;
        }

        await store.dispatch('lessons/fetchLessons', opts);

        const lesson = store.getters['lessons/getLessonMetadata'](lessonSlug);

        if (lesson) {
          next();
        } else {
          next({ name: 'notFound' });
        }
      },
    };
  }

  static getHomeRoute(store: StoreType): RouteRecordRaw {
    return {
      path: '/',
      name: 'Home',
      meta: { productCtaPageParam: 'learn-homepage' },
      component: Home,
      beforeEnter: async (to, from, next) => {
        await store.dispatch('lessons/fetchLessons');

        return next();
      },
    };
  }

  static getAdminDashboardRoute(store: StoreType): RouteRecordRaw {
    return {
      path: '/admin/reports',
      meta: { productCtaPageParam: 'learn-admin-dashboard' },
      name: 'AdminDashboard',
      component: AdminDashboard,
      beforeEnter: getBeforeEnterValidateLoggedIn(store, '/404'),
    };
  }

  static getAdminLessonOverviewRoute(store: StoreType): RouteRecordRaw {
    return {
      path: '/admin/lesson-overview',
      name: 'AdminLessonOverview',
      meta: { productCtaPageParam: 'learn-admin-dashboard' },
      component: AdminLessonOverview,
      beforeEnter: getBeforeEnterValidateLoggedIn(store, '/404'),
    };
  }

  static getNewAssignmentsRoute(store: StoreType): RouteRecordRaw {
    return {
      path: '/admin/assignments/new/:orgId?',
      meta: { productCtaPageParam: 'new-assignments' },
      name: 'NewAssignments',
      component: NewAssignments,
      props: (route) => ({ orgId: getOrgIdFromRoute(route) }),
      beforeEnter: [
        getBeforeEnterValidateLoggedIn(store, '/404'),
        getBeforeEnterValidateOrgId(store, '/admin/assignments/new'),
      ],
    };
  }

  static getAssignmentsDashboardRoute(store: StoreType): RouteRecordRaw {
    return {
      path: '/admin/assignments',
      name: 'AssignmentsDashboard',
      meta: { productCtaPageParam: 'learn-assignments-dashboard' },
      component: AssignmentsDashboard,
      beforeEnter: getBeforeEnterValidateLoggedIn(store, '/404'),
    };
  }

  static getAssignmentsDashboardSpecificOrgRoute(
    store: StoreType,
  ): RouteRecordRaw {
    return {
      path: '/admin/assignments/:orgId',
      name: 'AssignmentsDashboardSpecificOrg',
      meta: { productCtaPageParam: 'learn-assignments-dashboard' },
      component: AssignmentsDashboard,
      props: (route) => {
        const orgId = getOrgIdFromRoute(route);
        return {
          ...(orgId !== '' && {
            orgId,
          }),
        };
      },
      beforeEnter: getBeforeEnterValidateLoggedIn(store, '/404'),
    };
  }

  static learningProgressPage(store: StoreType): RouteRecordRaw {
    return {
      path: '/user/learning-progress',
      name: 'LearningProgress',
      meta: { productCtaPageParam: 'learn-user-dashboard' },
      component: () => import('./pages/LearningProgress/LearningProgress.vue'),
      beforeEnter: getBeforeEnterUserLearningProgressValidateLoggedIn(
        store,
        '/lessons',
      ),
    };
  }

  static userProfilePage(store: StoreType): RouteRecordRaw {
    return {
      path: '/profile',
      name: 'profile',
      meta: { productCtaPageParam: 'learn-user-profile' },
      component: UserProfile,
      beforeEnter: getBeforeEnterValidateLoggedIn(store),
    };
  }

  static catalogPage(store: StoreType): RouteRecordRaw {
    return {
      path: '/catalog',
      name: 'Catalog',
      meta: { productCtaPageParam: 'learn-catalog' },
      component: Catalog,
      beforeEnter: async () => {
        await store.dispatch('lessons/fetchLessons');
        return true;
      },
    };
  }

  static catalogPageByTopic(store: StoreType): RouteRecordRaw {
    return {
      path: '/catalog/security-education',
      alias: ['/catalog/product-training'],
      name: 'CatalogPageByTopic',
      component: Catalog,
      props: (route) => {
        const category = getEcosystemFromRoute(route);
        return {
          ...(category !== 'all' && {
            category,
          }),
        };
      },
      beforeEnter: async () => {
        await store.dispatch('lessons/fetchLessons');
        return true;
      },
    };
  }

  static catalogPageByCategory(store: StoreType): RouteRecordRaw {
    return {
      path: '/catalog/security-education/:ecosystem',
      name: 'CatalogPageByCategory',
      component: Catalog,
      props: (route) => {
        const category = getEcosystemFromRoute(route);
        return {
          ...(category !== 'all' && {
            category,
          }),
        };
      },
      beforeEnter: async () => {
        await store.dispatch('lessons/fetchLessons');
        return true;
      },
    };
  }

  static learningPathPage(store: StoreType): RouteRecordRaw {
    return {
      path: '/learning-paths/:learningPathSlug',
      name: 'LearningPathWithMultipleEcosystems',
      component: LearningPath,
      beforeEnter: async () => {
        await store.dispatch('lessons/fetchLessons');
        return true;
      },
    };
  }
  static notFoundRedirect(): RouteRecordRaw {
    return {
      path: '/:pathMatch(.*)*',
      redirect: '/404',
    };
  }

  static notFoundPage(): RouteRecordRaw {
    return {
      path: '/404',
      name: 'notFound',
      component: PageNotFound,
    };
  }

  static errorPage(): RouteRecordRaw {
    return {
      path: '/error-page',
      name: 'errorPage',
      component: ErrorPage,
    };
  }

  static spaFallback(): RouteRecordRaw {
    return {
      path: '/_fallback',
      name: 'fallback',
      component: FallbackPage,
    };
  }
}
