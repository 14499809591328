<template>
  <div class="block" :style="isHidden ? 'display:none;' : undefined">
    <div v-if="title || markdownContent" class="block__body_markdown">
      <div class="block__body_markdown_content">
        <div class="full-width">
          <h3 v-if="title">{{ title }}</h3>
          <div
            v-if="markdownContent"
            class="block__body_markdown_content__html"
            v-html="markdownContent"
          ></div>
        </div>
      </div>
    </div>
    <component
      :is="
        allWidgetComponents[getWidgetVueComponentName(widget._metadata?.type)]
      "
      v-for="(widget, index) in parsedWidgets"
      :key="widget._metadata.type + '' + index"
      v-bind="widget.props"
      class="block__body__widget"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, toRefs } from 'vue';
import { renderer } from '../../../lib/stepBlock';
import { marked } from 'marked';
import domPurify from 'isomorphic-dompurify';
import { convertWidgetDataToProps } from '../../../components/educationWidgets/baseWidget';
import {
  getWidgetVueComponentName,
  getWidgetVueComponents,
} from '../../../components/educationWidgets/widgetRegistrat';
import type { HiddenRestWidget } from '../../../api/lessons';

marked.use({ renderer });

const allWidgetComponents = getWidgetVueComponents();

interface BlockProps {
  title: string;
  text: string;
  stepId: string;
  isHidden: boolean;
  widgets: HiddenRestWidget[];
}
const props = defineProps<BlockProps>();
const { text, widgets, stepId } = toRefs(props);

const markdownContent = computed(() =>
  domPurify.sanitize(marked.parse(text.value)),
);
const parsedWidgets = computed(() =>
  widgets.value.map((widget) => {
    return {
      _metadata: widget.metadata,
      props: convertWidgetDataToProps(
        { ...widget, _metadata: widget.metadata, metadata: undefined },
        stepId.value,
      ),
    };
  }),
);
</script>

<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';
@import '~@/mixin';

.hidden {
  display: none !important;
}
$infoWidgetBackground: $greyBackground;
.block {
  // The first block of a step does not have any top-margin
  margin-top: token('space.l');
  &:first-child {
    margin-top: 0;
  }

  // The first child of a block does not have any top-margin (unless it is a widget)
  & > * {
    margin-top: token('space.l');
  }
  & > .block__body_markdown:first-child {
    margin-top: 0;
  }

  &__body {
    display: flex;
    flex-direction: column;
  }

  &__body_markdown {
    font-size: 18px;
    line-height: 36px;
    color: $stepBlockTextColor;

    &_content {
      display: flex;

      &__html {
        :deep(strong) {
          font-weight: bold;
        }
        :deep(em) {
          font-style: italic;
        }
        :deep(ol) {
          list-style-type: decimal;
          list-style-position: inside;
        }
        :deep(del) {
          text-decoration: line-through;
        }
        :deep(sub) {
          vertical-align: sub;
          font-size: 80%;
        }
        :deep(sup) {
          vertical-align: super;
          font-size: 80%;
        }
      }

      .full-width {
        width: 100%;
        display: block;
        flex: 1 0 auto;
      }

      h3 {
        font-weight: 600;
        font-size: rem(18px);
        line-height: rem(28px);
        margin-bottom: space(xxs);
      }
    }
  }
}
</style>

<style lang="scss">
@import '~@/utils';
@import '~@/variables';
$codeBorderColor: #d3d3d9;
$multilineBorderColor: #938fc7;
$codeFontFamily: 'JetBrains Mono';
$multilineBorderColor: #938fc7;
$codeFontFamily: 'JetBrains Mono';

.block {
  code {
    background: transparent;
    border: 1px solid $codeBorderColor;
    font-family: $codeFontFamily;
    border-radius: 5px;
    font-size: rem(12px);
    line-height: rem(20px);
  }
}

code.vue--code {
  &--multiline {
    display: block;
    padding: rem(15px);
    border-left: rem(4px) solid $multilineBorderColor;
    white-space: nowrap;
    overflow: auto;
  }
}
</style>
