import { authenticatedClient } from '../lib/apiClient';
import { OpenApiResponse } from '../lib/utils/type-utilities';
import { useLoggedIn } from './useLoggedIn';
import { components } from '../api/learn-backend-v1-schema';
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/vue-query';

export const USER_PROGRESS_KEY = ['userProgress'] as const;

export const fetchUserProgress = async () => {
  const response = await authenticatedClient.get<
    OpenApiResponse<'getAuthenticatedUserProgress'>
  >(`/v1/learn/user/progress`);

  return response.data;
};

/**
 * Returns the users current lesson progress if the user is logged in.
 */
export const useProgressQuery = () => {
  const isLoggedIn = useLoggedIn();

  return useQuery<
    {
      progress: components['schemas']['UserLessonProgress'][];
    },
    AxiosError
  >({
    queryKey: USER_PROGRESS_KEY,
    queryFn: fetchUserProgress,
    enabled: isLoggedIn,
  });
};
