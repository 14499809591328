export interface WidgetProps {
  [key: string]: any;
}
export interface WidgetData extends WidgetProps {
  _metadata: {
    type: string;
    name: string;
    id: string;
  };
}

export const convertWidgetDataToProps = (
  data: WidgetData,
  stepId: string,
): WidgetProps => {
  const id = data._metadata.id;
  const props: WidgetProps = { widgetId: id, stepId, ...data };
  delete props['_metadata'];
  return props;
};

export interface Widget extends WidgetData {
  /**
   * Returns the name of the widget chosen by user in the markdown, could be anything (any string).
   */
  getName(): string;

  /**
   * Returns the type of the widget, e.g. "diffExercise" or "editor" or "terminal".
   */
  getType(): string;

  /**
   * A subset of widgets are "mergeable". I.e. there might be multiple blocks in markdown describing the same
   * widget. When we parse these blocks, we parse each block into a separate Widget object, and then "merge"
   * all parsed objects with the same name and type into a single Widget object using this method.
   *
   * A good example of "mergeable" widgets is a DiffExerciseWidget. Diff Exercise, by definition, will have
   * multiple solutions, each presented as a "diff" to the original code. Each code block (original and
   * possible solutions) is parsed separately, and then "merged" into a single DiffExerciseWidget object.
   */
  merge(mergeWith: Widget): Widget;
  isMergeable(): boolean;
  /**
   * A widget can contain other widgets (e.g. PlayableWidget can contain all other widgets).
   * In order to construct that hierarchy during parsing, the parent widget needs to declare all its children.
   */
  getChildDeclarations(): ChildDeclaration[];

  addChild(child: Widget, title: string): void;
  getChildren(): Widget[];

  /**
   * Returns the list of properties to expose to the frontend.
   */
  propertiesToExpose: string[] | null;

  /**
   * Method to return this instances properties and metadata
   */
  getWidgetData(): WidgetData;
  /**
   * Method to return this instances properties to be passed to the widget component for render
   */
  getWidgetProps(): WidgetProps;

  /*
   * Can the widget be serialized into JSON
   */
  isSerializable(): boolean;
}

export interface ChildDeclaration {
  childName: string;
  childType: string;
  title?: string;
}

export abstract class BaseWidget implements Widget {
  _metadata: {
    type: string;
    name: string;
    id: string;
  };
  propertiesToExpose: string[] | null;

  constructor(options?: { name: string }) {
    this._metadata = {
      type: this.getType(),
      name: options?.name ?? '',
      id: '',
    };
    this.propertiesToExpose = null;
  }

  isMergeable(): boolean {
    return false;
  }

  isSerializable(): boolean {
    return !!this.propertiesToExpose;
  }

  merge(mergeWith: Widget): Widget {
    throw new Error(
      `${this.getType()} is not mergeable with ${mergeWith.getType()}`,
    );
  }

  getChildDeclarations(): ChildDeclaration[] {
    return [];
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  addChild(child: Widget, title: string): void {
    throw new Error(
      `${this.getType()} does not support adding children. Tried adding ${child.getType()}`,
    );
  }

  getChildren(): Widget[] {
    return [];
  }

  abstract getName(): string;

  abstract getType(): string;

  getWidgetProps(): WidgetProps {
    const propertiesToExpose = this.propertiesToExpose || [];

    return propertiesToExpose.reduce<WidgetProps>((acc, property) => {
      acc[property] = (this as Widget)[property];
      return acc;
    }, {});
  }

  getWidgetData(): WidgetData {
    const widgetProps = this.getWidgetProps();
    return {
      ...widgetProps,
      _metadata: this._metadata,
    };
  }
}
