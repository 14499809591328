import { EnrichedLessonOverview } from './TabLessons.interfaces';

export default function getVulns(enrichedLesson: EnrichedLessonOverview) {
  return [
    ...(enrichedLesson.lesson.cwes ?? []).map((cwe) => ({
      name: cwe,
      href: `https://cwe.mitre.org/data/definitions/${cwe.replace(
        'CWE-',
        '',
      )}.html`,
    })),
    ...(enrichedLesson.lesson.cves ?? []).map((cve) => ({
      name: cve,
      href: `https://www.cve.org/CVERecord?id=${cve}`,
    })),
    ...(enrichedLesson.lesson.rules ?? []).map((rule) => ({
      name: rule,
      href: `https://security.snyk.io/vuln/${rule}?loc=learn`,
    })),
  ];
}
