import { onServerPrefetch, watch } from 'vue';
import { keepPreviousData, useQuery } from '@tanstack/vue-query';
import axios, { AxiosError } from 'axios';
import { Router, useRouter } from 'vue-router';
import { get, MaybeRef } from '@vueuse/core';

import { unauthenticatedClient } from '../../lib/apiClient';
import { useLoggedIn } from '../../hooks/useLoggedIn';
import { OpenApiResponse } from '../../lib/utils/type-utilities';
import { isContentPreviewActive } from '../../lib/utils/isContentPreview';
import { components } from '../../api/learn-backend-v1-schema';
import { QueryMetaExtended } from '../../types';

const fetchLearningPathDetails = async (
  slug: string,
  ecosystem: string,
  isPreview: boolean,
  router: Router,
) => {
  let url = `/v1/learn/learning-paths/${slug}/${ecosystem}`;

  if (isPreview) url += `?content_source=source-preview`;

  try {
    const response = await unauthenticatedClient.get<
      OpenApiResponse<'getLearningPathDetails'>
    >(url);

    return response.data;
  } catch (e) {
    // https://tanstack.com/query/v5/docs/vue/guides/migrating-to-v5#callbacks-on-usequery-and-queryobserver-have-been-removed
    if (axios.isAxiosError(e) && e.response?.status === 404)
      await router.replace('/404');
    return Promise.reject(e);
  }
};

export const useLearningPathDetails = (
  slug: MaybeRef<string>,
  ecosystem: MaybeRef<string>,
) => {
  const isLoggedIn = useLoggedIn();
  const isPreview = isContentPreviewActive();
  const router = useRouter();

  const query = useQuery<
    components['schemas']['LearningPathDetails'],
    AxiosError
  >({
    queryKey: ['learningPaths', slug, ecosystem],
    queryFn: () =>
      fetchLearningPathDetails(get(slug), get(ecosystem), isPreview, router),
    placeholderData: keepPreviousData, // This gives you data from the previous query when you change from one query to the next in the same observer (component). That usually happens when the queryKey changes. It's meant to give you a transition like behaviour without using suspense.
    refetchOnMount: isPreview ? 'always' : undefined,
    meta: {
      isContentQuery: true,
    } as QueryMetaExtended,
  });

  onServerPrefetch(async () => {
    // Wait for this query on the serverside
    try {
      await query.suspense();
    } catch {
      // Do nothing if error thrown on prerender.
    }
  });

  // Refetch when user data becomes available.
  watch(
    isLoggedIn,
    (newValue) => newValue && !query.isFetching.value && query.refetch(),
  );

  return query;
};
