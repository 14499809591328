import { computed, Ref, ref } from 'vue';

export const useInfiniteScroll = <T>(
  items: Ref<T[]>,
  countOfVisibleItemsIncrement: number,
) => {
  const countOfVisibleItems = ref(countOfVisibleItemsIncrement);
  const resetCountOfVisibleItems = () => {
    countOfVisibleItems.value = countOfVisibleItemsIncrement;
  };

  const visibleItems = computed<T[]>(() => {
    return items.value.slice(0, countOfVisibleItems.value);
  });

  const infiniteScrollEventHandler = () => {
    if (
      countOfVisibleItems.value + countOfVisibleItemsIncrement <=
      items.value.length
    ) {
      countOfVisibleItems.value += countOfVisibleItemsIncrement;
    } else {
      countOfVisibleItems.value = items.value.length;
    }
  };

  return {
    visibleItems,
    resetCountOfVisibleItems,
    infiniteScrollEventHandler,
    countOfVisibleItems,
  };
};
