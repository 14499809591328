import { createSSRApp } from 'vue';
import App from './App/App.vue';
import { getClonedStore } from '../src/store/index';
import { createPlayerRouter } from './router';
import { Analytics } from './lib/analytics/analytics';
import { createHead } from '@vueuse/head';
import ObserveVisibility from './directives/observe-visibility';
import { getVueQueryConfig, vueQueryPlugin } from './lib/vueQueryOptions';
import Toast from 'vue-toastification';
import PrimeVue from 'primevue/config';
import { toastOptions } from './hooks/useToast';

export function createLearnApp(createApp = createSSRApp) {
  const app = createApp(App);

  const store = getClonedStore();
  const router = createPlayerRouter(store);
  const head = createHead();
  const vueQueryConfig = getVueQueryConfig();

  app.use(router);
  app.use(store);
  app.use(head);
  app.use(vueQueryPlugin, vueQueryConfig);
  app.use(Toast, toastOptions);
  app.use(PrimeVue);

  // Init packages that don't work serverside
  if (typeof window !== 'undefined') {
    Analytics.initializeAnalytics();
  }

  app.directive('observe-visibility', ObserveVisibility);

  return {
    app,
    router,
    head,
    store,
    queryClient: vueQueryConfig.queryClient,
  };
}
