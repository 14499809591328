import { ref } from 'vue';
import { DEFAULT_POLARIS_ENVIRONMENT, isValidDomain } from './env';

const getItemSafely = (key: string) => {
  try {
    return localStorage?.getItem(key);
  } catch {
    return null;
  }
};
const setItemSafely = (key: string, value: string) => {
  try {
    localStorage?.setItem(key, value);
  } catch {
    // continue regardless of error
  }
};
const removeItemSafely = (key: string) => {
  try {
    localStorage?.removeItem(key);
  } catch {
    // continue regardless of error
  }
};

const _getInitialApiDomain = () => {
  const storedDomain = getItemSafely('api-domain');
  if (storedDomain && isValidDomain(storedDomain)) {
    return storedDomain;
  } else {
    return DEFAULT_POLARIS_ENVIRONMENT.apiDomain;
  }
};

const _getInitialAppDomain = () => {
  const storedDomain = getItemSafely('app-domain');
  if (storedDomain && isValidDomain(storedDomain)) {
    return storedDomain;
  } else {
    return DEFAULT_POLARIS_ENVIRONMENT.appDomain;
  }
};

export const apiDomain = ref(_getInitialApiDomain());
export const appDomain = ref(_getInitialAppDomain());

const clearstoredDomains = () => {
  removeItemSafely('api-domain');
  removeItemSafely('app-domain');
};
export const resetApiAndAppDomains = () => {
  clearstoredDomains();
  apiDomain.value = _getInitialApiDomain();
  appDomain.value = _getInitialAppDomain();
};

export const setApiDomain = (domain: string) => {
  if (!isValidDomain(domain)) return;
  apiDomain.value = domain;
  setItemSafely('api-domain', domain);
};

export const setAppDomain = (domain: string) => {
  if (!isValidDomain(domain)) return;
  appDomain.value = domain;
  setItemSafely('app-domain', domain);
};
