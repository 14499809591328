import { Component, defineAsyncComponent } from 'vue';
import { WidgetType } from '../../types';

interface WidgetEntry {
  type: WidgetType;
  vueComponentName: string;
  vueComponent: Component;
}

const widgetEntries: WidgetEntry[] = [
  {
    vueComponentName: 'ChatWidget',
    type: 'chat',
    vueComponent: defineAsyncComponent(() => import('./chat/ChatWidget.vue')),
  },
  {
    vueComponentName: 'DiffWidget',
    type: 'diff',
    vueComponent: defineAsyncComponent(() => import('./diff/DiffWidget.vue')),
  },
  {
    vueComponentName: 'EditorWidget',
    type: 'editor',
    vueComponent: defineAsyncComponent(
      () => import('./editor/EditorWidget.vue'),
    ),
  },
  {
    vueComponentName: 'InfoWidget',
    type: 'info',
    vueComponent: defineAsyncComponent(() => import('./info/InfoWidget.vue')),
  },
  {
    vueComponentName: 'LoginWidget',
    type: 'login',
    vueComponent: defineAsyncComponent(() => import('./login/LoginWidget.vue')),
  },
  {
    type: 'terminal',
    vueComponentName: 'TerminalWidget',
    vueComponent: defineAsyncComponent(
      () => import('./terminal/TerminalWidget.vue'),
    ),
  },
  {
    type: 'codeTour',
    vueComponentName: 'CodeTourWidget',
    vueComponent: defineAsyncComponent(
      () => import('./codeTour/CodeTourWidget.vue'),
    ),
  },
  {
    type: 'codeRunner',
    vueComponentName: 'CodeRunnerWidget',
    vueComponent: defineAsyncComponent(
      () => import('./codeRunner/CodeRunnerWidget.vue'),
    ),
  },
  {
    type: 'congrats',
    vueComponentName: 'CongratsWidget',
    vueComponent: defineAsyncComponent(
      () => import('./congrats/CongratsWidget.vue'),
    ),
  },
  {
    type: 'image',
    vueComponentName: 'ImageWidget',
    vueComponent: defineAsyncComponent(() => import('./image/ImageWidget.vue')),
  },
  {
    type: 'hotspotImage',
    vueComponentName: 'HotspotImage',
    vueComponent: defineAsyncComponent(
      () => import('./hotspotImage/HotspotImage.vue'),
    ),
  },
  {
    type: 'stepByStep',
    vueComponentName: 'StepByStep',
    vueComponent: defineAsyncComponent(
      () => import('./stepByStep/StepByStep.vue'),
    ),
  },
  {
    type: 'video',
    vueComponentName: 'Video',
    vueComponent: defineAsyncComponent(() => import('./video/Video.vue')),
  },
  {
    type: 'multipleChoiceQuestion',
    vueComponentName: 'MultipleChoiceQuestionWrapper',
    vueComponent: defineAsyncComponent(
      () =>
        import('./multipleChoiceQuestion/MultipleChoiceQuestionWrapper.vue'),
    ),
  },
  {
    type: 'widgetAccordion',
    vueComponentName: 'AccordionWidget',
    vueComponent: defineAsyncComponent(
      () => import('./accordion/AccordionWidget.vue'),
    ),
  },
];

export { widgetEntries };

export interface VueComponents {
  [key: string]: Component;
}

export function getWidgetVueComponents(): VueComponents {
  const output: VueComponents = {};
  widgetEntries.forEach((entry) => {
    output[entry.vueComponentName] = entry.vueComponent;
  });
  return output;
}

export function getWidgetVueComponentName(widgetType: string): string {
  return getEntry(widgetType).vueComponentName;
}

function getEntry(widgetType: string): WidgetEntry {
  for (const entry of widgetEntries) {
    if (entry.type == widgetType) {
      return entry;
    }
  }

  throw new Error(
    `No widget for type '${widgetType}' found. Was it registered in widgetRegistrar.ts?`,
  );
}
