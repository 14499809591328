<template>
  <div v-if="percentage !== null || percentage !== undefined" class="progress">
    <template v-if="isCompleted && variant === 'lessonCard'">
      <div>
        <CheckIcon
          :size="20"
          role="img"
          title="completed"
          class="progress__icon progress__icon--completed"
          aria-label="completed"
        />
        <span class="progress__text">Completed</span>
      </div>
    </template>
    <template v-else>
      <span
        :id="progressLabelId"
        class="progress__text progress__text--with-margin"
        :class="{
          'progress__text--align-end': variant === 'learningPathCertificate',
        }"
      >
        {{ roundedPercentage }}% Completed
      </span>
      <div class="outer" role="progressbar" :aria-labelledby="progressLabelId">
        <div
          class="inner"
          :class="{
            'inner-alternative': variant === 'learningPathCertificate',
          }"
          :style="innerStyle"
        >
          &nbsp;
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import CheckIcon from '@patchui/icons/Check.vue';
import { makeId } from '@snyk/frontend-lib';

const progressLabelId = computed(() => `progress-${makeId(10)}`);
interface IProgressBar {
  percentage: number;
  isCompleted: boolean;
  variant?: 'lessonCard' | 'learningPathCertificate';
}
const props = withDefaults(defineProps<IProgressBar>(), {
  variant: 'lessonCard',
});

const roundedPercentage = computed(() => Math.round(props.percentage));

// Base width for any lesson card is 88, and before variants introduction
// it was the only use case for this component. 348 is base width for
// second variant. NB: its not related to css width of general div,
// but used in order to calculate width of progress bar
const baseWidth = props.variant === 'learningPathCertificate' ? 348 : 88;

const innerStyle = computed(() => {
  const progressWidth = (baseWidth / 100) * props.percentage;

  return {
    width: progressWidth + 'px',
    ...(props.variant === 'learningPathCertificate' && {}),
  };
});
</script>

<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';
.progress {
  max-width: 100%;
  max-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &__text {
    font-size: rem(13px);
    line-height: rem(18px);
    color: token('color.neutral.70');
    white-space: nowrap;
    &--with-margin {
      margin: 0 0 space(xxs) 0;
    }
    &--align-end {
      align-self: end;
    }
  }
  &__icon {
    vertical-align: middle;
    margin-right: 4px;
    &--completed {
      padding: 2px;
      color: #fff;
      border-radius: 50%;
      background: linear-gradient(token('gradient.vibe-to-dark-teal'));
      color: #fff;
    }
  }

  .outer {
    width: 100%;
    height: 8px;
    border-radius: 6px;
    background: token('color.neutral.20');
    .inner {
      height: inherit;
      border-radius: 6px 0px 0px 6px;
      background: token('color.brand.electric-blue');
    }
  }
}
</style>
