<template>
  <div class="no-results">
    <img
      src="https://res.cloudinary.com/snyk/image/upload/v1694515102/snyk-learn/patch-no-results-catalog.png"
      alt="A dog digging for something in the ground"
      width="251.66"
      height="220"
    />
    <p class="no-results__lede">No results found</p>
    <p class="no-results__description">
      Remove some filters so Patch can try again.
    </p>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';

onMounted(async () => {
  if (window.Sprig) window.Sprig('track', 'catalog-no-results');
});
</script>

<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';

.no-results {
  padding: space(xl) 0;
  text-align: center;
  grid-column: 1;
  @include media-query('large') {
    grid-column: 2;
  }

  &__lede {
    @include typography('typography.brand.subhead');
    color: token('color.neutral.90');
    font-size: rem(23px);
    line-height: 30px;
  }
  &__description {
    @include typography('typography.brand.body');
    color: token('color.brand.midnight');
    font-size: rem(16px);
    line-height: 24px;
  }
}
</style>
