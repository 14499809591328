<template>
  <div>
    <BaseTooltip
      :key="tooltipText"
      class="copy-to-clipboard"
      :open="copied"
      :description="tooltipText"
      render-container
    >
      <LinkVariant title="Copy to Clipboard" :size="24" @click="clickHandler" />
    </BaseTooltip>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, toRefs } from 'vue';
import LinkVariant from '@patchui/icons/LinkVariant.vue';
import BaseTooltip from '@patchui/productcl/components/BaseTooltip/BaseTooltip.vue';

const COPIED_TOOLTIP_CHANGE_DURATION_MS = 2000;

const props = defineProps({
  href: {
    type: String,
    required: true,
  },
});

const { href } = toRefs(props);
const copied = ref(false);
const timeout = ref<number | null>();

const tooltipText = computed(() =>
  copied.value ? 'Copied' : 'Copy to Clipboard',
);

const clickHandler = async () => {
  if (timeout.value) {
    clearTimeout(timeout.value);
  }

  try {
    await navigator.clipboard.writeText(href.value);
    copied.value = true;
    timeout.value = window.setTimeout(() => {
      copied.value = false;
    }, COPIED_TOOLTIP_CHANGE_DURATION_MS);
  } catch (err) {
    // Do nothing?
    // TODO: Maybe we should notify the user if the copy fails.
  }
};
</script>

<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';

.copy-to-clipboard {
  cursor: pointer;
  align-items: flex-end;

  .tooltip__label {
    svg {
      fill: currentColor;
    }
  }
}
</style>
