<template>
  <section class="fallback">
    <BaseSkeleton v-for="n in 2 * 3" :key="n" :height="240" />
  </section>
</template>

<script lang="ts" setup>
import BaseSkeleton from '@patchui/productcl/components/BaseSkeleton/BaseSkeleton.vue';
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

onMounted(() => {
  // The user should never actually navigate to this page
  // so on the off chance we get to this hook, we immediately
  // redirect them to the homepage
  router.replace('/');
});
</script>

<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';

.fallback {
  max-width: $pageMaxWidth;
  width: 100%;
  margin: 0 auto;
  padding: space(xl);

  display: grid;
  gap: space() space(m);
  grid-template-columns: 3fr 3fr 3fr;
}
</style>
