import { CatalogItem, useCatalog } from './../api/catalog';
import { computed } from 'vue';
import { listLessonsMetadata, LIST_LESSONS_METADATA_KEY } from '../api/lessons';
import { useSuspense } from './useSuspense';
import { langMapper } from '../lib/utils/mappers';
import { components } from '../api/learn-backend-v1-schema';
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/vue-query';
import { QueryMetaExtended } from '../types';

export const useListLessonsMetadata = () => {
  return useQuery<components['schemas']['LessonMetadata'][], AxiosError>({
    queryKey: [LIST_LESSONS_METADATA_KEY],
    queryFn: listLessonsMetadata,
    staleTime: Infinity,
    meta: {
      isContentQuery: true,
    } as QueryMetaExtended,
  });
};

export interface EcosystemMetadata {
  ecosystem: string;
  link: string;
  catalogLink: string;
  label: string;
}

export const useListEcosystems = () => {
  const { data: lessons } = useListLessonsMetadata();
  const ecosystems = computed(() => {
    const allLessonEcosystems = (lessons.value ?? []).flatMap(
      (lesson) => lesson.ecosystems ?? [],
    );
    const uniqueEcosystems = Array.from(new Set(allLessonEcosystems));
    const ecosystemsContent = uniqueEcosystems.map(
      (ecosystem) =>
        ({
          ecosystem,
          link: `/lessons/${ecosystem}/`,
          catalogLink: `/catalog/security-education/${ecosystem}/`,
          label: langMapper[ecosystem] ?? ecosystem,
        } as EcosystemMetadata),
    );
    return ecosystemsContent;
  });
  const { suspense } = useSuspense(() => ecosystems.value.length > 0);

  return { ecosystems, suspense };
};

export const listTopics = (educationResource: CatalogItem): string[] => {
  const topics = educationResource.attributes.tags.find(
    (tag) => tag.tag_type === 'topic',
  )?.tag_values;
  return topics || [];
};

export const useListTopics = () => {
  const { data: educationResources } = useCatalog();
  const topics = computed(() => {
    const allTopics = (educationResources.value ?? []).flatMap(listTopics);
    const uniqueTopics = Array.from(new Set(allTopics));
    return uniqueTopics;
  });
  const { suspense } = useSuspense(() => topics.value.length > 0);

  return { topics, suspense };
};
