<template>
  <BaseDropdown
    v-if="!isLoading && data"
    class="learning-path-selector"
    close-on-click
    data-snyk-test="LearningPathSelector"
  >
    <template #handle>
      <BaseButton size="medium">
        {{ handleText }}
        <template #rightIcon><MenuDownIcon /></template>
      </BaseButton>
    </template>
    <BaseDropdownMenu
      class="learning-path-selector__dropdown-menu"
      data-snyk-test="LearningPathSelectorPopup"
    >
      <BaseDropdownMenuItem
        v-for="lp in data['learning-paths']"
        :key="lp.learningPathId"
        class="learning-path-selector__learning-path"
      >
        <BaseDropdownMenuAction
          :selected="lp.slug === learningPathSlug"
          @click="
            () => {
              emit('learningPathSelected', lp.learningPathId);
              updateLearningPathSlug(lp.slug);
            }
          "
        >
          {{ lp.title }}
        </BaseDropdownMenuAction>
      </BaseDropdownMenuItem>
    </BaseDropdownMenu>
  </BaseDropdown>
</template>

<script setup lang="ts">
import { computed, watchEffect } from 'vue';
import BaseDropdownMenuAction from '@patchui/productcl/components/BaseDropdownMenuAction/BaseDropdownMenuAction.vue';
import BaseDropdownMenuItem from '@patchui/productcl/components/BaseDropdownMenuItem/BaseDropdownMenuItem.vue';
import BaseDropdownMenu from '@patchui/productcl/components/BaseDropdownMenu/BaseDropdownMenu.vue';
import BaseDropdown from '@patchui/productcl/components/BaseDropdown/BaseDropdown.vue';
import { useLearningPaths } from '../TabLearningPath/useLearningPaths';
import useAdminDashboardQueryParams from '../../useAdminDashboardQueryParams';
import MenuDownIcon from '@patchui/icons/MenuDown.vue';
import BaseButton from '@patchui/productcl/components/BaseButton/BaseButton.vue';

interface ILearningPathSelectorProps {
  learningPathTitle: string;
}

const { learningPathSlug, updateLearningPathSlug } =
  useAdminDashboardQueryParams();

const props = defineProps<ILearningPathSelectorProps>();

const { data, isLoading, error } = useLearningPaths();

const emit = defineEmits<{
  (event: 'learningPathSelected', newVal: string): void;
  (event: 'onError', error: Error): void;
}>();

watchEffect(() => {
  if (error.value && error.value instanceof Error) {
    emit('onError', error.value);
  }
});

const handleText = computed(() => {
  return props.learningPathTitle;
});
</script>

<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';

.learning-path-selector {
  border-radius: space(xxs);
  padding: 0 12px;
  border: 1px solid token('color.neutral.40');
  color: token('color.neutral.80');

  &.dropdown--open {
    border: 1px solid token('color.blue.30');
    color: token('color.brand.electric-blue');
    background-color: token('color.blue.10');
  }

  &__dropdown-handle {
    max-width: 25ex;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__learning-path {
    min-width: 300px;
  }

  &__dropdown-menu {
    max-height: 30vh;
    overflow-y: auto;
  }
}
</style>
