import axios from 'axios';
import {
  QueryClient,
  QueryClientConfig,
  VueQueryPlugin,
  VueQueryPluginOptions,
} from '@tanstack/vue-query';

// VueQueryPluginOptions is a union. This type forces one side of the union to be picked.
type VueQueryPluginOptionsWithClient = VueQueryPluginOptions & {
  queryClient: QueryClient;
};

export const queryClientOptions: QueryClientConfig = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 10 * 1000,
      retry: (attempt, err) => {
        if (axios.isAxiosError(err)) {
          const statusCode = err.response?.status ?? 0;

          // Dont retry 4xx class requests
          if (statusCode >= 400 && statusCode < 500) return false;
        }

        return attempt < 3;
      },
    },
  },
};

export const getVueQueryConfig: () => VueQueryPluginOptionsWithClient = () => ({
  queryClient: new QueryClient(queryClientOptions),
});

export const vueQueryPlugin = VueQueryPlugin;
