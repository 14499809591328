<template>
  <TableOfContents
    v-if="lessonTitle"
    :title="lessonTitle"
    :icon="selectedEcosystem || undefined"
    :contents="contents"
    :progress-loading="isLoadingProgress"
    v-bind="$attrs"
    @rowLinkClicked="tocLinkClicked"
  />
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, onServerPrefetch, toRefs } from 'vue';
import { useStore } from 'vuex';
import { restStepContainsAnAssessment } from '../../../lib/lessonStep';
import TableOfContents from '../../../components/TableOfContents/TableOfContents.vue';
import itly from '../../../lib/analytics/itly';
import { GET_LESSON_BASE_KEY, getLesson } from '../../../api/lessons';
import { useRouteParam } from '../../../hooks/useRouteParam';
import { MULTI_LESSON_PAGE_NAME, STEP_ID_PREFIX } from '../../../lib/constants';
import { selectedEcosystem } from '../lessonStore';

import type { LessonMetadata } from '../../../lib/lessonMetadata';
import type { TableOfContentsEntry } from '../../../components/TableOfContents/TableOfContents.interfaces';
import type { IStepProgress } from '../../../store/modules/progress';
import { components } from '../../../api/learn-backend-hidden-schema';
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/vue-query';
import { QueryMetaExtended } from '../../../types';

export interface LessonToCProps {
  lessonTitle: string;
  lessonId: string;
}

const props = defineProps<LessonToCProps>();
const { lessonTitle, lessonId } = toRefs(props);

const store = useStore();
const currentlyViewedStepId = computed<string | null>(
  () => store.getters['progress/currentlyViewedStepId'],
);
const lessonMetadata = computed<LessonMetadata>(() => {
  return store.getters['lessons/getLessonMetadata'](lessonSlug.value);
});

const lessonSlug = useRouteParam('lessonSlug', MULTI_LESSON_PAGE_NAME);
const queryKeyLessonId = computed(() => lessonMetadata.value?.lessonId);

const enabled = computed(() => !!lessonMetadata.value);
const { data: lessonContent, suspense } = useQuery<
  components['schemas']['LessonContent'],
  AxiosError
>({
  queryKey: [GET_LESSON_BASE_KEY, queryKeyLessonId],
  queryFn: () => getLesson(lessonMetadata.value.lessonId),
  staleTime: Infinity,
  enabled,
  meta: {
    isContentQuery: true,
  } as QueryMetaExtended,
});
onServerPrefetch(suspense);

const isMounted = ref(false);
onMounted(() => (isMounted.value = true));
const lessonProgress = computed<IStepProgress[]>(() => {
  // waiting for mount prevents hydration with user data
  if (isMounted.value && lessonId.value) {
    return store.getters['progress/lessonProgress'](lessonId.value);
  } else {
    return [];
  }
});

const contents = computed<TableOfContentsEntry[]>(() => {
  if (!lessonContent.value) return [];
  return lessonContent.value.steps.map(({ id: stepId, title, blocks }) => {
    const isAnAssessmentInStep = restStepContainsAnAssessment(blocks || []);
    const isStepCompleted = lessonProgress.value.some(
      (progress) => progress.stepId === stepId && progress.status === 'read',
    );
    const isActiveStep = currentlyViewedStepId.value === stepId;
    const readState = isStepCompleted ? 'read' : 'unread';
    const tableOfContentsEntry: TableOfContentsEntry = {
      id: stepId,
      title,
      href: `#${STEP_ID_PREFIX}${stepId}`,
      isHighlighted: isActiveStep,
      icon: isAnAssessmentInStep && readState !== 'read' ? 'quiz' : 'check',
      readState,
    };
    return tableOfContentsEntry;
  });
});

/**
 * Passed to the TOC's ReadIndicators to show that lesson progress
 * has not yet been loaded.
 */
const isLoadingProgress = computed<boolean>(
  () => store.getters['progress/isLoadingLessonProgress'],
);
const tocLinkClicked = ({ rowNumber }: { rowNumber: number }) => {
  if (selectedEcosystem.value && lessonTitle.value) {
    const payload = {
      eventSource: 'Learn',
      position: rowNumber,
      ecosystem: selectedEcosystem.value,
      lessonTitle: lessonTitle.value,
    } as const;
    itly.learnTocLinkIsClicked(payload);
  }
};
</script>
