import { LessonEcosystem, isValidEcosystem } from '../lessonMetadata';
import type { RouteLocationNormalized } from 'vue-router';

export function getEcosystemFromRoute(
  route: RouteLocationNormalized,
): LessonEcosystem {
  const ecosystemInParams = Array.isArray(route.params.ecosystem)
    ? route.params.ecosystem[0]
    : route.params.ecosystem;

  if (isValidEcosystem(ecosystemInParams)) {
    return ecosystemInParams;
  }
  const ecosystemInQuery = Array.isArray(route.query.ecosystem)
    ? route.query.ecosystem[0]
    : route.query.ecosystem;
  if (isValidEcosystem(ecosystemInQuery)) {
    return ecosystemInQuery;
  } else {
    return 'all';
  }
}

export function getOrgIdFromRoute(route: RouteLocationNormalized): string {
  const orgIdInParams = Array.isArray(route.params.orgId)
    ? route.params.orgId[0]
    : route.params.orgId;
  if (orgIdInParams) {
    return orgIdInParams;
  } else {
    return '';
  }
}
