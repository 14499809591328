import Axios, { isAxiosError } from 'axios';
import jwtDecode from 'jwt-decode';

import { Analytics } from '../analytics/analytics';
import { authenticatedClient, unauthenticatedClient } from '../apiClient';
import sessionLockoutState from '../../App/sessionLockoutSharedState';
import { resetApiAndAppDomains } from '../config/domains';

import type { JwtPayload } from 'jwt-decode';
import type { OpenApiResponse } from '../utils/type-utilities';
import type { components } from '../../api/learn-backend-v1-schema';

interface Tokens {
  accessToken: string;
  refreshToken: string;
}

export type SnykProject = components['schemas']['SnykProject'];
export type RegistryUser = components['schemas']['UserWithOrgs'];
// Number of seconds before the JWT actually expires that we'd like to refresh it
const EAGER_EXPIRE_TIME_SECS = 60;

export async function getCsrf() {
  const response = await unauthenticatedClient.get<
    OpenApiResponse<'getCsrfToken'>
  >('/v1/learn/csrf');
  if (response.data.csrfToken === undefined) {
    //TODO: capture with datadog frontend error monitoring
    window.console.error('No CSRF token returned from server');
  } else {
    Axios.defaults.headers.common['X-CSRF-TOKEN'] = response.data.csrfToken;
  }
}

export function isTokenExpired(accessToken: string): boolean {
  const decodedJwt = jwtDecode<JwtPayload>(accessToken);
  const currentTime = Date.now().valueOf() / 1000;

  return (decodedJwt.exp as number) < currentTime + EAGER_EXPIRE_TIME_SECS;
}

export async function refreshToken(): Promise<string | null> {
  const currentRefreshToken = localStorage.getItem('refreshToken');
  if (currentRefreshToken === null) return null;
  try {
    const response = await unauthenticatedClient.post<
      OpenApiResponse<'refreshAccessToken'>
    >('/v1/learn/auth/refresh', {
      refreshToken: currentRefreshToken,
    });

    localStorage.setItem('accessToken', response.data.access_token);
    if (response.data.refresh_token) {
      // If new refresh token is returned, update it in local storage
      localStorage.setItem('refreshToken', response.data.refresh_token);
    }
    return response.data.access_token;
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      sessionLockoutState.isLocked = true;
    }
    return null;
  }
}

export async function getTokens(id: string): Promise<Tokens> {
  const response = await unauthenticatedClient.post<
    OpenApiResponse<'getTokensByIdPost'>
  >(`/v1/learn/auth/tokens`, { id });

  if (response.data.accessToken === undefined) {
    throw new Error('Access token does not exist in server response');
  }

  if (response.data.refreshToken === undefined) {
    throw new Error('Refresh token does not exist in server response');
  }

  return {
    accessToken: response.data.accessToken,
    refreshToken: response.data.refreshToken,
  };
}

export function getAccessTokenSafe(): string | null {
  try {
    const token = window?.localStorage?.getItem('accessToken');
    return token;
  } catch (e) {
    return null;
  }
}

export function setTokensPostAuthFlow(
  accessToken: string,
  refreshToken: string,
): void {
  if (accessToken && refreshToken) {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
  }
}

export async function getUser(): Promise<RegistryUser | null> {
  if (!getAccessTokenSafe()) return null;

  const anonymousId = await Analytics.anonymousUserId();

  let route = `/v1/learn/user`;
  if (anonymousId) {
    route = `${route}?anonymous_id=${anonymousId}`;
  }

  const response = await authenticatedClient.get<
    OpenApiResponse<'getAuthenticatedUser'>
  >(route);
  return response.data;
}

export async function fetchUserProjects(): Promise<Array<SnykProject>> {
  const route = `/v1/learn/user/projects`;
  const response = await authenticatedClient.get<
    OpenApiResponse<'getUserProjects'>
  >(route);
  return response.data.projects || [];
}

export function clearAuthState(): void {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  resetApiAndAppDomains();
}

export interface AuthLinkArguments {
  /**
   * Where in the page is the CTA?
   *  If you want to add a new origin, make sure you also add it in this notion doc: https://www.notion.so/snyk/Tracking-Parameter-Guide-be1e3d81a205419ea6b9f5117dab2f0a
   */
  origin?: 'banner' | 'sidebar' | 'nav' | 'footer' | 'body' | 'filter-dropdown';
  /**
   * The intent of the user when visiting app.snyk.io.
   * `login` & `signup` take the user to the snyk auth flow, `product` takes the user to the snyk app homepage
   *  If you want to add a new intent, make sure you also add it in this notion doc: https://www.notion.so/snyk/Tracking-Parameter-Guide-be1e3d81a205419ea6b9f5117dab2f0a
   */
  intent: 'login' | 'signup' | 'product';
  page?: string;
  learnRedirectPath?: string;
  learnRedirectFragment?: string;
  apiDomain: string;
}

export const buildAuthLink = (args: AuthLinkArguments) => {
  const url = new URL(`${args.apiDomain}/v1/learn/auth`);
  url.searchParams.set('cta', args.intent);
  url.searchParams.set('page', args.page || 'learn-unknown');
  if (args.origin) {
    url.searchParams.set('loc', args.origin);
  }
  if (args.learnRedirectPath) {
    url.searchParams.set('learn_redirect_path', args.learnRedirectPath);
  }
  if (args.learnRedirectFragment) {
    url.searchParams.set('learn_redirect_fragment', args.learnRedirectFragment);
  }
  return url.toString();
};
