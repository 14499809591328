<template>
  <section class="box">
    <div class="box__header">
      <div class="box__header__icon">
        <component :is="icon" v-if="icon" :size="16" />
      </div>
      <div class="box__header__title">
        <slot name="title"></slot>
      </div>
    </div>
    <div v-if="slots.body">
      <slot name="body"></slot>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { toRefs, computed, useSlots } from 'vue';
import LaptopIcon from '@patchui/icons/Laptop.vue';
import TextBoxCheck from '@patchui/icons/TextBoxCheck.vue';

interface AttentionBoxProps {
  iconName: 'text-box-check' | 'laptop';
}
const props = defineProps<AttentionBoxProps>();

const { iconName } = toRefs(props);
const slots = useSlots();
const ICON_MAP = {
  'text-box-check': TextBoxCheck,
  laptop: LaptopIcon,
} as const;

const icon = computed(() => ICON_MAP[iconName.value]);
</script>
<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';

.box {
  border: 1px solid token('color.neutral.20');
  width: 100%;
  border-radius: token('radius.medium');

  &__header {
    display: flex;
    align-items: center;
    padding: 16px 20px;
    background-color: token('color.brand.snow');
    border-bottom: 1px solid token('color.neutral.20');

    &__icon {
      background: linear-gradient(token('gradient.vibe-to-dark-teal'));
      color: white;
      width: 24px;
      height: 24px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      flex-shrink: 0;
      margin-right: 20px;
    }

    &__title {
      color: token('color.brand.midnight');
      @include typography('typography.brand.subhead-small');
    }
  }
}
</style>
