<template>
  <CTABox
    v-if="userType === 'guest'"
    class="account-cta"
    title="Personalize your experience"
    :body="[
      'Create your profile',
      'Track your progress',
      'Get new lesson notifications',
    ]"
  >
    <template #button>
      <SnykAppCta
        text="Sign up for free"
        origin="sidebar"
        intent="signup"
        :is-full-width="true"
      />
    </template>
  </CTABox>
  <CTABox
    v-else-if="userType === 'new'"
    class="account-cta"
    title="Is your project secure?"
    body="Find and fix this vulnerability — and millions more — in your projects with Snyk."
  >
    <template #button>
      <SnykAppCta
        text="Scan for free"
        origin="sidebar"
        intent="product"
        :is-full-width="true"
      />
    </template>
  </CTABox>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import CTABox from './CTABox.vue';

import { useStore } from 'vuex';
import SnykAppCta from '../Button/SnykAppCta.vue';

const store = useStore();

const userType = computed(() => {
  const user = store.getters['user/getLoggedInUser'];
  if (!user) {
    return 'guest';
  } else {
    const userProjects = store.getters['user/getUserProjects'];
    if (!userProjects.length) {
      return 'new';
    } else {
      return 'experienced';
    }
  }
});
</script>

<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';
.account-cta {
  margin-top: space(l);
}
</style>
