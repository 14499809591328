<template>
  <div class="education-journey">
    <h2>Be in control of your own security<br />education journey</h2>
    <div class="education-journey__content">
      <div class="description">
        <section>
          <h3>Learn from experts for free</h3>
          <span>
            An intuitive learning tool that empowers developers to learn
            security from industry experts for free.
          </span>
        </section>
        <section>
          <h3>Learn when it’s relevant</h3>
          <span>
            Bite-sized, instant learning content, curated to help you find and
            focus on just what you need when you need it.
          </span>
        </section>
        <section>
          <h3>Learn on your own code</h3>
          <span>
            Learn about security based on issues found in your own code, so you
            can understand, fix, and avoid vulnerabilities.
          </span>
        </section>
      </div>
      <img
        alt="Snyk Learn education journey"
        src="https://res.cloudinary.com/snyk/image/upload/v1699638399/illustration-ui-spot-snyk-learn-sql-injection.svg"
        loading="lazy"
      />
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';

.education-journey {
  h2 {
    @include typography('typography.brand.heading3');
    color: token('color.brand.midnight');
    text-align: center;
    margin-bottom: space(m);

    @include media-query(medium) {
      @include typography('typography.brand.heading2');
      margin-bottom: 58px;
    }
  }

  h3 {
    color: token('color.brand.midnight');

    @include typography('typography.brand.subhead-small');
    color: token('color.brand.midnight');
    text-align: center;
    margin-bottom: token('space.xs');

    @include media-query(large) {
      text-align: left;
      @include typography('typography.brand.subhead');
    }
  }

  &__content {
    display: flex;
    flex-direction: column-reverse;

    @include media-query(large) {
      flex-direction: row;
    }
    img {
      margin-bottom: space(l);

      @include media-query(large) {
        margin-bottom: 0;
      }
    }
  }
  .description {
    section {
      margin-bottom: 32px;
      font-weight: 400;
      font-family: token('typography.family.poppins');
      font-size: rem(16px);
      line-height: rem(28px);
      color: token('color.brand.midnight');
      text-align: center;
      padding: 0 space(xs);

      @include media-query(large) {
        padding-right: space(xxl);
        text-align: left;
        margin-bottom: 45px;
      }

      ::v-deep(.heading--3) {
        font-weight: 600;
        font-size: rem(24px);
        font-family: token('typography.family.poppins');
        color: token('color.brand.midnight');
      }
    }
  }
}
</style>
