import { computed } from 'vue';
import { beforeNavigationChange } from '../../../../lib/utils/navigation';
import {
  setApiDomain,
  setAppDomain,
  apiDomain,
} from '../../../../lib/config/domains';
import itly, {
  EnvironmentIsSelectedProperties,
} from '../../../../lib/analytics/itly';
import { useSnykAppCTA } from '../../../../hooks/useSnykAppCTA';

import type { PolarisEnvironmentConfig } from '../../../../lib/config/env';

export const handleLoginClick = (
  e: KeyboardEvent | PointerEvent | MouseEvent,
  apiDomain: string,
  appDomain: string,
) =>
  beforeNavigationChange(async () => {
    setApiDomain(apiDomain);
    setAppDomain(appDomain);

    itly.learnButtonIsClicked({
      origin: `${window.location.origin}${window.location.pathname}`,
      action: 'login',
    });
    itly.environmentIsSelected({
      appDomain: appDomain as EnvironmentIsSelectedProperties['appDomain'],
    });
  })(e);

export const mapEnvironmentToDropdownItem = (
  polarisEnvironment: PolarisEnvironmentConfig,
) => {
  const apiDomainSelected = computed(
    () => apiDomain.value === polarisEnvironment.apiDomain,
  );
  const { href: loginHref } = useSnykAppCTA({
    intent: 'login',
    origin: 'nav',
    title: polarisEnvironment.label,
    apiDomain: polarisEnvironment.apiDomain,
    appDomain: polarisEnvironment.appDomain,
  });

  return {
    selected: apiDomainSelected,
    label: polarisEnvironment.label,
    labelWithEmoji: `${polarisEnvironment.emoji} ${polarisEnvironment.label}`,
    name: polarisEnvironment.name,
    emoji: polarisEnvironment.emoji,
    loginHref,
    apiDomain: polarisEnvironment.apiDomain,
    appDomain: polarisEnvironment.appDomain,
    login: polarisEnvironment.label,
  };
};
