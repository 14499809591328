<template>
  <div
    :class="lessonLinkIndicatorClasses"
    :aria-label="indicatorAccessibilityLabel"
    :title="indicatorAccessibilityLabel"
  >
    <CheckIcon :size="10" role="presentation" />
  </div>
</template>

<script lang="ts" setup>
import { computed, toRefs } from 'vue';
import CheckIcon from '@patchui/icons/Check.vue';

export interface ILessonLinkProps {
  title: string;
  state: 'todo' | 'inProgress' | 'completed';
}
const props = defineProps<ILessonLinkProps>();
const { title: lessonTitle, state } = toRefs(props);

const lessonLinkIndicatorClasses = computed(() => {
  return {
    lessonLinkIndicator: true,
    'lessonLinkIndicator--partial': state.value === 'inProgress',
    'lessonLinkIndicator--read': state.value === 'completed',
  };
});

const readStateToReadableName: Record<ILessonLinkProps['state'], string> = {
  todo: 'todo',
  inProgress: 'in progress',
  completed: 'completed',
};

const indicatorAccessibilityLabel = computed(() => {
  return `The lesson ${lessonTitle.value} is marked as ${
    (state.value && readStateToReadableName[state.value]) || 'unknown'
  }`;
});
</script>

<style lang="scss" scoped>
@import '~@/utils.scss';
@import '~@/variables.scss';

.lessonLinkIndicator {
  color: white;
  width: 16px;
  height: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #d3d3d9;
  transition: background-color 250ms;
  flex-shrink: 0;

  &--read {
    background-color: $darkTeal;
    border: 1px solid transparent;
  }

  &--partial {
    color: $successBorder;
    border: 1px solid $successBorder;
  }

  > * {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}

@keyframes pop {
  0% {
    box-shadow: 0 0 0 0 $successBorder;
  }

  70% {
    box-shadow: 0 0 3.5px 3.5px change-color($successBorder, $alpha: 0.8);
  }

  100% {
    box-shadow: 0 0 5px 5px change-color($successBorder, $alpha: 0.1);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
