import { Cloudinary } from '@cloudinary/url-gen';
import { Resize } from '@cloudinary/url-gen/actions/resize';
import { predominant } from '@cloudinary/url-gen/qualifiers/background';

const cld = new Cloudinary({
  cloud: {
    cloudName: 'snyk',
  },
  url: {
    secure: true,
  },
});

const aspectRatio = {
  twitter: '2',
  og: '1.91',
};

type OpenGraphSocialImageFormat = keyof typeof aspectRatio;

export const prepareOpenGraphImage = function (
  imagePath: string,
  format: OpenGraphSocialImageFormat,
) {
  if (imagePath.indexOf('images.ctfassets.net') >= 0) {
    const width = 1200;
    let height = 630;

    if (format === 'twitter') {
      height = 600;
    }

    return `${imagePath}?fm=png&w=${width}&h=${height}&fit=pad&bg=rgb:21214C`;
  }

  const image = cld.image(imagePath);
  const ratioRule = aspectRatio[format];
  const backgroundRule = predominant();

  const imageTransformed = image
    .resize(Resize.pad().aspectRatio(ratioRule).background(backgroundRule))
    .format('png');

  return imageTransformed.toURL();
};
