export const errorContentOptions = {
  '403': {
    title: "You can't create reports for this organization.",
    body: 'Contact your admin to request the relevant role and permissions.',
  },
  '404': {
    title: 'Unable to display this organization.',
    body: 'The organization does not exist, or you do not have permissions to access it.',
  },
  '500': {
    title: 'Sorry something went wrong.',
    body: 'We have already been alerted about it. Please contact support if this persists.',
  },
};
