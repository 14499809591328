<template>
  <main>
    <Hero />
    <div class="max-page-width">
      <EducationJourney />
      <ProductTraining />
      <SecurityEducationBanner />
      <LatestLessons origin="home">
        <template #title>Latest Lessons</template>
      </LatestLessons>
    </div>
  </main>
</template>
<script lang="ts" setup>
import Hero from './Hero/Hero.vue';
import ProductTraining from './ProductTraining/ProductTraining.vue';
import SecurityEducationBanner from './SecurityEducationBanner/SecurityEducationBanner.vue';
import EducationJourney from './EducationJourney/EducationJourney.vue';
import LatestLessons from './LatestLessons/LatestLessons.vue';
import { useHead } from '@vueuse/head';

const seoKeywords = [
  'security training for developers',
  'developer security training',
  'secure development training',
  'snyk learn',
  'snyk training',
];
useHead({
  title: 'Snyk Learn | Interactive Secure Development Lessons',
  meta: [
    {
      key: 'description',
      name: 'description',
      content:
        'Snyk Learn offers developer security training with interactive lessons on how to find and fix vulnerabilities, and using Snyk for security.',
    },
    {
      key: 'keywords',
      name: 'keywords',
      content: seoKeywords.join(', '),
    },
  ],
});
</script>
<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';
@import '~@/mixin';

.max-page-width {
  width: 100%;
  margin: 0 auto rem(60px);
  padding: rem($mobileContainerPadding);

  @include media-query(large) {
    padding: rem($containerPadding);
    max-width: $pageMaxWidth;
    margin-bottom: rem(120px);
  }
}
</style>
