const escapeCsvDelimiter = (value: string): string => {
  if (
    value.includes(',') ||
    value.includes('\n') ||
    value.includes('"') ||
    value.includes("'")
  ) {
    return `"${value.replace(/"/g, '""')}"`; // Surround with quotes
  }
  return value;
};

export const jsonArrayToCsv = (
  headers: { dataKey: string; title: string }[],
  jsonArray: Record<string, string | number | undefined>[],
) => {
  if (jsonArray.length === 0) {
    return '';
  }
  const rows = [];
  const dataKeys = headers.map((header) => header.dataKey);
  const headerRow = headers.map((header) => header.title).join(',');
  rows.push(headerRow);
  for (const item of jsonArray) {
    const dataRow = dataKeys
      .map((key) => {
        const value = item[key] ?? '';
        return escapeCsvDelimiter(`${value}`);
      })
      .join(',');
    rows.push(dataRow);
  }
  return rows.join('\n');
};

export const downloadCsv = (csvData: string, filename: string) => {
  const blob = new Blob([csvData], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);

  // Trigger file download
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();
  URL.revokeObjectURL(url);
};
