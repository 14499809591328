import { EducationResource } from '../../api/catalog';

// https://stackoverflow.com/a/6969486
export const escapeRegExp = (str: string): string => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
};

export const replaceSymbolsWithSpaces = (str: string) => {
  return str.replace(/[-_]/g, ' ');
};

const TAG_TYPES_TO_SEARCH_FOR = ['cve', 'swe', 'snyk_vuln_id', 'ecosystem'];

export const haystackFilter = (
  educationResource: EducationResource,
  searchRegex: RegExp,
) => {
  const tagResults: Record<
    string,
    { tag_type: string; tag_values: string[] } | undefined
  > = {};
  TAG_TYPES_TO_SEARCH_FOR.forEach((tagType: string) => {
    const foundTag = educationResource.attributes.tags.find(
      (tag) => tag.tag_type === tagType,
    );
    if (foundTag) {
      tagResults[tagType] = foundTag;
    }
  });

  const educationResourceTagsValues = Object.keys(tagResults)
    .reduce((result: string[], key) => {
      return [...result, ...tagResults[key]!.tag_values];
    }, [])
    .join(' ');

  const haystack = [
    educationResource.attributes.name,
    educationResource.attributes.description,
    educationResourceTagsValues,
  ].join(' ');

  try {
    const haystackWithoutSymbols = replaceSymbolsWithSpaces(haystack);
    return !!searchRegex.test(haystackWithoutSymbols);
  } catch {
    // Return no results if we're given an invalid regex.
    // This generally shouldn't be able to happen as we escape the regex input,
    // but it's worth handling just in case.
    return false;
  }
};
